import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { useComponentStateUpdateMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  SiteComponentState,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

interface Props {
  state: SiteComponentState;
  commandName: string;
  iconName: string;
  siteId: string;
}

const ModifySiteAgentStateCommandButton = ({
  siteId,
  state,
  commandName,
  iconName
}: Props) => {
  const commandRunner = useCommandRunner();

  const mutation = useComponentStateUpdateMutation();
  const handleUpdateState = () =>
    commandRunner.runMutation(commandName, mutation, {
      siteId,
      componentType: SiteComponentType.SiteAgent,
      state
    });

  return (
    <SiteCommandButton
      commandName={commandName}
      onClick={handleUpdateState}
      iconName={iconName}
    />
  );
};

export const DownloadSiteAgentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteAgentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Download}
      commandName="Download site agent"
      iconName="DownloadDocument"
    />
  );
};

export const InstallSiteAgentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteAgentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Install}
      commandName="Install site agent"
      iconName="AddLink"
    />
  );
};

export const UninstallSiteAgentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteAgentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Uninstall}
      commandName="Uninstall site agent"
      iconName="RemoveLink"
    />
  );
};

export const UpdateSiteAgentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteAgentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Update}
      commandName="Apply update to site agent"
      iconName="Installation"
    />
  );
};
