import { SideNavLayout } from "@layouts/SideNavLayout";
import { UsersRoutes } from "modules/users/UsersRoutes";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ExpandableNav, ExpandableNavItem } from "@components/ExpandableNav";
import { UsersTabs } from "./users-tabs.constants";

export const UsersSection: React.FC = () => {
  const { sectionId } = useParams();
  const navigate = useNavigate();
  const items: ExpandableNavItem[] = [
    { name: "Consumer Users", links: Object.values(UsersTabs.ConsumerUsers) },
    { name: "Business Users", links: Object.values(UsersTabs.BusinessUsers) }
  ];
  return (
    <SideNavLayout
      nav={
        <ExpandableNav
          selectedKey={sectionId}
          onSectionChange={navigate}
          items={items}
        />
      }
    >
      <UsersRoutes />
    </SideNavLayout>
  );
};
