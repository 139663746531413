import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";

import { FilterBar, Item, Stack } from "@bps/fluent-ui";

import { OutboundSmsAuditTable } from "./OutboundSmsAuditTable";
import { OutboundSmsAuditArgs } from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface OutboundSmsAuditScreenProps {
  tenant: Tenant;
}

const OutboundSmsAuditScreenBase: React.FC<OutboundSmsAuditScreenProps> = ({
  tenant
}) => {
  const LAST_X_DAYS: number = 7;
  const defaultFromDate = new Date();
  defaultFromDate.setDate(defaultFromDate.getDate() - LAST_X_DAYS);
  const defaultToDate = new Date();
  const items: Item<OutboundSmsAuditArgs>[] = [
    {
      type: "searchBox",
      name: "phone",
      stickItem: true,
      props: {
        placeholder: "Search by phone",
        removeSpecialCharacters: false
      }
    },
    {
      type: "datesRangePicker",
      name: "datesRangePicker",
      stickItem: true,
      props: {
        id: "reminder-filter-dates-range",
        startDateProps: { id: "reminder-filter-start-date" },
        endDateProps: { id: "reminder-filter-end-date" },
        startDateName: "fromDate",
        endDateName: "toDate",
        placeholder: "Date(s)"
      }
    }
  ];

  return (
    <Stack styles={{ root: { height: "100%" } }}>
      <SectionTitle>Outbound SMS Audit</SectionTitle>
      <FilterBar<OutboundSmsAuditArgs>
        items={items}
        initialValues={{
          tenantId: tenant.id,
          fromDate: defaultFromDate,
          toDate: defaultToDate
        }}
      >
        {({ values: filter }) => <OutboundSmsAuditTable filter={filter} />}
      </FilterBar>
    </Stack>
  );
};

export const OutboundSmsAuditScreen = withPermissions(
  OutboundSmsAuditScreenBase,
  [Permissions.PltCommsCreditsWrite]
);
