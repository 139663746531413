import { InviteFormDialog } from "modules/_shared-blocks/InviteFormDialog";
import { useParams } from "react-router-dom";
import { IDialogProps } from "@bps/fluent-ui";
import { CustomerAccountUser } from "@libs/api/gateways/cam/cam-gateway.dtos";

interface InviteModalProps extends Pick<IDialogProps, "onDismiss"> {
  user: CustomerAccountUser | undefined;
  tenantId: string;
}

export const InviteModal = ({
  user: initialUser,
  tenantId,
  onDismiss
}: InviteModalProps) => {
  const { crmId } = useParams<{ crmId: string }>();

  return (
    <InviteFormDialog
      tenantId={tenantId}
      userId={initialUser?.tenantUserId}
      bpIdUserId={initialUser?.bpIdUserId}
      crmId={crmId}
      crmContactId={initialUser?.crmContactId}
      firstName={initialUser?.firstName}
      lastName={initialUser?.lastName}
      onDismiss={onDismiss}
    />
  );
};
