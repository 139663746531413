import { FontIcon, Image, useTheme } from "@bps/fluent-ui";
import mastercard from "assets/mastercard.svg";
import visa from "assets/visa.svg";
import { FunctionComponent } from "react";
import {
  CardBrand,
  PaymentType
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface PaymentCardImageProps {
  brand: CardBrand;
  type: PaymentType;
}

export const PaymentCardImage: FunctionComponent<PaymentCardImageProps> = ({
  brand,
  type
}) => {
  const theme = useTheme();
  if (type.toLowerCase() === PaymentType.Becs)
    return (
      <FontIcon
        iconName="Bank"
        styles={{
          root: {
            color: theme.palette.themePrimary,
            fontSize: 24,
            paddingLeft: 6
          }
        }}
      />
    );

  const getCardImageSrc = () => {
    switch (brand.toLowerCase()) {
      case CardBrand.Visa:
        return visa;
      case CardBrand.Master:
        return mastercard;
      default:
        return undefined;
    }
  };
  return (
    <Image width={36} height={24} src={getCardImageSrc()} alt="Card brand" />
  );
};
