import { dangerButtonStyles } from "@components/buttons/DangerButton";
import React, { useState } from "react";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";

interface DeleteUserDialogProps extends Omit<ConfirmDialogProps, "onConfirm"> {
  firstname: string;
  surname: string;
  onDelete: () => void;
}

export const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({
  firstname,
  surname,
  onDelete,
  onCancel,
  confirming,
  ...props
}) => {
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>("");
  const [deleting, setDeleting] = useState<boolean>(false);

  const fullName = `${firstname} ${surname}`;

  const onConfirm = async () => {
    setDeleting(true);
    try {
      onDelete();
      onCancel?.();
    } catch (e) {}
    setDeleting(false);
  };

  return (
    <ConfirmDialog
      dialogContentProps={{
        title: "Confirm Delete User"
      }}
      confirmButtonProps={{
        text: "Delete",
        styles: dangerButtonStyles(theme),
        disabled: name !== fullName || deleting
      }}
      cancelButtonProps={{ text: "No" }}
      {...props}
      confirming={confirming || deleting}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          <Text>Please enter the user name to confirm:</Text>
          <Text>{fullName}</Text>
        </Stack>
        <TextField value={name} onChange={(e, value) => setName(value)} />
      </Stack>
    </ConfirmDialog>
  );
};
