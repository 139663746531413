import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";
import React, { FunctionComponent, useState } from "react";

import { Dialog } from "@bps/fluent-ui";
import { useCreateCertificate } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface RegisterCertificateDialogProps {
  tenant: Tenant;
  onDismiss: () => void;
}

export interface RegisterCertificateFormValues {
  content: string;
}

export const RegisterCertificateDialog: FunctionComponent<RegisterCertificateDialogProps> = ({
  tenant,
  onDismiss
}) => {
  const {
    mutateAsync: createCertificate,
    error: createCertificateError
  } = useCreateCertificate();

  const onSubmit = async () => {
    if (!file) return;
    await createCertificate({
      content: await file.text(), //.Cer files are base64 encoded
      tenantId: tenant.id
    });
    onDismiss();
  };

  const [file, setFile] = useState<File>();

  const defaultValues = {
    content: ""
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Register Public Certificate",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<RegisterCertificateFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={createCertificateError}
        submitButtonText="Upload"
      >
        <FormTextField
          name="content"
          type="File"
          label="Name (.cer extensions only)"
          required
          rules={{ required: "File is required" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (files && files?.length > 0) {
              setFile(files[0]);
            }
          }}
          accept=".cer,application/pkix-cert"
        />
      </Form>
    </Dialog>
  );
};
