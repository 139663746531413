import { DataTable, DataTableColumn } from "@components/tables/DataTable";

import { SelectionMode } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

import { toLocalTime } from "../utils";
import {
  PowerBiDataset,
  PowerBiDatasetRefresh
} from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";

interface Props {
  dataset: PowerBiDataset;
}

const getKey = (refreshes: PowerBiDataset) => refreshes.id ?? "";

export const ReportDetailsTable = ({ dataset }: Props) => {
  const timeToCompleteRefresh = (refresh: PowerBiDatasetRefresh) => {
    if (refresh.startTime && refresh.endTime) {
      const startDateTime = DateTime.fromISO(refresh.startTime);
      const endDateTime = DateTime.fromISO(refresh.endTime);
      const diff = endDateTime.diff(startDateTime, "seconds");
      return `${diff.seconds.toFixed(2)} secs`;
    }
    return "";
  };

  const columns: DataTableColumn<PowerBiDatasetRefresh>[] = [
    {
      key: "startTime",
      name: "Start time (local)",
      fieldName: "startTime",
      maxWidth: 250,
      onRender: (refresh: PowerBiDatasetRefresh) =>
        toLocalTime(refresh.startTime)
    },
    {
      key: "endTime",
      name: "End time (local)",
      fieldName: "endTime",
      maxWidth: 250,
      onRender: (refresh: PowerBiDatasetRefresh) => toLocalTime(refresh.endTime)
    },
    {
      key: "timeToComplete",
      name: "Duration",
      maxWidth: 250,
      onRender: (refresh: PowerBiDatasetRefresh) =>
        timeToCompleteRefresh(refresh)
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      maxWidth: 250
    },
    {
      key: "exception",
      name: "Exception",
      fieldName: "exception",
      maxWidth: 250
    }
  ];

  return (
    <DataTable
      items={dataset.refreshes}
      columns={columns}
      getKey={getKey}
      selectionMode={SelectionMode.none}
    />
  );
};
