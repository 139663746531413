import { FunctionComponent } from "react";
import { FilterBar } from "@components/filter-bar/FilterBar";
import { GetComponentsArgs } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FilterBarProps, Item } from "@components/filter-bar/FilterBar.types";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";

export interface ComponentsFilterValues
  extends Pick<GetComponentsArgs, "code"> {}

const SCOPE_TO_EXCLUDE = "Environment";
interface ComponentsFilterProps
  extends Pick<FilterBarProps<ComponentsFilterValues>, "children"> {}
export const ComponentsFilter: FunctionComponent<ComponentsFilterProps> = props => {
  const { data = [] } = useComponentsDefs();
  const items: Item<ComponentsFilterValues>[] = [
    {
      name: "code",
      stickItem: true,
      type: "optionsSelect",
      props: {
        options: data
          .filter(d => d.scope !== SCOPE_TO_EXCLUDE)
          .map(d => ({ key: d.code, text: d.text })),
        placeholder: "Search by code",
        styles: { root: { width: 250 } }
      }
    }
  ];
  return <FilterBar<ComponentsFilterValues> items={items} {...props} />;
};
