import { withPermissions } from "@components/withPermissions";
import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { IdentityFilter } from "modules/users/components/IdentityFilter";
import { ConsumerUserIdentitiesTable } from "modules/users/ConsumerUser/ConsumerUserIdentitiesTable";
import React, { useState } from "react";

import {
  CenteredBox,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { Permissions } from "@libs/permissions/permissions.enum";
import { useCUIdentitySearch } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";

const ConsumerUserIdentitiesBase: React.FC = () => {
  const theme = useTheme();
  const [formValues, setFormValues] = useState<BpIdUsersSearchArgs>();
  const { data, isFetching } = useCUIdentitySearch(formValues);

  return (
    <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
      <IdentityFilter
        onSubmit={setFormValues}
        isLoading={isFetching}
        showProfile={true}
      />
      {data?.tooManyResults === true && (
        <MessageBar messageBarType={MessageBarType.error}>
          Your search returned too many results, please refine your search
          paramters.
        </MessageBar>
      )}
      <Stack verticalFill>
        {data?.records?.length || isFetching ? (
          <ConsumerUserIdentitiesTable
            enableShimmer={isFetching}
            items={data?.records || []}
          />
        ) : (
          <CenteredBox>
            <Text>No Consumer Users found</Text>
          </CenteredBox>
        )}
      </Stack>
    </Stack>
  );
};

export const ConsumerUserIdentities = withPermissions(
  ConsumerUserIdentitiesBase,
  [Permissions.PltCatalogUserRead, Permissions.PltCatalogUserWrite],
  "or"
);
