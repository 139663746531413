import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { useCheckUpdateMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

export const CheckUpdateCommandButton = (props: { siteId: string }) => {
  const { siteId } = props;

  const commandRunner = useCommandRunner();
  const mutation = useCheckUpdateMutation();

  const handleClick = () =>
    commandRunner.runMutation("Site Manager Check Update", mutation, siteId);

  return (
    <SiteCommandButton
      commandName="Site Manager Check Update"
      onClick={handleClick}
      iconName="Installation"
    />
  );
};
