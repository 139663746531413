import { TenantPicker } from "@components/pickers/TenantPicker";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent, useState } from "react";

import { Stack } from "@bps/fluent-ui";
import { ApiCallButton } from "@components/buttons/ApiCallButton";
import { useUpdateTenantCustomerAccount } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface AssignTenantFormProps {
  tenantId: string;
  country: string;
  assignChildTenant?: boolean;
  excludeKeys?: string[];
}
export const AssignTenantForm: FunctionComponent<AssignTenantFormProps> = ({
  tenantId,
  assignChildTenant,
  country,
  excludeKeys = []
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string | undefined>(
    undefined
  );

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogTenantWrite
  );

  const {
    mutateAsync,
    isLoading: isLoadingUpdateTenant
  } = useUpdateTenantCustomerAccount();

  return (
    <Stack
      horizontal
      horizontalAlign="start"
      verticalAlign="center"
      tokens={{ childrenGap: 8 }}
    >
      <TenantPicker
        excludeKeys={[...excludeKeys, tenantId]}
        applications={
          assignChildTenant
            ? [ApplicationType.OMNI, ApplicationType.PROS]
            : [ApplicationType.CAM]
        }
        selectedKeys={selectedKeys}
        country={country}
        onChangeSelectedKeys={(key: string | undefined) => setSelectedKeys(key)}
      />
      <ApiCallButton
        error={undefined}
        isLoading={isLoadingUpdateTenant}
        disabled={!selectedKeys && !hasWritePermission}
        primary
        onClick={async () => {
          if (selectedKeys) {
            const payload = {
              tenantId: assignChildTenant ? selectedKeys : tenantId,
              customerTenantId: assignChildTenant ? tenantId : selectedKeys
            };
            await mutateAsync(payload);
          }
        }}
      >
        {!hasWritePermission ? NO_ACTION_PERMISSION : "Save"}
      </ApiCallButton>
    </Stack>
  );
};
