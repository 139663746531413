import { FunctionComponent } from "react";
import {
  ComponentDto,
  ComponentImplementationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { DetailsList, IColumn, NoDataTile } from "@bps/fluent-ui";
import { ComponentText } from "../ComponentText";

interface ComponentImplementationProps {
  component: ComponentDto;
}
export const ComponentImplementation: FunctionComponent<ComponentImplementationProps> = ({
  component
}) => {
  if (!component.implementations?.length) {
    return (
      <NoDataTile
        textProps={{ text: "No implementations" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
      />
    );
  }

  const columns: IColumn[] = [
    {
      key: "code",
      name: "Code",
      minWidth: 100,
      onRender: (item: ComponentImplementationDto) => item.code
    },
    {
      key: "text",
      name: "Text",
      minWidth: 100,
      onRender: (item: ComponentImplementationDto) => (
        <ComponentText code={item.code} />
      )
    },
    {
      key: "status",
      name: "Status",
      minWidth: 100,
      onRender: (item: ComponentImplementationDto) => item.status
    }
  ];
  return (
    <DetailsList items={component.implementations} columns={columns} compact />
  );
};
