import { LicenceFormValues } from "./LicenceFormDialog.types";
import { LicenceDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const getLicenceFormValues = (dto: LicenceDto): LicenceFormValues => {
  return {
    productId: dto.productId,
    userId: dto.userId,
    username: dto.username,
    expiryDate: new Date(dto.expiryDate),
    isActive: !dto.isInactive,
    licenceTypeCode: dto.licenceTypeCode,
    name: dto.name,
    displayName: dto.displayName,
    description: dto.description,
    doNotRenew: dto.doNotRenew
  };
};
