import { dangerButtonStyles } from "@components/buttons/DangerButton";
import React, { useState } from "react";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";

export interface TenantIsInactiveChangeDialogProps
  extends Omit<ConfirmDialogProps, "onConfirm"> {
  tenantName: string;
  changeIsInactiveToValue: boolean;
  onChange: () => void;
}

export const TenantIsInactiveChangeDialog: React.FC<TenantIsInactiveChangeDialogProps> = ({
  tenantName,
  changeIsInactiveToValue,
  onChange,
  onCancel,
  confirming,
  ...props
}) => {
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>("");
  const [changing, setChanging] = useState<boolean>(false);

  const onConfirm = async () => {
    setChanging(true);
    try {
      onChange();
      onCancel?.();
    } catch (e) {}
    setChanging(false);
  };

  const modeName = changeIsInactiveToValue === true ? "Activate" : "Deactivate";

  const title = `Confirm ${modeName} Tenant`;

  return (
    <ConfirmDialog
      dialogContentProps={{
        title
      }}
      confirmButtonProps={{
        text: modeName,
        styles: dangerButtonStyles(theme),
        disabled: name !== tenantName || changing
      }}
      cancelButtonProps={{ text: "No" }}
      {...props}
      confirming={confirming || changing}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          <Text>Please enter the tenant name to confirm:</Text>
          <Text>{tenantName}</Text>
        </Stack>
        <TextField value={name} onChange={(e, value) => setName(value)} />
      </Stack>
    </ConfirmDialog>
  );
};
