export interface ComponentDetailsTabsProps {
  tenantId: string;
  id: string;
  scopeId?: string;
}

export enum DetailsTabs {
  Info = "info",
  Actions = "actions",
  Implementations = "implementations",
  Settings = "settings"
}
