import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { FormTextField } from "@components/form/fields/FormTextField";
import React, { FunctionComponent } from "react";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";

interface MessageCreditsEventFormFieldsProps {
  // isNewMessageCreditsEvent: boolean;
  tenantId: string;
}
export const MessageCreditsEventFormFields: FunctionComponent<MessageCreditsEventFormFieldsProps> = () => {
  return (
    <>
      <FormSpinNumberInput
        label="Units"
        name="units"
        rules={{ required: ValidationMessages.required }}
        required
      />
      <FormTextField
        label="Details"
        name="details"
        rules={{ required: ValidationMessages.required }}
        required
      />
    </>
  );
};
