import { CopyableText } from "@components/CopyableText";
import { SectionTitle } from "@components/SectionTitle";
import React from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Stack
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { InstallerDetail } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { useSiteManagerInstallerDetailQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

export const ProsSiteComponents: React.FC = () => {
  const query = useSiteManagerInstallerDetailQuery();

  const columns: IColumn[] = [
    {
      key: "version",
      fieldName: "version",
      name: "Version",
      minWidth: 100,
      maxWidth: 140,
      flexGrow: 0
    },
    {
      key: "url",
      fieldName: "url",
      name: "Url",
      minWidth: 300,
      maxWidth: 300,
      onRender: (installerDetail: InstallerDetail) => (
        <CopyableText
          clipboardContent={installerDetail.url}
          nowrap
          horizontalFill
          styles={{ root: { maxWidth: "500px" } }}
        >
          {installerDetail.url}
        </CopyableText>
      )
    }
  ];

  return (
    <QueryStateIndicator<InstallerDetail> {...query}>
      {data => (
        <Stack
          verticalFill
          styles={(_prop, theme) => ({ root: { padding: theme.spacing.s1 } })}
        >
          <SectionTitle>Site Manager Installer</SectionTitle>

          <DetailsList
            items={[data]}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            styles={{
              contentWrapper: {
                "[role=gridcell]": {
                  display: "flex",
                  alignItems: "center"
                }
              }
            }}
          />
        </Stack>
      )}
    </QueryStateIndicator>
  );
};
