import { UserModal } from "modules/cam/UserModal";
import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
  FontIcon,
  IconButton,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { ApiCallButton } from "@components/buttons/ApiCallButton";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { withPermissions } from "@components/withPermissions";
import {
  CustomerAccount,
  CustomerAccountUser
} from "@libs/api/gateways/cam/cam-gateway.dtos";
import {
  useCustomerAccountAllUsersQuery,
  useCustomerAccountCreateMutation,
  useCustomerAccountQuery
} from "@libs/api/gateways/cam/cam-gateway.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { TenantsTabs } from "../tenants/tenants-tabs.constants";
import { InviteModal } from "./InviteModal";

const CustomerAccountSectionBase: React.FC = () => {
  const { crmId } = useParams<{ crmId: string }>();

  const theme = useTheme();
  const navigate = useNavigate();

  const customerAccountQuery = useCustomerAccountQuery(crmId!);
  const customerAccountAllUsersQuery = useCustomerAccountAllUsersQuery(crmId!);
  const customerAccountCreateMutation = useCustomerAccountCreateMutation(
    crmId!
  );

  const [showUserModal, setShowUserModal] = useState<boolean>();
  const [showInviteModal, setShowInviteModal] = useState<boolean>();
  const [selectedUser, setSelectedUser] = useState<
    CustomerAccountUser | undefined
  >();

  const selectUser = (user: CustomerAccountUser | undefined) => {
    setSelectedUser(user);
    setShowUserModal(true);
    setShowInviteModal(false);
  };

  const inviteUser = (user: CustomerAccountUser | undefined) => {
    setSelectedUser(user);
    setShowUserModal(false);
    setShowInviteModal(true);
  };

  const selectTenant = (id?: string) => {
    const to = generatePath("/tenants/:tenantId/:sectionId", {
      tenantId: id,
      sectionId: TenantsTabs.Platform.Details.id
    });
    navigate(to);
  };

  const onCreateAccountClick = async (customerAccount: CustomerAccount) => {
    await customerAccountCreateMutation.mutateAsync({
      crmId: customerAccount.crmId,
      name: customerAccount.businessName
    });
  };

  const onDismissUserModal = () => {
    setShowUserModal(false);
    setSelectedUser(undefined);
  };

  const onDismissInviteModal = () => {
    setShowInviteModal(false);
    setSelectedUser(undefined);
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    [Permissions.PltCustomerWrite, Permissions.PltSalesWrite],
    "or"
  );

  const { data: hasInvitePermission } = usePermissionsCheckQuery([
    Permissions.PltInvitationWrite
  ]);

  const getColumns = (
    customerAccount: CustomerAccount
  ): DataTableColumn<CustomerAccountUser>[] => [
    {
      key: "id",
      name: "Id",
      minWidth: 100,
      onRender: (user: CustomerAccountUser) => (
        <Stack>
          <Text>CrmContactId: {user.crmContactId}</Text>
          <Text>TenantUserId: {user.tenantUserId}</Text>
          <Text>BpIdUserId: {user.bpIdUserId}</Text>
        </Stack>
      )
    },
    {
      key: "email",
      name: "Email",
      minWidth: 200,
      onRender: (user: CustomerAccountUser) => <span>{user.crmEmail}</span>
    },
    {
      key: "firstName",
      name: "First Name",
      minWidth: 100,
      isSorted: true,
      sort: true,
      onRender: (user: CustomerAccountUser) => <span>{user.firstName}</span>
    },
    {
      key: "lastName",
      name: "Last Name",
      minWidth: 100,
      onRender: (user: CustomerAccountUser) => <Text>{user.lastName}</Text>,
      sort: true
    },
    {
      key: "actions",
      name: "",
      maxWidth: 32,
      minWidth: 32,
      onRender: (item: CustomerAccountUser) => (
        <IconButton
          styles={{ root: { justifySelf: "end" } }}
          menuIconProps={{ iconName: "more" }}
          menuProps={{
            items: [
              {
                key: "edit",
                text: "Edit",
                disabled: !hasWritePermission,
                title: !hasWritePermission ? NO_ACTION_PERMISSION : undefined,
                onClick: () => selectUser(item)
              },
              {
                key: "invite", // dont show if bpcloud customer account doesn't exist
                text: "Send Invite",
                title: !hasInvitePermission
                  ? NO_ACTION_PERMISSION
                  : "Operation not available at this time",
                onClick: () => inviteUser(item),
                disabled:
                  (!customerAccount.headOfficeId &&
                    (customerAccount?.caTenants?.length || 0) === 0) ||
                  (item?.bpIdUserId || undefined) !== undefined ||
                  !hasInvitePermission
              }
            ]
          }}
        />
      )
    }
  ];

  return (
    <QueryStateIndicator<CustomerAccount> {...customerAccountQuery}>
      {customerAccount => (
        <Stack tokens={{ childrenGap: 10 }} verticalFill>
          <Text
            variant="xxLarge"
            styles={{ root: { color: theme.palette.themePrimary } }}
          >
            Customer Account
          </Text>
          {customerAccount.headOfficeId &&
            customerAccount.caTenants.length === 0 && (
              <Stack>
                <Text>
                  {customerAccount.crmId} does not support a BpCloud Customer
                  Account:
                </Text>
                <Text>
                  - Cannot add BpCloud Customer Accounts to child practices.
                </Text>
              </Stack>
            )}
          {!customerAccount.headOfficeId &&
            customerAccount.caTenants.length === 0 && (
              <ApiCallButton
                primary
                isLoading={customerAccountCreateMutation.isLoading}
                error={customerAccountCreateMutation.error}
                styles={{ root: { width: "fit-content" } }}
                disabled={!hasWritePermission}
                onClick={() => onCreateAccountClick(customerAccount)}
              >
                {!hasWritePermission
                  ? NO_ACTION_PERMISSION
                  : "Create BpCloud Customer Account"}
              </ApiCallButton>
            )}
          {customerAccount.caTenants.length > 0 && (
            <Stack>
              <Link
                onClick={() => selectTenant(customerAccount.caTenants[0].id)}
              >
                {customerAccount.caTenants[0].name}
              </Link>
              <Text variant="xSmall">{customerAccount.caTenants[0].id}</Text>
            </Stack>
          )}
          {!customerAccount.headOfficeId &&
            customerAccount.caTenants.length > 1 && (
              <TooltipHost content="We display a warning when there are more than one CA AppTypes associated with this Customer Id">
                <FontIcon
                  iconName="Warning"
                  styles={{
                    root: { color: "orange", paddingTop: 3, paddingLeft: 20 }
                  }}
                />
              </TooltipHost>
            )}
          <Text
            variant="xxLarge"
            styles={{ root: { color: theme.palette.themePrimary } }}
          >
            Users
          </Text>
          <QueryStateIndicator {...customerAccountAllUsersQuery}>
            {users => (
              <Stack grow styles={{ root: { position: "relative" } }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  <DataTable
                    items={users}
                    columns={getColumns(customerAccount)}
                    getKey={(user: CustomerAccountUser) =>
                      user?.crmContactId ?? user?.tenantUserId ?? ""
                    }
                  />
                </ScrollablePane>
              </Stack>
            )}
          </QueryStateIndicator>
          {showUserModal && (
            <UserModal onDismiss={onDismissUserModal} pltUser={selectedUser} />
          )}

          {showInviteModal && (
            <InviteModal
              onDismiss={onDismissInviteModal}
              user={selectedUser}
              tenantId={customerAccount.caTenants[0].id}
            />
          )}
        </Stack>
      )}
    </QueryStateIndicator>
  );
};

export const CustomerAccountSection = withPermissions(
  CustomerAccountSectionBase,
  [Permissions.PltCustomerRead, Permissions.PltCustomerWrite],
  "or"
);
