import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FunctionComponent } from "react";
import {
  DefaultButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { CreateStripeAccountDialog } from "./components/CreateStripeAccountDialog";
import { StripeAccountDetails } from "./components/StripeAccountDetails";
import { HttpError } from "@bps/http-client";
import { useCustomerAccountReset } from "@libs/api/gateways/cam/cam-gateway.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useStripeCustomer } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface StripeConfigScreenProps {
  tenant: Tenant;
}

const StripeConfigScreenBase: FunctionComponent<StripeConfigScreenProps> = ({
  tenant: { id, country, crmId, stripeCustomerId }
}) => {
  const theme = useTheme();

  const { isLoading, mutate } = useCustomerAccountReset(id, crmId || "");

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  const stripeCustomerQuery = useStripeCustomer(id);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontal>
        <SectionTitle>Stripe Configuration</SectionTitle>
        {stripeCustomerQuery.isLoading && <Spinner />}
      </Stack>
      {stripeCustomerQuery.isError && (
        <Stack horizontal>
          <Text>{`${(stripeCustomerQuery.error as HttpError).detail}`}</Text>
        </Stack>
      )}
      {stripeCustomerId && crmId && stripeCustomerQuery.isError && (
        <Stack horizontal>
          <DefaultButton
            onClick={() => {
              mutate();
            }}
            disabled={isLoading || !hasWritePermission}
          >
            {isLoading ? <Spinner size={SpinnerSize.small} /> : "Reset"}
          </DefaultButton>
        </Stack>
      )}
      {!stripeCustomerId && crmId && (
        <Stack horizontal>
          <CreateStripeAccountDialog
            tenantId={id}
            country={country}
            crmId={crmId}
          />
        </Stack>
      )}
      {stripeCustomerId && stripeCustomerQuery.data && (
        <StripeAccountDetails data={stripeCustomerQuery.data} />
      )}
    </Stack>
  );
};

export const StripeConfigScreen = withPermissions(
  StripeConfigScreenBase,
  [Permissions.PltSalesRead, Permissions.PltSalesWrite],
  "or"
);
