import { CopyableGuid } from "@components/CopyableText";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { Permissions } from "@libs/permissions/permissions.enum";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import {
  Checkbox,
  IColumn,
  IconButton,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";

import { TenantUsersFilters } from "../TenantUsersScreen";
import { AdditionalUserInformationRow } from "./AdditionalUserInformationRow";
import { PltTenantApplicationBadge } from "../../../../../_shared-blocks/PltTenantApplicationBadge";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import {
  useSearchUsers,
  useUpdateUser
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useExpandedRows } from "@components/tables/useExpandedRows";
import { ExpandableRow } from "@components/tables/ExpandableRow";

interface PltUsersTableProps {
  tenantId: string;
  filter: TenantUsersFilters;
  selectedUsersIds: Set<string>;
  setSelectedUsersIds: Dispatch<SetStateAction<Set<string>>>;
  showNotificationsDialog: () => void;
}

export const PltUsersTable: FC<PltUsersTableProps> = ({
  tenantId,
  filter,
  selectedUsersIds,
  setSelectedUsersIds,
  showNotificationsDialog
}) => {
  const navigate = useNavigate();

  const bpIdUserId = filter.bpIdUserId === "" ? undefined : filter.bpIdUserId;

  const { isLoading, error, data = [] } = useSearchUsers(
    tenantId,
    filter.name,
    bpIdUserId
  );

  const {
    isLoading: isUpdatedUserLoading,
    mutateAsync: updateUser
  } = useUpdateUser();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogUserWrite
  );

  const { data: hasNotificationPermission } = usePermissionsCheckQuery(
    Permissions.PltNotificationsWrite
  );

  const { expandedIds, toggleExpandedIds } = useExpandedRows();

  const handleSelectedUsersIds = (id: string) => {
    setSelectedUsersIds(prev =>
      prev.has(id)
        ? new Set([...prev].filter(x => x !== id))
        : new Set(prev.add(id))
    );
  };

  const columns: IColumn[] = [
    {
      key: "actions",
      name: "",
      maxWidth: 32,
      minWidth: 32,
      onRender: (item: PltUser) => (
        <IconButton
          styles={{ root: { justifySelf: "end" } }}
          menuIconProps={{ iconName: "more" }}
          menuProps={{
            items: [
              {
                key: "notification",
                text: "Send notification",
                onClick: () => {
                  handleSelectedUsersIds(item.id);
                  showNotificationsDialog();
                },
                disabled: !hasNotificationPermission,
                title: !hasNotificationPermission
                  ? NO_ACTION_PERMISSION
                  : undefined
              },
              {
                key: "edit",
                text: hasWritePermission ? "Edit/more" : "More",
                onClick: () => navigate(`./${item.id}`)
              },
              {
                key: "delete",
                text: !item.isInactive ? "Disable" : "Enable",
                onClick: () => {
                  updateUser({
                    ...item,
                    isInactive: !item.isInactive
                  });
                },
                disabled: isUpdatedUserLoading || !hasWritePermission,
                title: !hasWritePermission ? NO_ACTION_PERMISSION : undefined
              }
            ]
          }}
        />
      )
    },
    {
      key: "check",
      name: "",
      maxWidth: 35,
      minWidth: 35,
      onRenderHeader: () => (
        <Checkbox
          automationAttribute="check-for-notifications-all"
          checked={data.length > 0 && data.length === selectedUsersIds.size}
          onChange={(ev, checked) => {
            ev?.stopPropagation();
            if (!checked) {
              setSelectedUsersIds(new Set());
            } else {
              setSelectedUsersIds(new Set(data.map(d => d.id)));
            }
          }}
          styles={{ root: { paddingTop: 8 } }}
        />
      ),
      onRender: (item: PltUser) => (
        <Checkbox
          automationAttribute={`check-for-notification-${item.id}`}
          checked={selectedUsersIds.has(item.id)}
          onChange={ev => {
            ev?.stopPropagation();
            handleSelectedUsersIds(item.id);
          }}
        />
      )
    },
    {
      key: "id",
      name: "Id",
      maxWidth: 100,
      minWidth: 100,
      onRender: (item: PltUser) => <CopyableGuid short value={item.id} />
    },
    {
      key: "firstName",
      name: "First Name",
      maxWidth: 100,
      minWidth: 100,
      onRender: (item: PltUser) => item.firstName
    },
    {
      key: "lastName",
      name: "Last Name",
      maxWidth: 150,
      minWidth: 200,
      onRender: (item: PltUser) => item.lastName
    },
    {
      key: "email",
      name: "Email",
      maxWidth: 250,
      minWidth: 200,
      onRender: (item: PltUser) => item.username
    },
    {
      key: "application",
      name: "Application",
      maxWidth: 170,
      minWidth: 170,
      onRender: (item: PltUser) => (
        <PltTenantApplicationBadge tenantId={item.tenantId} />
      )
    },
    {
      key: "bpIdExists",
      name: "Has Bp ID",
      maxWidth: 75,
      minWidth: 75,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.bpIdUserId ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{
            root: { alignSelf: "start", width: 75, justifySelf: "center" }
          }}
        >
          {item.bpIdUserId ? "Yes" : "No"}
        </TextBadge>
      )
    },
    {
      key: "externalIdExists",
      name: "Has Ext. ID",
      maxWidth: 75,
      minWidth: 75,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.externalId ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{
            root: { alignSelf: "start", width: 75, justifySelf: "center" }
          }}
        >
          {item.externalId ? "Yes" : "No"}
        </TextBadge>
      )
    },
    {
      key: "status",
      name: "Status",
      minWidth: 80,
      maxWidth: 100,
      onRender: (item: PltUser) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            !item.isInactive ? TextBadgeColor.green : TextBadgeColor.red
          }
          styles={{
            root: { alignSelf: "start", width: 100, justifySelf: "center" }
          }}
        >
          {!item.isInactive ? "Active" : "Inactive"}
        </TextBadge>
      )
    }
  ];

  return (
    <>
      <ShimmeredDetailsList
        items={data}
        columns={columns}
        setKey="plt_users_id"
        errorMessage={error?.message}
        onShouldVirtualize={() => false}
        enableShimmer={isLoading}
        detailsListStyles={{
          contentWrapper: {}
        }}
        onRenderRow={(props, renderDefault) => {
          if (props && renderDefault) {
            return (
              <ExpandableRow
                id={props.item.id}
                expandedIds={expandedIds}
                toggleExpandedIds={toggleExpandedIds}
                ExpandableComponent={
                  <AdditionalUserInformationRow user={props.item} />
                }
                rowProps={props}
              />
            );
          }
          return null;
        }}
      />
    </>
  );
};
