import { withPermissions } from "@components/withPermissions";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { FunctionComponent } from "react";

import {
  confirm,
  CopyToClipboardButton,
  IconButton,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { CertificateDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import {
  useDeleteCertificate,
  useDeployCertificateToGateway,
  useRemoveCertificateFromGateway
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface IntegCertificateProps {
  tenant: Tenant;
  certificate: CertificateDto;
}

const IntegCertificateBase: FunctionComponent<IntegCertificateProps> = ({
  certificate,
  tenant
}) => {
  const theme = useTheme();

  const {
    mutateAsync: deployCertificateToGateway
  } = useDeployCertificateToGateway();

  const {
    mutateAsync: removeCertificateFromGateway
  } = useRemoveCertificateFromGateway();

  const downLoadCertificate = async (): Promise<void> => {
    window.open(
      `data:application/pkix-cert;base64,${btoa(certificate.content)}` //.Cer files are base64 encoded
    );
  };

  const { mutateAsync: deleteCertificate } = useDeleteCertificate();

  const onDeleteCertificate = async () => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Delete"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: "Delete this certificate?",
        subText: "Are you sure you want to delete this certificate?"
      }
    });

    if (isConfirmed) {
      await deleteCertificate({
        tenantId: tenant.id,
        id: certificate.id
      });
    }
  };

  const toggleCertificateOnApiGateway = async (): Promise<void> => {
    if (certificate.isDeployedToApimAppGateway) {
      await removeCertificateFromGateway({
        id: certificate.id,
        tenantId: tenant.id
      });
    } else {
      await deployCertificateToGateway({
        id: certificate.id,
        tenantId: tenant.id
      });
    }
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegConsentWrite
  );

  return (
    <Stack
      verticalAlign="space-between"
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          background: theme.palette.neutralLighter,
          height: 100
        }
      }}
    >
      <Stack verticalAlign="start">
        <Text block>{certificate.metadata?.friendlyName}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Id:</Text>
            <Text>{certificate.id}</Text>
            <CopyToClipboardButton clipboardContent={certificate.id} />
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Subject Name:</Text>
            <Text> {certificate.metadata?.subject}</Text>
          </Stack>
        </Stack>
        <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Deployed to API Gateway:</Text>
            <Text>{certificate.isDeployedToApimAppGateway ? "Yes" : "No"}</Text>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Registered On:</Text>
            <Text>
              {DateTime.fromISO(certificate.changeLog!.createdDate!).toFormat(
                DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD
              )}
            </Text>
          </Stack>
        </Stack>
        <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Algorithm:</Text>
            <Text>{certificate.metadata?.keyExchangeAlgorithm}</Text>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Version:</Text>
            <Text>{certificate.metadata?.version}</Text>
          </Stack>
        </Stack>
        <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Issuer Name:</Text>
            <Text> {certificate.metadata?.issuer}</Text>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Certificate Expired:</Text>
            <Text>
              {certificate.metadata &&
              DateTime.fromISO(certificate.metadata.notAfter) <= DateTime.now()
                ? "Yes"
                : "No"}
            </Text>
          </Stack>
        </Stack>
        <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
          {" "}
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Not Before:</Text>
            <Text>
              {certificate.metadata &&
                DateTime.fromISO(certificate.metadata.notBefore!).toFormat(
                  DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD
                )}
            </Text>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text bold>Not After:</Text>
            <Text>
              {certificate.metadata &&
                DateTime.fromISO(certificate.metadata.notAfter).toFormat(
                  DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD
                )}
            </Text>
          </Stack>
        </Stack>
        <Stack verticalAlign="start" tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <TooltipHost content="Download certificate">
              <IconButton
                disabled={!hasWritePermission}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
                iconProps={{ iconName: "Download" }}
                styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
                onClick={() => downLoadCertificate()}
              />
            </TooltipHost>
            <TooltipHost content="Delete certificate">
              <IconButton
                disabled={!hasWritePermission}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
                iconProps={{ iconName: "Delete" }}
                styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
                onClick={() => onDeleteCertificate()}
              />
            </TooltipHost>
            <PrimaryButton onClick={() => toggleCertificateOnApiGateway()}>
              {certificate.isDeployedToApimAppGateway
                ? "Remove from API gateway"
                : "Deploy to API Gateway"}
            </PrimaryButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const IntegCertificate = withPermissions(
  IntegCertificateBase,
  [Permissions.PltIntegAppRead, Permissions.PltIntegAppWrite],
  "or"
);
