import { FunctionComponent } from "react";
import { Link, Text } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useComponentManagerWorkflowRunInstance } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface ManagerWorkflowRunInstanceLinkProps {
  id: string;
}
export const ManagerWorkflowRunInstanceLink: FunctionComponent<ManagerWorkflowRunInstanceLinkProps> = ({
  id
}) => {
  const workflowRunInstanceLinkQuery = useComponentManagerWorkflowRunInstance(
    id
  );
  return (
    <QueryStateIndicator {...workflowRunInstanceLinkQuery}>
      {({ workflowInstanceUrl }) =>
        workflowInstanceUrl ? (
          <Link target="_blank" href={workflowInstanceUrl}>
            {id}
          </Link>
        ) : (
          <Text>{id}</Text>
        )
      }
    </QueryStateIndicator>
  );
};
