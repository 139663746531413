import { YesOrNoIcon } from "@components/YesOrNoIcon";
import {
  ProsTenant,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { observer } from "mobx-react";
import React from "react";

import { Shimmer, Stack, Text } from "@bps/fluent-ui";
import {
  useSiteComponentVersionsQuery,
  useSiteVersionsQuery
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

interface SiteAgentCellProps {
  tenant: ProsTenant;
}

export const SiteAgentCell: React.FC<SiteAgentCellProps> = observer(
  ({ tenant }) => {
    const { isFetching, data } = useSiteComponentVersionsQuery(tenant.id);
    const versionData = data?.find(
      item => item.componentType === SiteComponentType.SiteAgent
    );

    const {
      data: downloadData,
      isFetching: isFetchingDownloaded
    } = useSiteVersionsQuery(
      tenant.id,
      versionData?.desiredVersion || undefined
    );

    const { IsSiteAgentDesiredVersionDownloaded } = downloadData || {};

    return (
      <Stack>
        <Shimmer isDataLoaded={!isFetching}>
          <Text variant="small">
            Current Version: {versionData?.version ?? "Not installed"}
          </Text>
        </Shimmer>
        <Shimmer isDataLoaded={!isFetching}>
          <Text variant="small">
            Desired Version: {versionData?.desiredVersion ?? "None Selected"}
          </Text>
        </Shimmer>
        <Shimmer isDataLoaded={!isFetchingDownloaded}>
          <Text variant="small">
            Desired Downloaded:{" "}
            {IsSiteAgentDesiredVersionDownloaded !== undefined ? (
              <YesOrNoIcon when={IsSiteAgentDesiredVersionDownloaded} />
            ) : (
              "Unable to find"
            )}
          </Text>
        </Shimmer>
      </Stack>
    );
  }
);
