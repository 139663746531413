import { CallbackDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { OrgUnitDto, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent, useState } from "react";
import Highlight from "react-highlight";

import {
  CollapsibleCard,
  LabeledText,
  LabeledTextGroup,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { UpdateCallbackDialog } from "./UpdateCallbackDialog";

interface CallbackProps {
  tenant: Tenant;
  item: CallbackDto;
  orgUnits: OrgUnitDto[];
  shouldDeleteItem?: boolean;
}

export const Callback: FunctionComponent<CallbackProps> = ({
  tenant,
  item,
  orgUnits
}) => {
  const theme = useTheme();
  const [showUpdateCallbackDialog, setshowUpdateCallbackDialog] = useState<
    boolean
  >(false);

  const heading = (
    <Stack horizontal horizontalAlign="space-between">
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>Callback Key:</Text>
        <Text>{item.key}</Text>
      </Stack>
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>Callback Type: </Text>
        <Text>{item.type}</Text>
      </Stack>
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>Location Name: </Text>
        <Text>{item.locationId}</Text>
      </Stack>
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>User Name: </Text>
        <Text>{item.userId}</Text>
      </Stack>
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>Created: </Text>
        <Text>{item.changeLog.createdBy}</Text>
      </Stack>
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>Updated: </Text>
        <Text>{item.changeLog.updatedBy}</Text>
      </Stack>
      <Stack verticalAlign="start" tokens={{ childrenGap: 5 }}>
        <Text bold>Enabled:</Text>
        <Text> {item.isEnabled ? "Yes" : "No"}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
        <PrimaryButton onClick={() => setshowUpdateCallbackDialog(true)}>
          Update Callback
        </PrimaryButton>
        {showUpdateCallbackDialog && (
          <UpdateCallbackDialog
            tenant={tenant}
            callback={item}
            orgUnits={orgUnits}
            onDismiss={() => {
              setshowUpdateCallbackDialog(false);
            }}
          />
        )}
      </Stack>
    </Stack>
  );

  return (
    <CollapsibleCard
      heading={heading}
      styles={{
        header: { padding: 16 },
        heading: { display: "block", flexGrow: 1 },
        collapseContent: { padding: 16 },
        headerWrapper: { backgroundColor: theme.palette.neutralLighter }
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 16 }}
        horizontalAlign="space-between"
      >
        <StackItem>
          <LabeledTextGroup>
            <LabeledText label="Callback ID">{item.id}</LabeledText>
            <LabeledText label="Created By">
              {item.changeLog.createdBy}
            </LabeledText>
            <LabeledText label="Updated By">
              {item.changeLog.updatedBy}
            </LabeledText>
          </LabeledTextGroup>
        </StackItem>
        <StackItem>
          <Highlight className="json">{item.propertyBag}</Highlight>
        </StackItem>
      </Stack>
    </CollapsibleCard>
  );
};
