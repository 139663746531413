import {
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { SelectorDto } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface SelectorRulesProps {
  selector: SelectorDto;
}

export const SelectorRules = ({ selector }: SelectorRulesProps) => {
  const theme = useTheme();

  if (selector.rules === null || selector.rules.length === 0) {
    return <Text variant="small">No rules have been configured.</Text>;
  }

  return (
    <Stack horizontal styles={{ root: { display: "flex", flexWrap: "wrap" } }}>
      {selector.rules.map(rule => (
        <TooltipHost
          content={`First Created: ${
            rule.changeLog?.createdDate
              ? DateTime.fromISO(rule.changeLog?.createdDate)?.toFormat(
                  DATE_FORMATS.LONG_DATE_TIME_FORMAT
                )
              : "No date recorded."
          }`}
          key={rule.id}
        >
          <TextBadge
            badgeColor={TextBadgeColor.blue}
            badgeSize={TextBadgeSize.medium}
            styles={{
              root: {
                width: "max-content",
                margin: 4,
                padding: "0 8px 1px",
                height: 24,
                lineHeight: "1.385",
                borderRadius: 16
              }
            }}
          >
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <Text styles={{ root: { fontSize: 13 } }}>
                {rule.attributeType} {rule.comparisonOperator}
              </Text>
              <Text styles={{ root: { fontSize: 13 } }} bold>
                {rule.value}
              </Text>
            </Stack>
          </TextBadge>
        </TooltipHost>
      ))}
    </Stack>
  );
};
