import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";

import { AuditLogsFilter } from "./components/AuditLogsFilter";
import { AuditLogsList } from "./components/AuditLogsList";

export const AuditLogsBase: FunctionComponent = () => {
  return (
    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: "100%" } }}>
      <SectionTitle>Audit Logs</SectionTitle>
      <AuditLogsFilter>
        {({ values }) => (
          <AuditLogsList
            startDate={values.startDate}
            endDate={values.endDate}
          />
        )}
      </AuditLogsFilter>
    </Stack>
  );
};

export const AuditLogs = withPermissions(
  AuditLogsBase,
  Permissions.PltCatalogUserRead
);
