import { FunctionComponent } from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, groupBy } from "@bps/utils";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useUsedRedirectLinks } from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";
import { compareDatesOrUndefinedPredicate } from "@libs/common/common.utils";

interface LinkUsageProps {
  linkId: string;
}

export const LinkUsage: FunctionComponent<LinkUsageProps> = ({ linkId }) => {
  const theme = useTheme();
  const linkUsageQuery = useUsedRedirectLinks(linkId);
  return (
    <QueryStateIndicator
      {...linkUsageQuery}
      NoDataNode={
        <Text styles={{ root: { color: theme.semanticColors.disabledText } }}>
          No usage history
        </Text>
      }
    >
      {(data = []) => {
        const items = groupBy(data, i => i.redirectUrl);
        return (
          <Stack>
            {items.map(([, usage]) => {
              const sortedUsage = Array.from(usage).sort((a, b) =>
                compareDatesOrUndefinedPredicate(a.timestamp, b.timestamp)
              );
              return sortedUsage.map(u => (
                <li key={u.linkId}>
                  {u.timestamp
                    ? DateTime.fromISO(u.timestamp).toFormat(
                        DATE_FORMATS.LONG_DATE_TIME_FORMAT
                      )
                    : undefined}
                </li>
              ));
            })}
          </Stack>
        );
      }}
    </QueryStateIndicator>
  );
};
