import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormTimePicker } from "@components/form/fields/FormTimePicker";
import { useCallback } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { PrimaryButton, Spinner, Stack, Text, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

export type BannerFormOnSubmit = (values: BannerFormSubmitValues) => void;

export interface BannerFormSubmitValues {
  text: string;
  startTime: DateTime;
  endTime: DateTime;
}

interface BannerFormValues {
  text: string;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
}

interface BannerFormProps {
  onSubmit: BannerFormOnSubmit;
  isLoading?: boolean;
}

export const BannerForm: React.FC<BannerFormProps> = ({
  onSubmit,
  isLoading
}) => {
  const form = useForm<BannerFormValues>();
  const theme = useTheme();

  const _onSumbmit = useCallback<SubmitHandler<BannerFormValues>>(
    values => {
      const {
        text,
        startDate,
        startTime: startTimeStr,
        endDate,
        endTime: endTimeStr
      } = values;

      const [startHour, startMinute] = startTimeStr.split(":");
      const startTime = DateTime.fromJSDate(startDate).set({
        hour: Number(startHour),
        minute: Number(startMinute)
      });

      const [endHour, endMinute] = endTimeStr.split(":");
      const endTime = DateTime.fromJSDate(endDate).set({
        hour: Number(endHour),
        minute: Number(endMinute)
      });
      onSubmit({
        text,
        startTime,
        endTime
      });
    },
    [onSubmit]
  );

  return (
    <FormProvider {...form}>
      <Text>Add a text message to show as a banner message.</Text>
      <Stack
        as="form"
        tokens={{ childrenGap: theme.spacing.s1 }}
        onSubmit={form.handleSubmit(_onSumbmit)}
      >
        <FormTextField
          name="text"
          multiline
          rules={{
            required: {
              message: "Text is required",
              value: true
            }
          }}
        />
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <FormDatePicker
            name="startDate"
            rules={{
              required: {
                message: "Start Date is required",
                value: true
              }
            }}
          />
          <FormTimePicker
            name="startTime"
            rules={{
              required: {
                message: "Start Time is required",
                value: true
              }
            }}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <FormDatePicker
            name="endDate"
            rules={{
              required: {
                message: "End Date is required",
                value: true
              }
            }}
          />
          <FormTimePicker
            name="endTime"
            rules={{
              required: {
                message: "End Time is required",
                value: true
              }
            }}
          />
        </Stack>
        <Stack
          horizontal
          horizontalAlign="start"
          tokens={{
            childrenGap: theme.spacing.s1
          }}
        >
          <PrimaryButton
            type="submit"
            disabled={form.formState.isSubmitting || isLoading}
          >
            Submit
          </PrimaryButton>
          {isLoading && <Spinner />}
        </Stack>
      </Stack>
    </FormProvider>
  );
};
