import { Form } from "@components/form/Form";
import { FormCheckbox } from "@components/form/fields/FormCheckbox";

import { FunctionComponent } from "react";

import { Dialog } from "@bps/fluent-ui";
import {
  ApplicationDto,
  CloudApplicationScopeDto
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { useUpdateApplicationScopes } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface EditScopesDialogProps {
  application: ApplicationDto;
  tenant: Tenant;
  scopes: string[];
  availableScopes: CloudApplicationScopeDto[];
  onDismiss: () => void;
}

export const EditScopesDialog: FunctionComponent<EditScopesDialogProps> = ({
  application,
  tenant,
  scopes,
  availableScopes,
  onDismiss
}) => {
  const {
    mutateAsync: updateApplicationScopes,
    error: updateApplicationScopesError
  } = useUpdateApplicationScopes();

  const onSubmit = async (formData: Object) => {
    const effectiveScopes: string[] = [];

    availableScopes.forEach(availableScope => {
      if (formData[availableScope.id] === true) {
        effectiveScopes.push(availableScope.id);
      }
    });
    await updateApplicationScopes({
      applicationId: application.id,
      tenantId: tenant.id,
      scopes: effectiveScopes
    });
    onDismiss();
  };

  const defaultValues = {};

  for (const scopeId of scopes) {
    defaultValues[scopeId] = true;
  }
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Edit scopes",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<Object>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={updateApplicationScopesError}
      >
        {availableScopes.map(availableScope => (
          <FormCheckbox
            key={availableScope.id}
            label={availableScope.value}
            name={availableScope.id}
          />
        ))}
      </Form>
    </Dialog>
  );
};
