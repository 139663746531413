import { FunctionComponent } from "react";

import {
  FontIcon,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import {
  CustomerProductsSubscription,
  SubscriptionStatuses
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface SubscriptionStatusBadgeProps {
  item: CustomerProductsSubscription;
  horizontal?: boolean;
}
export const SubscriptionStatusBadge: FunctionComponent<SubscriptionStatusBadgeProps> = ({
  item,
  horizontal
}) => {
  const theme = useTheme();
  const getColor = () => {
    switch (item.status) {
      case SubscriptionStatuses.Active:
        return TextBadgeColor.green;
      case SubscriptionStatuses.Canceled:
      case SubscriptionStatuses.PastDue:
      case SubscriptionStatuses.IncompleteExpired:
        return TextBadgeColor.red;
      default:
        return TextBadgeColor.yellow;
    }
  };
  return item.cancelAtPeriodEnd ? (
    <Stack
      horizontal={horizontal}
      verticalAlign="center"
      tokens={{ childrenGap: 4 }}
    >
      <TextBadge
        badgeSize={TextBadgeSize.medium}
        badgeColor={TextBadgeColor.yellow}
        styles={{
          root: { flexDirection: "row" }
        }}
      >
        <FontIcon
          iconName="Warning"
          styles={{
            root: {
              color: theme.semanticColors.errorIcon,
              fontSize: 16,
              marginRight: 4
            }
          }}
        />
        <Text>
          Pending cancellation (
          {item.cancelAtPeriodEnd &&
            DateTime.fromISO(item.cancellationDate).toFormat(
              DATE_FORMATS.DAY_DEFAULT_FORMAT
            )}
          )
        </Text>
      </TextBadge>
      <Text variant="small" />
    </Stack>
  ) : (
    <TextBadge
      badgeSize={TextBadgeSize.small}
      badgeColor={getColor()}
      styles={{ root: { textTransform: "capitalize" } }}
    >
      {item.status}
    </TextBadge>
  );
};
