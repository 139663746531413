import { FeatureNode } from "modules/system/sections/plt/feature/FeatureGraph";
import React from "react";

import {
  Heading,
  IPanelProps,
  Panel,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

interface FeatureGraphPanelProps extends IPanelProps {
  node?: FeatureNode;
}

export const FeatureGraphPanel: React.FC<FeatureGraphPanelProps> = ({
  node,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Panel isLightDismiss {...props}>
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Text variant="large">{node?.text}</Text>
        <Stack>
          <Heading>Id</Heading>
          <Text>{node?.id}</Text>
        </Stack>
        <Stack>
          <Heading>Code</Heading>
          <Text>{node?.code}</Text>
        </Stack>
        <Stack>
          <Heading>Description</Heading>
          <Text>{node?.description}</Text>
        </Stack>
        <Stack>
          <Heading>Permission Grants</Heading>
          <Stack>
            {node?.permissions.map(permission => (
              <Text key={permission} variant="small">
                {permission}
              </Text>
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Heading>Depends On</Heading>
          <Stack>
            {node?.dependencies.map(dependency => (
              <Text key={dependency}>{dependency}</Text>
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Heading>Depended On By</Heading>
          <Stack>
            {node?.children?.map(child => (
              <Text key={child}>{child}</Text>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Panel>
  );
};
