import { BhaSignInList } from "modules/users/BhaProfile/BhaSignInList";
import React from "react";

import {
  IconButton,
  MessageBar,
  MessageBarType,
  Shimmer,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { ConsumerUser } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";

interface ConsumerUserCardProps {
  isLoading?: boolean;
  error?: unknown;
  refetch?: () => void;
  identity?: ConsumerUser;
}

export const ConsumerUserCard: React.FC<ConsumerUserCardProps> = ({
  identity,
  error,
  isLoading,
  refetch
}) => {
  const theme = useTheme();
  return (
    <Stack
      tokens={{
        childrenGap: theme.spacing.s2
      }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Text
          variant="medium"
          styles={{
            root: {
              fontWeight: "bold"
            }
          }}
        >
          Identity Record
        </Text>
        {isLoading && (
          <Spinner
            size={SpinnerSize.small}
            styles={{
              root: {
                padding: theme.spacing.s1
              }
            }}
          />
        )}

        {refetch && (
          <IconButton
            onClick={() => refetch?.()}
            iconProps={{
              iconName: "Refresh"
            }}
          />
        )}
      </Stack>
      {error && (
        <MessageBar messageBarType={MessageBarType.error}>
          {String(error)}
        </MessageBar>
      )}
      <Stack
        horizontal
        tokens={{
          childrenGap: theme.spacing.m
        }}
      >
        <Stack
          tokens={{
            childrenGap: theme.spacing.s2
          }}
          styles={{
            root: {
              flex: 1
            }
          }}
        >
          <Shimmer isDataLoaded={!isLoading}>
            <Text>
              {identity?.surname}, {identity?.givenName}
            </Text>
          </Shimmer>
          <Shimmer isDataLoaded={!isLoading}>
            <Text>{identity?.id}</Text>
          </Shimmer>
        </Stack>
        <Stack
          tokens={{
            childrenGap: theme.spacing.s2
          }}
          styles={{
            root: {
              flex: 1
            }
          }}
        >
          <Shimmer isDataLoaded={!isLoading}>
            <Text>{identity?.userPrincipalName}</Text>
          </Shimmer>
          <Shimmer isDataLoaded={!isLoading}>
            <Text>{identity?.mail}</Text>
          </Shimmer>
          <Shimmer isDataLoaded={!isLoading}>
            <Text>{identity?.mobilePhone}</Text>
          </Shimmer>
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        <Stack>Sign In Devices</Stack>
        <BhaSignInList identity={identity} />
      </Stack>
    </Stack>
  );
};
