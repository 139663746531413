import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { Report } from "modules/tenants/sections/pros/reports/Report";
import { ReportList } from "modules/tenants/sections/pros/reports/ReportList";
import { useState } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  ReportsType,
  SiteAvailableReport,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

interface Props {
  tenant: Tenant;
  type: ReportsType;
  component: SiteComponentType;
}

const ReportsBase = ({ tenant, type, component }: Props) => {
  const theme = useTheme();
  const [report, setReport] = useState<SiteAvailableReport | undefined>();

  return (
    <Stack horizontal verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
      <ReportList
        tenantId={tenant.id}
        type={type}
        component={component}
        onReportSelected={setReport}
      />
      <Report tenantId={tenant.id} component={component} report={report} />
    </Stack>
  );
};

export const Reports = withPermissions(
  ReportsBase,
  [Permissions.ProsSiteManagementRead, Permissions.ProsSiteManagementWrite],
  "or"
);
