import React, { FunctionComponent } from "react";
import { useWatch } from "react-hook-form";

import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormUsersSelect } from "@components/form/fields/FormUsersSelect";

import { FormLicenceTypesSelect } from "./FormLicenceTypesSelect";
import { FormProductTypesSelect } from "./FormProductSelect";
import { licenceFormValidator } from "./LicenceFormDialog.validator";

interface LicenceFormFieldsProps {
  isManagedLicence: boolean;
  tenantId: string;
}
export const LicenceFormFields: FunctionComponent<LicenceFormFieldsProps> = ({
  isManagedLicence,
  tenantId
}) => {
  const isActive = useWatch({ name: "isActive" });
  const licenceTypeCode = useWatch({ name: "licenceTypeCode" });

  return (
    <>
      <FormLicenceTypesSelect
        required
        label="Licence type code"
        name="licenceTypeCode"
        rules={licenceFormValidator.licenceTypeCode}
        disabled={isManagedLicence}
      />
      <FormProductTypesSelect
        required
        name="productId"
        label="Product"
        rules={licenceFormValidator.product}
        licenceTypeCode={licenceTypeCode}
        disabled={isManagedLicence}
      />
      <FormUsersSelect label="User" name="userId" tenantId={tenantId} />
      <FormDatePicker
        required
        label="Expiry"
        name="expiryDate"
        calendarProps={{ minDate: new Date() }}
        rules={licenceFormValidator.expiry(isActive)}
      />
      <FormSwitch label="Enabled" name="isActive" />
    </>
  );
};
