import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { FunctionComponent } from "react";

import { IColumn } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import {
  ServiceAgreementDto,
  ServiceAgreementsArgs
} from "@libs/api/gateways/cam/cam-gateway.dtos";
import { useServiceAgreements } from "@libs/api/gateways/cam/cam-gateway.hooks";

interface ServiceAgreementsListProps {
  filter: ServiceAgreementsArgs;
}

export const ServiceAgreementsList: FunctionComponent<ServiceAgreementsListProps> = ({
  filter
}) => {
  const columns: IColumn[] = [
    {
      key: "tenantId",
      name: "Tenant Id",
      onRender: (item: ServiceAgreementDto) => item.tenantId,
      minWidth: 100,
      maxWidth: 300
    },
    {
      key: "productFamily",
      name: "Product Family",
      onRender: (item: ServiceAgreementDto) => item.productFamily,
      minWidth: 100,
      maxWidth: 200
    },
    {
      key: "acceptedDateTime",
      name: "Accepted Date Time",
      onRender: (item: ServiceAgreementDto) =>
        item.acceptedDateTime
          ? DateTime.fromISO(item.acceptedDateTime).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : undefined,
      minWidth: 100,
      maxWidth: 100
    }
  ];

  const { data = [], isLoading, error } = useServiceAgreements(filter);

  return (
    <ShimmeredDetailsList
      items={data}
      columns={columns}
      enableShimmer={isLoading}
      errorMessage={error?.message}
    />
  );
};
