import { withPermissions } from "@components/withPermissions";
import { UserModal } from "modules/system/sections/env/components/UserModal";
import { UsersTable } from "modules/system/sections/env/components/UsersTable";
import { useState } from "react";

import { DefaultButton, IIconProps, IStackTokens, Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { EnvUser, NewEnvUser } from "@libs/api/gateways/env/env-gateway.dtos";
import {
  usePermissionsCheckQuery,
  useUpsertUserMutation,
  useUsersQuery
} from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

const UsersBase = () => {
  const usersQuery = useUsersQuery();
  const updateUserMutation = useUpsertUserMutation();

  const [showUserModal, setShowUserModal] = useState<boolean>();
  const [selectedUser, setSelectedUser] = useState<EnvUser | undefined>();

  const addUserIcon: IIconProps = { iconName: "AddFriend" };
  const stackTokens: IStackTokens = { childrenGap: 20 };

  const selectUser = (user: EnvUser | undefined) => {
    setSelectedUser(user);
    setShowUserModal(true);
  };

  const onSave = async (user: EnvUser | NewEnvUser, isCreate: boolean) => {
    await updateUserMutation.mutateAsync({ user, isCreate });
  };

  const onDismiss = () => {
    setShowUserModal(false);
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.EnvSecurityWrite
  );

  return (
    <QueryStateIndicator<EnvUser[]> {...usersQuery}>
      {users => (
        <Stack tokens={stackTokens} verticalFill>
          <DefaultButton
            iconProps={addUserIcon}
            onClick={() => selectUser(undefined)}
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            primary
            styles={{ root: { width: "fit-content" } }}
          >
            Create user
          </DefaultButton>
          <UsersTable users={users} onSelectUser={selectUser} />
          <UserModal
            key={`${selectedUser?.id}${selectedUser?.etag}` || Math.random()}
            hidden={!showUserModal}
            onDismiss={onDismiss}
            onSave={onSave}
            user={selectedUser}
          />
        </Stack>
      )}
    </QueryStateIndicator>
  );
};

export const Users = withPermissions(
  UsersBase,
  [Permissions.EnvSecurityRead, Permissions.EnvSecurityWrite],
  "or"
);
