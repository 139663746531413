import { withPermissions } from "@components/withPermissions";
import { SecurableComponent } from "@components/SecurableComponent";
import { Permissions } from "@libs/permissions/permissions.enum";
import { EntitySequenceState } from "modules/tenants/sections/pros/data-sync/entity-sequence-state";
import { EntitySnapshots } from "modules/tenants/sections/pros/data-sync/entity-snapshots";
import { ReplicationQueueProcessingState } from "modules/tenants/sections/pros/data-sync/replication-queue-processing-state";

import { Stack, useTheme } from "@bps/fluent-ui";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

const DataSyncBase = ({ tenant }: Props) => {
  const theme = useTheme();

  return (
    <Stack verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
      <Stack.Item>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <ReplicationQueueProcessingState siteId={tenant.id} />
        </SecurableComponent>
      </Stack.Item>
      <Stack.Item>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <EntitySnapshots tenant={tenant} />
        </SecurableComponent>
      </Stack.Item>
      <Stack.Item>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <EntitySequenceState tenant={tenant} />
        </SecurableComponent>
      </Stack.Item>
    </Stack>
  );
};

export const DataSync = withPermissions(
  DataSyncBase,
  [Permissions.ProsSiteManagementRead, Permissions.ProsSiteManagementWrite],
  "or"
);
