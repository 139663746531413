import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";

import { InvitationsFilter } from "./components/InvitationsFilter";
import { InvitationsList } from "./components/InvitationsList";

export const InvitationsBase: FunctionComponent = () => {
  return (
    <Stack tokens={{ childrenGap: 16 }} styles={{ root: { height: "100%" } }}>
      <SectionTitle>Invitations</SectionTitle>
      <InvitationsFilter children={args => <InvitationsList args={args} />} />
    </Stack>
  );
};

export const Invitations = withPermissions(
  InvitationsBase,
  [Permissions.PltInvitationRead, Permissions.PltInvitationWrite],
  "or"
);
