import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";

const authenticatedFilterValues = [true, false];

export interface IdentifiersFilterValues {
  name: string;
  authenticated: string[];
}

export const IdentifiersFilter = ({ children }: PropsWithChildren<any>) => {
  const filterItems: Item<IdentifiersFilterValues>[] = [
    {
      name: "name",
      type: "searchBox",
      stickItem: true,
      props: { placeholder: "Search by name", removeSpecialCharacters: false }
    },
    {
      name: "authenticated",
      type: "optionsSelect",
      props: {
        placeholder: "Authenticated",
        options: authenticatedFilterValues.map(x => ({
          key: x ? "true" : "false",
          data: x,
          text: x ? "Authenticated" : "Not Authenticated"
        })),
        multiSelect: true,
        calloutWidth: 150,
        hideSearchOption: true,
        hideSelectAllButton: false
      }
    }
  ];

  return (
    <FilterBar<IdentifiersFilterValues>
      items={filterItems}
      initialValues={{
        name: "",
        authenticated: []
      }}
    >
      {children}
    </FilterBar>
  );
};
