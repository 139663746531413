import React, { FunctionComponent, useState } from "react";
import { ChoiceComboBox, DefaultButton, Dialog, Stack } from "@bps/fluent-ui";
import { Permissions } from "@libs/permissions/permissions.enum";
import { TenantTag } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  useUsedTenantTagsQuery,
  useAddTenantTag
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { ApiCallButton } from "@components/buttons/ApiCallButton";
import { QueryStateIndicator } from "@components/QueryStateIndicator";

interface NewTagDialogButtonProps {
  tenantId: string;
  tenantTags: TenantTag[];
  onDismiss: () => void;
}

const NewTagDialogButtonBase: FunctionComponent<NewTagDialogButtonProps> = ({
  onDismiss,
  tenantId,
  tenantTags
}) => {
  const usedTenantTagsQuery = useUsedTenantTagsQuery();
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const { isLoading, error, mutateAsync: addTenantTag } = useAddTenantTag();
  const excludeTags = tenantTags.map(tag => tag.tag);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "New Tag",
        showCloseButton: true,
        onDismiss
      }}
    >
      <QueryStateIndicator allowNullOrUndefined {...usedTenantTagsQuery}>
        {usedTenantTags => {
          const options = (usedTenantTags ?? [])
            .map(tag => ({ key: tag.tag, text: tag.tag }))
            .filter(t => {
              if (!excludeTags || excludeTags.length === 0) {
                return true;
              } else {
                return !excludeTags.includes(t.key);
              }
            });

          return (
            <Stack verticalFill tokens={{ childrenGap: 24 }}>
              <ChoiceComboBox
                placeholder="Enter or select a tag"
                data-name-for-input="ChoiceComboBox"
                allowFreeform
                autoComplete="on"
                selectedKey={selectedTag}
                onChange={(evt, key) => setSelectedTag(key)}
                options={options}
              />
              <ApiCallButton
                error={error}
                isLoading={isLoading}
                primary
                onClick={async () => {
                  if (selectedTag) {
                    await addTenantTag({
                      tenantId,
                      tag: selectedTag
                    });
                    onDismiss();
                  }
                }}
              >
                Add
              </ApiCallButton>
            </Stack>
          );
        }}
      </QueryStateIndicator>
    </Dialog>
  );
};

export const NewTagDialogButton: FunctionComponent<Omit<
  NewTagDialogButtonProps,
  "onDismiss"
>> = ({ tenantId, tenantTags }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogUserWrite
  );
  return (
    <>
      <DefaultButton
        onClick={() => setShowDialog(true)}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      >
        Add Tag
      </DefaultButton>
      {showDialog && (
        <NewTagDialogButtonBase
          onDismiss={() => setShowDialog(false)}
          tenantId={tenantId}
          tenantTags={tenantTags}
        />
      )}
    </>
  );
};
