import { SecurableComponent } from "@components/SecurableComponent";

import {
  DetailsList,
  DetailsListLayoutMode,
  Heading,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Stack
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  SiteComponentLog,
  SiteComponentLogDto
} from "@libs/api/gateways/sia/models/SiteComponentLog";
import { useLogFilesQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { convertBytes } from "@libs/common/common.utils";
import { Permissions } from "@libs/permissions/permissions.enum";

interface SiteLogsProps {
  siteId: string;
}

export const SiteLogs = ({ siteId }: SiteLogsProps) => {
  const query = useLogFilesQuery(siteId);

  const columns = [
    {
      key: "name",
      name: "Name",
      minWidth: 140,
      onRender: (item: SiteComponentLog) => (
        <Link href={item.uri}>{item.name}</Link>
      )
    },
    {
      key: "size",
      name: "Size",
      fieldName: "size",
      minWidth: 140,
      onRender: (item: SiteComponentLog) => convertBytes(item.size)
    },
    {
      key: "date",
      name: "Date",
      fieldName: "date",
      minWidth: 140,
      onRender: (item: SiteComponentLog) =>
        item.date
          ? DateTime.fromISO(item.date).toFormat(
              DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD
            )
          : "--"
    }
  ];

  return (
    <>
      <Heading variant="heading">Logs</Heading>
      <QueryStateIndicator {...query} loadingTitle="Loading log files">
        {(logs: SiteComponentLogDto[]) => (
          <Stack
            grow
            styles={{
              root: {
                position: "relative",
                paddingBottom: 10,
                minHeight: 100
              }
            }}
            tokens={{ childrenGap: 20 }}
          >
            <SecurableComponent
              permissions={[Permissions.ProsSiteManagementRead]}
            >
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                  selectionMode={SelectionMode.none}
                  columns={columns}
                  items={logs}
                  layoutMode={DetailsListLayoutMode.justified}
                  data-test="site-logs-list"
                />
              </ScrollablePane>
            </SecurableComponent>
          </Stack>
        )}
      </QueryStateIndicator>
    </>
  );
};
