import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { FunctionComponent, useState } from "react";

import {
  CollapsibleCard,
  confirm,
  CopyToClipboardButton,
  Dialog,
  FontIcon,
  Heading,
  IconButton,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  ApplicationDto,
  CloudApplicationScopeDto,
  CloudApplicationSecretDto,
  IpRangeDto
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import {
  useApplicationQuery,
  useDeleteApplicationSecret,
  useGatewayScopesQuery
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { CreateSecretDialog } from "./CreateSecretDialog";
import { EditScopesDialog } from "./EditScopesDialog";
import { UpdateApplicationDialog } from "./UpdateApplicationDialog";

interface ApplicationProps {
  tenant: Tenant;
  item: ApplicationDto;
  onApplicationUpdate: () => void;
}

interface CreateSecretState {
  showSecretCreatedDialog?: boolean;
  secretText?: string;
}

export const Application: FunctionComponent<ApplicationProps> = ({
  tenant,
  item,
  onApplicationUpdate
}) => {
  const theme = useTheme();
  const applicationQuery = useApplicationQuery({
    applicationId: item.id,
    tenantId: tenant.id
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegAppWrite
  );

  const gatewayScopesQuery = useGatewayScopesQuery();

  const [showEditScopesDialog, setShowEditScopesDialog] = useState<boolean>(
    false
  );

  const [showCreateSecretDialog, setShowCreateSecretDialog] = useState<boolean>(
    false
  );

  const [createSecretState, setCreateSecretState] = useState<
    CreateSecretState
  >();

  const [
    showUpdateApplicationDialog,
    setShowUpdateApplicationDialog
  ] = useState<boolean>(false);

  const availableScopes = { ...gatewayScopesQuery };

  const { mutateAsync: deleteApplicationSecret } = useDeleteApplicationSecret();

  const onDeleteSecret = async (id: string) => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Remove"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: "Remove this secret?",
        subText: "Are you sure you want to remove this secret?"
      }
    });

    if (isConfirmed) {
      await deleteApplicationSecret({
        applicationId: item.id,
        tenantId: tenant.id,
        id
      });
    }
  };

  const secretTableColumns: DataTableColumn<CloudApplicationSecretDto>[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (secret: CloudApplicationSecretDto) => (
        <Text>{secret.displayName}</Text>
      )
    },
    {
      key: "expires",
      name: "Expires",
      minWidth: 200,
      onRender: (secret: CloudApplicationSecretDto) => (
        <Text>
          {DateTime.fromISO(secret.endDateTime!).toFormat(
            DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD
          )}
        </Text>
      ),
      sort: true
    },
    {
      key: "id",
      name: "Id",
      minWidth: 300,
      onRender: (secret: CloudApplicationSecretDto) => <Text>{secret.id}</Text>,
      sort: true
    },
    {
      key: "delete",
      name: "",
      minWidth: 100,
      onRender: (secret: CloudApplicationSecretDto) => (
        <IconButton
          iconProps={{ iconName: "Delete" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => onDeleteSecret(secret.id)}
        />
      ),
      sort: true
    }
  ];

  const scopeTableColumns: DataTableColumn<CloudApplicationScopeDto>[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 300,
      isSorted: true,
      sort: true,
      onRender: (secret: CloudApplicationScopeDto) => (
        <Text>{secret.value}</Text>
      )
    },
    {
      key: "description",
      name: "Description",
      minWidth: 400,
      isSorted: true,
      sort: true,
      onRender: (secret: CloudApplicationScopeDto) => (
        <Text>{secret.description}</Text>
      )
    }
  ];

  const ipRangeTableColumns: DataTableColumn<IpRangeDto>[] = [
    {
      key: "ipRangeBegin",
      name: "IpRange Begin",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (secret: IpRangeDto) => <Text>{secret.ipRangeBegin}</Text>
    },
    {
      key: "ipRangeEnd",
      name: "IpRange End",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (secret: IpRangeDto) => <Text>{secret.ipRangeEnd}</Text>
    }
  ];

  const heading = (
    <Stack verticalAlign="space-between" tokens={{ childrenGap: 10 }}>
      <Stack verticalAlign="start">
        <Text block>{item.name}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <Text bold>Application(Client) Id:</Text>
          <Text>{item.id}</Text>
          <CopyToClipboardButton clipboardContent={item.id} />
        </Stack>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <Text bold>API Gateway Access:</Text>
          <Text> {item.isEnabled ? "Enabled" : "Disabled"}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <Text bold>Created on:</Text>
          <Text>
            {DateTime.fromISO(item.changeLog!.createdDate!).toFormat(
              DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD
            )}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
  return (
    <CollapsibleCard
      heading={heading}
      styles={{
        header: { padding: 16 },
        heading: { display: "block", flexGrow: 1 },
        collapseContent: { padding: 16 },
        headerWrapper: { backgroundColor: theme.palette.neutralLighter }
      }}
    >
      <QueryStateIndicator {...applicationQuery}>
        {data => (
          <>
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 1 }}
            >
              <Stack>
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  tokens={{ childrenGap: 30 }}
                >
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 4 }}
                  >
                    <Text bold>Object Id:</Text>
                    <Text>{data.cloudApplication.id}</Text>
                    <CopyToClipboardButton
                      clipboardContent={data.cloudApplication.id}
                    />
                  </Stack>
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 4 }}
                  >
                    <Text bold>Directory Id: </Text>
                    <Text>{data.cloudApplication.tenantId}</Text>
                    <CopyToClipboardButton
                      clipboardContent={data.cloudApplication.tenantId}
                    />
                  </Stack>
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 4 }}
                  >
                    <Text bold>Max Secret Expiry: </Text>
                    <Text>
                      {" "}
                      {data.cloudApplication.secrets.length > 0 &&
                        data.cloudApplication.secrets
                          .map(x => DateTime.fromISO(x.endDateTime!))
                          .sort()
                          .reverse()[0]
                          .toFormat(DATE_FORMATS.FULL_MONTH_TEXT_MONTH_AHEAD)}
                    </Text>
                  </Stack>
                  {data.cloudApplication.secrets.length > 0 &&
                    data.cloudApplication.secrets.findIndex(
                      x => DateTime.fromISO(x.endDateTime!) <= DateTime.now()
                    ) > -1 && (
                      <Stack
                        horizontal
                        horizontalAlign="center"
                        tokens={{ childrenGap: 4 }}
                      >
                        <Text bold>All Secrets expired: </Text>
                        <FontIcon iconName="WarningSolid" />
                      </Stack>
                    )}
                </Stack>
              </Stack>
              <Stack>
                <PrimaryButton
                  disabled={!hasWritePermission}
                  title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
                  onClick={() => setShowUpdateApplicationDialog(true)}
                >
                  Update Application
                </PrimaryButton>
                {showUpdateApplicationDialog && (
                  <UpdateApplicationDialog
                    application={item}
                    tenant={tenant}
                    onDismiss={() => {
                      setShowUpdateApplicationDialog(false);
                      onApplicationUpdate();
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Separator />
            <Stack tokens={{ childrenGap: 20 }}>
              <Stack
                horizontal
                tokens={{ childrenGap: 1 }}
                horizontalAlign="space-between"
              >
                <Stack tokens={{ childrenGap: 20 }}>
                  <Stack>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 5 }}
                      horizontalAlign="space-between"
                    >
                      <Heading>Scopes</Heading>
                      <PrimaryButton
                        disabled={!hasWritePermission}
                        title={
                          !hasWritePermission ? NO_ACTION_PERMISSION : undefined
                        }
                        onClick={() => setShowEditScopesDialog(true)}
                      >
                        {!data.cloudApplication.scopes.length
                          ? "Create Scopes"
                          : "Edit scopes"}
                      </PrimaryButton>
                    </Stack>
                    <Stack>
                      <DataTable
                        items={availableScopes.data?.filter(x =>
                          data.cloudApplication.scopes.includes(x.id)
                        )}
                        columns={scopeTableColumns}
                        getKey={(scope: CloudApplicationScopeDto) => scope.id}
                      />
                      {!data.cloudApplication.scopes.length &&
                        "There are no scopes configured."}
                      {showEditScopesDialog && (
                        <EditScopesDialog
                          application={item}
                          tenant={tenant}
                          scopes={data.cloudApplication.scopes}
                          availableScopes={availableScopes.data!}
                          onDismiss={() => setShowEditScopesDialog(false)}
                        />
                      )}
                    </Stack>
                  </Stack>
                  <Stack tokens={{ childrenGap: 10 }} verticalAlign="start">
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 10 }}
                      horizontalAlign="space-between"
                    >
                      <Heading>Ip Ranges</Heading>
                      <PrimaryButton
                        disabled={!hasWritePermission}
                        title={
                          !hasWritePermission ? NO_ACTION_PERMISSION : undefined
                        }
                        onClick={() => setShowUpdateApplicationDialog(true)}
                      >
                        Update Ip Ranges
                      </PrimaryButton>
                      {showUpdateApplicationDialog && (
                        <UpdateApplicationDialog
                          application={item}
                          tenant={tenant}
                          onDismiss={() => {
                            setShowUpdateApplicationDialog(false);
                            onApplicationUpdate();
                          }}
                        />
                      )}
                    </Stack>
                    <Stack>
                      <DataTable
                        items={data.ipRanges}
                        columns={ipRangeTableColumns}
                      />
                      {!data.ipRanges?.length &&
                        "There are no Ip Ranges configured."}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="space-between"
                  >
                    <Heading>Secrets</Heading>
                    <PrimaryButton
                      disabled={!hasWritePermission}
                      title={
                        !hasWritePermission ? NO_ACTION_PERMISSION : undefined
                      }
                      onClick={() => setShowCreateSecretDialog(true)}
                    >
                      Create Secret
                    </PrimaryButton>
                  </Stack>
                  <Stack>
                    <DataTable
                      items={data.cloudApplication.secrets}
                      columns={secretTableColumns}
                      getKey={(secret: CloudApplicationSecretDto) => secret.id}
                    />
                    {!data.cloudApplication.secrets.length &&
                      "There are no secrets configured."}
                    {showCreateSecretDialog && (
                      <CreateSecretDialog
                        application={item}
                        tenant={tenant}
                        onDismiss={(
                          createSuccesful: boolean,
                          secretText?: string
                        ) => {
                          setCreateSecretState({
                            showSecretCreatedDialog: createSuccesful,
                            secretText
                          });
                          setShowCreateSecretDialog(false);
                        }}
                      />
                    )}
                    {createSecretState?.showSecretCreatedDialog && (
                      <Dialog
                        hidden={false}
                        dialogContentProps={{
                          title: "Secret Value",
                          showCloseButton: true
                        }}
                        minWidth={450}
                        onDismiss={() => {
                          setCreateSecretState({
                            showSecretCreatedDialog: false,
                            secretText: "" //remove so that its no longer in state
                          });
                        }}
                      >
                        <Stack tokens={{ childrenGap: 20 }}>
                          <Text>The new secret value is displayed below.</Text>
                          <Text>
                            This is the only time you will be able to access
                            this secret.
                          </Text>
                          <Stack horizontal>
                            <Text styles={{ root: { marginTop: 5 } }}>
                              {createSecretState.secretText}
                            </Text>
                            <CopyToClipboardButton
                              clipboardContent={createSecretState.secretText!}
                            />
                          </Stack>
                        </Stack>
                      </Dialog>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </QueryStateIndicator>
    </CollapsibleCard>
  );
};
