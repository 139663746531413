import { FunctionComponent } from "react";

import { DefaultButton } from "@bps/fluent-ui";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import {
  ADD_TYPE_TITLE,
  AddLicenceTypeDialogProps
} from "./AddLicenceTypeDialog.types";
import { AddLicenceTypeForm } from "./AddLicenceTypeForm";

export const AddLicenceTypeDialog: FunctionComponent<AddLicenceTypeDialogProps> = ({
  code,
  setCode
}) => {
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  return (
    <>
      {typeof code !== "undefined" && (
        <AddLicenceTypeForm onDismiss={() => setCode(undefined)} code={code} />
      )}
      <DefaultButton
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        onClick={() => setCode(null)}
      >
        {ADD_TYPE_TITLE}
      </DefaultButton>
    </>
  );
};
