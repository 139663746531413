import React from "react";

import { Stack, useTheme } from "@bps/fluent-ui";

import { AuthActionListItem } from "./AuthActionListItem";
import { FieldDeviceAuthAction } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface AuthActionListProps {
  authActions?: FieldDeviceAuthAction[];
}

export const AuthActionList: React.FC<AuthActionListProps> = ({
  authActions
}) => {
  const theme = useTheme();

  if (authActions === undefined || authActions?.length === 0) {
    return <>No actions have been found.</>;
  }

  return (
    <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
      {authActions?.map(authAction => (
        <AuthActionListItem key={authAction.id} authAction={authAction} />
      ))}
    </Stack>
  );
};
