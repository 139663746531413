import {
  PltUser,
  TenantDefaultUsersAuthenticationDto,
  UserDefaultAuthenticationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface PltUserAuthenticationFormProps {
  user: PltUser;
  tenantAuth: TenantDefaultUsersAuthenticationDto | undefined;
  userAuth: UserDefaultAuthenticationDto | undefined;
}

export interface PltUserAuthenticationFormValues {
  deviceMfaMaxDuration: string;
  sessionMaxDuration: string;
  quickPinRequired: Options;
  longPasswordRequired: Options;
}

export enum Options {
  on = 0,
  off = 1,
  default = 2
}

export const options = [
  { key: Options.default, text: "Default" },
  { key: Options.on, text: "On" },
  { key: Options.off, text: "Off" }
];

export const HINT = "Tenant default value:";
