import { UsersSection } from "modules/users/UsersSection";
import { Route, Routes } from "react-router-dom";

export const UserRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<UsersSection />}>
        <Route path=":sectionId" element={null} />
      </Route>
    </Routes>
  );
};
