import { observer } from "mobx-react";
import {
  SiteComponentVersionStatus,
  SiteComponentVersionStatusProps
} from "modules/tenants/sections/pros/dashboard/site-deployment/SiteComponentVersionStatus";
import { useMemo } from "react";

import { Heading, Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SecurableComponent } from "@components/SecurableComponent";
import { SiteComponentVersion } from "@libs/api/gateways/sia/models/SiteComponentVersion";
import {
  useAvailableComponentVersionsQuery,
  useSiteComponentVersionsQuery
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { CommandRunnerProvider } from "../../../_shared-models/CommandRunner";
import { CommandRunnerStatusBar } from "../CommandRunnerStatusBar";

interface SiteVersionsProps {
  siteId: string;
}

export const SiteVersions = observer(({ siteId }: SiteVersionsProps) => {
  const {
    isLoading: isLoadingCurrentVersions,
    data: currentVersions
  } = useSiteComponentVersionsQuery(siteId);

  const {
    isLoading: isLoadingAllVersions,
    data: allVersionsByComponent
  } = useAvailableComponentVersionsQuery();

  const siteComponentVersionData = useMemo(():
    | SiteComponentVersionStatusProps[]
    | undefined => {
    if (!(currentVersions && allVersionsByComponent)) return undefined;

    // Map to dropdown object
    const versionsMapped = allVersionsByComponent.map(
      dto => new SiteComponentVersion(dto)
    );
    return currentVersions.map(version => {
      const siteComponent = versionsMapped.find(
        o => o.component === version.componentType
      );

      return {
        siteId,
        siteVersion: version,
        siteComponent
      };
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersions, allVersionsByComponent]);

  return (
    <CommandRunnerProvider>
      <Heading variant="heading">Site Versions</Heading>
      <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
        <QueryStateIndicator
          data={siteComponentVersionData}
          isLoading={isLoadingAllVersions || isLoadingCurrentVersions}
          loadingTitle="Loading site component versions"
        >
          {(data: SiteComponentVersionStatusProps[]) => (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              {data.map(props => (
                <SiteComponentVersionStatus
                  key={props.siteComponent?.component}
                  {...props}
                />
              ))}
            </Stack>
          )}
        </QueryStateIndicator>
        <CommandRunnerStatusBar />
      </SecurableComponent>
    </CommandRunnerProvider>
  );
});
