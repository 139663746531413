import { FunctionComponent, useMemo } from "react";
import { ComponentDefDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { IColumn, NoDataTile } from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";

interface ComponentComponentImplementationsProps {
  component: ComponentDefDto;
}
export const ComponentImplementations: FunctionComponent<ComponentComponentImplementationsProps> = ({
  component
}) => {
  const { data = [], isLoading, error } = useComponentsDefs();
  const columns: IColumn[] = [
    {
      key: "code",
      name: "Code",
      minWidth: 100,
      maxWidth: 300,
      onRender: (item: ComponentDefDto) => item.code
    },
    {
      key: "text",
      name: "Name",
      minWidth: 100,
      maxWidth: 400,
      onRender: (item: ComponentDefDto) => item.text
    }
  ];

  const items = useMemo(
    () => component?.implementations.map(dep => data.find(d => d.code === dep)),
    [component?.implementations, data]
  );

  if (!items?.length)
    return (
      <NoDataTile
        textProps={{ text: "No implementations" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
      />
    );
  return (
    <ShimmeredDetailsList
      items={items}
      columns={columns}
      enableShimmer={isLoading}
      errorMessage={error?.message}
      compact
    />
  );
};
