import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { Form } from "@components/form/Form";
import { FormApplicationsSelect } from "@components/form/fields/FormApplicationsSelect";
import { FormIconSelect } from "@components/form/fields/FormIconSelect";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";

import { FormLicenceTypesSelect } from "modules/tenants/sections/plt/licencing/licence-form-dialog/FormLicenceTypesSelect";
import React, { FunctionComponent, useMemo } from "react";

import { Dialog, Option, Stack } from "@bps/fluent-ui";

import {
  defaultProductFormValues,
  ProductFormDialogBaseProps,
  ProductFormDialogProps,
  ProductFormValues
} from "./ProductFormDialog.types";
import { getProductFormValues } from "./utils";
import {
  useAddSalesProduct,
  useSalesProduct,
  useUpdateSalesProduct
} from "@libs/api/gateways/plt/plt-gateway.hooks";

const ProductFormDialogBase: FunctionComponent<Omit<
  ProductFormDialogBaseProps,
  "productId"
> & {
  productId: "new" | string;
}> = ({ closeDialog, productId, product }) => {
  const isNewProduct = productId === "new";

  const {
    mutateAsync: addProduct,
    error: addProductError
  } = useAddSalesProduct();

  const {
    mutateAsync: updateProduct,
    error: updateProductError
  } = useUpdateSalesProduct();

  const defaultValues = useMemo(() => {
    if (isNewProduct || !product) return defaultProductFormValues;
    return getProductFormValues(product);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const onSubmit = async (values: ProductFormValues) => {
    const { isActive, source, ...rest } = values;
    const convertedValues = {
      isInactive: !isActive,
      source: !!source ? source : undefined
    };
    try {
      if (isNewProduct) {
        await addProduct({ ...rest, ...convertedValues });
      } else {
        product &&
          (await updateProduct({ ...product, ...rest, ...convertedValues }));
      }
      closeDialog();
    } catch (e) {
      return e;
    }
  };

  const title: string = `${isNewProduct ? "New" : "Edit"} product`;

  const productsIconsOptions: Option[] = [
    { key: "SchoolDataSyncLogo", text: "" },
    { key: "EaseOfAccess", text: "" },
    { key: "ProgressLoopOuter", text: "" }
  ];

  const NAME_VALIDATION_MESSAGE = "Maximum length is 40 characters.";
  const DESCRIPTION_VALIDATION_MESSAGE = "Maximum length is 150 characters.";

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title,
        showCloseButton: true,
        onDismiss: closeDialog
      }}
      minWidth={550}
    >
      <Form<ProductFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={addProductError ?? updateProductError}
      >
        <FormApplicationsSelect
          name="applicationCode"
          label="Application code"
        />
        <FormTextField name="productFamily" label="Product family" />
        <FormTextField name="productCode" label="Code" />
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <FormTextField
            name="source"
            label="Source"
            required
            rules={{ required: "Source is required" }}
          />
          <FormTextField name="sourceProductId" label="Source product id" />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <FormTextField
            name="name"
            label="Name"
            required
            rules={{ required: ValidationMessages.required }}
          />
          <FormTextField
            name="displayName"
            label="Display name"
            rules={{
              maxLength: {
                value: 40,
                message: NAME_VALIDATION_MESSAGE
              }
            }}
          />
        </Stack>
        <FormTextField
          name="description"
          multiline
          label="Description"
          rules={{
            maxLength: {
              value: 150,
              message: DESCRIPTION_VALIDATION_MESSAGE
            }
          }}
          description={DESCRIPTION_VALIDATION_MESSAGE}
        />
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <FormTextField name="interval" label="Interval" disabled={true} />
          <FormTextField name="country" label="Country" disabled={true} />
        </Stack>
        <FormSwitch label="Active" name="isActive" />
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <FormLicenceTypesSelect
            fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            label="Licence type code"
            name="licenceType"
          />
          <FormIconSelect
            fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            name="iconName"
            label="Icon"
            options={productsIconsOptions}
          />
        </Stack>
      </Form>
    </Dialog>
  );
};

export const ProductFormDialog: FunctionComponent<ProductFormDialogProps> = props => {
  const isNewProduct = props.productId === "new";

  const { data } = useSalesProduct(props.productId!, {
    enabled: !isNewProduct && !!props.productId
  });
  if (!props.productId || (!isNewProduct && !data)) return null;

  return (
    <ProductFormDialogBase
      {...props}
      productId={props.productId}
      product={data}
    />
  );
};
