import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FunctionComponent, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Dialog, Stack, Text } from "@bps/fluent-ui";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { IP_REGEX } from "@libs/validation/validation-rules.constants";
import { IpRanges } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface IpRangeFormDialogProps {
  ipRanges?: IpRanges | null;
  onDismiss: () => void;
}

export interface AddIpRangeFormValues {
  ipRangeBegin?: string;
  ipRangeEnd?: string;
}

export const IpRangeFormDialog: FunctionComponent<IpRangeFormDialogProps> = ({
  onDismiss,
  ipRanges
}) => {
  const form = useFormContext();

  const defaultValues = useMemo(() => {
    if (ipRanges) return ipRanges;
    return {
      ipRangeBegin: "",
      ipRangeEnd: ""
    };
  }, [ipRanges]);

  const ipRangesLocal: IpRanges[] = form.getValues("ipRanges") ?? [];
  const onSubmit = async (formData: AddIpRangeFormValues) => {
    ipRangesLocal?.push({
      ipRangeBegin: formData.ipRangeBegin!,
      ipRangeEnd: formData.ipRangeEnd!
    });
    form.setValue("ipRanges", ipRangesLocal, { shouldDirty: true });
    onDismiss();
  };

  const validate = (value: string | undefined) =>
    (value && IP_REGEX.test(value)) || ValidationMessages.ipRange;

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: ipRanges ? "Edit IpRange" : "Add IpRange",
        showCloseButton: true,
        onDismiss: () => onDismiss()
      }}
      minWidth={450}
    >
      <Form<AddIpRangeFormValues>
        onSubmit={onSubmit}
        defaultValues={ipRanges ? ipRanges : defaultValues}
        showCancelButton
        cancelButtonText="Close"
        submitButtonText="Add"
        onCancel={onDismiss}
      >
        <Stack tokens={{ childrenGap: 8 }} verticalAlign="start" horizontal>
          <FormTextField
            label="IP Range Begin"
            name="ipRangeBegin"
            placeholder="x.x.x.x"
            rules={{
              required: ValidationMessages.required,
              validate
            }}
          />
          <Text styles={{ root: { paddingTop: 32 } }}>to</Text>
          <FormTextField
            name="ipRangeEnd"
            label="IP Range End"
            placeholder="x.x.x.x"
            rules={{
              required: ValidationMessages.required,
              validate
            }}
          />
        </Stack>
      </Form>
    </Dialog>
  );
};
