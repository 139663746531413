import { FunctionComponent } from "react";
import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { ComponentsRequestsList } from "./components/ComponentsRequestsList";
import { ComponentsRequestsFilter } from "./components/ComponentsRequestsFilter";

interface ComponentsActionsRequestsScreenBaseProps {
  tenant: Tenant;
}

const ComponentsActionsRequestsScreenBase: FunctionComponent<ComponentsActionsRequestsScreenBaseProps> = ({
  tenant
}) => {
  const theme = useTheme();

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <SectionTitle>Components requests</SectionTitle>
      <ComponentsRequestsFilter>
        {({ values }) => (
          <ComponentsRequestsList
            filter={{
              tenantId: tenant.id,
              componentAction: values.componentAction,
              componentCode: values.componentCode,
              status: values.status
            }}
          />
        )}
      </ComponentsRequestsFilter>
    </Stack>
  );
};

export const ComponentsActionsRequestsScreen = withPermissions(
  ComponentsActionsRequestsScreenBase,
  [Permissions.PltComponentsRead]
);
