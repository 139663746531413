import Highlight from "react-highlight";

import { MessageBar, MessageBarType, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";

interface LogEntriesComponentProps {
  logEntries: LogEntry[];
}

export const LogEntriesComponent = ({
  logEntries
}: LogEntriesComponentProps) => {
  if (logEntries?.length === 0) {
    return (
      <MessageBar
        messageBarType={MessageBarType.success}
        styles={{
          content: {
            alignItems: "center"
          }
        }}
      >
        No log entries returned.
      </MessageBar>
    );
  }

  function convertLogEntryLevel(level: string) {
    switch (level) {
      case "0":
        return "Debug";
      case "1":
        return "Information";
      case "2":
        return "Warning";
      case "3":
        return "Error";
      default:
        return level;
    }
  }

  return (
    <div
      style={{
        overflowY: "scroll",
        height: "270px",
        width: "100%",
        border: "1px solid grey"
      }}
    >
      <Stack verticalFill>
        <Highlight>
          {logEntries.map(logEntry => {
            return (
              <p key={`${logEntry.logDate}-${logEntry.message}`}>
                {DateTime.fromISO(logEntry.logDate).toFormat("f")} -{" "}
                {convertLogEntryLevel(logEntry.level)} ({logEntry.application}){" "}
                {logEntry.message}
              </p>
            );
          })}
        </Highlight>
      </Stack>
    </div>
  );
};
