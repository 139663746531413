import { withPermissions } from "@components/withPermissions";
import { QueryStateIndicator } from "../../components/QueryStateIndicator";
import { Permissions } from "@libs/permissions/permissions.enum";
import React from "react";
import { useParams } from "react-router-dom";

import { Stack, StackItem, Text, useTheme } from "@bps/fluent-ui";
import { useCustomerAccountQuery } from "@libs/api/gateways/cam/cam-gateway.hooks";
import { CustomerAccount } from "@libs/api/gateways/cam/cam-gateway.dtos";

const CRMClientDetailsSectionBase: React.FC = () => {
  const { crmId } = useParams<{ crmId: string }>();
  const theme = useTheme();
  const customerAccountQuery = useCustomerAccountQuery(crmId!);

  return (
    <QueryStateIndicator<CustomerAccount> {...customerAccountQuery}>
      {customerAccount => (
        <Stack tokens={{ childrenGap: 10 }} verticalFill>
          <Text
            variant="xxLarge"
            styles={{ root: { color: theme.palette.themePrimary } }}
          >
            CRM Overview
          </Text>
          <Stack horizontal>
            <StackItem basis="14em">Name</StackItem>
            <StackItem>{customerAccount.businessName}</StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem basis="14em">CRM Id</StackItem>
            <StackItem>{customerAccount.crmId}</StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem basis="14em">Customer Tenant CRM Id</StackItem>
            <StackItem>{customerAccount.customerTenantCrmId}</StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem basis="14em">Head Office CRM Id</StackItem>
            <StackItem>{customerAccount.headOfficeCrmId}</StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem basis="14em">Billing Client CRM Id</StackItem>
            <StackItem>{customerAccount.billingClientCrmId}</StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem basis="14em">Country</StackItem>
            <StackItem>{customerAccount.country}</StackItem>
          </Stack>
          <Stack horizontal>
            <StackItem basis="14em">Exists In Crm</StackItem>
            <StackItem>{customerAccount.existsInCrm ? "Yes" : "No"}</StackItem>
          </Stack>
          {/*<Text variant="xxLarge" styles={{ root: { color: theme.palette.themePrimary } }}>CRM Record Links</Text>*/}
          {/*<Stack grow styles={{ root: { position: 'relative' } }} >*/}
          {/*    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>*/}
          {/*        <DataTable items={customerAccount.tenants} columns={columns} getKey={(tenant: Tenant) => tenant?.id ?? ''} />*/}
          {/*    </ScrollablePane>*/}
          {/*</Stack>*/}
        </Stack>
      )}
    </QueryStateIndicator>
  );
};

export const CRMClientDetailsSection = withPermissions(
  CRMClientDetailsSectionBase,
  [Permissions.PltCustomerRead, Permissions.PltCustomerWrite],
  "or"
);
