import { DATE_FORMATS, DateTime } from "@bps/utils";
import { CustomerProductsSubscription } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const getCurrentPeriod = (item: CustomerProductsSubscription) => {
  return `${
    item.currentPeriodStart
      ? DateTime.fromISO(item.currentPeriodStart).toFormat(
          DATE_FORMATS.DAY_DEFAULT_FORMAT
        )
      : undefined
  } - ${
    item.currentPeriodEnd
      ? DateTime.fromISO(item.currentPeriodEnd).toFormat(
          DATE_FORMATS.DAY_DEFAULT_FORMAT
        )
      : undefined
  }`;
};
