import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { observer } from "mobx-react";
import {
  commandButton,
  iconClass
} from "modules/tenants/sections/pros/dashboard/commonDashboardStyles";

import { CompoundButton, FontIcon, IButtonProps } from "@bps/fluent-ui";

type SiteCommandButtonProps = Pick<IButtonProps, "onClick" | "menuProps"> & {
  commandName: string;
  iconName: string;
};

export const SiteCommandButton = observer(
  ({ commandName, iconName, ...buttonProps }: SiteCommandButtonProps) => {
    const commandRunner = useCommandRunner();

    return (
      <CompoundButton
        {...buttonProps}
        disabled={commandRunner.isBusy}
        secondaryText={commandName}
        styles={commandButton}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <FontIcon iconName={iconName} className={iconClass} />
          {buttonProps.menuProps ? (
            <div style={{ paddingTop: "5px", paddingLeft: "10px" }}>
              <FontIcon iconName="ChevronDownSmall" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </CompoundButton>
    );
  }
);
