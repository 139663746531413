import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { SubscriptionsScreen } from "./SubscriptionsScreen";
import { SubscriptionEditScreen } from "./SubscriptionEditScreen";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface SubscriptionsRouterProps {
  tenant: Tenant;
}
export const SubscriptionsRouter: FunctionComponent<SubscriptionsRouterProps> = ({
  tenant
}) => {
  return (
    <Routes>
      <Route path=":id" element={<SubscriptionEditScreen tenant={tenant} />} />
      <Route path="*" element={<SubscriptionsScreen tenant={tenant} />} />
    </Routes>
  );
};
