import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { DefaultButton, Heading, Stack, Text } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  PowerBiDataset,
  PowerBiDatasetRefresh
} from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";
import { usePostDatasetRefresh } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.hooks";

interface Props {
  dataset: PowerBiDataset;
}

export const ReportDetailsHeader = ({ dataset }: Props) => {
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.TiReportsWrite
  );

  const refreshReport = usePostDatasetRefresh(dataset.id);
  const onRefreshClick = () => {
    refreshReport.mutate(dataset.id);
  };

  const successfulRefresh = (dataset: PowerBiDataset) => {
    return dataset.refreshes.filter(r => r.status === "Completed");
  };

  const refreshesInLast24Hours = (dataset: PowerBiDataset) => {
    const twentyFourHoursAgo = DateTime.now().minus({ hours: 24 });
    const filter = (refresh: PowerBiDatasetRefresh) => {
      if (refresh.startTime) {
        const startDateTime = DateTime.fromISO(refresh.startTime);
        return startDateTime >= twentyFourHoursAgo;
      }
      return false;
    };
    return dataset.refreshes.filter(filter).length;
  };

  const averageRefreshTime = (dataset: PowerBiDataset) => {
    const successfulRefreshes = successfulRefresh(dataset);
    const totalRefreshTime = successfulRefreshes
      .map(refresh => {
        if (refresh.startTime && refresh.endTime) {
          const startDateTime = DateTime.fromISO(refresh.startTime);
          const endDateTime = DateTime.fromISO(refresh.endTime);
          const seconds = endDateTime.diff(startDateTime, "seconds");
          return seconds.seconds;
        }
        return 0;
      })
      .reduce((acc, val) => acc + val, 0);

    const totalRefreshes = successfulRefreshes.length;
    if (totalRefreshTime > 0 && totalRefreshes > 0) {
      return (totalRefreshTime / totalRefreshes).toFixed(2);
    }
  };

  return (
    <>
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <Heading
          variant="section-heading"
          styles={{ root: { paddingLeft: 12 } }}
        >
          Refresh history
        </Heading>
        <DefaultButton
          iconProps={{ iconName: "Refresh" }}
          onClick={onRefreshClick}
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        >
          Refresh {dataset.name}
        </DefaultButton>
      </Stack>
      <Text variant="small" styles={{ root: { paddingLeft: 12 } }}>
        {refreshesInLast24Hours(dataset)} refreshes in last 24 hours. Average
        refresh time: {averageRefreshTime(dataset)} secs
      </Text>
    </>
  );
};
