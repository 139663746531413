import { DateTime } from "@bps/utils";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export const convertToDateTime = (
  fromDate: Date,
  fromTime: string
): DateTime => {
  const [startHour, startMinute] = fromTime.split(":");

  const result = DateTime.now()
    .setZone("utc")
    .set({
      year: fromDate.getFullYear(),
      month: fromDate.getMonth() + 1,
      day: fromDate.getDate(),
      hour: Number(startHour),
      minute: Number(startMinute)
    });

  return result;
};

export const hasRolloutOccurred = (rollout: Rollout) => {
  return rollout.initialDownloadStartUtc! < DateTime.now().setZone("utc");
};
