import { CopyableGuid } from "@components/CopyableText";
import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { YesOrNoIcon } from "@components/YesOrNoIcon";
import { ConsumerUserIdentitiesExpand } from "modules/users/ConsumerUser/ConsumerUserIdentitiesExpand";
import React from "react";

import {
  IColumn,
  mergeStyles,
  SelectionMode,
  ShimmeredDetailsListProps,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { ConsumerUser } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";

const getSignInTypeId = (
  signInIdentities: ConsumerUser["signInIdentities"],
  signInType: string
) => {
  return signInIdentities?.find(x => x.signInType === signInType)
    ?.issuerAssignedId;
};

export const ConsumerUserIdentitiesTable: React.FC<ShimmeredDetailsListProps> = props => {
  const columns: IColumn[] = [
    {
      key: "id",
      name: "Id",
      minWidth: 100,
      maxWidth: 150,
      onRender: ({ id }) => (id ? <CopyableGuid short value={id} /> : "")
    },
    {
      key: "profileId",
      name: "Profile Id",
      minWidth: 100,
      maxWidth: 150,
      onRender: ({ profileId }) =>
        profileId ? <CopyableGuid short value={profileId} /> : ""
    },
    {
      key: "name",
      name: "Name",
      fieldName: "displayName",
      minWidth: 100,
      maxWidth: 350
    },
    {
      key: "mobile",
      name: "Mobile",
      minWidth: 100,
      maxWidth: 150,
      onRender: ({ signInIdentities }) => (
        <Text nowrap>{getSignInTypeId(signInIdentities, "phoneNumber")}</Text>
      )
    },
    {
      key: "email",
      name: "Email",
      minWidth: 100,
      maxWidth: 300,
      onRender: ({ signInIdentities }) => (
        <Text nowrap>{getSignInTypeId(signInIdentities, "emailAddress")}</Text>
      )
    },
    {
      key: "createdDateTime",
      name: "Created",
      minWidth: 200,
      maxWidth: 200,
      onRender: ({ createdDateTime }: ConsumerUser) =>
        createdDateTime?.toFormat(DATE_FORMATS.DETAILED_DATE_TIME)
    },
    {
      key: "accountEnabled",
      name: "Enabled",
      minWidth: 100,
      maxWidth: 100,
      onRender: ({ accountEnabled }) => <YesOrNoIcon when={accountEnabled} />
    }
  ];
  return (
    <ShimmeredDetailsList
      selectionMode={SelectionMode.none}
      columns={columns}
      className={mergeStyles({
        "& [role=gridcell]": {
          display: "flex",
          alignItems: "center"
        }
      })}
      onRenderRow={row => (
        <DetailsRowExpand
          row={row}
          expand={<ConsumerUserIdentitiesExpand identity={row?.item} />}
        />
      )}
      {...props}
    />
  );
};
