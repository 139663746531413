import {
  DirectionalHint,
  Dropdown,
  DropdownProps,
  Option,
  Stack,
  Text
} from "@bps/fluent-ui";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { VirusScanFailedBadge } from "./VirusScanFailedBadge";

export interface SoftwarePackageVersionDropDownProps extends DropdownProps {}

export const SoftwarePackageVersionDropDown = ({
  ...dropdownProps
}: SoftwarePackageVersionDropDownProps) => {
  const onRenderTitle = (option: Option<SoftwarePackageVersion>[]) => (
    <OptionWithScanCheck {...option[0]} /> // Will only ever have 1 option selected
  );

  const onRenderOption = (option: Option<SoftwarePackageVersion>) => (
    <OptionWithScanCheck {...option} />
  );

  const OptionWithScanCheck = (option: Option<SoftwarePackageVersion>) => (
    <Stack
      horizontal
      styles={{
        root: { justifyContent: "space-between", width: "100%" }
      }}
    >
      <Text>{option.text}</Text>

      {option.data?.virusScanIssueCount! > 0 && (
        <VirusScanFailedBadge
          virusScanIssueCount={option.data?.virusScanIssueCount!}
        />
      )}
    </Stack>
  );

  return (
    <Dropdown
      required
      withNoEmptyOption
      onRenderOption={onRenderOption}
      onRenderTitle={onRenderTitle}
      onClick={e => {
        e.stopPropagation();
        return false;
      }}
      styles={{
        root: {
          minWidth: "200px"
        }
      }}
      calloutProps={{
        calloutMaxHeight: 350,
        directionalHint: DirectionalHint.bottomCenter
      }}
      {...dropdownProps}
    />
  );
};
