import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { DateTimeWithDurationSinceTooltip } from "@components/DateTimeWithDurationSinceTooltip";
import { TableGuidDisplay } from "@components/TableGuidDisplay";
import { useState } from "react";

import {
  DefaultButton,
  SelectionMode,
  ShimmeredDetailsListProps,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";

import { IdentifierHistoryModal } from "./history/IdentifierHistoryModal";
import { FieldDeviceIdentifier } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface IdentifiersTableProps extends ShimmeredDetailsListProps {
  items: FieldDeviceIdentifier[];
}

export const IdentifiersTable = ({
  items,
  ...props
}: IdentifiersTableProps) => {
  const [showHistory, setShowHistory] = useState(false);
  const [selectedIdentifier, setSelectedIdentifier] = useState<
    FieldDeviceIdentifier
  >();

  const onShowIdentifierHistory = (identifier: FieldDeviceIdentifier) => {
    setSelectedIdentifier(identifier);
    setShowHistory(true);
  };

  const onDismiss = () => {
    setShowHistory(false);
    setSelectedIdentifier(undefined);
  };

  const columns: DataTableColumn<FieldDeviceIdentifier>[] = [
    {
      key: "identifierType",
      name: "Name",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      isSorted: true,
      sort: true,
      onRender: (identifier: FieldDeviceIdentifier) => identifier.identifierType
    },
    {
      key: "identifierValue",
      name: "Value",
      minWidth: 150,
      isResizable: true,
      onRender: (identifier: FieldDeviceIdentifier) =>
        identifier.identifierValue
    },
    {
      key: "lastObservedUtc",
      name: "Last Observed",
      minWidth: 150,
      onRender: (identifier: FieldDeviceIdentifier) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={identifier.lastObservedUtc}
        />
      )
    },
    {
      key: "prevObservedUtc",
      name: "Previous Observed",
      minWidth: 150,
      onRender: (identifier: FieldDeviceIdentifier) => (
        <DateTimeWithDurationSinceTooltip
          dateUtc={identifier.prevObservedUtc}
        />
      )
    },
    {
      key: "lastSourceId",
      name: "Last Source ID",
      minWidth: 150,
      onRender: (identifier: FieldDeviceIdentifier) => (
        <TableGuidDisplay id={identifier.lastSourceId} />
      )
    },
    {
      key: "lastSourceType",
      name: "Last Source Type",
      onRender: (identifier: FieldDeviceIdentifier) => identifier.lastSourceType
    },
    {
      key: "observationCount",
      name: "Observation Count",
      onRender: (identifier: FieldDeviceIdentifier) =>
        identifier.observationCount
    },
    {
      key: "authenticated",
      name: "Authenticated",
      onRender: (identifier: FieldDeviceIdentifier) => {
        const authenticatedLabel = identifier.authenticated
          ? "Authenticated"
          : "Not Authenticated";

        return (
          <TextBadge
            hasBorder={true}
            badgeSize={TextBadgeSize.small}
            badgeColor={
              identifier.authenticated
                ? TextBadgeColor.green
                : TextBadgeColor.red
            }
          >
            {authenticatedLabel}
          </TextBadge>
        );
      }
    },
    {
      key: "history",
      name: "History",
      onRender: (identifier: FieldDeviceIdentifier) => {
        return (
          <DefaultButton onClick={() => onShowIdentifierHistory(identifier)}>
            Show History
          </DefaultButton>
        );
      }
    }
  ];

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          flex: "1 1 0",
          overflowY: "auto"
        }
      }}
    >
      <DataTable
        shimmerLines={10}
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
        onShouldVirtualize={() => true}
        {...props}
      />
      {selectedIdentifier && (
        <IdentifierHistoryModal
          identifier={selectedIdentifier}
          show={showHistory}
          onDismiss={onDismiss}
        />
      )}
    </Stack>
  );
};
