import { FunctionComponent, useRef } from "react";

import { FilterBar, FilterBarProps, Item, Option } from "@bps/fluent-ui";
import {
  CalendarEventReminderSearchArgs,
  DateType
} from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.dtos";

interface AppointmentReminderFilterProps
  extends Pick<FilterBarProps<CalendarEventReminderSearchArgs>, "children"> {}

export const AppointmentReminderFilter: FunctionComponent<AppointmentReminderFilterProps> = props => {
  const dateTypeOptions = Object.keys(DateType).map(k => {
    const op: Option<string> = { key: k, text: k };
    return op;
  });

  const items: Item<CalendarEventReminderSearchArgs>[] = [
    {
      type: "optionsSelect",
      name: "dateType",
      stickItem: true,
      props: {
        id: "reminder-filter-date-type",
        placeholder: "Search Type",
        options: dateTypeOptions,
        hideSearchOption: true,
        calloutWidth: 200,
        hideClearButton: true,
        styles: { root: { width: 150, minWidth: 150 } }
      }
    },
    {
      type: "datesRangePicker",
      name: "datesRangePicker",
      stickItem: true,
      props: {
        id: "reminder-filter-dates-range",
        startDateProps: { id: "reminder-filter-start-date" },
        endDateProps: { id: "reminder-filter-end-date" },
        startDateName: "startDateTime",
        endDateName: "endDateTime",
        placeholder: "Date(s)"
      }
    },
    {
      type: "searchBox",
      name: "phoneNumber",
      stickItem: true,
      props: {
        placeholder: "Search for phone number",
        removeSpecialCharacters: false
      }
    }
  ];

  const initialValues = useRef<CalendarEventReminderSearchArgs>({
    orgUnitId: undefined,
    dateType: DateType.CalendarEvent,
    phoneNumber: undefined,
    startDateTime: undefined,
    endDateTime: undefined
  });

  return (
    <FilterBar<CalendarEventReminderSearchArgs>
      {...props}
      items={items}
      initialValues={initialValues.current}
    />
  );
};
