import { IContextualMenuProps } from "@bps/fluent-ui";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { useComponentStateUpdateMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  SiteComponentState,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import { getShortComponentTypeName } from "@libs/api/gateways/sia/sia-ops-gateway.utils";

interface Props {
  state: SiteComponentState;
  commandPrefix: string;
  commandName: string;
  iconName: string;
  siteId: string;
}

const ModifySiteComponentStateCommandButton = ({
  siteId,
  state,
  commandPrefix,
  commandName,
  iconName
}: Props) => {
  const commandRunner = useCommandRunner();

  const mutation = useComponentStateUpdateMutation();
  const handleUpdateState = (componentType: SiteComponentType) => () =>
    commandRunner.runMutation(
      `${commandPrefix} ${getShortComponentTypeName(componentType)}`,
      mutation,
      {
        siteId,
        componentType,
        state
      }
    );

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: SiteComponentType.SiteAgent,
        text: `${commandPrefix} site agent`,
        onClick: handleUpdateState(SiteComponentType.SiteAgent)
      },
      {
        key: SiteComponentType.SiteManager,
        text: `${commandPrefix} site manager`,
        onClick: handleUpdateState(SiteComponentType.SiteManager)
      }
    ]
  };

  return (
    <SiteCommandButton
      commandName={commandName}
      menuProps={menuProps}
      iconName={iconName}
    />
  );
};

export const StartSiteComponentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteComponentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Start}
      commandName="Start Site Component"
      commandPrefix="Start"
      iconName="Play"
    />
  );
};

export const StopSiteComponentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteComponentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Stop}
      commandName="Stop Site Component"
      commandPrefix="Stop"
      iconName="Stop"
    />
  );
};

export const RestartSiteComponentCommandButton = ({
  siteId
}: {
  siteId: string;
}) => {
  return (
    <ModifySiteComponentStateCommandButton
      siteId={siteId}
      state={SiteComponentState.Restart}
      commandName="Restart Site Component"
      commandPrefix="Restart"
      iconName="Refresh"
    />
  );
};
