import { FunctionComponent } from "react";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useComponent } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Shimmer } from "@bps/fluent-ui";

interface ComponentActionCodeProps {
  codeOrId: string;
}
export const ComponentActionCode: FunctionComponent<ComponentActionCodeProps> = ({
  codeOrId
}) => {
  const componentQuery = useComponent({ codeOrId });
  return (
    <QueryStateIndicator {...componentQuery} LoadingComponent={<Shimmer />}>
      {component => <>{component.code}</>}
    </QueryStateIndicator>
  );
};
