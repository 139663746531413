import { useCommandRunner } from "../../../_shared-models/CommandRunner";

import { ProvisionTenantFeatureItem } from "modules/tenants/sections/components/ProvisionTenantFeatureItem";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";

import { IContextualMenuProps } from "@bps/fluent-ui";
import { useEnableDisableTenantFeatureMutation } from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  FeatureAction,
  TenantFeature
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export const ProvisionFeatureCommandButton = (props: { siteId: string }) => {
  const { siteId } = props;
  const commandRunner = useCommandRunner();
  const provisionTenantFeatureMutation = useEnableDisableTenantFeatureMutation();

  const onMenuItemClick = (
    tenantId: string,
    feature: TenantFeature,
    action: FeatureAction
  ) => {
    commandRunner.runMutation(
      `${action === FeatureAction.Enable ? "Enable" : "Disable"} Feature`,
      provisionTenantFeatureMutation,
      {
        tenantId,
        featureCode: feature,
        action
      }
    );
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: TenantFeature.BhbIntegration,
        onRender: () => {
          return (
            <ProvisionTenantFeatureItem
              tenantId={siteId}
              feature={TenantFeature.BhbIntegration}
              action={FeatureAction.Enable}
              onMenuItemClick={onMenuItemClick}
            >
              Provision BHB Integration
            </ProvisionTenantFeatureItem>
          );
        }
      },
      {
        key: TenantFeature.AvailabilityCalculator,
        onRender: () => {
          return (
            <ProvisionTenantFeatureItem
              tenantId={siteId}
              feature={TenantFeature.AvailabilityCalculator}
              action={FeatureAction.Enable}
              onMenuItemClick={onMenuItemClick}
            >
              Provision Availability Calculator
            </ProvisionTenantFeatureItem>
          );
        }
      },
      {
        key: `${TenantFeature.AvailabilityCalculatorOnSite}-enable`,
        onRender: () => {
          return (
            <ProvisionTenantFeatureItem
              tenantId={siteId}
              feature={TenantFeature.AvailabilityCalculatorOnSite}
              action={FeatureAction.Enable}
              onMenuItemClick={onMenuItemClick}
            >
              Enable Availability On-Site Calculation
            </ProvisionTenantFeatureItem>
          );
        }
      },
      {
        key: `${TenantFeature.AvailabilityCalculatorOnSite}-disable`,
        onRender: () => {
          return (
            <ProvisionTenantFeatureItem
              tenantId={siteId}
              feature={TenantFeature.AvailabilityCalculatorOnSite}
              action={FeatureAction.Disable}
              onMenuItemClick={onMenuItemClick}
            >
              Disable Availability On-Site Calculation
            </ProvisionTenantFeatureItem>
          );
        }
      },
      {
        key: `${TenantFeature.TenantReplicationMessageIsolation}-enable`,
        onRender: () => {
          return (
            <ProvisionTenantFeatureItem
              tenantId={siteId}
              feature={TenantFeature.TenantReplicationMessageIsolation}
              action={FeatureAction.Enable}
              onMenuItemClick={onMenuItemClick}
            >
              Enable Tenant Replication Message Isolation
            </ProvisionTenantFeatureItem>
          );
        }
      },
      {
        key: `${TenantFeature.TenantReplicationMessageIsolation}-disable`,
        onRender: () => {
          return (
            <ProvisionTenantFeatureItem
              tenantId={siteId}
              feature={TenantFeature.TenantReplicationMessageIsolation}
              action={FeatureAction.Disable}
              onMenuItemClick={onMenuItemClick}
            >
              Disable Tenant Replication Message Isolation
            </ProvisionTenantFeatureItem>
          );
        }
      }
    ]
  };

  return (
    <SiteCommandButton
      commandName="Provision Feature"
      menuProps={menuProps}
      iconName="WebAppBuilderFragmentCreate"
    />
  );
};
