import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { AppointmentStatsTable } from "modules/tenants/sections/best-health/appointments/AppointmentStatsTable";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  AppointmentStats,
  LocationAppointmentStats
} from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";
import {
  useAppointmentStatsQuery,
  useLocationAppointmentStatsQuery
} from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { Permissions } from "@libs/permissions/permissions.enum";

import { LocationStatsTable } from "./LocationStatsTable";

interface Props {
  tenant: Tenant;
}

const BestHealthAppointmentStatsBase = ({ tenant }: Props) => {
  const tenantStatsQuery = useAppointmentStatsQuery(tenant.id);
  const locationStatsQuery = useLocationAppointmentStatsQuery(tenant.id);
  return (
    <>
      <SectionTitle>Tenant Appointment Statistics Summary</SectionTitle>
      <QueryStateIndicator<AppointmentStats> {...tenantStatsQuery}>
        {data => <AppointmentStatsTable stats={data} />}
      </QueryStateIndicator>

      <SectionTitle>Location Appointment Statistics Summary</SectionTitle>
      <QueryStateIndicator<LocationAppointmentStats[]> {...locationStatsQuery}>
        {data => <LocationStatsTable stats={data} />}
      </QueryStateIndicator>
    </>
  );
};

export const BestHealthAppointmentStats = withPermissions(
  BestHealthAppointmentStatsBase,
  [
    Permissions.BhbPracticeManagementWrite,
    Permissions.BhbPracticeManagementRead,
    Permissions.OpsConsolePreRelease
  ],
  "or"
);
