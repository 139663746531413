import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { FilterBar, FilterBarProps, Item } from "@bps/fluent-ui";
import { useComponentVersions } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

export interface ProsDeployFilterValues {
  search: string;
  siteManagerVersion: string[];
  siteAgentVersion: string[];
}

export const ProsDeployFilter = ({
  children
}: Pick<FilterBarProps, "children">) => {
  const { data: siteAgentVersions } = useComponentVersions(
    SiteComponentType.SiteAgent
  );

  const { data: siteManagerVersions } = useComponentVersions(
    SiteComponentType.SiteManager
  );

  const filterItems: Item<ProsDeployFilterValues>[] = [
    {
      type: "searchBox",
      name: "search",
      stickItem: true,
      props: {
        placeholder: "Filter tenant",
        removeSpecialCharacters: false
      }
    },
    {
      type: "optionsSelect",
      name: "siteManagerVersion",
      props: {
        placeholder: "Site Manager Version",
        options: siteManagerVersions
          ? siteManagerVersions.map(x => ({
              key: x,
              data: x,
              text: x
            }))
          : [],
        multiSelect: true,
        calloutWidth: 150,
        hideSearchOption: true,
        hideSelectAllButton: false
      }
    },
    {
      type: "optionsSelect",
      name: "siteAgentVersion",
      props: {
        placeholder: "Site Agent Version",
        options: siteAgentVersions
          ? siteAgentVersions.map(x => ({
              key: x,
              data: x,
              text: x
            }))
          : [],
        multiSelect: true,
        calloutWidth: 150,
        hideSearchOption: true,
        hideSelectAllButton: false
      }
    }
  ];

  return (
    <FilterBar<ProsDeployFilterValues>
      items={filterItems}
      initialValues={{
        search: "",
        siteManagerVersion: [],
        siteAgentVersion: []
      }}
    >
      {children}
    </FilterBar>
  );
};
