import { SalesProductDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface ProductFormValues
  extends Omit<SalesProductDto, "changeLog" | "isInactive" | "id"> {
  isActive: boolean;
}
export type ProductId = string | "new" | undefined;

export interface ProductFormDialogProps {
  productId: ProductId;
  closeDialog: () => void;
}

export interface ProductFormDialogBaseProps
  extends Omit<ProductFormDialogProps, "productId"> {
  productId: "new" | string;
  product: SalesProductDto | undefined;
}

export const defaultProductFormValues: ProductFormValues = {
  isActive: false,
  productCode: "",
  applicationCode: undefined,
  licenceType: "",
  sourceProductId: "",
  source: "",
  description: "",
  name: "",
  displayName: ""
};
