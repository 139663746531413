import React, { FunctionComponent } from "react";
import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { ComponentsDefinitionsList } from "./components/ComponentsDefinitionsList";
import { ComponentsDefinitionsFilter } from "./components/ComponentsDefinitionsFilter";

const ComponentsDefinitionsScreenBase: FunctionComponent = () => {
  const theme = useTheme();
  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <SectionTitle>Components definitions</SectionTitle>
      <ComponentsDefinitionsFilter>
        {({ values }) => <ComponentsDefinitionsList filter={values} />}
      </ComponentsDefinitionsFilter>
    </Stack>
  );
};

export const ComponentsDefinitionsScreen = withPermissions(
  ComponentsDefinitionsScreenBase,
  [Permissions.PltComponentsRead]
);
