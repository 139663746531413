import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import React from "react";

import { ShimmeredDetailsListProps, Text } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { OutboundEmailAudit } from "@libs/api/gateways/plt-comms/models/OutboundEmailAudit";

interface EmailAuditingTableProps extends ShimmeredDetailsListProps {
  items: OutboundEmailAudit[];
}

export const EmailAuditingTable: React.FC<EmailAuditingTableProps> = props => {
  const columns: DataTableColumn<OutboundEmailAudit>[] = [
    {
      key: "createdDate",
      name: "Created Date",
      minWidth: 250,
      maxWidth: 250,
      onRender: (email: OutboundEmailAudit) => {
        return (
          <Text>
            {email.createdDate?.toFormat(
              DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD
            )}
          </Text>
        );
      }
    },
    {
      key: "email",
      name: "Email",
      minWidth: 300,
      maxWidth: 300
    },
    {
      key: "subject",
      name: "Subject",
      minWidth: 200
    }
  ];

  return (
    <DataTable
      shimmerLines={10}
      columns={columns}
      onShouldVirtualize={() => true}
      {...props}
    />
  );
};
