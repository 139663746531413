import React, { FunctionComponent, useState } from "react";
import { useWatch } from "react-hook-form";

import { NoDataTile, Separator, Stack } from "@bps/fluent-ui";

import { SubscriptionDetails } from "../subscription-details/SubscriptionDetails";
import { SubscriptionItems } from "./SubscriptionItems";
import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import { SubscriptionFormHeader } from "./SubscriptionFormHeader";
import {
  CustomerProductsSubscription,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface SubscriptionEditFormFieldsProps {
  tenant: Tenant;
  subscription: CustomerProductsSubscription | undefined;
}
export const SubscriptionEditFormFields: FunctionComponent<SubscriptionEditFormFieldsProps> = ({
  tenant,
  subscription
}) => {
  // state to show/hide add new subscription item dialog
  const [hidden, setHidden] = useState<boolean>(true);

  const subscriptionProducts: SubscriptionItemProducts[] = useWatch({
    name: "subscriptionProducts"
  });

  return (
    <Stack grow tokens={{ childrenGap: 24 }}>
      <SubscriptionFormHeader
        subscription={subscription}
        tenant={tenant}
        hidden={hidden}
        setHidden={setHidden}
      />
      <Stack grow>
        {subscriptionProducts.length ? (
          <>
            <SubscriptionDetails
              subscription={subscription}
              tenantId={tenant.id}
              customerTenantId={tenant.customerTenantId}
            />
            <Separator />
            <SubscriptionItems
              subscription={subscription}
              subscriptionProducts={subscriptionProducts}
              tenant={tenant}
            />
          </>
        ) : (
          <NoDataTile
            showBoxShadow={false}
            textProps={{ text: "There not subscription items" }}
            linkProps={{
              text: "Add new subscription item",
              onClick: () => setHidden(false)
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
