import { Route, Routes } from "react-router-dom";

import { EmailsAuditing } from "./EmailsAuditing";

export const EmailsAuditingRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<EmailsAuditing />} />
    </Routes>
  );
};
