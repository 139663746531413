import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { useState } from "react";

import { Link, Stack, Text, useTheme } from "@bps/fluent-ui";

import { AssignSelectorDialog } from "./assign";
import { DeploymentRingSelector } from "./DeploymentRingSelector";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface DeploymentRingSelectorHostProps {
  deploymentRing: DeploymentRing;
}

export const DeploymentRingSelectorHost: React.FC<DeploymentRingSelectorHostProps> = ({
  deploymentRing
}) => {
  const theme = useTheme();
  const [showAssignSelector, setShowAssignSelector] = useState<boolean>(false);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <>
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Text variant="xLarge">Selector</Text>
        </Stack>
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          {!!deploymentRing.selectorId ? (
            <DeploymentRingSelector deploymentRing={deploymentRing} />
          ) : (
            <>
              <Link
                onClick={() => setShowAssignSelector(true)}
                disabled={!hasWritePermission}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              >
                No selector assigned.
              </Link>
              <AssignSelectorDialog
                deploymentRing={deploymentRing}
                hidden={!showAssignSelector}
                onDismiss={() => setShowAssignSelector(false)}
              />
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
