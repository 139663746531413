import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { PltUsersRowExpand } from "modules/users/PltUsers/PltUsersRowExpand";
import React, { useCallback, useMemo, useState } from "react";

import {
  DetailsListProps,
  IColumn,
  ShimmeredDetailsListProps,
  Text,
  Stack,
  ScrollablePane
} from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { PltUserStatusBadge } from "./PltUserStatusBadge";

enum ColumnKeys {
  Tenant = "tenant",
  Status = "status",
  Username = "username",
  Roles = "roles"
}

function copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T;
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
}

interface PltUsersListProps extends ShimmeredDetailsListProps {}

export const PltUsersList: React.FC<PltUsersListProps> = ({
  items,
  ...props
}) => {
  const [sortColumn, setSortColumn] = useState<string>(ColumnKeys.Tenant);
  const [sortDesc, setSortDesc] = useState<boolean>(false);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        isResizable: true,
        key: ColumnKeys.Tenant,
        isSorted: sortColumn === ColumnKeys.Tenant,
        isSortedDescending: sortDesc,
        name: "Tenant",
        minWidth: 300,
        maxWidth: 300,
        fieldName: "tenantId"
      },
      {
        isResizable: true,
        key: ColumnKeys.Status,
        isSorted: sortColumn === ColumnKeys.Status,
        isSortedDescending: sortDesc,
        name: "Status",
        minWidth: 140,
        maxWidth: 140,
        onRender: PltUserStatusBadge
      },
      {
        isResizable: true,
        key: ColumnKeys.Username,
        isSorted: sortColumn === ColumnKeys.Username,
        isSortedDescending: sortDesc,
        name: "Username",
        minWidth: 300,
        maxWidth: 300,
        fieldName: "username"
      },
      {
        isResizable: true,
        key: ColumnKeys.Roles,
        isSorted: sortColumn === ColumnKeys.Roles,
        isSortedDescending: sortDesc,
        name: "Roles",
        minWidth: 200,
        onRender: item => (
          <Text nowrap>{item?.roles?.map(role => role.code).join(", ")}</Text>
        )
      }
    ],
    [sortColumn, sortDesc]
  );

  const onColumnHeaderClick: DetailsListProps["onColumnHeaderClick"] = useCallback(
    (e, column) => {
      if (sortColumn === column?.key) {
        setSortDesc(_sortAsc => !_sortAsc);
      } else {
        setSortColumn(column?.key);
      }
    },
    [sortColumn]
  );

  const sortedItems = useMemo(() => copyAndSort(items, sortColumn, sortDesc), [
    sortColumn,
    sortDesc,
    items
  ]);

  return (
    <Stack styles={{ root: { position: "relative", width: "100%" } }} grow>
      <ScrollablePane>
        <ShimmeredDetailsList
          {...props}
          items={sortedItems}
          columns={columns}
          stickyHeader
          onColumnHeaderClick={onColumnHeaderClick}
          onRenderRow={row => {
            return (
              <DetailsRowExpand
                row={row}
                expand={<PltUsersRowExpand user={row?.item} />}
              />
            );
          }}
        />
      </ScrollablePane>
    </Stack>
  );
};
