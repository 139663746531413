import {
  ExpandableNav,
  ExpandableNavItem,
  SectionConfig
} from "@components/ExpandableNav";
import { Permissions } from "@libs/permissions/permissions.enum";

import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { useCallback, useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import {
  useFieldDeviceConfiguration,
  useFieldDeviceReportedConfig
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import {
  FieldDeviceDateRangeSoftwarePackageVersionConfig,
  SoftwarePackageType
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  combinedSoftwarePackages,
  shorthandServiceName
} from "@libs/api/gateways/field/field-ops-gateway.utils";
import { FieldDeviceTabs } from "../field-device-tabs.constants";

interface FieldDeviceSectionsProps {
  id: string;
}

export const FieldDeviceSections: React.FC<FieldDeviceSectionsProps> = ({
  id
}) => {
  const location = useLocation();
  const match = matchPath(
    "/field-mgmt/:fieldDeviceId/:page",
    location.pathname
  );

  const sectionId = match?.params.page;
  const { data: deviceReportedConfig } = useFieldDeviceReportedConfig(id);

  const { data: deviceDesiredConfig } = useFieldDeviceConfiguration(id);

  const navigate = useNavigate();

  const onSectionChange = useCallback(
    (nextId: string) => {
      navigate(`/field-mgmt/${id}/${nextId}`);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const modulesTabs = useMemo(() => {
    const desiredModules = [] as FieldDeviceDateRangeSoftwarePackageVersionConfig[];

    deviceDesiredConfig?.componentsToInstall.forEach(component => {
      Object.keys(component.modules!).forEach(key => {
        if (component.modules![key].type === SoftwarePackageType.Module) {
          desiredModules.push(component.modules![key]);
        }
      });
    });

    const combinedModules = combinedSoftwarePackages(
      desiredModules,
      deviceReportedConfig?.filter(
        item => item.softwarePackageType === SoftwarePackageType.Module
      ) ?? []
    );

    return (
      combinedModules.map(
        module =>
          ({
            id: `modules/${module?.softwarePackageCode}`,
            name: module.name
          } as SectionConfig)
      ) ?? []
    );
  }, [deviceDesiredConfig?.componentsToInstall, deviceReportedConfig]);

  const serviceTabs = useMemo(() => {
    const combinedServices = combinedSoftwarePackages(
      deviceDesiredConfig?.componentsToInstall.filter(
        c => c.type === SoftwarePackageType.Service
      ) ?? [],
      deviceReportedConfig?.filter(
        item => item.softwarePackageType === SoftwarePackageType.Service
      ) ?? []
    );

    return (
      combinedServices.map(
        service =>
          ({
            id: `services/${service?.softwarePackageCode}`,
            name: shorthandServiceName(service)
          } as SectionConfig)
      ) ?? []
    );
  }, [deviceDesiredConfig?.componentsToInstall, deviceReportedConfig]);

  const { data: hasReadPermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementRead
  );

  const items: ExpandableNavItem[] = [
    {
      name: "Device",
      links: Object.values(FieldDeviceTabs.Device),
      hasPermission: hasReadPermission
    },
    {
      name: "Services",
      links: serviceTabs,
      hasPermission: hasReadPermission
    },
    {
      name: "Modules",
      links: modulesTabs,
      hasPermission: hasReadPermission
    }
  ];

  return (
    <ExpandableNav
      selectedKey={sectionId}
      onSectionChange={onSectionChange}
      items={items}
    />
  );
};
