import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { useDebouncedValue } from "@libs/hooks/useDebouncedValue";
import { useMemo, useState } from "react";

import {
  Checkbox,
  Link,
  SelectionMode,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";
import { EnvUser } from "@libs/api/gateways/env/env-gateway.dtos";

interface Props {
  users: EnvUser[];
  onSelectUser?: (user: EnvUser) => void;
}

const getKey = (user: EnvUser) => user?.id ?? "";

const stringIncludes = (str: string, to: string) => {
  return str.toLowerCase().includes(to.toLowerCase());
};

export const UsersTable = ({ users, onSelectUser }: Props) => {
  const theme = useTheme();

  const [filterName, setFilterName] = useState<string>();
  const filterNameDebounced = useDebouncedValue(filterName, 400);

  const filteredUsers = useMemo(
    () =>
      filterNameDebounced
        ? users.filter(
            user =>
              stringIncludes(String(user.name), filterNameDebounced) ||
              stringIncludes(String(user.username), filterNameDebounced) ||
              stringIncludes(String(user.id), filterNameDebounced)
          )
        : users,
    [users, filterNameDebounced]
  );

  const columns: DataTableColumn<EnvUser>[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (user: EnvUser) => (
        <Stack>
          <Link onClick={() => onSelectUser && onSelectUser(user)}>
            <Stack>
              <span>{user.name}</span>
              <Text variant="xSmall">{user.id}</Text>
            </Stack>
          </Link>
        </Stack>
      )
    },
    {
      key: "roles",
      name: "Roles",
      minWidth: 300,
      onRender: (user: EnvUser) => (user.roles?.join(", ") || "--").toString(),
      sort: (left, right) => {
        const leftValue = left.roles?.join(", ") ?? "";
        const rightValue = right.roles?.join(", ") ?? "";

        return leftValue.localeCompare(rightValue);
      }
    },
    {
      key: "inactive",
      name: "Inactive",
      minWidth: 100,
      onRender: (user: EnvUser) => (
        <Checkbox
          automationAttribute="inactive-checkbox"
          key={`${user?.id}${user?.etag}`}
          checked={user?.isInactive}
          disabled={true}
        />
      ),
      sort: true
    }
  ];

  return (
    <>
      <Stack
        verticalFill
        tokens={{
          childrenGap: theme.spacing.s2
        }}
        styles={{
          root: {
            flex: "1 1 0",
            overflowY: "auto",
            backgroundColor: theme.semanticColors.bodyBackground,
            padding: theme.spacing.s1
          }
        }}
      >
        <Stack horizontal>
          <Stack>
            <TextField
              value={filterName}
              placeholder="Filter EnvUser"
              iconProps={{
                iconName: "Search"
              }}
              styles={{ root: { width: 350 } }}
              onChange={(e, value) => setFilterName(value)}
            />
          </Stack>
        </Stack>
        <DataTable
          items={filteredUsers}
          columns={columns}
          getKey={getKey}
          selectionMode={SelectionMode.none}
          onShouldVirtualize={() => true}
        />
      </Stack>
    </>
  );
};
