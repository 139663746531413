import { Form } from "@components/form/Form";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";

import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { TtmReportingSnapshotOptions } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";

interface SnapshotOptionsModalProps extends Pick<IDialogProps, "onDismiss"> {
  onSubmit: (options: TtmReportingSnapshotOptions) => Promise<void>;
}

export const SnapshotOptionsModal = ({
  onDismiss,
  onSubmit
}: SnapshotOptionsModalProps) => {
  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={onDismiss}
        dialogContentProps={{ showCloseButton: true, title: "Snapshot" }}
      >
        <Form<TtmReportingSnapshotOptions>
          defaultValues={{}}
          submitButtonText="Send"
          onSubmit={onSubmit}
          cancelButtonText="Close"
          allowSubmitOnClean={true}
          showCancelButton
          onCancel={() => onDismiss?.()}
        >
          <FormDatePicker name="startDate" label="Start date" />
          <FormDatePicker name="endDate" label="End date" />
        </Form>
      </Dialog>
    </>
  );
};
