import { Link, mergeStyles, Spinner, useTheme } from "@bps/fluent-ui";
import { PropsWithChildren, useEffect, useState } from "react";
import { guid } from "@libs/common/guid";
import { useCommandRunner } from "../_shared-models/CommandRunner";
import { useTenantFeatureQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  FeatureAction,
  FeatureStatus,
  TenantFeature
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface ProvisionTenantFeatureItemProps {
  tenantId: guid;
  feature: TenantFeature;
  action: FeatureAction;
  onMenuItemClick: (
    tenantId: guid,
    feature: TenantFeature,
    action: FeatureAction
  ) => void;
}

export const ProvisionTenantFeatureItem = ({
  tenantId,
  feature,
  action,
  onMenuItemClick,
  children
}: PropsWithChildren<ProvisionTenantFeatureItemProps>) => {
  const theme = useTheme();
  const commandRunner = useCommandRunner();

  const { isLoading, data: tenantFeature } = useTenantFeatureQuery(
    tenantId,
    feature
  );

  const [featureItemEnabled, setFeatureItemEnabled] = useState<boolean>();

  const onClick = () => {
    onMenuItemClick(tenantId, feature, action);
  };

  useEffect(() => {
    const alreadyHasTenantFeature =
      tenantFeature?.lastAction?.action === FeatureAction.Enable &&
      tenantFeature?.lastAction?.status === FeatureStatus.Completed;

    const shouldBeEnabled =
      (action === FeatureAction.Enable && !alreadyHasTenantFeature) ||
      (action === FeatureAction.Disable && alreadyHasTenantFeature);
    setFeatureItemEnabled(shouldBeEnabled);
  }, [tenantFeature, action]);

  const linkStyles = mergeStyles({
    padding: theme.spacing.s1,
    width: "100%",
    color: theme.palette.neutralPrimary,

    "&:focus, &:hover, &:active, &:hover:active": {
      backgroundColor: theme.palette.neutralLight,
      textDecoration: "none"
    },

    "&.is-disabled": {
      color: theme.semanticColors.buttonTextDisabled
    }
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Link
      onClick={onClick}
      disabled={!featureItemEnabled || commandRunner.isBusy}
      className={linkStyles}
    >
      {children}
    </Link>
  );
};
