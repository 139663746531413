import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";

import { FunctionComponent } from "react";

import { Dialog, Stack, Text } from "@bps/fluent-ui";
import {
  CallbackTypesDto,
  IpRangeDto
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { useUpdateCallbackType } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";

export interface CallbackTypeDialogProps {
  callbackType: CallbackTypesDto;
  onDismiss: () => void;
}

export interface CallbackTypeFormValues {
  code: string;
  description?: string;
  ipRanges?: IpRangeDto[];
}

export const CallbackTypeDialog: FunctionComponent<CallbackTypeDialogProps> = ({
  callbackType,
  onDismiss
}) => {
  const {
    mutateAsync: updateCallbackTypes,
    error: callbackTypesError
  } = useUpdateCallbackType();

  const onSubmit = async (formData: CallbackTypeFormValues) => {
    await updateCallbackTypes({
      code: formData.code,
      description: formData.description,
      ipRanges: formData.ipRanges
    });
    onDismiss();
  };

  const defaultValues = {
    code: callbackType.code,
    description: callbackType.description,
    ipRanges: callbackType.ipRanges
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Configure Callback Type",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={500}
    >
      <Form<CallbackTypeFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={callbackTypesError}
      >
        <Stack tokens={{ childrenGap: 50 }} verticalAlign="space-between">
          <Stack tokens={{ childrenGap: 8 }} verticalAlign="space-between">
            <Text>Code: {defaultValues.code}</Text>
            <Text>Description: {defaultValues.description}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} verticalAlign="space-between">
            <Stack
              tokens={{ childrenGap: 10 }}
              horizontal
              horizontalAlign="space-between"
            >
              <Text>Range1</Text>
              <FormTextField
                name="ipRanges[0].ipRangeBegin"
                placeholder="IP Range Begin"
                width={5}
              />
              <Text>to</Text>
              <FormTextField
                name="ipRanges[0].ipRangeEnd"
                placeholder="IP Range End"
              />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal>
              <Text>Range2</Text>
              <FormTextField
                name="ipRanges[1].ipRangeBegin"
                placeholder="IP Range Begin"
              />
              <Text>to</Text>
              <FormTextField
                name="ipRanges[1].ipRangeEnd"
                placeholder="IP Range End"
              />
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </Dialog>
  );
};
