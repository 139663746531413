import { ObjectPermissions } from "modules/system/sections/plt/roles/SystemRoles";
import { useMemo } from "react";

import {
  FontIcon,
  Grid,
  noWrap,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { SecurityRole } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface RolePermissionsProps {
  role: SecurityRole;
  objectPermissions: ObjectPermissions;
}

export const RolePermissions: React.FC<RolePermissionsProps> = ({
  role,
  objectPermissions
}) => {
  const theme = useTheme();

  const permissionsByKey = useMemo(
    () =>
      role.rolePermissions?.reduce((acc, permission) => {
        acc[permission.permissionCode.toUpperCase()] = true;
        return acc;
      }, {}),
    [role.rolePermissions]
  );

  return (
    <Stack>
      {Object.keys(objectPermissions)
        ?.sort()
        ?.filter(
          object =>
            !!role.rolePermissions?.find(permission => {
              return (
                permission.permissionCode.split(".")[0] === object.split(".")[0]
              );
            })
        )
        .map(object => (
          <Grid
            key={object}
            templateColumns="repeat(auto-fill, 226px)"
            childrenTokens={{ gap: 8 }}
          >
            {objectPermissions[object].sort().map(permission => {
              const perm = permission.code;
              return (
                <Grid
                  key={`${role.code}.${object}.${permission.code}`}
                  templateColumns="18px 200px"
                  childrenTokens={{ columnGap: 8 }}
                >
                  <FontIcon
                    styles={{
                      root: { color: theme.palette.themeDark, fontSize: 18 }
                    }}
                    iconName={
                      !!permissionsByKey?.[permission.code]
                        ? "BoxCheckmarkSolid"
                        : "Checkbox"
                    }
                  />
                  <TooltipHost styles={{ root: noWrap }} content={perm}>
                    <Text nowrap>{perm}</Text>
                  </TooltipHost>
                </Grid>
              );
            })}
          </Grid>
        ))}
    </Stack>
  );
};
