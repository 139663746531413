import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import { AppointmentRemindersScreen } from "modules/tenants/sections/ttm/appointment-reminders/AppointmentRemindersScreen";
import { CommsPreferencesCard } from "modules/tenants/sections/ttm/appointment-reminders/CommsPreferencesCard";
import { CommsSchedulesTable } from "modules/tenants/sections/ttm/appointment-reminders/CommsSchedulesTable";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";

import { TenantSettingsCard } from "../booking/TenantSettingsCard";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

const TtmAppointmentRemindersBase: FunctionComponent<Props> = ({ tenant }) => {
  return (
    <Stack
      verticalFill
      styles={{
        root: {
          paddingLeft: 10,
          paddingRight: 10
        }
      }}
      tokens={{ childrenGap: 24 }}
    >
      <SectionTitle>Appointment Reminder Diagnostics</SectionTitle>
      <TenantSettingsCard tenant={tenant} />
      <CommsPreferencesCard tenant={tenant} />
      <CommsSchedulesTable tenant={tenant} />
      <AppointmentRemindersScreen tenant={tenant} />
    </Stack>
  );
};

export const TtmAppointmentReminders = withPermissions(
  TtmAppointmentRemindersBase,
  [Permissions.TiSettingsRead]
);
