import React from "react";

import { noWrap, Stack, Text } from "@bps/fluent-ui";
import { WarningIconTooltip } from "./WarningIconTooltip";
import { TabsNavLinkProps } from "@components/tabs-nav/TabNav.types";
import { CustomerAccount } from "@libs/api/gateways/cam/cam-gateway.dtos";

export const CustomerTabLink: React.FC<TabsNavLinkProps<CustomerAccount>> = ({
  details
}) => {
  return (
    <>
      {!details.data?.existsInCrm && (
        <WarningIconTooltip content="BpCloud Customer Id does not exist in Dynamics" />
      )}

      <Stack
        styles={{ root: { lineHeight: "1rem", textAlign: "left", ...noWrap } }}
      >
        <Text variant="small" bold nowrap>
          {details.name}
        </Text>
        <Text variant="xSmall">{details.id}</Text>
      </Stack>
    </>
  );
};
