import { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Dialog,
  ExpDropdownField,
  ExpSubmissionForm,
  IDropdownOption,
  Stack
} from "@bps/fluent-ui";
import { DateInfo, DateTime } from "@bps/utils";
import { PaymentMethodDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useUpdateTenantPaymentMethods } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface EditCardDetailsDialogProps {
  data: PaymentMethodDto;
  onDismiss: () => void;
}

interface EditCardDetailsValues {
  year: number;
  month: number;
}

const MAX_YEARS = 100;
export const EditCardDetailsDialog: FunctionComponent<EditCardDetailsDialogProps> = ({
  data,
  onDismiss
}) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const navigate = useNavigate();
  const { mutateAsync } = useUpdateTenantPaymentMethods();

  useEffect(() => {
    if (!tenantId) {
      navigate(-1);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const monthsOptions: IDropdownOption[] = DateInfo.months().map((m, idx) => ({
    key: idx + 1,
    text: m
  }));

  const yearsOptions: IDropdownOption[] = Array(MAX_YEARS)
    .fill({})
    .map((_, idx) => ({
      key: DateTime.now().year + idx,
      text: (DateTime.now().year + idx).toString()
    }));

  const dropdownCommonProps = {
    fieldItemStyles: { root: { flexGrow: 1, flexBasis: 0 } },
    calloutProps: { styles: { root: { height: 300 } } }
  };

  return (
    <Dialog
      minWidth={400}
      dialogContentProps={{
        title: `Edit ${data?.brand} **** ${data?.expYear}, exp. ${data?.expMonth}/${data?.expYear}`,
        showCloseButton: true,
        styles: { innerContent: { display: "flex", flexDirection: "column" } }
      }}
      hidden={false}
      onDismiss={onDismiss}
      modalProps={{ className: "cam-EditCardDetailsDialog" }}
    >
      <ExpSubmissionForm<EditCardDetailsValues>
        initialValues={{ year: data.expYear, month: data.expMonth }}
        onSubmit={async values => {
          await mutateAsync({
            expYear: values.year,
            expMonth: values.month,
            id: data.id,
            customerTenantId: tenantId!
          });
          onDismiss();
        }}
        cancelButtonProps={{ onClick: onDismiss }}
      >
        {() => (
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <ExpDropdownField
              withNoEmptyOption
              options={monthsOptions}
              name="month"
              label="Month"
              {...dropdownCommonProps}
            />
            <ExpDropdownField
              withNoEmptyOption
              options={yearsOptions}
              name="year"
              label="Year"
              {...dropdownCommonProps}
            />
          </Stack>
        )}
      </ExpSubmissionForm>
    </Dialog>
  );
};
