import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { FunctionComponent, ReactNode } from "react";
import { config } from "@libs/config/config";
import { Country, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface StripeFormElementsProps {
  options: StripeElementsOptions | undefined;
  tenant: Tenant;
  children: ReactNode;
}

export const StripeFormElements: FunctionComponent<StripeFormElementsProps> = ({
  options,
  tenant,
  ...rest
}) => {
  const key =
    tenant.country === Country.NewZealand
      ? config.stripePublicNzKey
      : config.stripePublicAuKey;

  const stripePromise = loadStripe(key);

  return <Elements stripe={stripePromise} options={options} {...rest} />;
};
