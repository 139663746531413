import { FeatureNode } from "modules/system/sections/plt/feature/FeatureGraph";
import React, { useMemo } from "react";

import {
  FontIcon,
  IStyle,
  Heading,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

interface FeatureGraphNodeProps {
  isSelected?: boolean;
  highlightConnectors?: (id: string, on: boolean) => void;
  setSelectedNode?: (data: FeatureNode) => void;
  data: FeatureNode;
  id: string;
}

export const FeatureGraphNode: React.FC<FeatureGraphNodeProps> = ({
  isSelected,
  data,
  id: nodeId,
  highlightConnectors,
  setSelectedNode
}) => {
  const theme = useTheme();

  const containerStyle = useMemo<IStyle>(
    () => ({
      background: isSelected
        ? theme.semanticColors.listItemBackgroundCheckedHovered
        : theme.semanticColors.bodyBackground,
      textAlign: "center",
      borderColor: theme.semanticColors.focusBorder,
      borderWidth: 1,
      borderStyle: "solid",
      "&:hover": {
        background: theme.semanticColors.listItemBackgroundHovered,
        cursor: "pointer"
      }
    }),
    [isSelected, theme]
  );

  const headerStyle = useMemo<IStyle>(
    () => ({
      fontWeight: "bold",
      padding: theme.spacing.s1,
      borderBottomColor: theme.semanticColors.focusBorder,
      borderBottomWidth: 1,
      borderBottomStyle: "solid"
    }),
    [theme]
  );

  const contentStyle = useMemo<IStyle>(
    () => ({
      paddingTop: theme.spacing.s1,
      paddingBottom: theme.spacing.s1,
      paddingLeft: theme.spacing.m,
      paddingRight: theme.spacing.m
    }),
    [theme]
  );

  return (
    <Stack
      verticalFill
      styles={{
        root: containerStyle
      }}
      onMouseOver={() => {
        highlightConnectors?.(nodeId, true);
      }}
      onMouseOut={() => {
        highlightConnectors?.(nodeId, false);
      }}
      onClick={() => setSelectedNode?.(data)}
    >
      <Stack
        styles={{ root: headerStyle }}
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Text>{data.text}</Text>
        {data.isSystemManaged && (
          <TooltipHost content="System Managed Feature" delay={0}>
            <FontIcon iconName="ShieldAlert" />
          </TooltipHost>
        )}
      </Stack>
      <Stack styles={{ root: contentStyle }}>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Heading labelPaddings>Code:</Heading>
          <Stack>{data.code}</Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Heading labelPaddings>System Managed:</Heading>
          <Stack>{data.isSystemManaged ? "Yes" : "No"}</Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Heading labelPaddings>Actions:</Heading>
          <Stack>{data.hasActionProcessor ? "Yes" : "No"}</Stack>
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Heading labelPaddings>Source:</Heading>
          <Stack>{data.source}</Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
