import { FunctionComponent, useState } from "react";
import { MoreLessToggle, Stack } from "@bps/fluent-ui";
import { LinkUsage } from "./LinkUsage";

interface LinkUsageToggleProps {
  linkId: string;
}
export const LinkUsageToggle: FunctionComponent<LinkUsageToggleProps> = ({
  linkId
}) => {
  const [showUsage, setShowUsage] = useState<boolean>(true);
  return (
    <Stack horizontalAlign="start" tokens={{ childrenGap: 8 }}>
      <MoreLessToggle
        value={showUsage}
        onChanged={setShowUsage}
        styles={{ root: { alignSelf: "start" } }}
        linkProps={{ moreStateText: "Show", lessStateText: "Hide" }}
      />
      {!showUsage && <LinkUsage linkId={linkId} />}
    </Stack>
  );
};
