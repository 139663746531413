import { stripeAccountBaseUrl } from "@libs/stripe/stripe.utils";
import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { config } from "@libs/config/config";
import { Permissions } from "@libs/permissions/permissions.enum";

import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IColumn,
  IconButton,
  Link,
  NoDataTile,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { NotFoundError } from "@bps/http-client";

import {
  CancelRestoreDialogState,
  CancelRestoreSubscriptionDialog
} from "./components/CancelRestoreSubscriptionDialog";
import { LatestInvoiceLink } from "./components/subscription-details/LatestInvoiceLink";
import { NewSubscriptionButton } from "./components/NewSubscriptionButton";
import { SubscriptionLink } from "./components/subscription-details/SubscriptionLink";
import { SubscriptionResetModal } from "./components/SubscriptionResetModal";
import { SubscriptionStatusBadge } from "./components/subscription-details/SubscriptionStatusBadge";
import { getCurrentPeriod } from "./components/utils";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  useCustomerProducts,
  useTenantDefaultPaymentMethod
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  CustomerProductsSubscription,
  SubscriptionDto,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface SubscriptionsScreenProps {
  tenant: Tenant;
}

enum Actions {
  edit = "edit",
  cancel = "cancel",
  restore = "restore",
  reset = "reset"
}

const SubscriptionsScreenBase: FunctionComponent<SubscriptionsScreenProps> = ({
  tenant
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [cancelRestoreDialogState, setCancelRestoreDialogState] = useState<
    CancelRestoreDialogState | undefined
  >(undefined);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltSalesWrite
  );

  const subQuery = useCustomerProducts(tenant.id);

  const [showSubscriptionResetModal, setShowSubscriptionResetModal] = useState<
    boolean
  >();

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    string | undefined
  >();

  const [selectedCustomerTenantId, setSelectedCustomerTenantId] = useState<
    string | undefined
  >();

  const selectSubscription = (
    subscriptionId: string | undefined,
    customerTenantId: string | undefined
  ) => {
    setSelectedSubscriptionId(subscriptionId);
    setSelectedCustomerTenantId(customerTenantId);
    setShowSubscriptionResetModal(true);
  };

  const onDismissResetModal = () => {
    setShowSubscriptionResetModal(false);
    setSelectedSubscriptionId(undefined);
    setSelectedCustomerTenantId(undefined);
  };

  const defaultMethodQuery = useTenantDefaultPaymentMethod(tenant.id);

  const columns: IColumn[] = [
    {
      key: "action",
      name: "",
      onRender: (item: CustomerProductsSubscription) => {
        // The reset action should NOT be available in the prod environment
        const hideResetBillingDate = config.env === "prod";

        return (
          <TooltipHost
            content={
              !defaultMethodQuery.data
                ? "Please set up default payment method"
                : undefined
            }
          >
            <IconButton
              disabled={!defaultMethodQuery.data}
              styles={{ root: { justifySelf: "end" } }}
              menuIconProps={{ iconName: "More" }}
              menuProps={{
                items: [
                  {
                    key: Actions.edit as string,
                    text: "Edit",
                    disabled: !hasWritePermission,
                    onClick: () => navigate(`./${item.subscriptionId}`)
                  },
                  {
                    key: Actions.cancel as string,
                    text: "Cancel",
                    disabled: !hasWritePermission,
                    onClick: () => {
                      tenant.id &&
                        setCancelRestoreDialogState({
                          customerTenantId: tenant.id,
                          subscriptionId: item.subscriptionId,
                          operationType: "cancel"
                        });
                    }
                  },
                  {
                    key: Actions.restore as string,
                    text: "Restore",
                    disabled: !hasWritePermission,
                    onClick: () => {
                      tenant.id &&
                        setCancelRestoreDialogState({
                          customerTenantId: tenant.id,
                          subscriptionId: item.subscriptionId,
                          operationType: "restore"
                        });
                    }
                  },
                  {
                    key: Actions.reset as string,
                    text: "Reset Billing Date",
                    onClick: () => {
                      tenant.id &&
                        selectSubscription(item.subscriptionId, tenant.id);
                    },
                    disabled: !hasWritePermission
                  }
                ].filter(
                  i =>
                    !(item.cancelAtPeriodEnd && i.key === Actions.cancel) && // @TODO
                    !(!item.cancelAtPeriodEnd && i.key === Actions.restore) &&
                    !(hideResetBillingDate && i.key === Actions.reset)
                )
              }}
            />
          </TooltipHost>
        );
      },
      minWidth: 30,
      maxWidth: 30
    },
    {
      key: "id",
      name: "Id",
      onRender: (item: CustomerProductsSubscription) => (
        <SubscriptionLink subscriptionId={item.subscriptionId} short />
      ),

      minWidth: 120,
      maxWidth: 120
    },
    {
      key: "status",
      name: "Status",
      onRender: (item: CustomerProductsSubscription) => (
        <SubscriptionStatusBadge item={item} />
      ),
      minWidth: 50,
      maxWidth: 280
    },
    {
      key: "schedule",
      name: "Schedule",
      onRender: (item: SubscriptionDto) => (
        <Text
          styles={{
            root: { textTransform: "capitalize" }
          }}
        >
          {item.schedule}
        </Text>
      ),
      minWidth: 70,
      maxWidth: 70
    },
    {
      key: "desc",
      name: "Description",
      onRender: (item: CustomerProductsSubscription) => item.description,
      minWidth: 160,
      maxWidth: 160
    },
    {
      key: "current_period",
      name: "Current Period",
      onRender: getCurrentPeriod,
      minWidth: 180,
      maxWidth: 180
    },
    {
      key: "latest_invoice_id",
      name: "Latest Invoice",
      onRender: (item: CustomerProductsSubscription) => (
        <LatestInvoiceLink latestInvoiceId={item.latestInvoiceId} />
      ),
      minWidth: 100,
      maxWidth: 170
    }
  ];

  const items = subQuery.data?.subscriptionList ?? [];
  const isLoading = subQuery.isLoading || defaultMethodQuery.isLoading;
  const subQueryError =
    subQuery.error instanceof NotFoundError
      ? undefined
      : subQuery?.error?.message;

  const defaultMethodQueryError =
    defaultMethodQuery.error instanceof NotFoundError
      ? undefined
      : defaultMethodQuery?.error?.message;

  const isFulfilledWithNoData = !isLoading && !items?.length;
  const shouldRenderList = !!items.length || isLoading || subQuery.error;
  const errorMessage = subQueryError ?? defaultMethodQueryError;

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Stack>
          <SectionTitle>Subscriptions</SectionTitle>
          {tenant.stripeCustomerId && (
            <Link
              target="_blank"
              href={`${stripeAccountBaseUrl}subscriptions?customer=${tenant.stripeCustomerId}`}
            >
              Manage customer subscriptions
            </Link>
          )}
        </Stack>

        {/*Enforce one subscription for now */}
        {isFulfilledWithNoData && (
          <NewSubscriptionButton
            isLoading={isLoading}
            tenantId={tenant.id}
            hasDefaultPaymentMethod={!!defaultMethodQuery.data}
          />
        )}
      </Stack>
      {isFulfilledWithNoData && (
        <NoDataTile
          showBoxShadow={false}
          textProps={{ text: "No subscriptions have been found!" }}
          linkProps={{ hidden: true }}
        />
      )}
      {shouldRenderList && (
        <ShimmeredDetailsList
          items={items}
          columns={columns}
          enableShimmer={isLoading}
          errorMessage={errorMessage}
        />
      )}
      {showSubscriptionResetModal && (
        <SubscriptionResetModal
          onDismiss={onDismissResetModal}
          customerTenantId={selectedCustomerTenantId}
          subscriptionId={selectedSubscriptionId}
        />
      )}

      {cancelRestoreDialogState && (
        <CancelRestoreSubscriptionDialog
          state={cancelRestoreDialogState}
          onCancel={() => {
            setCancelRestoreDialogState(undefined);
          }}
        />
      )}
    </Stack>
  );
};

export const SubscriptionsScreen = withPermissions(
  SubscriptionsScreenBase,
  [Permissions.PltSalesRead, Permissions.PltSalesWrite],
  "or"
);
