import { FilterBar, Item } from "@bps/fluent-ui";
import { OutboundEmailAuditArgs } from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";

export interface EmailAuditingFilterValues {
  searchTerm?: string;
  startDate?: Date;
  endDate?: Date;
}

interface EmailAuditingFilterProps {
  setFilterArgs: React.Dispatch<
    React.SetStateAction<OutboundEmailAuditArgs | undefined>
  >;
}

export const EmailAuditingFilter = ({
  setFilterArgs
}: EmailAuditingFilterProps) => {
  const filterItems: Item<EmailAuditingFilterValues>[] = [
    {
      name: "searchTerm",
      type: "searchBox",
      stickItem: true,
      props: {
        placeholder: "Search by email or subject",
        styles: { root: { width: 400 } },
        removeSpecialCharacters: false
      }
    },
    {
      name: "datesRangePicker",
      type: "datesRangePicker",
      props: {
        startDateProps: {
          label: "Start date",
          maxDate: new Date()
        },
        endDateProps: { label: "End date" },
        startDateName: "startDate",
        endDateName: "endDate",
        placeholder: "Date(s)",
        label: "Date(s)",
        styles: { fieldGroup: { width: "100%" } }
      }
    }
  ];

  return (
    <FilterBar<EmailAuditingFilterValues>
      items={filterItems}
      initialValues={{
        searchTerm: undefined,
        startDate: undefined,
        endDate: undefined
      }}
      onChange={filter => {
        setFilterArgs({ ...filter.values });
      }}
    />
  );
};
