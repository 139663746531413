import { FontIcon, TooltipHost } from "@bps/fluent-ui";
import React, { FunctionComponent } from "react";

interface WarningIconTooltipProps {
  content: string;
}
export const WarningIconTooltip: FunctionComponent<WarningIconTooltipProps> = ({
  content
}) => (
  <TooltipHost content={content}>
    <FontIcon
      iconName="Warning"
      styles={{ root: { color: "orange", paddingTop: 3, paddingLeft: 8 } }}
    />
  </TooltipHost>
);
