import { FunctionComponent, useState } from "react";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useComponent } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Pivot, PivotItem } from "@bps/fluent-ui";
import { ComponentInfo } from "./ComponentInfo";
import { ComponentImplementation } from "./ComponentImplementation";
import { ComponentSettings } from "./ComponentSettings";
import {
  ComponentDetailsTabsProps,
  DetailsTabs
} from "./ComponentDetailsTabs.types";
import { ComponentActions } from "./ComponentActions";

export const ComponentDetailsTabs: FunctionComponent<ComponentDetailsTabsProps> = ({
  tenantId,
  scopeId,
  id
}) => {
  const [selectedKey, setSelectedKey] = useState<DetailsTabs | undefined>(
    DetailsTabs.Info
  );

  const componentQuery = useComponent({ tenantId, codeOrId: id, scopeId });
  return (
    <QueryStateIndicator {...componentQuery}>
      {component => (
        <Pivot
          selectedKey={selectedKey}
          styles={{ itemContainer: { padding: 8 } }}
          onLinkClick={item => {
            setSelectedKey(item?.props.itemKey as DetailsTabs);
          }}
        >
          <PivotItem itemKey={DetailsTabs.Info} headerText="Info">
            <ComponentInfo component={component} />
          </PivotItem>

          <PivotItem itemKey={DetailsTabs.Actions} headerText="Actions">
            <ComponentActions componentId={component.id} />
          </PivotItem>

          <PivotItem
            itemKey={DetailsTabs.Implementations}
            headerText="Implementations"
          >
            <ComponentImplementation component={component} />
          </PivotItem>

          <PivotItem itemKey={DetailsTabs.Settings} headerText="Settings">
            <ComponentSettings component={component} />
          </PivotItem>
        </Pivot>
      )}
    </QueryStateIndicator>
  );
};
