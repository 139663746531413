import React from "react";

import { Stack, TextBadge, TextBadgeColor } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";

import { ActionProperty } from "./AuthProperty";
import { FieldDeviceAuthAction } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface AuthActionListItemDetailsProps {
  authAction: FieldDeviceAuthAction;
}

export const AuthActionListItemDetails: React.FC<AuthActionListItemDetailsProps> = ({
  authAction
}) => {
  if (!authAction) {
    return null;
  }

  const properties = {
    firstColumn: [
      {
        propertyName: "Identifier Type",
        propertyValue: authAction.deviceIdentifierType
      },
      {
        propertyName: "Identifier Value",
        propertyValue: authAction.deviceIdentifierValue
      },
      {
        propertyName: "Action",
        propertyValue: authAction.deviceAction
      }
    ],
    secondColumn: [
      {
        propertyName: "Created",
        propertyValue:
          authAction.changeLog.createdDate?.toFormat(
            DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
          ) ?? ""
      },
      {
        propertyName: "Created By",
        propertyValue: authAction.changeLog.createdBy ?? ""
      },
      {
        propertyName: "Updated",
        propertyValue:
          authAction.changeLog.updatedDate?.toFormat(
            DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
          ) ?? ""
      },
      {
        propertyName: "Updated By",
        propertyValue: authAction.changeLog.updatedBy ?? ""
      }
    ]
  };

  const isExecuted = authAction.runDate !== undefined;

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={{ root: { width: "100%" } }}
      tokens={{ childrenGap: 8 }}
    >
      <Stack styles={{ root: { width: "100%" } }}>
        {properties.firstColumn.map(property => (
          <ActionProperty {...property} key={property.propertyName} />
        ))}
        {isExecuted ? (
          <TextBadge
            styles={{ root: { width: "100%" } }}
            badgeColor={TextBadgeColor.green}
          >
            Executed (
            {authAction.runDate?.toFormat(
              DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
            )}
            )
          </TextBadge>
        ) : (
          <TextBadge
            styles={{ root: { width: "100%" } }}
            badgeColor={TextBadgeColor.red}
          >
            Not Executed
          </TextBadge>
        )}
      </Stack>
      <Stack styles={{ root: { width: "55%" } }}>
        {properties.secondColumn.map(property => (
          <ActionProperty {...property} key={property.propertyName} />
        ))}
      </Stack>
    </Stack>
  );
};
