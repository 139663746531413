import { ValidationMessages } from "@libs/validation/validation-messages.enum";
import { Form } from "@components/form/Form";
import { FormButtonsGroupSingleChoice } from "@components/form/fields/FormButtonsGroupSingleChoice";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Permissions } from "@libs/permissions/permissions.enum";

import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import React, { FunctionComponent, useState } from "react";

import { DefaultButton, Dialog } from "@bps/fluent-ui";
import { useCreateUser } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";

import { BaseUserStatuses } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface NewUserDialogButtonProps {
  tenantId: string;
  onDismiss: () => void;
}

interface NewUserFormValues {
  firstName: string;
  lastName: string;
  status: BaseUserStatuses;
}

const NewUserDialogButtonBase: FunctionComponent<NewUserDialogButtonProps> = ({
  onDismiss,
  tenantId
}) => {
  const { error, mutateAsync: createUser } = useCreateUser();
  const onSubmit = async (values: NewUserFormValues) => {
    await createUser({
      tenantId,
      firstName: values.firstName,
      lastName: values.lastName,
      status: values.status
    });
    onDismiss();
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "New user",
        showCloseButton: true,
        onDismiss
      }}
    >
      <Form<NewUserFormValues>
        defaultValues={{
          firstName: "",
          lastName: "",
          status: BaseUserStatuses.active
        }}
        onSubmit={onSubmit}
        showCancelButton
        cancelButtonText="Close"
        onCancel={onDismiss}
        error={error}
      >
        <FormTextField
          label="First name"
          name="firstName"
          rules={{ required: ValidationMessages.required }}
          required
        />
        <FormTextField
          label="Last name"
          name="lastName"
          rules={{ required: ValidationMessages.required }}
          required
        />
        <FormButtonsGroupSingleChoice
          options={Object.values(BaseUserStatuses).map(s => ({
            key: s,
            text: s
          }))}
          name="status"
          label="Status"
        />
      </Form>
    </Dialog>
  );
};

export const NewUserDialogButton: FunctionComponent<Omit<
  NewUserDialogButtonProps,
  "onDismiss"
>> = ({ tenantId }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogUserWrite
  );
  return (
    <>
      <DefaultButton
        onClick={() => setShowDialog(true)}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      >
        Create user
      </DefaultButton>
      {showDialog && (
        <NewUserDialogButtonBase
          onDismiss={() => setShowDialog(false)}
          tenantId={tenantId}
        />
      )}
    </>
  );
};
