import { Tabs } from "@components/tabs-nav/TabNav.types";

export const SystemPltTabs: Tabs = {
  Catalog: {
    FeatureGraph: { id: "cat-feature-graph", name: "Feature Graph" },
    FeatureExplorer: { id: "cat-feature-explorer", name: "Feature Explorer" },
    SystemRoles: { id: "cat-security-roles", name: "Security Roles" },
    Components: { id: "cat-components", name: "Components" }
  },
  Sales: {
    Product: { id: "subs-products", name: "Product" },
    LicencesCodes: { id: "licence-types", name: "Licence types" }
  },
  FieldMgmt: {
    FieldConfig: {
      id: "field-configuration",
      name: "Default Field Config"
    },
    DeploymentRings: { id: "deployment-rings", name: "Deployment Rings" },
    Selectors: { id: "selectors", name: "Selectors" }
  },
  Comms: {
    Emails: { id: "email-auditing", name: "Emails" },
    CommsTesting: { id: "comms-testing", name: "Testing" }
  },
  Integrators: {
    Callbacks: { id: "integ-callbackTypes", name: "Callbacks" }
  },
  Redirects: {
    LinkShortenerSettings: {
      id: "link-shortener-settings",
      name: "Link Shortener Settings"
    }
  },
  BpId: {
    Invitations: { id: "user-invitations", name: "Invitations" },
    AuditLogs: { id: "audit-logs", name: "Audit Logs" }
  }
};
