import { DefaultButton, Stack, useTheme } from "@bps/fluent-ui";

export const SysComVersionsApprove = ({
  selectedVersions,
  handleAdd,
  handleRemove
}) => {
  const theme = useTheme();
  const isSelectedVersions = selectedVersions.length !== 0;

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      tokens={{ childrenGap: theme.spacing.s1 }}
    >
      <Stack.Item
        styles={{
          root: {
            background: isSelectedVersions
              ? theme.semanticColors.listItemBackgroundChecked
              : theme.semanticColors.infoBackground,

            width: 200,
            height: 30,
            display: "flex",
            placeContent: "center",
            flexWrap: "wrap"
          }
        }}
      >
        Versions Selected: {selectedVersions.length}
      </Stack.Item>

      <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
        <DefaultButton
          iconProps={{ iconName: "Add" }}
          disabled={!isSelectedVersions}
          onClick={handleAdd}
          text="Approve"
          styles={{ root: { minWidth: 140 } }}
        />
        <DefaultButton
          iconProps={{ iconName: "Remove" }}
          disabled={!isSelectedVersions}
          onClick={handleRemove}
          text="Unapprove"
          styles={{
            root: {
              minWidth: 140
            },
            rootHovered: {
              background: `${theme.palette.redDark}20` // For 20% opacity
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
