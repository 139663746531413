import { withPermissions } from "@components/withPermissions";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Permissions } from "@libs/permissions/permissions.enum";
import React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { CustomersTabs } from "./customers-tabs.constants";
import { useCustomerAccountApplicationsQuery } from "@libs/api/gateways/cam/cam-gateway.hooks";
import { CustomerAccountApplication } from "@libs/api/gateways/cam/cam-gateway.dtos";
import { TenantsTabs } from "../tenants/tenants-tabs.constants";

const ApplicationsSectionBase: React.FC = () => {
  const { crmId } = useParams<{ crmId: string }>();

  const theme = useTheme();
  const navigate = useNavigate();

  const customerAccountApplicationsQuery = useCustomerAccountApplicationsQuery(
    crmId!
  );

  const selectTenant = (id?: string) => {
    const to = generatePath("/tenants/:tenantId/:sectionId", {
      tenantId: id,
      sectionId: TenantsTabs.Platform.Details.id
    });
    navigate(to);
  };

  const selectCustomer = (id?: string) => {
    const to = generatePath("/cam/:crmId/:sectionId", {
      crmId: id,
      sectionId: CustomersTabs.Customer.CRMClientDetails.id
    });
    navigate(to);
  };

  const columns: DataTableColumn<CustomerAccountApplication>[] = [
    {
      key: "crmId",
      name: "CRM ID",
      minWidth: 100,
      isSorted: true,
      sort: true,
      onRender: (application: CustomerAccountApplication) => (
        <Link onClick={() => selectCustomer(application.crmId)}>
          {application.crmId}
        </Link>
      )
    },
    {
      key: "application",
      name: "App Type",
      minWidth: 80,
      onRender: (application: CustomerAccountApplication) => (
        <Text>{application.appType}</Text>
      ),
      sort: true
    },
    {
      key: "tenantId",
      name: "Tenant Id",
      minWidth: 80,
      onRender: (application: CustomerAccountApplication) => (
        <Link onClick={() => selectTenant(application.tenantId)}>
          {application.tenantId}
        </Link>
      ),
      sort: true
    },
    {
      key: "tenantName",
      name: "Tenant Name",
      minWidth: 160,
      onRender: (application: CustomerAccountApplication) => (
        <Text>{application.tenantName}</Text>
      ),
      sort: true
    },
    {
      key: "custAcctTenantId",
      name: "CustAcct Tenant Id",
      minWidth: 160,
      onRender: (application: CustomerAccountApplication) => (
        <Link onClick={() => selectTenant(application.customerAccountTenantId)}>
          {application.customerAccountTenantId}
        </Link>
      ),
      sort: true
    },
    {
      key: "custAcctTenantName",
      name: "CustAcct Tenant Name",
      minWidth: 160,
      onRender: (application: CustomerAccountApplication) => (
        <Text>{application.customerAccountTenantName}</Text>
      ),
      sort: true
    }
  ];

  return (
    <QueryStateIndicator<CustomerAccountApplication[]>
      {...customerAccountApplicationsQuery}
    >
      {applications => (
        <Stack tokens={{ childrenGap: 10 }} verticalFill>
          <Text
            variant="xxLarge"
            styles={{ root: { color: theme.palette.themePrimary } }}
          >
            BpCloud Applications
          </Text>
          <Stack grow styles={{ root: { position: "relative" } }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DataTable
                items={applications}
                columns={columns}
                getKey={(application: CustomerAccountApplication) =>
                  application?.tenantId ?? ""
                }
              />
            </ScrollablePane>
          </Stack>
        </Stack>
      )}
    </QueryStateIndicator>
  );
};

export const ApplicationsSection = withPermissions(
  ApplicationsSectionBase,
  [Permissions.PltCustomerRead, Permissions.PltCustomerWrite],
  "or"
);
