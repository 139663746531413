import React, { FunctionComponent, useState } from "react";

import {
  FontIcon,
  Link,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import {
  CancelRestoreDialogState,
  CancelRestoreSubscriptionDialog
} from "../CancelRestoreSubscriptionDialog";
import { CustomerProductsSubscription } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface RestoreLinkProps {
  customerTenantId?: string;
  subscription: CustomerProductsSubscription;
}
export const RestoreLink: FunctionComponent<RestoreLinkProps> = ({
  subscription,
  customerTenantId
}) => {
  const theme = useTheme();
  const [cancelRestoreDialogState, setCancelRestoreDialogState] = useState<
    CancelRestoreDialogState | undefined
  >(undefined);
  if (!subscription.cancellationDate || !customerTenantId) return null;

  return (
    <Stack horizontal tokens={{ childrenGap: 16 }}>
      <Text bold>Pending cancellation:</Text>
      <FontIcon
        iconName="Warning"
        styles={{
          root: {
            color: theme.semanticColors.errorIcon,
            fontSize: 18
          }
        }}
      />
      <TextBadge
        badgeSize={TextBadgeSize.small}
        badgeColor={TextBadgeColor.yellow}
        styles={{
          root: { textTransform: "capitalize" }
        }}
      >
        {DateTime.fromISO(subscription.cancellationDate).toFormat(
          DATE_FORMATS.DAY_DEFAULT_FORMAT
        )}
      </TextBadge>
      <Link
        onClick={() =>
          setCancelRestoreDialogState({
            customerTenantId,
            subscriptionId: subscription.subscriptionId,
            operationType: "restore"
          })
        }
      >
        Restore
      </Link>

      <CancelRestoreSubscriptionDialog
        hidden={!cancelRestoreDialogState}
        state={cancelRestoreDialogState}
        onCancel={() => {
          setCancelRestoreDialogState(undefined);
        }}
      />
    </Stack>
  );
};
