import { Form } from "@components/form/Form";
import { FormButtonsGroupSingleChoice } from "@components/form/fields/FormButtonsGroupSingleChoice";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { FunctionComponent } from "react";
import { UseFormReturn } from "react-hook-form";

import { Heading } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  PltUser,
  UserDefaultAuthenticationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useAddUserDefaultAuth,
  useTenantDefaultUserAuth,
  useUpdateUserDefaultAuth,
  useUserDefaultAuth
} from "@libs/api/gateways/plt/plt-gateway.hooks";

import {
  HINT,
  options,
  Options,
  PltUserAuthenticationFormProps,
  PltUserAuthenticationFormValues
} from "./PltUserAuthenticationForm.types";
import { getDefaultOption } from "./utils";

export const PltUserAuthenticationFormBase: FunctionComponent<PltUserAuthenticationFormProps> = ({
  user,
  tenantAuth,
  userAuth
}) => {
  const {
    mutateAsync: updateUserAuth,
    error: updateUserError
  } = useUpdateUserDefaultAuth();

  const {
    mutateAsync: addUserAuth,
    error: addUserError
  } = useAddUserDefaultAuth();

  const getDtoOption = (
    option: Options,
    key: "quickPinRequired" | "longPasswordRequired"
  ) => {
    if (option === Options.on) return true;
    if (option === Options.off) return false;
    if (option === Options.default)
      return tenantAuth ? tenantAuth[key] : undefined;
  };

  const defaultValues: PltUserAuthenticationFormValues = {
    deviceMfaMaxDuration: userAuth?.deviceMfaMaxDuration
      ? userAuth.deviceMfaMaxDuration.toString()
      : "",
    sessionMaxDuration: userAuth?.sessionMaxDuration
      ? userAuth.sessionMaxDuration.toString()
      : "",
    quickPinRequired: getDefaultOption(userAuth?.quickPinRequired),
    longPasswordRequired: getDefaultOption(userAuth?.longPasswordRequired)
  };

  const onSubmit = async (
    values: PltUserAuthenticationFormValues,
    form: UseFormReturn
  ) => {
    const payload: UserDefaultAuthenticationDto = {
      userId: user.id,
      tenantId: user.tenantId,
      deviceMfaMaxDuration: values.deviceMfaMaxDuration
        ? Number(values.deviceMfaMaxDuration)
        : undefined,
      sessionMaxDuration: values.sessionMaxDuration
        ? Number(values.sessionMaxDuration)
        : undefined,
      quickPinRequired: getDtoOption(
        values.quickPinRequired,
        "quickPinRequired"
      ),
      longPasswordRequired: getDtoOption(
        values.longPasswordRequired,
        "longPasswordRequired"
      )
    };

    if (userAuth) {
      await updateUserAuth(payload);
    } else {
      await addUserAuth(payload);
    }

    try {
      form.reset(values);
    } catch (e) {
      return e;
    }
  };

  return (
    <Form<PltUserAuthenticationFormValues>
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      error={addUserError ?? updateUserError}
      buttonsRootStyles={{ root: { alignSelf: "start" } }}
    >
      <Heading variant="section-heading">User authentication</Heading>
      <FormButtonsGroupSingleChoice
        label="User login password required"
        name="quickPinRequired"
        options={options}
        hint={
          typeof tenantAuth?.quickPinRequired !== "undefined"
            ? `${HINT} ${tenantAuth.quickPinRequired ? "on" : "off"}`
            : undefined
        }
      />
      <FormButtonsGroupSingleChoice
        label="User quick PIN required"
        name="longPasswordRequired"
        options={options}
        hint={
          typeof tenantAuth?.longPasswordRequired !== "undefined"
            ? `${HINT} ${tenantAuth.longPasswordRequired ? "on" : "off"}`
            : undefined
        }
      />
      <FormSpinNumberInput
        label="Pin Lifetime (days)"
        name="sessionMaxDuration"
        min={1}
        hint={
          tenantAuth?.sessionMaxDuration
            ? `${HINT} ${tenantAuth.sessionMaxDuration}`
            : undefined
        }
      />
      <FormSpinNumberInput
        label="Device MFA max duration (min) (NOT USED)"
        name="deviceMfaMaxDuration"
        min={1}
        hint={
          tenantAuth?.deviceMfaMaxDuration
            ? `${HINT} ${tenantAuth.deviceMfaMaxDuration}`
            : undefined
        }
      />
    </Form>
  );
};

export const PltUserAuthenticationForm: FunctionComponent<{
  user: PltUser;
}> = ({ user }) => {
  const tenantInfoQuery = useTenantDefaultUserAuth(user.tenantId);
  const userInfoQuery = useUserDefaultAuth(user.tenantId, user.id);
  return (
    <QueryStateIndicator
      data={{ userAuth: userInfoQuery.data, tenantAuth: tenantInfoQuery.data }}
      isLoading={userInfoQuery?.isLoading || tenantInfoQuery?.isLoading}
      error={userInfoQuery?.error ?? tenantInfoQuery?.error}
      allowNullOrUndefined
    >
      {({ userAuth, tenantAuth }) => (
        <PltUserAuthenticationFormBase
          user={user}
          tenantAuth={tenantAuth}
          userAuth={userAuth}
        />
      )}
    </QueryStateIndicator>
  );
};
