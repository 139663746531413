import { dangerButtonStyles } from "@components/buttons/DangerButton";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Stack,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { useUpdateDeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import {
  DeploymentRing,
  SelectorDto
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface ConfirmUnassignSelectorDialogProps
  extends Omit<ConfirmDialogProps, "onConfirm"> {
  deploymentRing: DeploymentRing;
  selector: SelectorDto;
}

export const ConfirmUnassignSelectorDialog: React.FC<ConfirmUnassignSelectorDialogProps> = ({
  deploymentRing,
  selector,
  ...props
}) => {
  const theme = useTheme();
  const { mutateAsync: updateDeploymentRing } = useUpdateDeploymentRing();

  const handleUnassignDeploymentRingSelector = () => {
    const { id, eTag, selectorId, ...rest } = deploymentRing!;

    updateDeploymentRing({
      deploymentRingId: id,
      eTag: eTag!,
      selectorId: undefined,
      ...rest
    });
  };

  return (
    <ConfirmDialog
      confirmButtonProps={{
        text: "Yes",
        styles: dangerButtonStyles(theme)
      }}
      cancelButtonProps={{ text: "No" }}
      onConfirm={handleUnassignDeploymentRingSelector}
      dialogContentProps={{
        title: "Unassign Selector"
      }}
      {...props}
    >
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Text>
          Are you sure you want to unassign this selector from this deployment
          ring?
        </Text>
        <Text variant="large">Selector:</Text>
        <Tile>
          <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
            <Text variant="large">{selector.name}</Text>
            <Text variant="small">{selector.description}</Text>
            <Text variant="xSmall">{selector.id}</Text>
          </Stack>
        </Tile>
        <Text variant="large">Deployment Ring:</Text>
        <Tile>
          <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
            <Text variant="large">
              {deploymentRing.displayName} - {deploymentRing.code}
            </Text>
            <Text variant="small">{deploymentRing.description}</Text>
            <Text variant="xSmall">{deploymentRing.id}</Text>
          </Stack>
        </Tile>
      </Stack>
    </ConfirmDialog>
  );
};
