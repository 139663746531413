import { observer } from "mobx-react";
import { ApproveBuildButton } from "modules/tenants/sections/pros/dashboard/site-deployment/ApproveBuildButton";
import { useState } from "react";

import {
  Heading,
  IDropdownOption,
  IDropdownStyles,
  Stack
} from "@bps/fluent-ui";
import { SiteComponentDropdown } from "@components/pickers/SiteComponentDropdown";
import { SiteComponentVersionDropdown } from "@components/pickers/SiteComponentVersionDropdown";
import { SecurableComponent } from "@components/SecurableComponent";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { useSiteComponentVersionsQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { CommandRunnerProvider } from "../../../_shared-models/CommandRunner";

interface SiteDeploymentProps {
  siteId: string;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 150 }
};

export const SiteDeployment = observer(({ siteId }: SiteDeploymentProps) => {
  const [desiredVersion, setDesiredVersion] = useState<string>();
  const [componentType, setComponentType] = useState<SiteComponentType>();

  const currentVersionsQuery = useSiteComponentVersionsQuery(siteId);

  const handleComponentChange = (_, item: IDropdownOption) => {
    setComponentType(item.key as SiteComponentType);
    // Reset version selected
    setDesiredVersion("");
  };

  const handleVersionChange = (item: IDropdownOption): void => {
    setDesiredVersion(item.key.toString());
  };

  const selectedComponent = currentVersionsQuery.data?.find(
    c => c.componentType === componentType
  );

  return (
    <CommandRunnerProvider>
      <Heading variant="heading">Manage Deployment</Heading>
      <SecurableComponent permissions={[Permissions.ProsSiteManagementWrite]}>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item>
              <SiteComponentDropdown
                label="Component"
                onChange={handleComponentChange}
                styles={dropdownStyles}
              />
            </Stack.Item>
            <Stack.Item>
              <SiteComponentVersionDropdown
                label="Version"
                componentType={componentType}
                selectedKey={desiredVersion}
                onChange={handleVersionChange}
                styles={dropdownStyles}
              />
            </Stack.Item>
            <Stack.Item align="end">
              <ApproveBuildButton
                siteId={siteId}
                component={selectedComponent}
                version={desiredVersion}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </SecurableComponent>
    </CommandRunnerProvider>
  );
});
