import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { Checkbox } from "@bps/fluent-ui";

import { ConfigValueInputProps } from "./";

interface BoolConfigValueInputProps extends ConfigValueInputProps {}

export const BoolConfigValueInput = ({
  value,
  onUpdate,
  rolloutOccurred,
  readonly
}: BoolConfigValueInputProps) => {
  const boolValue = value === "true";
  const boolUpdate = (value: boolean | undefined) => {
    onUpdate(value ? "true" : "false");
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <Checkbox
      checked={boolValue}
      onChange={(e, value) => boolUpdate(value)}
      automationAttribute="bool-config-input"
      disabled={rolloutOccurred || readonly || !hasWritePermission}
      title={
        !hasWritePermission && !readonly ? NO_ACTION_PERMISSION : undefined
      }
    />
  );
};
