import { CopyableGuid } from "@components/CopyableText";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import { IColumn, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { MessageCreditsBalance } from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";
import { useMessageCreditsBalances } from "@libs/api/gateways/plt-comms/plt-comms-gateway.hooks";
import { Guid } from "@libs/common/guid";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenantId: string;
}

const MessageCreditsBalancesTableBase: FunctionComponent<Props> = ({
  tenantId
}) => {
  const theme = useTheme();
  const balancesQuery = useMessageCreditsBalances(tenantId);

  const columns: IColumn[] = [
    {
      key: "createdDate",
      name: "Created Date",
      onRender: (item: MessageCreditsBalance) =>
        item.changeLog.createdDate?.toFormat(
          DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
        ),
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: "createdBy",
      name: "Created By",
      onRender: (item: MessageCreditsBalance) => item.changeLog.createdBy,
      minWidth: 150,
      maxWidth: 300
    },
    {
      key: "units",
      name: "Units",
      onRender: (item: MessageCreditsBalance) => item.units,
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: "eventId",
      name: "Event Id",
      onRender: (item: MessageCreditsBalance) => {
        return (
          <CopyableGuid
            value={item.eventId ?? Guid.empty}
            variant="small"
            color={theme.palette.themeSecondary}
          />
        );
      },
      minWidth: 300,
      maxWidth: 350
    }
  ];

  return (
    <QueryStateIndicator {...balancesQuery}>
      {data => {
        return <ShimmeredDetailsList items={data} columns={columns} />;
      }}
    </QueryStateIndicator>
  );
};

export const MessageCreditsBalancesTable = withPermissions(
  MessageCreditsBalancesTableBase,
  [Permissions.PltCommsCreditsRead, Permissions.PltCommsCreditsWrite],
  "or"
);
