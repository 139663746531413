import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { useInstallPrerequisites } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

export const InstallPrerequisitesCommandButton = (props: {
  siteId: string;
}) => {
  const { siteId } = props;

  const commandRunner = useCommandRunner();
  const mutation = useInstallPrerequisites();

  const handleClick = () =>
    commandRunner.runMutation("Install Prerequisites", mutation, siteId);

  return (
    <SiteCommandButton
      commandName="Install Prerequisites"
      onClick={handleClick}
      iconName="Installation"
    />
  );
};
