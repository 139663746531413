import React, { useState } from "react";

import { DefaultButton, ScrollablePane, Stack, useTheme } from "@bps/fluent-ui";
import { Item, SelectionList } from "@components/SelectionList";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { NewRingDialog } from "./edit/NewRingDialog";
import { RingListItem } from "./RingListItem";

interface RingListProps {
  deploymentRings?: DeploymentRing[];
  deploymentRing?: DeploymentRing;
  onSelectedItemChange: (item: Item) => void;
}

export const RingList: React.FC<RingListProps> = ({
  deploymentRings,
  deploymentRing,
  onSelectedItemChange
}) => {
  const theme = useTheme();
  const [showNew, setShowNew] = useState<boolean>(false);
  const rings = deploymentRings ?? [];

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const ringItems =
    rings?.map(
      ring =>
        ({
          id: ring.id,
          name: ring.displayName,
          node: <RingListItem ring={ring} />,
          requiredPermissions: [Permissions.PltFieldManagementRead]
        } as Item)
    ) ?? [];

  const selectedItem = ringItems.find(x => x.id === deploymentRing?.id);

  return (
    <Stack verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
      <DefaultButton
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        iconProps={{ iconName: "Add" }}
        onClick={() => setShowNew(true)}
        styles={{ root: { width: "max-content", alignSelf: "center" } }}
      >
        New Ring
      </DefaultButton>
      <NewRingDialog hidden={!showNew} onDismiss={() => setShowNew(false)} />
      <Stack
        verticalFill
        verticalAlign="space-between"
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack verticalFill styles={{ root: { position: "relative" } }}>
          <ScrollablePane>
            <SelectionList
              items={ringItems}
              selectedItem={selectedItem}
              onSelectedItemChange={onSelectedItemChange}
            />
          </ScrollablePane>
        </Stack>
      </Stack>
    </Stack>
  );
};
