import { FieldDeviceStatus } from "modules/field-mgmt/tabs/FieldDeviceStatus";
import React from "react";

import {
  ButtonsGroupChoice,
  ButtonsGroupOption,
  Heading,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import {
  FieldDeviceComponentConfig,
  FieldDeviceReportedConfig
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface SoftwarePackageHeaderProps {
  reportedConfig: FieldDeviceReportedConfig | undefined;
  desiredConfig: FieldDeviceComponentConfig | undefined;
  selectedVersion: SelectedVersionState;
  setSelectedVersion: (version: SelectedVersionState) => void;
}

export enum SelectedVersionState {
  ReportedConfig = "reportedConfig",
  DesiredConfig = "desiredConfig"
}

export const SoftwarePackageHeader: React.FC<SoftwarePackageHeaderProps> = ({
  reportedConfig,
  desiredConfig,
  selectedVersion,
  setSelectedVersion
}) => {
  const theme = useTheme();
  if (!reportedConfig && !desiredConfig) {
    return <Text variant="xLarge">Software Package Not Found</Text>;
  }

  const versionOptions: ButtonsGroupOption<string>[] = [
    ...(reportedConfig?.softwarePackageVersion
      ? [
          {
            key: SelectedVersionState.ReportedConfig,
            text: "", // Replace by onRender
            onRenderText: () => (
              <Stack>
                <Text variant="tiny">Current Version</Text>
                <Text variant="medium">
                  {reportedConfig?.softwarePackageVersion}
                </Text>
              </Stack>
            )
          }
        ]
      : []),
    ...(desiredConfig?.version
      ? [
          {
            key: SelectedVersionState.DesiredConfig,
            text: "", // Replace by onRender
            onRenderText: () => (
              <Stack>
                <Text variant="tiny">Desired Version</Text>
                <Text variant="medium">{desiredConfig?.version}</Text>
              </Stack>
            )
          }
        ]
      : [])
  ];

  return (
    <Stack
      styles={{
        root: {
          borderColor: theme.semanticColors.variantBorder,
          borderWidth: 1,
          borderStyle: "solid",
          padding: theme.spacing.s1
        }
      }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Stack verticalFill verticalAlign="space-between">
          <Text variant="xLarge">
            {reportedConfig?.softwarePackageDisplayName ?? desiredConfig?.name}
          </Text>
          <Stack.Item>
            <Heading labelPaddings>Command target version</Heading>
            <ButtonsGroupChoice
              notUnselectable
              options={versionOptions}
              onChange={(version: SelectedVersionState) =>
                setSelectedVersion(version)
              }
              value={selectedVersion}
            />
          </Stack.Item>
        </Stack>
        <Stack
          verticalFill
          verticalAlign="space-between"
          styles={{ root: { height: "fit-content" } }}
        >
          <FieldDeviceStatus
            status={reportedConfig?.status ?? reportedConfig?.runStatus}
          />
          <Text variant="small">
            Last Observed:{" "}
            {reportedConfig?.lastObservedDateTimeUtc.toFormat(
              DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
            )}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
