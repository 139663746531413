import { CopyToClipboardButton } from "@components/buttons/CopyToClipboardButton";
import { SectionTitle } from "@components/SectionTitle";
import React from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface FieldDeviceOverviewProps {
  device: FieldDevice;
}

export const FieldDeviceOverview: React.FC<FieldDeviceOverviewProps> = ({
  device
}) => {
  const theme = useTheme();
  return (
    <>
      <SectionTitle>Device Overview</SectionTitle>
      <Stack
        tokens={{ childrenGap: 4 }}
        styles={{
          root: {
            maxWidth: 470,
            borderColor: theme.semanticColors.variantBorder,
            borderWidth: 1,
            borderStyle: "solid",
            padding: theme.spacing.s1
          }
        }}
      >
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          styles={{ root: { display: "flex", justifyContent: "center" } }}
        >
          <Text bold>Field Device Id: </Text>
          <Text>{device.id}</Text>
          <CopyToClipboardButton clipboardContent={device.id} />
        </Stack>
        <Stack
          horizontal
          tokens={{ childrenGap: 4 }}
          styles={{ root: { display: "flex", justifyContent: "center" } }}
        >
          <Text bold>Cloud Connector Id: </Text>
          <Text>{device.cloudConnectorId}</Text>
          <CopyToClipboardButton clipboardContent={device.cloudConnectorId} />
        </Stack>
      </Stack>
    </>
  );
};
