import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FunctionComponent } from "react";

import { Dialog, Heading, Stack, StackItem, Text } from "@bps/fluent-ui";
import { IpRangeDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { useCreateApplication } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface CreateApplicationDialogProps {
  tenant: Tenant;
  onDismiss: () => void;
}

export interface CreateApplicationFormValues {
  name: string;
  ipRanges?: IpRangeDto[];
}

export const CreateApplicationDialog: FunctionComponent<CreateApplicationDialogProps> = ({
  tenant,
  onDismiss
}) => {
  const {
    mutateAsync: createApplication,
    error: createApplicationError
  } = useCreateApplication();

  const onSubmit = async (formData: CreateApplicationFormValues) => {
    await createApplication({
      name: formData.name,
      tenantId: tenant.id,
      ipRanges: formData.ipRanges
    });
    onDismiss();
  };

  const defaultValues = {
    name: ""
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Create Application",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<CreateApplicationFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={createApplicationError}
      >
        <FormTextField
          name="name"
          label="Name"
          required
          rules={{ required: "Name is required" }}
        />
        <Heading labelPaddings>Ip Ranges (Optional)</Heading>

        <Stack tokens={{ childrenGap: 8 }} verticalAlign="space-between">
          <StackItem>
            <Text>Range 1</Text>
            <FormTextField
              name="ipRanges[0].ipRangeBegin"
              placeholder="IP Range Begin"
            />
            <Text>to</Text>
            <FormTextField
              name="ipRanges[0].ipRangeEnd"
              placeholder="IP Range End"
            />
          </StackItem>
          <StackItem>
            <Text>Range 2</Text>
            <FormTextField
              name="ipRanges[1].ipRangeBegin"
              placeholder="IP Range Begin"
            />
            <Text>to</Text>
            <FormTextField
              name="ipRanges[1].ipRangeEnd"
              placeholder="IP Range End"
            />
          </StackItem>
        </Stack>
      </Form>
    </Dialog>
  );
};
