import React from "react";

import { Separator, Stack, useTheme } from "@bps/fluent-ui";
import { PermissionsAndRoles } from "./PermissionsAndRoles";
import { PltUserAuthenticationForm } from "./plt-user-authentication-form/PltUserAuthenticationForm";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface PltUsersRowExpandProps {
  user: PltUser;
}

export const PltUsersRowExpand: React.FC<PltUsersRowExpandProps> = ({
  user
}) => {
  const theme = useTheme();
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      tokens={{ padding: theme.spacing.m, childrenGap: theme.spacing.m }}
    >
      <Stack.Item grow styles={{ root: { flexBasis: 0 } }}>
        <PermissionsAndRoles user={user} />
      </Stack.Item>
      <Separator vertical />
      <Stack grow styles={{ root: { flexBasis: 0 } }}>
        <PltUserAuthenticationForm user={user} />
      </Stack>
    </Stack>
  );
};
