import { Dialog, IDialogProps } from "@bps/fluent-ui";

import { RingForm, RingFormValues } from "./RingForm";
import { useCreateDeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.hooks";

interface NewRingDialogProps extends IDialogProps {
  onDismiss: () => void;
}

export const NewRingDialog: React.FC<NewRingDialogProps> = ({
  onDismiss,
  ...props
}) => {
  const { mutateAsync: createDeploymentRing } = useCreateDeploymentRing();

  const onSubmit = async (formData: RingFormValues) => {
    await createDeploymentRing({
      code: formData.code,
      description: formData.description,
      displayName: formData.displayName,
      isPublic: formData.isPublic
    });
    onDismiss();
  };
  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "New Ring"
      }}
      onDismiss={onDismiss}
      {...props}
    >
      <RingForm onSubmit={onSubmit} />
    </Dialog>
  );
};
