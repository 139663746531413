import React, { FunctionComponent, useState } from "react";
import {
  PivotItem,
  PivotTabs,
  Stack,
  TopBarDefaultHeader
} from "@bps/fluent-ui";
import { UserForm } from "./UserForm";

import { UserBpId } from "./UserBpId";
import { UserSignInLogs } from "./UserSignInLogs";
import { useNavigate } from "react-router-dom";
import { SectionTitle } from "@components/SectionTitle";
import {
  ApplicationType,
  PltUser,
  TenantDefaultUsersAuthenticationDto,
  UserDefaultAuthenticationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";

enum UserScreenTabs {
  Base = "base",
  BpId = "bp-id",
  Logs = "logs"
}

interface UserScreenWrapperProps {
  user: PltUser;
  tenantAuth: TenantDefaultUsersAuthenticationDto | undefined;
  userAuth: UserDefaultAuthenticationDto | undefined;
  application?: ApplicationType;
}
export const UserScreenWrapper: FunctionComponent<UserScreenWrapperProps> = props => {
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState<UserScreenTabs | undefined>(
    UserScreenTabs.Base
  );
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <TopBarDefaultHeader
        backButtonOnClick={() => navigate(-1)}
        heading="Back to users list"
      />
      <SectionTitle>
        {props.user.firstName} {props.user.lastName}
      </SectionTitle>
      <PivotTabs
        selectedKey={selectedKey}
        onLinkClick={item => {
          setSelectedKey(item?.props.itemKey as UserScreenTabs);
        }}
      >
        <PivotItem itemKey={UserScreenTabs.Base} headerText="Details">
          <UserForm {...props} />
        </PivotItem>

        <PivotItem itemKey={UserScreenTabs.BpId} headerText="Bp Id">
          <UserBpId user={props.user} />
        </PivotItem>

        <PivotItem itemKey={UserScreenTabs.Logs} headerText="Sign-In Logs">
          <UserSignInLogs user={props.user} />
        </PivotItem>
      </PivotTabs>
    </Stack>
  );
};
