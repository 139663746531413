import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import React from "react";

import { FontIcon, IColumn, Link, Stack, Text } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  AppointmentReminderJob,
  AppointmentReminderJobRun
} from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.dtos";
import { useAppointmentReminderJobRuns } from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.hooks";
import { config } from "@libs/config/config";

interface AppointmentReminderJobCardProps {
  job: AppointmentReminderJob;
  tenantId: string;
}

export const AppointmentReminderJobCard: React.FC<AppointmentReminderJobCardProps> = ({
  job,
  tenantId
}) => {
  const appointmentReminderJobRunsQuery = useAppointmentReminderJobRuns(
    tenantId,
    job.id
  );

  const columns: IColumn[] = [
    {
      key: "appointmentReminderJobRunId",
      name: "Job run id",
      onRender: (item: AppointmentReminderJobRun) => {
        const link = `${config.seqUrl}?filter=AppointmentReminderJobRunId%20%3D%20'${item.id}'%20or%20OutboundCommBatchId%20%3D%20'${item.id}'`;
        return (
          <Link href={link} target="_blank" rel="noreferrer">
            {item.id} (seq link) <FontIcon iconName="NavigateExternalInline" />
          </Link>
        );
      },
      minWidth: 400,
      maxWidth: 400
    },
    {
      key: "updatedDate",
      name: "Last updated",
      onRender: (item: AppointmentReminderJobRun) =>
        item.changeLog.updatedDate?.toString() ??
        item.changeLog.createdDate?.toString() ??
        "--",
      minWidth: 200
    },
    {
      key: "calendarEventCount",
      name: "Event count",
      onRender: (item: AppointmentReminderJobRun) =>
        item.calendarEventCount ?? "--",
      minWidth: 200
    }
    //show summary of calendar events/reminders, inc errors?
  ];

  return (
    <Stack>
      <Text>Job Schedule: {JSON.stringify(job.jobSchedule)}</Text>
      <Text>
        AppointmentReminderOptions:{" "}
        {JSON.stringify(job.appointmentReminderOptions)}
      </Text>
      <QueryStateIndicator {...appointmentReminderJobRunsQuery}>
        {data => {
          return <ShimmeredDetailsList items={data} columns={columns} />;
        }}
      </QueryStateIndicator>
    </Stack>
  );
};
