import { DataTable } from "@components/tables/DataTable";
import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import { Card, FontIcon, IColumn, Link } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { AppointmentReminderJob } from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.dtos";
import { useAppointmentReminderJobs } from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.hooks";
import { config } from "@libs/config/config";
import { Permissions } from "@libs/permissions/permissions.enum";

import { AppointmentReminderJobCard } from "./AppointmentReminderJobCard";

interface Props {
  tenant: Tenant;
}

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

const CommsSchedulesTableBase: FunctionComponent<Props> = ({
  tenant: { id: tenantId }
}) => {
  const appointmentReminderJobsQuery = useAppointmentReminderJobs(tenantId);

  const columns: IColumn[] = [
    {
      key: "jobName",
      name: "Name",
      onRender: (item: AppointmentReminderJob) => item.jobName,
      minWidth: 100,
      maxWidth: 300
    },
    {
      key: "appointmentReminderJobId",
      name: "Id",
      onRender: (item: AppointmentReminderJob) => {
        const link = `${config.seqUrl}?filter=AppointmentReminderJobId%20%3D%20'${item.id}'`;
        return (
          <Link href={link} target="_blank" rel="noreferrer">
            {item.id} (seq link) <FontIcon iconName="NavigateExternalInline" />
          </Link>
        );
      },
      minWidth: 400,
      maxWidth: 400
    },
    {
      key: "status",
      name: "Status",
      onRender: (item: AppointmentReminderJob) => item.status,
      minWidth: 100
    },
    {
      key: "updatedDate",
      name: "Last updated",
      onRender: (item: AppointmentReminderJob) =>
        item.changeLog.updatedDate?.toString() ??
        item.changeLog.createdDate?.toString() ??
        "--",
      minWidth: 200
    },
    {
      key: "updatedBy",
      name: "Updated by",
      onRender: (item: AppointmentReminderJob) =>
        item.changeLog.updatedBy ?? item.changeLog.createdBy ?? "--",
      minWidth: 250
    }
  ];

  return (
    <Card
      iconName="CalendarSettings"
      headingLevel="section-heading"
      heading="Reminder Jobs"
      styles={cardStyles}
    >
      <QueryStateIndicator {...appointmentReminderJobsQuery}>
        {data => {
          return (
            <DataTable
              onRenderRow={row => (
                <DetailsRowExpand
                  row={row}
                  expand={
                    <AppointmentReminderJobCard
                      job={row?.item}
                      tenantId={tenantId}
                    />
                  }
                />
              )}
              items={data}
              columns={columns}
            />
          );
        }}
      </QueryStateIndicator>
    </Card>
  );
};

export const CommsSchedulesTable = withPermissions(CommsSchedulesTableBase, [
  Permissions.TiSettingsRead
]);
