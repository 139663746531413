import React from "react";

import { Text, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import humanizer from "@libs/common/humanize-duration";

import { EMPTY_LABEL, getRootStyles, humanizeOptions } from "../general-utils";
import { GeneralSection } from "../GeneralSection";

interface GeneralTokenLifeTimeProps {
  tokenExpiryUtc?: DateTime;
}

export const GeneralTokenLifeTime: React.FC<GeneralTokenLifeTimeProps> = ({
  tokenExpiryUtc
}) => {
  const theme = useTheme();
  const now = DateTime.now();

  if (tokenExpiryUtc === undefined) {
    return (
      <GeneralSection heading="Token Lifetime">{EMPTY_LABEL}</GeneralSection>
    );
  }

  const hasExpired = now >= tokenExpiryUtc;
  const tokenLifetimeHumanized = humanizer.humanize(
    tokenExpiryUtc.diff(now).milliseconds,
    humanizeOptions
  );

  const tokenLifetimeLabel =
    hasExpired === true
      ? `Expired ${tokenLifetimeHumanized} ago`
      : `Expires in ${tokenLifetimeHumanized}`;

  return (
    <GeneralSection heading="Token Lifetime">
      <Text styles={getRootStyles(theme, hasExpired)}>
        {tokenLifetimeLabel}
      </Text>
    </GeneralSection>
  );
};
