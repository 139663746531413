import { ScrollListener } from "@components/tables/ScrollListener";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import React from "react";

import {
  FontIcon,
  Link,
  NativeList,
  ScrollablePane,
  SelectionMode,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { PltTenantApplicationBadge } from "../../_shared-blocks/PltTenantApplicationBadge";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenants: Tenant[];
  error?: string;
  isLoading?: boolean;
  onTenantSelected: (tenant: Tenant) => void;
  hasNextPage: boolean | undefined;
  handleScrolledToBottom: () => Promise<void>;
}

export const TenantsTable = ({
  tenants,
  onTenantSelected,
  error,
  isLoading,
  handleScrolledToBottom,
  hasNextPage
}: Props) => {
  const columns: DataTableColumn<Tenant>[] = [
    {
      key: "name",
      name: "Name",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (tenant: Tenant) => (
        <Stack>
          <Link onClick={() => onTenantSelected(tenant)}>{tenant.name}</Link>
          <Text variant="xSmall">{tenant.id}</Text>
        </Stack>
      )
    },
    {
      key: "application",
      name: "Application",
      minWidth: 170,
      sort: true,
      onRender: (item: Tenant) => (
        <PltTenantApplicationBadge applicationCode={item.application} />
      )
    },
    {
      key: "country",
      name: "Country",
      minWidth: 100,
      sort: true
    },
    {
      key: "permissions",
      name: "Permissions",
      minWidth: 82,
      onRender: (item: Tenant) => {
        if (!item.permissions?.length) {
          return "--";
        }
        return (
          <TooltipHost
            content={
              <NativeList>
                {item.permissions.map(perm => (
                  <ol key={perm}>{perm}</ol>
                ))}
              </NativeList>
            }
          >
            <TextBadge
              badgeSize={TextBadgeSize.small}
              badgeColor={TextBadgeColor.yellow}
              horizontal
              horizontalAlign="center"
            >
              {item.permissions?.length}
              <FontIcon
                iconName="Info"
                styles={{ root: { fontSize: 10, paddingLeft: 4 } }}
              />
            </TextBadge>
          </TooltipHost>
        );
      },
      sort: (left, right) =>
        (left.permissions?.length ?? 0) - (right.permissions?.length ?? 0)
    },
    {
      key: "resources",
      name: "Resources",
      minWidth: 100,
      onRender: (item: Tenant) => (item.resources?.length || "--").toString()
    },
    {
      key: "updated",
      name: "Last Updated",
      minWidth: 125,
      onRender: ({ changeLog }: Tenant) =>
        (changeLog.updatedDate ?? changeLog.createdDate).toFormat(
          DATE_FORMATS.DAY_DEFAULT_FORMAT
        ),
      sort: (left, right) => {
        const leftValue =
          left.changeLog.updatedDate ?? left.changeLog.createdDate;

        const rightValue =
          right.changeLog.updatedDate ?? right.changeLog.createdDate;

        return leftValue.valueOf() - rightValue.valueOf();
      }
    },
    {
      key: "Active",
      name: "Status",
      minWidth: 100,
      onRender: (item: Tenant) => {
        return (
          <TextBadge
            badgeSize={TextBadgeSize.small}
            badgeColor={
              item.isInactive ? TextBadgeColor.red : TextBadgeColor.green
            }
          >
            {item.isInactive ? "Inactive" : "Active"}
          </TextBadge>
        );
      },
      sort: true
    },
    {
      key: "isTemporary",
      name: "Is Temp",
      minWidth: 100,
      onRender: (item: Tenant) => {
        return (
          <TextBadge
            badgeSize={TextBadgeSize.small}
            badgeColor={
              item.isTemporary ? TextBadgeColor.red : TextBadgeColor.green
            }
          >
            {item.isTemporary ? "Yes" : "No"}
          </TextBadge>
        );
      },
      sort: true
    }
  ];

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          position: "relative",
          height: "100%",
          flex: "1 1 0",
          overflowY: "auto"
        }
      }}
    >
      <ScrollablePane>
        <DataTable
          items={tenants}
          stickyHeader
          columns={columns}
          selectionMode={SelectionMode.none}
          onShouldVirtualize={() => false}
          enableShimmer={isLoading}
          errorMessage={error}
        />
        <ScrollListener
          hasNextPage={hasNextPage ?? false}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
