import { Route, Routes } from "react-router-dom";

import { useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SideNavLayout } from "@layouts/SideNavLayout";
import { useFieldDeviceQuery } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { FieldDeviceTabs } from "../field-device-tabs.constants";
import { FieldDeviceConfiguration } from "./device/configuration";
import { FieldDeviceLogs } from "./device/devicelogs";
import { FieldDeviceDeploymentRings } from "./device/field-device-deployment-rings";
import { FieldDeviceIdentifiers } from "./device/identifiers";
import { FieldDeviceOverview } from "./device/overview";
import { FieldDeviceProperties } from "./device/properties";
import { FieldDeviceSecurity } from "./device/security";
import { SoftwarePackagePage } from "./device/software-package";
import { FieldDeviceSections } from "./FieldDeviceSections";

interface FieldDeviceSectionsHostProps {
  id: string;
}

export const FieldDeviceSectionsHost: React.FC<FieldDeviceSectionsHostProps> = ({
  id
}) => {
  const deviceQuery = useFieldDeviceQuery(id);
  const theme = useTheme();

  return (
    <QueryStateIndicator {...deviceQuery}>
      {device => {
        const routes = [
          {
            path: FieldDeviceTabs.Device.Overview.id,
            element: <FieldDeviceOverview device={device} />
          },
          {
            path: FieldDeviceTabs.Device.Identifiers.id,
            element: <FieldDeviceIdentifiers device={device} />
          },
          {
            path: FieldDeviceTabs.Device.Properties.id,
            element: <FieldDeviceProperties device={device} />
          },
          {
            path: FieldDeviceTabs.Device.Security.id,
            element: <FieldDeviceSecurity device={device} />
          },
          {
            path: FieldDeviceTabs.Device.Configuration.id,
            element: <FieldDeviceConfiguration device={device} />
          },
          {
            path: FieldDeviceTabs.Device.DeploymentRings.id,
            element: <FieldDeviceDeploymentRings device={device} />
          },
          {
            path: FieldDeviceTabs.Device.Logs.id,
            element: <FieldDeviceLogs device={device} />
          }
        ];

        return (
          <SideNavLayout
            nav={<FieldDeviceSections id={id} />}
            bodyProps={{
              styles: {
                root: {
                  padding: theme.spacing.m
                }
              }
            }}
          >
            <Routes>
              {routes.map(r => (
                <Route key={r.path} path={r.path} element={r.element} />
              ))}
              <Route
                path="services/:softwarePackageCode/*"
                element={<SoftwarePackagePage device={device} />}
              />
              <Route
                path="modules/:softwarePackageCode/*"
                element={<SoftwarePackagePage device={device} />}
              />
            </Routes>
          </SideNavLayout>
        );
      }}
    </QueryStateIndicator>
  );
};
