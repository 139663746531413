import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { SpinNumberInput } from "@bps/fluent-ui";

import { ConfigValueInputProps } from "./";

interface IntConfigValueInputProps extends ConfigValueInputProps {}

export const IntConfigValueInput = ({
  value,
  onUpdate,
  rolloutOccurred,
  readonly
}: IntConfigValueInputProps) => {
  const validateValidInt = (inputValue: string | undefined) => {
    if (!inputValue) return "0";

    try {
      parseInt(inputValue);
    } catch {
      return "0";
    }

    return inputValue;
  };

  const handleUpdate = (value: string | undefined) => {
    const validated = validateValidInt(value);
    onUpdate(validated);
  };

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <SpinNumberInput
      value={value}
      onChange={handleUpdate}
      disabled={rolloutOccurred || readonly || !hasWritePermission}
      title={
        !hasWritePermission && !readonly ? NO_ACTION_PERMISSION : undefined
      }
    />
  );
};
