import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import React, { useEffect, useMemo, useState } from "react";

import {
  DefaultButton,
  Dropdown,
  DropdownProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { useAvailableComponentVersionsQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { SiteComponentVersion } from "@libs/api/gateways/sia/models/SiteComponentVersion";

const DEFAULT_VERSION_COUNT = 10;

const ProsBuildMgmtBase: React.FC = () => {
  const theme = useTheme();
  const [desiredVersion, setDesiredVersion] = useState<string>();
  const {
    data: allVersionsByComponent = []
  } = useAvailableComponentVersionsQuery();

  const options = useMemo(() => {
    // Map to dropdown object
    const versionsMapped = allVersionsByComponent.map(
      dto => new SiteComponentVersion(dto)
    );

    const versions =
      versionsMapped.find(x => x.component === SiteComponentType.SiteManager)
        ?.versions || [];
    return [...versions].slice(0, DEFAULT_VERSION_COUNT);
  }, [allVersionsByComponent]);

  useEffect(() => {
    setDesiredVersion(String(options[0]?.key));
  }, [options]);

  const handleVersionChange: DropdownProps["onChange"] = () => {};

  return (
    <Stack
      tokens={{
        childrenGap: theme.spacing.m
      }}
    >
      <SectionTitle>Site Manager</SectionTitle>
      <Stack
        horizontal
        tokens={{
          childrenGap: theme.spacing.m
        }}
      >
        <Stack
          styles={{
            root: {
              padding: theme.spacing.m,
              borderWidth: 1,
              borderColor: theme.semanticColors.inputBorder,
              borderStyle: "solid"
            }
          }}
        >
          Detailed version info
        </Stack>
        <Dropdown
          name="detailed-version-info"
          options={options}
          selectedKey={desiredVersion}
          onChange={handleVersionChange}
          withNoEmptyOption
          styles={{
            root: {
              width: 150
            }
          }}
        />
        <DefaultButton primary>Set Default Version</DefaultButton>
      </Stack>
    </Stack>
  );
};

export const ProsBuildMgmt = withPermissions(ProsBuildMgmtBase, [
  Permissions.OpsConsolePreRelease
]);
