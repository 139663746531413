import React from "react";
import { Route, Routes } from "react-router-dom";

import { CommandJsonForm } from "./CommandJsonForm";

import { CustomRJSFSchema } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface CommandRoutesProps<TData = unknown> {
  onSubmit: (obj: TData) => Promise<void>;
  schema: CustomRJSFSchema[];
}

export const CommandRoutes: React.FC<CommandRoutesProps> = ({
  onSubmit,
  schema
}) => {
  return (
    <Routes>
      {schema.map(schema => (
        <Route
          key={schema.title}
          path={schema.title}
          element={<CommandJsonForm schema={schema} onSubmit={onSubmit} />}
        />
      ))}
      <Route
        path="/*"
        element={<CommandJsonForm schema={undefined} onSubmit={onSubmit} />}
      />
    </Routes>
  );
};
