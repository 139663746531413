import { BoolConfigValueInput } from "./BoolConfigValueInput";
import { DecimalConfigValueInput } from "./DecimalConfigValueInput";
import { IntConfigValueInput } from "./IntConfigValueInput";
import { StringConfigValueInput } from "./StringConfigValueInput";

export interface ConfigValueInputProps {
  value: string | undefined;
  onUpdate: (value: string | undefined) => void;
  rolloutOccurred?: boolean;
  readonly?: boolean;
}

interface ConfigValueProps extends ConfigValueInputProps {
  dataType: string;
}

export const ConfigValueInput = ({ dataType, ...props }: ConfigValueProps) => {
  switch (dataType) {
    case "String":
      return <StringConfigValueInput {...props} />;
    case "Boolean":
      return <BoolConfigValueInput {...props} />;
    case "Int32":
      return <IntConfigValueInput {...props} />;
    case "Double":
      return <DecimalConfigValueInput {...props} />;
    default:
      return <StringConfigValueInput {...props} />;
  }
};
