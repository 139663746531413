import { LogEntriesComponent } from "modules/tenants/sections/ops/logging/LogEntriesComponent";
import React from "react";

import { Heading, Link, Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";
import {
  useFieldDeviceAzureMonitorLogsQuery,
  useFieldDeviceSeqLogsQuery
} from "@libs/api/gateways/user-experience/user-experience.hooks";
import { config } from "@libs/config/config";

interface FieldDeviceSecurityProps {
  device: FieldDevice;
}

export const FieldDeviceLogs: React.FC<FieldDeviceSecurityProps> = ({
  device
}) => {
  const azureQuery = useFieldDeviceAzureMonitorLogsQuery(
    device.cloudConnectorId,
    device.id
  );

  const seqQuery = useFieldDeviceSeqLogsQuery(
    device.cloudConnectorId,
    device.id
  );

  return (
    <>
      <SectionTitle>Field Device Logs</SectionTitle>

      <Stack tokens={{ childrenGap: 5 }}>
        <Heading variant="section-heading">SEQ Logs</Heading>
        <div>
          <Link
            href={`${config.seqUrl}?filter=BpFieldDeviceId%20%3D%20"${device.id}"%20%7C%7C%20BpCloudConnectorId%20%3D%20"${device.cloudConnectorId}"`}
            target="_blank"
            rel="noreferrer"
          >
            Click here to open Seq for device {device.id}
          </Link>
        </div>
        <Stack.Item>
          <QueryStateIndicator<LogEntry[]> {...seqQuery}>
            {data => <LogEntriesComponent logEntries={data} />}
          </QueryStateIndicator>
        </Stack.Item>

        <Heading variant="section-heading">Azure Monitor Logs</Heading>
        <div>
          <Link href={config.azureMonitorUrl} target="_blank" rel="noreferrer">
            Click here to open Azure Monitor.
          </Link>
        </div>
        <Stack.Item>
          <QueryStateIndicator<LogEntry[]> {...azureQuery}>
            {data => <LogEntriesComponent logEntries={data} />}
          </QueryStateIndicator>
        </Stack.Item>
      </Stack>
    </>
  );
};
