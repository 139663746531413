import { ComponentActionDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent } from "react";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useComponentActions } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { DetailsList, IColumn } from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { ComponentActionCode } from "./ComponentActionCode";
import { WorkflowRunInstanceLink } from "../../_shared-blocks/WorkflowRunInstanceLink";

interface ComponentRequestActionsProps {
  componentActionRequestId: string;
}
export const ComponentRequestActions: FunctionComponent<ComponentRequestActionsProps> = ({
  componentActionRequestId
}) => {
  const componentActionsQuery = useComponentActions({
    componentActionRequestId
  });

  const columns: IColumn[] = [
    {
      key: "code",
      name: "Component Code",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: ComponentActionDto) => (
        <ComponentActionCode codeOrId={item.componentId} />
      )
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: ComponentActionDto) => item.action
    },
    {
      key: "status",
      name: "Status",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: ComponentActionDto) => item.status
    },
    {
      key: "request-id",
      name: "Component Request ID",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentActionDto) => (
        <CopyableGuid value={item.componentActionRequestId} short />
      )
    },
    {
      key: "instance-id",
      name: "WF Instance Link",
      minWidth: 200,
      maxWidth: 300,
      onRender: (item: ComponentActionDto) => {
        if (!item.componentWorkflowRunInstanceId) return "-";
        return (
          <WorkflowRunInstanceLink id={item.componentWorkflowRunInstanceId} />
        );
      }
    }
  ];

  return (
    <QueryStateIndicator {...componentActionsQuery} noDataText="No actions">
      {actions => <DetailsList items={actions} columns={columns} compact />}
    </QueryStateIndicator>
  );
};
