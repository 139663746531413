import { CopyableGuid } from "@components/CopyableText";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import React, { FunctionComponent } from "react";

import {
  CollapsibleCard,
  DetailsList,
  ScrollablePane,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useChildTenants } from "@libs/api/gateways/plt/plt-gateway.hooks";

import {
  ItemChildTenant,
  SubscriptionItemsProps
} from "./SubscriptionItems.types";
import { SubscriptionItemsHeader } from "./SubscriptionItemsHeader";
import { SubscriptionNotes } from "./SubscriptionNotes";

export const SubscriptionItems: FunctionComponent<SubscriptionItemsProps> = ({
  subscription,
  subscriptionProducts,
  tenant
}) => {
  const theme = useTheme();
  const childTenantQuery = useChildTenants(tenant.id);
  const getScheduledQuantityFieldName = (
    subIndex: number,
    childTenantId: number | undefined
  ) =>
    `subscriptionProducts.${subIndex}.childTenants.${childTenantId}.scheduledQuantity`;

  return (
    <Stack
      verticalAlign="center"
      tokens={{ childrenGap: 8 }}
      grow
      styles={{ root: { height: "100%", position: "relative" } }}
    >
      <ScrollablePane>
        <Stack verticalAlign="center" tokens={{ childrenGap: 1 }}>
          {subscriptionProducts.map((subscriptionProduct, subIndex) => (
            <CollapsibleCard
              openOnRender
              key={subscriptionProduct.salesProductId}
              heading={
                <SubscriptionItemsHeader
                  data={subscriptionProduct}
                  subscription={subscription}
                />
              }
              styles={{
                header: { padding: 8 },
                heading: { display: "block", flexGrow: 1 },
                collapseContent: { padding: 0 },
                headerWrapper: {
                  backgroundColor: theme.palette.neutralLighter
                }
              }}
            >
              <QueryStateIndicator {...childTenantQuery} allowNullOrUndefined>
                {childTenants => (
                  <DetailsList
                    compact
                    items={subscriptionProduct.childTenants}
                    columns={[
                      {
                        name: "Id",
                        key: "id",
                        minWidth: 100,
                        maxWidth: 200,
                        onRender: (item: ItemChildTenant) => (
                          <CopyableGuid short value={item.childTenantId} />
                        )
                      },
                      {
                        name: "Crm Id",
                        key: "crm-id",
                        minWidth: 100,
                        maxWidth: 200,
                        onRender: (item: ItemChildTenant) => {
                          const childTenant = childTenants.find(
                            c => c.id === item.childTenantId
                          );

                          const crmId = childTenant?.crmId ?? tenant.crmId;
                          if (!crmId) return null;
                          return <CopyableGuid value={crmId} />;
                        }
                      },
                      {
                        name: "Tenant Name",
                        key: "tenant-name",
                        minWidth: 200,
                        maxWidth: 200,
                        onRender: (item: ItemChildTenant) => {
                          const childTenant = childTenants.find(
                            c => c.id === item.childTenantId
                          );

                          return childTenant?.name ?? tenant.name;
                        }
                      },
                      {
                        name: "Quantity",
                        key: "quantity",
                        minWidth: 100,
                        maxWidth: 100,
                        onRender: (_item, idx) => {
                          return (
                            <FormSpinNumberInput
                              disabled={!!subscription?.cancelAtPeriodEnd}
                              styles={{ root: { width: 100 } }}
                              name={getScheduledQuantityFieldName(
                                subIndex,
                                idx
                              )}
                            />
                          );
                        }
                      },
                      {
                        name: "Notes",
                        key: "notes",
                        minWidth: 150,
                        maxWidth: 150,
                        onRender: (_item, idx: number) => (
                          <SubscriptionNotes
                            subIndex={subIndex}
                            name={getScheduledQuantityFieldName(subIndex, idx)}
                            childTenantIdx={idx}
                            currentPeriodEnd={subscription?.currentPeriodEnd}
                          />
                        )
                      }
                    ]}
                  />
                )}
              </QueryStateIndicator>
            </CollapsibleCard>
          ))}
        </Stack>
      </ScrollablePane>
    </Stack>
  );
};
