import { useCallback } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
  DefaultButton,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DangerButton } from "@components/buttons/DangerButton";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { withPermissions } from "@components/withPermissions";
import { MobileAppSettings } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";
import {
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_DESCRIPTION,
  MINIMUM_VERSION_NUMBER,
  VERSION_UPGRADE_MESSAGES
} from "@libs/api/gateways/bp-mobile/bp-mobile.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { DECIMAL_NUMBER_REGEX } from "@libs/validation/validation-rules.constants";

export type MobileAppFormOnSubmit = (values: MobileAppFormSubmitValues) => void;

export interface VersionListItem {
  Version: string;
  Message: string;
}

export interface MobileAppFormSubmitValues {
  maintenanceModeId?: string;
  maintenanceModeDescriptionId?: string;
  versionUpgradeMessagesId?: string;
  minimumVersionNumberId?: string;
  maintenanceModeDescription?: string;
  maintenanceModeEnabled?: boolean;
  appVersions: VersionListItem[];
  minimumVersionNumber?: string;
}

interface MobileAppFormValues {
  maintenanceModeDescription?: string;
  maintenanceModeEnabled?: boolean;
  minimumVersionNumber?: string;
  appVersions: VersionListItem[];
}

interface MobileAppFormProps {
  onSubmit: MobileAppFormOnSubmit;
  mobileAppSettings: MobileAppSettings[];
  isLoading?: boolean;
}

const MobileAppFormBase: React.FC<MobileAppFormProps> = ({
  onSubmit,
  mobileAppSettings,
  isLoading
}) => {
  const maintenanceModeSetting = mobileAppSettings.find(
    setting => setting.settingName === MAINTENANCE_MODE
  );

  const maintenanceModeDescriptionSetting = mobileAppSettings.find(
    setting => setting.settingName === MAINTENANCE_MODE_DESCRIPTION
  );

  const versionUpgradeMessagesSetting = mobileAppSettings.find(
    setting => setting.settingName === VERSION_UPGRADE_MESSAGES
  );

  const minimumVersionNumberSetting = mobileAppSettings.find(
    setting => setting.settingName === MINIMUM_VERSION_NUMBER
  );

  const appVersions = versionUpgradeMessagesSetting
    ? JSON.parse(versionUpgradeMessagesSetting.settingValue)
    : [];

  const form = useForm<MobileAppFormValues>({
    defaultValues: {
      maintenanceModeDescription:
        maintenanceModeDescriptionSetting?.settingValue,
      maintenanceModeEnabled: maintenanceModeSetting?.settingValue === "true",
      minimumVersionNumber: minimumVersionNumberSetting?.settingValue,
      appVersions
    }
  });

  const theme = useTheme();

  const _onSubmit = useCallback<SubmitHandler<MobileAppFormValues>>(
    values => {
      const { maintenanceModeDescription } = values;
      const { maintenanceModeEnabled } = values;

      const submitValues: MobileAppFormSubmitValues = {
        maintenanceModeId: maintenanceModeSetting?.mobileAppSettingsId,
        maintenanceModeDescriptionId:
          maintenanceModeDescriptionSetting?.mobileAppSettingsId,
        versionUpgradeMessagesId:
          versionUpgradeMessagesSetting?.mobileAppSettingsId,
        minimumVersionNumberId:
          minimumVersionNumberSetting?.mobileAppSettingsId,
        maintenanceModeDescription,
        maintenanceModeEnabled,
        appVersions: values?.appVersions,
        minimumVersionNumber: values?.minimumVersionNumber
      };

      onSubmit(submitValues);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmit]
  );

  const addVersion = () => {
    const versions = form.getValues("appVersions");
    const newVersions = [...versions, { Message: "", Version: "" }];
    form.setValue("appVersions", newVersions);
  };

  const removeVersion = (index: number) => {
    const versions = form.getValues("appVersions");
    versions.splice(index, 1);
    form.setValue("appVersions", versions);
  };

  const handleVersionChange = (index, fieldName, e) => {
    const versions = [...form.getValues("appVersions")];
    versions[index][fieldName] = (e.target as HTMLInputElement).value;
    form.setValue("appVersions", versions);
  };

  return (
    <FormProvider {...form}>
      <Text variant="xLarge">Mobile App</Text>

      <Stack
        as="form"
        tokens={{ childrenGap: theme.spacing.s1 }}
        onSubmit={form.handleSubmit(_onSubmit)}
      >
        <FormTextField
          label="Maintenance Mode Description"
          name="maintenanceModeDescription"
          multiline
          disabled={form.formState.isSubmitting || isLoading}
          rules={{
            maxLength: {
              value: 250,
              message: "Maximum length is 250 characters"
            }
          }}
        />

        <FormSwitch
          name="maintenanceModeEnabled"
          label="Maintenance Mode Enabled"
        />

        <Stack
          horizontalAlign="start"
          styles={{ root: { marginTop: theme.spacing.l2 } }}
        >
          <Text variant="xLarge">Force Upgrade</Text>
        </Stack>

        <FormTextField
          label="Minimum Version Number"
          name="minimumVersionNumber"
          rules={{
            pattern: {
              value: DECIMAL_NUMBER_REGEX,
              message: "Only decimal numbers up to 5 decimal places are allowed"
            }
          }}
          disabled={form.formState.isSubmitting || isLoading}
          styles={{
            root: {
              width: "20%",
              paddingBottom: theme.spacing.s1,
              fontWeight: 700
            }
          }}
        />

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="start"
        >
          <Text
            styles={{
              root: {
                width: "20%",
                paddingLeft: theme.spacing.s1,
                fontWeight: 700
              }
            }}
          >
            Version
          </Text>
          <Text
            styles={{
              root: {
                width: "70%",
                paddingLeft: theme.spacing.s1,
                fontWeight: 700
              }
            }}
          >
            Message
          </Text>
        </Stack>

        {form.watch("appVersions").length > 0 ? (
          form.watch("appVersions").map((version, index) => (
            <Stack
              key={version.Version}
              horizontal
              tokens={{ childrenGap: theme.spacing.s1 }}
            >
              <FormTextField
                name={`appVersions[${index}].Version`}
                onChange={e => handleVersionChange(index, "Version", e)}
                rules={{
                  required: {
                    message: "Version is required",
                    value: true
                  },
                  pattern: {
                    value: DECIMAL_NUMBER_REGEX,
                    message:
                      "Only decimal numbers up to 5 decimal places are allowed"
                  }
                }}
                disabled={form.formState.isSubmitting || isLoading}
                styles={{ root: { width: "20%" } }}
              />
              <FormTextField
                name={`appVersions[${index}].Message`}
                onChange={e => handleVersionChange(index, "Message", e)}
                rules={{
                  required: {
                    message: "Message is required",
                    value: true
                  },
                  maxLength: {
                    value: 150,
                    message: "Maximum length is 150 characters"
                  }
                }}
                disabled={form.formState.isSubmitting || isLoading}
                styles={{ root: { width: "70%" } }}
              />
              <DangerButton
                styles={{ root: { width: "10%" } }}
                onClick={() => removeVersion(index)}
              >
                Delete
              </DangerButton>
            </Stack>
          ))
        ) : (
          <Stack horizontalAlign="center">
            <Text>
              No app versions available. Click 'Add Version' to add a new entry.
            </Text>
          </Stack>
        )}

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          styles={{ root: { marginBottom: theme.spacing.l2 } }}
        >
          <DefaultButton onClick={addVersion}>Add Version</DefaultButton>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <PrimaryButton
            type="submit"
            disabled={form.formState.isSubmitting || isLoading}
          >
            Submit
          </PrimaryButton>
        </Stack>

        {isLoading && <Spinner />}
      </Stack>
    </FormProvider>
  );
};

export const MobileAppForm = withPermissions(
  MobileAppFormBase,
  [Permissions.EnvSecurityRead, Permissions.EnvSecurityWrite],
  "or"
);
