import React from "react";

import {
  DefaultButton,
  DetailsRow,
  IColumn,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DangerButton } from "@components/buttons/DangerButton";
import { SectionTitle } from "@components/SectionTitle";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  SoftwarePackage,
  SoftwarePackageDefault,
  SoftwarePackageType
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  usePurgeFieldmanCDN,
  useRegenerateDefaultFieldConfig,
  useSoftwarePackageDefaults,
  useSoftwarePackages
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { SoftwarePublishers } from "@libs/api/gateways/field/SoftwarePublisher";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SoftwarePackageDefaultCurrentVersion } from "../common/SoftwarePackageDefaultCurrentVersion";
import { SoftwarePackageDefaultHost } from "./SoftwarePackageDefaultHost";

export interface SoftwarePackageDefaultSummaryDto
  extends SoftwarePackageDefault {
  name: string;
  code: string;
}

const validSoftwarePackageTypes = [
  SoftwarePackageType.Service,
  SoftwarePackageType.Application,
  SoftwarePackageType.File
];

const REGENERATE_TOOLTIP =
  "Recreates the default stored field-configuration.json file in blob storage if it is corrupted or not present. Also initiates a purge on the CDN.";

const PURGE_TOOLTIP =
  "Manually purges the CDN endpoint which is used to distrubute the field configuration. It may take up to 10 minutes to complete the purge request.";

const FieldConfigScreenBase: React.FC = () => {
  const theme = useTheme();
  const regenerateDefaultFieldConfig = useRegenerateDefaultFieldConfig();
  const purgeFieldmanCDN = usePurgeFieldmanCDN();

  const softwarePackageDefaultsQuery = useSoftwarePackageDefaults();
  const {
    data: softwarePackageDefaults,
    isLoading: softwarePackageDefaultsIsLoading,
    error: softwarePackageDefaultsError
  } = softwarePackageDefaultsQuery;

  const softwarePackagesQuery = useSoftwarePackages({
    softwarePublisherCode: SoftwarePublishers.FieldManagement
  });

  const {
    data: softwarePackages,
    isLoading: softwarePackagesIsLoading,
    error: softwarePackagesError
  } = softwarePackagesQuery;

  const error = softwarePackageDefaultsError ?? softwarePackagesError;

  const isLoading =
    softwarePackageDefaultsIsLoading ?? softwarePackagesIsLoading;

  const items = getSummaryData(softwarePackageDefaults!, softwarePackages!);

  const columns: IColumn[] = [
    {
      name: "Software Package",
      key: "name",
      minWidth: 150,
      maxWidth: 450,
      onRender: (item: SoftwarePackageDefaultSummaryDto) => item.name
    },
    {
      name: "Current Version",
      key: "version",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: SoftwarePackageDefaultSummaryDto) => (
        <SoftwarePackageDefaultCurrentVersion softwarePackage={item} />
      )
    },

    {
      name: "New Version",
      key: "form",
      minWidth: 400,
      onRender: (item: SoftwarePackageDefaultSummaryDto) => (
        <SoftwarePackageDefaultHost softwarePackage={item} />
      )
    }
  ];

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <Stack tokens={{ childrenGap: theme.spacing.m }}>
      <SectionTitle>Default Field Config</SectionTitle>
      <ShimmeredDetailsList
        enableShimmer={isLoading}
        errorMessage={error?.message}
        columns={columns}
        items={items ?? []}
        onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
      />
      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.s1 }}
        horizontalAlign="end"
      >
        <TooltipHost content={REGENERATE_TOOLTIP}>
          <DefaultButton
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            onClick={regenerateDefaultFieldConfig}
          >
            Regenerate Default Config
          </DefaultButton>
        </TooltipHost>
        <TooltipHost content={PURGE_TOOLTIP}>
          <DangerButton
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            onClick={purgeFieldmanCDN}
          >
            Purge CDN
          </DangerButton>
        </TooltipHost>
      </Stack>
    </Stack>
  );
};

function getSummaryData(
  softwarePackageDefaults: SoftwarePackageDefault[],
  softwarePackages: SoftwarePackage[]
) {
  if (!softwarePackages) return undefined;

  const results = softwarePackages
    .filter(softwarePackage =>
      validSoftwarePackageTypes.includes(softwarePackage.softwarePackageType)
    )
    .map(softwarePackage => {
      const softwarePackageDefault = softwarePackageDefaults?.find(
        x => x.softwarePackageId === softwarePackage?.id
      );

      return {
        ...softwarePackageDefault,
        softwarePackageId: softwarePackage?.id,
        code: softwarePackage?.code,
        name: softwarePackage?.displayName
      } as SoftwarePackageDefaultSummaryDto;
    });

  return results;
}

export const FieldConfigScreen = withPermissions(
  FieldConfigScreenBase,
  [Permissions.PltFieldManagementRead, Permissions.PltFieldManagementWrite],
  "or"
);
