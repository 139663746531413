import { Tabs } from "@components/tabs-nav/TabNav.types";

export const FieldDeviceTabs: Tabs = {
  Device: {
    Overview: { id: "fd-overview", name: "Overview" },
    Identifiers: { id: "fd-identifiers", name: "Identifiers" },
    Properties: { id: "fd-properties", name: "Properties" },
    Security: { id: "fd-security", name: "Security" },
    Configuration: { id: "fd-configuration", name: "Configuration" },
    DeploymentRings: { id: "fd-deployment-rings", name: "Deployment Rings" },
    Logs: { id: "fd-logs", name: "Logs" }
  },
  Services: {
    Service: { id: "fd-services", name: "Service" }
  },
  Modules: {
    Module: { id: "fd-modules", name: "Module" }
  }
};
