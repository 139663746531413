import { ReactNode } from "react";

import { Heading, Stack, Text } from "@bps/fluent-ui";

interface ActionPropertyProps {
  propertyValue: ReactNode;
  propertyName: string;
}

export const ActionProperty: React.FC<ActionPropertyProps> = ({
  propertyValue,
  propertyName
}) => {
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      styles={{ root: { justifyContent: "space-between" } }}
    >
      <Heading>{propertyName}</Heading>
      <Text variant="medium">{propertyValue}</Text>
    </Stack>
  );
};
