import React, { FunctionComponent } from "react";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import {
  useArchiveComponent,
  useComponents,
  useDeInitializeComponent,
  useDisableComponent,
  useInitializeComponent,
  useReConfigureComponent
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  ComponentLiteDto,
  GetComponentsArgs
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  confirm,
  IColumn,
  IconButton,
  ScrollablePane,
  Stack
} from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { ExpandableRow } from "@components/tables/ExpandableRow";
import { useExpandedRows } from "@components/tables/useExpandedRows";
import { ComponentDetailsTabs } from "./component-details/ComponentDetailsTabs";
import { ComponentText } from "./ComponentText";
import { ComponentDateText } from "../../_shared-blocks";

interface ComponentsListProps extends Pick<GetComponentsArgs, "code"> {
  tenantId: string;
}

enum ComponentActions {
  Initialize = "initialize",
  DeInitialize = "de-initialize",
  Disable = "disable",
  ReConfigure = "re-configure",
  Archive = "archive"
}

export const ComponentsList: FunctionComponent<ComponentsListProps> = ({
  code,
  tenantId
}) => {
  const { expandedIds, toggleExpandedIds } = useExpandedRows();

  const { data: hasWritePermission } = usePermissionsCheckQuery([
    Permissions.PltComponentsWrite
  ]);

  const { isLoading, error, data = [] } = useComponents({ tenantId, code });
  const {
    mutateAsync: initComponent,
    isLoading: initIsLoading
  } = useInitializeComponent();

  const {
    mutateAsync: deInitComponent,
    isLoading: deInitIsLoading
  } = useDeInitializeComponent();

  const {
    mutateAsync: archiveComponent,
    isLoading: archiveIsLoading
  } = useArchiveComponent();

  const {
    mutateAsync: reConfigComponent,
    isLoading: reConfigIsLoading
  } = useReConfigureComponent();

  const {
    mutateAsync: disableComponent,
    isLoading: disableIsLoading
  } = useDisableComponent();

  const confirmAction = (action: ComponentActions, code: string) =>
    confirm({
      dialogContentProps: {
        title: `${action} component`,
        subText: `Are you sure you want to ${action} ${code}?`,
        styles: { title: { "&:first-letter": { textTransform: "uppercase" } } }
      }
    });

  const actionIsInLoading =
    initIsLoading ||
    disableIsLoading ||
    reConfigIsLoading ||
    deInitIsLoading ||
    archiveIsLoading;

  const columns: IColumn[] = [
    {
      key: "actions",
      name: "",
      minWidth: 32,
      maxWidth: 32,
      onRender: (item: ComponentLiteDto) => (
        <IconButton
          disabled={!hasWritePermission || actionIsInLoading}
          styles={{ root: { justifySelf: "end" } }}
          menuIconProps={{ iconName: "more" }}
          menuProps={{
            items: [
              {
                key: "init",
                text: "Initialize",
                onClick: () => {
                  confirmAction(ComponentActions.Initialize, item.code).then(
                    isConfirmed => {
                      if (isConfirmed) {
                        initComponent({
                          tenantId: item.tenantId,
                          code: item.code,
                          scopeId: item.scopeId
                        });
                      }
                    }
                  );
                }
              },
              {
                key: "re-init",
                text: "DeInitialize",
                onClick: () => {
                  confirmAction(ComponentActions.DeInitialize, item.code).then(
                    isConfirmed => {
                      if (isConfirmed) {
                        deInitComponent({
                          tenantId: item.tenantId,
                          code: item.code,
                          scopeId: item.scopeId
                        });
                      }
                    }
                  );
                }
              },
              {
                key: "re-config",
                text: "ReConfigure",
                onClick: () => {
                  confirmAction(ComponentActions.ReConfigure, item.code).then(
                    isConfirmed => {
                      if (isConfirmed) {
                        reConfigComponent({
                          tenantId: item.tenantId,
                          code: item.code,
                          scopeId: item.scopeId
                        });
                      }
                    }
                  );
                }
              },
              {
                key: "arch",
                text: "Archive",
                onClick: () => {
                  confirmAction(ComponentActions.Archive, item.code).then(
                    isConfirmed => {
                      if (isConfirmed) {
                        archiveComponent({
                          tenantId: item.tenantId,
                          code: item.code,
                          scopeId: item.scopeId
                        });
                      }
                    }
                  );
                }
              },
              {
                key: "dis",
                text: "Disable",
                onClick: () => {
                  confirmAction(ComponentActions.Disable, item.code).then(
                    isConfirmed => {
                      if (isConfirmed) {
                        disableComponent({
                          tenantId: item.tenantId,
                          code: item.code,
                          scopeId: item.scopeId
                        });
                      }
                    }
                  );
                }
              }
            ]
          }}
        />
      )
    },
    {
      key: "id",
      name: "ID",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentLiteDto) => (
        <CopyableGuid value={item.id} short />
      )
    },
    {
      key: "code",
      name: "Code",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentLiteDto) => <ComponentText code={item.code} />
    },
    {
      key: "scope-id",
      name: "Scope ID",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentLiteDto) => (
        <CopyableGuid value={item.scopeId} short />
      )
    },
    {
      key: "status",
      name: "Status",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentLiteDto) => item.status
    },
    {
      key: "data",
      name: "Date",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: ComponentLiteDto) => (
        <ComponentDateText changeLog={item.changeLog} />
      )
    }
  ];
  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <ShimmeredDetailsList
          enableShimmer={isLoading}
          errorMessage={error?.message}
          items={data}
          columns={columns}
          stickyHeader
          onRenderRow={(props, renderDefault) => {
            if (props && renderDefault) {
              return (
                <ExpandableRow
                  expandedIds={expandedIds}
                  toggleExpandedIds={toggleExpandedIds}
                  id={props.item.id}
                  ExpandableComponent={
                    <ComponentDetailsTabs
                      tenantId={tenantId}
                      id={props.item.id}
                      scopeId={props.item.scopeId}
                    />
                  }
                  rowProps={props}
                />
              );
            }
            return null;
          }}
        />
      </ScrollablePane>
    </Stack>
  );
};
