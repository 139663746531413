import {
  ComponentDto,
  ComponentSettingLiteDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent, useState } from "react";
import {
  DefaultButton,
  DetailsList,
  IColumn,
  IconButton,
  NoDataTile,
  Stack
} from "@bps/fluent-ui";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { CreateSettingDialog } from "../create-setting-dialog/CreateSettingDialog";

interface ComponentSettingsProps {
  component: ComponentDto;
}

const CREATE_SETTING_TEXT = "Create setting";
export const ComponentSettings: FunctionComponent<ComponentSettingsProps> = ({
  component
}) => {
  const { data: hasWritePermission } = usePermissionsCheckQuery([
    Permissions.PltComponentsWrite
  ]);

  const [showEditActionDialogValues, setShowEditActionDialogValues] = useState<
    Partial<ComponentSettingLiteDto> | undefined
  >(undefined);

  const columns: IColumn[] = [
    {
      key: "action",
      name: "",
      minWidth: 32,
      maxWidth: 32,
      onRender: (item: ComponentSettingLiteDto) => (
        <IconButton
          disabled={!hasWritePermission}
          iconProps={{ iconName: "Edit" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() =>
            setShowEditActionDialogValues({
              key: item.key,
              value: item.value,
              desiredValue: item.desiredValue
            })
          }
        />
      )
    },
    {
      key: "key",
      name: "Key",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: ComponentSettingLiteDto) => item.key
    },
    {
      key: "value",
      name: "Value",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentSettingLiteDto) => item.value
    },
    {
      key: "desired-value",
      name: "Desired value",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentSettingLiteDto) => item.desiredValue
    }
  ];
  return (
    <Stack>
      {!component.settings?.length ? (
        <NoDataTile
          textProps={{ text: "No settings" }}
          linkProps={{
            text: CREATE_SETTING_TEXT,
            onClick: () => setShowEditActionDialogValues({}),
            hidden: !hasWritePermission
          }}
          showBoxShadow={false}
        />
      ) : (
        <Stack>
          {hasWritePermission && (
            <Stack.Item>
              <DefaultButton onClick={() => setShowEditActionDialogValues({})}>
                {CREATE_SETTING_TEXT}
              </DefaultButton>
            </Stack.Item>
          )}
          <Stack.Item styles={{ root: { width: "50%" } }}>
            <DetailsList items={component.settings} columns={columns} compact />
          </Stack.Item>
        </Stack>
      )}
      {!!showEditActionDialogValues && (
        <CreateSettingDialog
          tenantId={component.tenantId}
          code={component.code}
          scopeId={component.scopeId}
          initialValues={showEditActionDialogValues}
          onDismiss={() => setShowEditActionDialogValues(undefined)}
        />
      )}
    </Stack>
  );
};
