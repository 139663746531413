import { ProductsFilterBarValues } from "./ProductsFilterBar";
import { SalesProductDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const getSortedFilteredProducts = (
  products: SalesProductDto[],
  filter: ProductsFilterBarValues
) => {
  return Array.from(products)
    .sort((a, b) => {
      if (a.isInactive === b.isInactive) return 0;
      return a.isInactive ? -1 : 1;
    })
    .filter(p => {
      const matchProductName = filter.name
        ? p.name?.toLowerCase().includes(filter.name.toLowerCase())
        : true;

      const matchProductCode = filter.code
        ? p.productCode?.toLowerCase().includes(filter.code.toLowerCase())
        : true;

      const matchApplicationCode = filter.applicationCodes.length
        ? filter.applicationCodes.includes(p.applicationCode ?? "")
        : true;

      const showInactive = !(!filter.showInactive && p.isInactive);
      const matchCountry = !filter.country
        ? true
        : filter.country === p.country;

      return (
        matchProductName &&
        matchProductCode &&
        matchApplicationCode &&
        showInactive &&
        matchCountry
      );
    });
};
