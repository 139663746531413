import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";

export interface CreateEmailRequestFormValues {
  tenantId: string;
  to: string;
  subject: string;
  message: string;
}

interface ICommsEmailTestingSendDialogProps extends IDialogProps {
  onSubmit: (values: CreateEmailRequestFormValues) => Promise<void>;
  onDismiss: () => void;
  error: HttpError | null;
}

export const CreateEmailRequestDialog: React.FC<ICommsEmailTestingSendDialogProps> = ({
  onSubmit,
  onDismiss,
  error,
  ...props
}) => {
  const handleSubmit = async (values: CreateEmailRequestFormValues) => {
    await onSubmit(values);
  };

  return (
    <Dialog
      {...props}
      onDismiss={onDismiss}
      dialogContentProps={{
        showCloseButton: true,
        title: "Send Email"
      }}
    >
      <Form<CreateEmailRequestFormValues>
        defaultValues={{
          tenantId: "",
          to: "",
          subject: "Ops console testing message",
          message: "This email is an ops console testing message."
        }}
        submitButtonText="Send"
        allowSubmitOnClean={true}
        showCancelButton={false}
        onSubmit={handleSubmit}
        onCancel={() => onDismiss()}
        error={error}
      >
        <FormTextField name="tenantId" label="Tenant ID" required />
        <FormTextField name="to" label="To" required />
        <FormTextField name="subject" label="Subject" required />
        <FormTextField
          name="message"
          label="Message"
          multiline
          rows={3}
          required
        />
      </Form>
    </Dialog>
  );
};
