import { Form } from "@components/form/Form";
import { FormDropdown } from "@components/form/fields/FormDropdown";

import { OrgUnitDto, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent } from "react";

import { Dialog, Option } from "@bps/fluent-ui";
import { ConsentDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { usePatchConsentLocation } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";

export interface AddLocationDialogProps {
  item: ConsentDto;
  tenant: Tenant;
  orgUnits: OrgUnitDto[];
  onDismiss: () => void;
}

export interface AddLocationFormValues {
  locationId: string[];
  tenantId: string;
  id: string;
}

export const AddLocationDialog: FunctionComponent<AddLocationDialogProps> = ({
  item,
  tenant,
  orgUnits,
  onDismiss
}) => {
  const {
    mutateAsync: patchConsentLocation,
    error: patchConsentLocationError
  } = usePatchConsentLocation();

  const onSubmit = async (formData: AddLocationFormValues) => {
    await patchConsentLocation({
      locations: formData.locationId,
      tenantId: tenant.id,
      id: item.id
    });
    onDismiss();
  };

  const defaultValues = {
    locationId: [],
    tenantId: "",
    id: ""
  };

  const orgUnitsOption: Option[] = !orgUnits
    ? []
    : [
        {
          key: "",
          text: "None"
        }
      ].concat(
        orgUnits.map(t => {
          return { key: t.id, text: t.name };
        }) ?? []
      );

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Add Location",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<AddLocationFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={patchConsentLocationError}
      >
        <FormDropdown
          name="locationId"
          placeholder="Select locations id"
          label="Location OrgUnit Id"
          options={orgUnitsOption}
          withNoEmptyOption={true}
          multiSelect={true}
        />
      </Form>
    </Dialog>
  );
};
