import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";

import { IContextualMenuProps } from "@bps/fluent-ui";
import { useModifyDatabaseMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { ProsDatabaseCommands } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

export const ModifyDatabaseCommandButton = (props: { siteId: string }) => {
  const { siteId } = props;

  const commandRunner = useCommandRunner();
  const modifyDatabaseMutation = useModifyDatabaseMutation();

  const handleRunCommand = (
    commandName: string,
    command: ProsDatabaseCommands
  ) => () =>
    commandRunner.runMutation(commandName, modifyDatabaseMutation, {
      siteId,
      command
    });

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: ProsDatabaseCommands.InstallUpdate,
        text: "Install/Update",
        onClick: handleRunCommand(
          "Install/Update PrOS database",
          ProsDatabaseCommands.InstallUpdate
        )
      },
      {
        key: ProsDatabaseCommands.Enable,
        text: "Enable",
        onClick: handleRunCommand(
          "Enable PrOS database",
          ProsDatabaseCommands.Enable
        )
      },
      {
        key: ProsDatabaseCommands.Uninstall,
        text: "Uninstall",
        onClick: handleRunCommand(
          "Uninstall PrOS database",
          ProsDatabaseCommands.Uninstall
        )
      },
      {
        key: ProsDatabaseCommands.EnableChangeTracking,
        text: "Enable Change Tracking",
        onClick: handleRunCommand(
          "Enable Change Tracking",
          ProsDatabaseCommands.EnableChangeTracking
        )
      },
      {
        key: ProsDatabaseCommands.DisableChangeTracking,
        text: "Disable Change Tracking",
        onClick: handleRunCommand(
          "Disable Change Tracking",
          ProsDatabaseCommands.DisableChangeTracking
        )
      }
    ]
  };

  return (
    <SiteCommandButton
      commandName="Modify PrOS database"
      menuProps={menuProps}
      iconName="DataManagementSettings"
    />
  );
};
