import { Form } from "@components/form/Form";
import { FormDropdown } from "@components/form/fields/FormDropdown";

import { Dialog, IDialogProps, Option, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { BpIdUserDto, UserGroupDto } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useGetAllGroups } from "@libs/api/gateways/bp-id/bp-id.hooks";

interface Props extends IDialogProps {
  user: BpIdUserDto | undefined;
  userGroups: UserGroupDto[] | undefined;
  onUpdate: (
    userId: string,
    addGroupIds: string[],
    removeGroupIds: string[]
  ) => void;
}

interface AddGroupValues {
  userGroups: string[];
}

export const UserGroupDialog = ({
  user: currentUser,
  userGroups: currentGroups,
  onUpdate,
  onDismiss,
  ...modalProps
}: Props) => {
  const theme = useTheme();

  const { isLoading, error, data } = useGetAllGroups();

  const allGroups: UserGroupDto[] = data?.groups || [];
  const originalGroups = currentGroups?.map(x => x.id) ?? [];

  const onSubmit = async (values: AddGroupValues) => {
    const addGroups = values.userGroups.filter(
      g => originalGroups.indexOf(g) < 0
    );

    const removeGroups = originalGroups.filter(
      g => values.userGroups.indexOf(g) < 0
    );

    onUpdate(currentUser?.id ?? "", addGroups, removeGroups);
    onDismiss?.();
  };

  const defaultValues: AddGroupValues = {
    userGroups: currentGroups?.map(x => x.displayName) || []
  };

  const groupOptions: Option[] = allGroups.map(g => ({
    key: g.id,
    text: g.displayName,
    selected: currentGroups?.some(x => x.id === g.id)
  }));

  return (
    <Dialog
      styles={{
        main: {
          borderTop: `4px solid ${theme.palette.themePrimary}`
        }
      }}
      dialogContentProps={{
        title: "Add Group To User",
        showCloseButton: true,
        onDismiss
      }}
      {...modalProps}
    >
      <QueryStateIndicator<UserGroupDto[]>
        {...useGetAllGroups}
        isLoading={isLoading}
        error={error}
        data={allGroups}
      >
        <Form<AddGroupValues> defaultValues={defaultValues} onSubmit={onSubmit}>
          <FormDropdown
            name="userGroups"
            placeholder="Select Group"
            label="Groups"
            multiSelect
            required
            options={groupOptions}
          />
        </Form>
      </QueryStateIndicator>
    </Dialog>
  );
};
