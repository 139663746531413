import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import React from "react";
import { Controller, ControllerProps, FieldError } from "react-hook-form";

import { DirectionalHint, TooltipDelay, TooltipHost } from "@bps/fluent-ui";

interface IdentityFilterFieldProps
  extends ControllerProps<BpIdUsersSearchArgs> {
  isSelected?: boolean;
  tooltip: (error?: FieldError) => React.ReactElement;
}

export const IdentityFilterField: React.FC<IdentityFilterFieldProps> = ({
  control,
  isSelected,
  name,
  render,
  tooltip,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={renderProps => (
        <TooltipHost
          delay={TooltipDelay.zero}
          content={tooltip(renderProps.fieldState.error)}
          directionalHint={DirectionalHint.bottomCenter}
        >
          {render(renderProps)}
        </TooltipHost>
      )}
      {...props}
    />
  );
};
