import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

import {
  DetailsList,
  IColumn,
  IconButton,
  NoDataTile,
  Text,
  TooltipHost
} from "@bps/fluent-ui";

import { IpRangeTableProps } from "../users-auth/components/TenantUsersAuthForm.types";
import { IpRanges } from "@libs/api/gateways/plt/plt-gateway.dtos";

const ADD_RANGE_TEXT = "Add Ip Range";
export const IpRangeTable: FunctionComponent<IpRangeTableProps> = ({
  setShowAddIpRangeDialog,
  hasWritePermission
}) => {
  const { watch, setValue } = useFormContext();
  const [ipRanges] = watch(["ipRanges"]);

  const ipRangeTableColumns: IColumn[] = [
    {
      key: "ipRangeBegin",
      name: "IpRange Begin",
      minWidth: 120,
      maxWidth: 120,
      onRender: (ipRange: IpRanges) => <Text>{ipRange.ipRangeBegin}</Text>
    },
    {
      key: "ipRangeEnd",
      name: "IpRange End",
      minWidth: 120,
      maxWidth: 120,
      onRender: (ipRange: IpRanges) => <Text>{ipRange.ipRangeEnd}</Text>
    },
    {
      key: "edit",
      name: "",
      minWidth: 32,
      maxWidth: 32,
      onRender: (ipRange: IpRanges) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => {
            setShowAddIpRangeDialog(ipRange);
          }}
        />
      )
    },
    {
      key: "delete",
      name: "",
      minWidth: 32,
      maxWidth: 32,
      onRenderHeader: () =>
        hasWritePermission ? (
          <TooltipHost content={ADD_RANGE_TEXT}>
            <IconButton
              iconProps={{ iconName: "Add" }}
              styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
              onClick={() => setShowAddIpRangeDialog(null)}
            />
          </TooltipHost>
        ) : null,
      onRender: (ipRange: IpRanges) => (
        <IconButton
          iconProps={{ iconName: "Delete" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => {
            const delIndex = ipRanges.findIndex(
              x =>
                x.ipRangeBegin === ipRange.ipRangeBegin &&
                x.ipRangeEnd === ipRange.ipRangeEnd
            );

            const newIpRanges = Array.from(ipRanges);
            newIpRanges.splice(delIndex, 1);

            setValue("ipRanges", newIpRanges, {
              shouldDirty: true,
              shouldValidate: true
            });
          }}
        />
      )
    }
  ];
  return !ipRanges?.length ? (
    <NoDataTile
      showBoxShadow={false}
      styles={{ root: { width: "100%" } }}
      textProps={{ text: "There are no IP Ranges configured" }}
      linkProps={{
        onClick: () => setShowAddIpRangeDialog(null),
        text: ADD_RANGE_TEXT,
        hidden: !hasWritePermission
      }}
    />
  ) : (
    <DetailsList items={ipRanges} columns={ipRangeTableColumns} />
  );
};
