import React, { useEffect, useState } from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { DurationText } from "@components/DurationText";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { TimeText } from "@components/TimeText";
import { withPermissions } from "@components/withPermissions";
import { useGateways } from "@libs/api/gateways-context";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantsPltQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { SiteSyncState } from "@libs/api/gateways/sia/models/SiteSyncState";
import { SequenceNumberSyncState } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { guid } from "@libs/common/guid";
import { getPagesResult } from "@libs/paging/paging.utils";
import { Permissions } from "@libs/permissions/permissions.enum";

import { ReplicationQueueProcessingStateBadge } from "../../../../../_shared-blocks/ReplicationQueueProcessingStateBadge";
import { getSequenceNumberSyncStates } from "../../../../../tenants/sections/pros/data-sync/replication-queue-processing-state/sequenc-number-sync-states.utils";

interface TenantSyncState extends SequenceNumberSyncState {
  tenantId: string;
  name: string;
  outOfSync?: boolean;
}

const ProsTenantSyncStateBase: React.FC = () => {
  const { tgOpsGateway } = useGateways();
  const tenantsQuery = useTenantsPltQuery({
    isInactive: false,
    applications: [ApplicationType.PROS]
  });

  const [siteSyncDataFetched, setSiteSyncDataFetched] = useState(false);
  const [tenantStates, setTenantStates] = useState<Map<guid, TenantSyncState>>(
    new Map<guid, TenantSyncState>()
  );

  // Map the tenants out into a new Map object
  useEffect(() => {
    if (!tenantsQuery.data) return;

    const mappedTenants = new Map(
      getPagesResult(tenantsQuery.data).map(tenant => {
        const { id: tenantId, name } = tenant;

        return [tenantId, { tenantId, name } as TenantSyncState];
      })
    );

    setTenantStates(mappedTenants);
  }, [tenantsQuery]);

  // Call getReplicationSyncState() for each tenant and populate map object as items return
  useEffect(() => {
    if (siteSyncDataFetched) return;
    if (tenantStates.size === 0) return;

    const tenantStatePromises = Array.from(tenantStates).map(async tenant => {
      const tenantId = tenant[0];
      const tenantObject = tenant[1];
      try {
        const stateDto = await tgOpsGateway.getReplicationSyncState(tenantId);
        const state = new SiteSyncState(stateDto);
        const summaryState = getSequenceNumberSyncStates(state);

        tenantStates.set(tenantId, {
          tenantId,
          name: tenantObject.name,
          outOfSync: state.outOfSync,
          ...summaryState[0]
        });

        setTenantStates(new Map(tenantStates));
      } catch {
        tenantStates.delete(tenantId);
        setTenantStates(tenantStates);
      }
    });

    setSiteSyncDataFetched(true);
    Promise.all(tenantStatePromises);
  }, [
    tenantStates,
    setTenantStates,
    tgOpsGateway,
    siteSyncDataFetched,
    setSiteSyncDataFetched
  ]);

  if (!tenantStates) return null;

  const tenantData = Array.from(tenantStates.values());

  const columns: IColumn[] = [
    {
      key: "tenantId",
      fieldName: "tenantId",
      name: "Tenant ID",
      minWidth: 310,
      maxWidth: 310,
      onRender: (state: TenantSyncState) => (
        <CopyableGuid
          styles={{ root: { width: "280px" } }}
          value={state.tenantId}
        />
      )
    },
    {
      key: "name",
      fieldName: "name",
      name: "Name",
      minWidth: 80
    },
    {
      key: "syncState",
      fieldName: "syncState",
      name: "Sync State",
      minWidth: 80,
      onRender: (item: TenantSyncState) => (
        <ReplicationQueueProcessingStateBadge outOfSync={item.outOfSync} />
      )
    },
    {
      key: "sequenceNumber",
      fieldName: "sequenceNumber",
      name: "Sequence #",
      minWidth: 80
    },
    {
      key: "status",
      fieldName: "status",
      name: "Status",
      minWidth: 80,
      onRender: (item: TenantSyncState) => {
        let badgeColor = TextBadgeColor.red;
        if (item.status === "Processed") {
          badgeColor = TextBadgeColor.green;
        }
        if (item.status === "Dequeued") {
          badgeColor = TextBadgeColor.yellow;
        }

        return (
          <TextBadge
            badgeColor={badgeColor}
            badgeSize={TextBadgeSize.small}
            styles={{ root: { width: "fit-content" } }}
          />
        );
      }
    },
    {
      key: "sentAt",
      fieldName: "sentAt",
      name: "Queued At",
      minWidth: 150,
      onRender: (item: TenantSyncState) => <TimeText time={item.sentAt} />
    },
    {
      key: "receiveDelayMillis",
      fieldName: "receiveDelayMillis",
      name: "Time enqueued",
      minWidth: 120,
      onRender: (item: TenantSyncState) => (
        <DurationText duration={item.receiveDelayMillis} />
      ),
      onRenderHeader: () => (
        <TooltipHost
          id="receive-delay-tooltip-id"
          content="How long the message spent waiting to be dequeued"
        >
          Time enqueued
        </TooltipHost>
      )
    },
    {
      key: "receivedAt",
      fieldName: "receivedAt",
      name: "Dequeued At",
      minWidth: 150,
      onRender: (item: TenantSyncState) => <TimeText time={item.receivedAt} />
    },
    {
      key: "processingDelayMillis",
      fieldName: "processingDelayMillis",
      name: "Time processing",
      minWidth: 120,
      onRender: (item: TenantSyncState) => (
        <DurationText duration={item.processingDelayMillis} />
      ),
      onRenderHeader: () => (
        <TooltipHost
          id="processing-delay-tooltip-id"
          content="The time spent processing the message after it had been dequeued"
        >
          Time processing
        </TooltipHost>
      )
    },
    {
      key: "processedAt",
      fieldName: "processedAt",
      name: "Processed At",
      minWidth: 150,
      onRender: (item: TenantSyncState) => <TimeText time={item.processedAt} />
    }
  ];

  return (
    <QueryStateIndicator {...tenantsQuery}>
      {() => {
        return (
          <Stack
            verticalFill
            styles={(_prop, theme) => ({ root: { padding: theme.spacing.s1 } })}
          >
            <SectionTitle>Tenant Sync State</SectionTitle>

            <DetailsList
              items={tenantData}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              styles={{
                contentWrapper: {
                  "[role=gridcell]": {
                    display: "flex",
                    alignItems: "center"
                  }
                }
              }}
            />
          </Stack>
        );
      }}
    </QueryStateIndicator>
  );
};

export const ProsTenantSyncState = withPermissions(
  ProsTenantSyncStateBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
