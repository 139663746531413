import React, { useState } from "react";

import { DefaultButton, Stack, useTheme } from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { SelectorArgs } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSelectors } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { NewSelectorDialog } from "./dialogs/NewSelectorDialog";
import { Selectors } from "./Selectors";
import { SelectorsFilter } from "./SelectorsTableFilter";

const SelectorsScreenBase: React.FC = () => {
  const theme = useTheme();
  const [filtersArgs, setFilterArgs] = useState<SelectorArgs>();
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [formError, setFormError] = useState<HttpError | null>(null);

  const {
    data: SelectorData,
    isLoading: isSelectorDataLoading,
    error: selectorError
  } = useSelectors(filtersArgs);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const onNewSelectorDismiss = () => {
    setShowAdd(false);
    setFormError(null);
  };

  return (
    <Stack verticalFill>
      <Stack
        verticalFill
        styles={{
          root: { padding: theme.spacing.s1, width: "100%" }
        }}
        tokens={{ childrenGap: theme.spacing.m }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <SectionTitle>Selectors</SectionTitle>

          <Stack.Item align="end">
            <DefaultButton
              iconProps={{ iconName: "Add" }}
              onClick={() => setShowAdd(true)}
              disabled={!hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            >
              Create Selector
            </DefaultButton>
          </Stack.Item>
        </Stack>
        <SelectorsFilter setFilterArgs={setFilterArgs} />
        <Selectors
          items={SelectorData ?? []}
          enableShimmer={isSelectorDataLoading}
          errorMessage={selectorError?.message}
          isSelectedOnFocus
        />
      </Stack>

      <NewSelectorDialog
        hidden={!showAdd}
        onDismiss={onNewSelectorDismiss}
        formError={formError}
        setFormError={setFormError}
      />
    </Stack>
  );
};

export const SelectorsScreen = withPermissions(
  SelectorsScreenBase,
  [Permissions.PltFieldManagementRead, Permissions.PltFieldManagementWrite],
  "or"
);
