import { FunctionComponent, useMemo } from "react";
import { ComponentDefDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { IColumn, NoDataTile } from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";

interface ComponentDependenciesProps {
  component: ComponentDefDto;
}
export const ComponentDependencies: FunctionComponent<ComponentDependenciesProps> = ({
  component
}) => {
  const { data = [], isLoading, error } = useComponentsDefs();
  const columns: IColumn[] = [
    {
      key: "code",
      name: "Code",
      minWidth: 100,
      maxWidth: 300,
      onRender: (item: ComponentDefDto) => item.code
    },
    {
      key: "text",
      name: "Name",
      minWidth: 100,
      maxWidth: 400,
      onRender: (item: ComponentDefDto) => item.text
    },
    {
      key: "scope",
      name: "Scope",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentDefDto) => item.scope
    },
    {
      key: "implementations-allowed",
      name: "Implementations allowed",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentDefDto) => item.implementationsLimit
    },
    {
      key: "source",
      name: "Source",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentDefDto) => item.source
    }
  ];

  const items = useMemo(
    () => component?.dependencies.map(dep => data.find(d => d.code === dep)),
    [component?.dependencies, data]
  );

  if (!items?.length)
    return (
      <NoDataTile
        textProps={{ text: "No dependencies" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
      />
    );
  return (
    <ShimmeredDetailsList
      items={items}
      columns={columns}
      enableShimmer={isLoading}
      errorMessage={error?.message}
      compact
    />
  );
};
