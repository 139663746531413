import { PrimaryButton, Spinner, SpinnerSize } from "@bps/fluent-ui";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FunctionComponent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { useNavigate, useParams } from "react-router-dom";
import { useRootStore } from "@stores/StoresProvider";
import {
  PltCacheKeys,
  useSetupTenantPaymentMethods,
  useTenantPaymentMethodsAsDefault
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { PaymentMethodDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface AddNewCardSubmitButtonProps {
  onDismiss: () => void;
  validationError: string | undefined;
  completed: boolean;
}
export const AddNewCardSubmitButton: FunctionComponent<AddNewCardSubmitButtonProps> = ({
  onDismiss,
  validationError,
  completed
}) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const navigate = useNavigate();
  const { feedback } = useRootStore();

  useEffect(() => {
    if (!tenantId) {
      navigate(-1);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    mutateAsync: setup,
    isLoading: setupIsLoading
  } = useSetupTenantPaymentMethods();

  const {
    mutateAsync: setDefault,
    isLoading: setDefaultIsLoading
  } = useTenantPaymentMethodsAsDefault(tenantId!);

  const stripe = useStripe();
  const elements = useElements();

  const queryClient = useQueryClient();

  const handleSubmit = async (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements || !tenantId) {
      return;
    }

    const res = await setup(tenantId);

    try {
      const result = await stripe.confirmCardSetup(res.clientSecret, {
        // eslint-disable-next-line camelcase
        payment_method: {
          card: elements.getElement(CardElement)!
        }
      });

      if (result.error) {
        result.error.message && feedback.error(result.error.message);
        setIsLoading(false);
      } else {
        const currentMethods =
          queryClient.getQueryData<PaymentMethodDto[]>([
            PltCacheKeys.TenantPaymentMethods,
            tenantId
          ]) ?? [];

        // If there are no existing payment methods, we are setting the new card as default one.
        const newMethodId = result.setupIntent.payment_method;
        if (!currentMethods.length && typeof newMethodId === "string") {
          await setDefault(newMethodId);
        } else {
          await queryClient.invalidateQueries([
            PltCacheKeys.TenantPaymentMethods,
            tenantId
          ]);
        }

        feedback.success("A new method has been added!");
        onDismiss();
      }
    } catch (e) {
      feedback.error((e as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimaryButton
      disabled={!!validationError || !completed}
      onClick={handleSubmit}
      styles={{ root: { marginTop: 16, alignSelf: "end" } }}
      className="cam-UpdateCardSubmitButton"
    >
      {isLoading || setupIsLoading || setDefaultIsLoading ? (
        <Spinner size={SpinnerSize.small} />
      ) : (
        "Save"
      )}
    </PrimaryButton>
  );
};
