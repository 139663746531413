import React, { Fragment, FunctionComponent, useEffect } from "react";
import {
  Heading,
  NoDataTile,
  Separator,
  Stack,
  Text,
  FieldItemError
} from "@bps/fluent-ui";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface ChildTenantsListFieldProps {
  childTenants: Tenant[];
  tenant: Tenant;
}
export const ChildTenantsListField: FunctionComponent<ChildTenantsListFieldProps> = ({
  childTenants,
  tenant
}) => {
  const productId = useWatch({ name: "salesProductId" });

  const { setValue, register } = useFormContext();
  const { errors } = useFormState();
  useEffect(() => {
    register("items", {
      validate: items => {
        if (
          items.length &&
          items.every(i => Number(i.quantity) === 0 || !i.quantity)
        ) {
          return "Add the selected product for at least for one of the child tenants!";
        }
        return undefined;
      }
    });
  }, [register]);

  useEffect(() => {
    // reset items if productId has been changed
    if (productId) {
      setValue(
        "items",
        childTenants.map(c => ({
          childTenantId: c.id,
          quantity: "0"
        }))
      );
    }
  }, [childTenants, productId, setValue]);

  if (!productId) {
    return (
      <NoDataTile
        textProps={{ text: "No product selected!" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
      />
    );
  }

  return (
    <Stack>
      <FieldItemError
        name="items"
        errorMessage={errors?.items?.message as string}
        styles={{ root: { paddingBottom: 16 } }}
      />
      {childTenants.map((c, idx) => (
        <Fragment key={c.id}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack>
              <Heading>{c.name}</Heading>
              <Text variant="small">{c.id}</Text>
              <Text variant="small">{c.application}</Text>
              <Text variant="small">{tenant.crmId}</Text>
            </Stack>
            <FormSpinNumberInput
              name={`items.${idx}.quantity`}
              styles={{ root: { width: 50 } }}
            />
          </Stack>
          <Separator />
        </Fragment>
      ))}
    </Stack>
  );
};
