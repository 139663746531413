import { FormFieldProps } from "@components/form/fields/FormFieldProps";

import { FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  FieldItem,
  FieldItemStyleProps,
  FieldItemStyles,
  IStyleFunctionOrObject,
  Option,
  OptionsSelect,
  OptionsSelectProps,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { useLicenceTypes } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { LicenceTypeDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export type FormLicenceTypesSelectProps = Omit<
  OptionsSelectProps,
  "options" | "selectedKeys" | "onChangeSelectedKeys"
> & {
  fieldItemStyles?: IStyleFunctionOrObject<
    FieldItemStyleProps,
    FieldItemStyles
  >;
} & FormFieldProps;

export const FormLicenceTypesSelect: FunctionComponent<FormLicenceTypesSelectProps> = ({
  name,
  rules,
  fieldItemStyles,
  ...rest
}) => {
  const theme = useTheme();
  const { control } = useFormContext();

  const { isLoading, error, data = [] } = useLicenceTypes();

  const sortedData = Array.from(data).sort((a, b) => {
    if (a.isInactive === b.isInactive) return 0;
    return a.isInactive ? 1 : -1;
  });

  const options: Option<LicenceTypeDto>[] = sortedData.map(data => ({
    key: data?.code ?? "",
    text: data.text ?? "",
    disabled: data.isInactive,
    data
  }));

  const renderInactiveOption = (option: Option<LicenceTypeDto>) => (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
      <Text
        styles={{
          root: {
            color: theme.semanticColors.disabledText
          }
        }}
      >
        {option.text}
      </Text>
      <TextBadge
        badgeSize={TextBadgeSize.small}
        badgeColor={TextBadgeColor.red}
      >
        Inactive
      </TextBadge>
    </Stack>
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error: validationError }
      }) => (
        <FieldItem
          name={name}
          errorMessage={validationError?.message}
          styles={fieldItemStyles}
        >
          <OptionsSelect
            options={options}
            selectedKeys={value}
            onChangeSelectedKeys={onChange}
            errorMessage={error?.message}
            loading={isLoading}
            hideSearchOption
            styles={{ fieldContent: { span: { width: "100%" } } }}
            onRenderOption={option =>
              option.data.isInactive ? (
                renderInactiveOption(option)
              ) : (
                <>{option.text}</>
              )
            }
            {...fieldProps}
            {...rest}
          />
        </FieldItem>
      )}
    />
  );
};
