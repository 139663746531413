import { config } from "@libs/config/config";
import { PracticeLocationConfig } from "modules/tenants/sections/best-health/locations/PracticeLocationConfig";
import { PracticeLocationProvidersTable } from "modules/tenants/sections/best-health/locations/PracticeLocationProvidersTable";
import React from "react";

import { Image, ImageFit, Link, Stack, Text, useTheme } from "@bps/fluent-ui";
import { PracticeLocation } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface PracticeLocationsExpandProps {
  location: PracticeLocation;
}

export const PracticeLocationsExpand: React.FC<PracticeLocationsExpandProps> = ({
  location
}) => {
  const theme = useTheme();

  const url = `${config.bhbBaseURL}/booking/?locationId=${location.id}`;

  return (
    <Stack>
      <Stack
        horizontal
        styles={{
          root: {
            padding: theme.spacing.m
          }
        }}
        tokens={{
          childrenGap: theme.spacing.l2
        }}
      >
        <Stack
          styles={{
            root: {
              flex: 1
            }
          }}
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <Link href={url} target="_blank">
            {url}
          </Link>
          <PracticeLocationConfig location={location} />
        </Stack>
        <Image
          height={100}
          width={150}
          imageFit={ImageFit.centerContain}
          src={location.logoUrl}
        />
      </Stack>
      <Text variant="large" styles={{ root: { marginLeft: theme.spacing.m } }}>
        Provider Settings
      </Text>
      {location.providers && (
        <PracticeLocationProvidersTable providers={location.providers} />
      )}
    </Stack>
  );
};
