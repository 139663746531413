import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useState } from "react";

import { PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";

import { MessageCreditsBalancesTable } from "./MessageCreditsBalancesTable";
import { MessageCreditsEventFormDialog } from "./MessageCreditsEventFormDialog";
import { MessageCreditsEventId } from "./MessageCreditsEventFormDialog.types";
import { MessageCreditsEventsTable } from "./MessageCreditsEventsTable";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface MessageCreditsProps {
  tenant: Tenant;
}

const MessageCreditsBase: React.FC<MessageCreditsProps> = ({ tenant }) => {
  const theme = useTheme();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCommsCreditsWrite
  );

  const [messageCreditsEventId, setMessageCreditsEventId] = useState<
    MessageCreditsEventId
  >(undefined);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Balance</SectionTitle>
        <MessageCreditsEventFormDialog
          tenantId={tenant.id}
          messageCreditsEventId={messageCreditsEventId}
          messageCreditsEvent={undefined}
          closeDialog={() => setMessageCreditsEventId(undefined)}
        />
        <PrimaryButton
          onClick={() => setMessageCreditsEventId("new")}
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        >
          Adjust Credits
        </PrimaryButton>
      </Stack>

      <MessageCreditsBalancesTable tenantId={tenant.id} />

      <Stack.Item grow>
        <Stack>
          <SectionTitle>Recent History</SectionTitle>
          <MessageCreditsEventsTable tenantId={tenant.id} />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export const MessageCredits = withPermissions(
  MessageCreditsBase,
  [Permissions.PltCommsCreditsRead, Permissions.PltCommsCreditsWrite],
  "or"
);
