import { TextBadge, TextBadgeColor } from "@bps/fluent-ui";

interface ReplicationQueueProcessingStateBadgeProps {
  outOfSync?: boolean;
}

export const ReplicationQueueProcessingStateBadge = ({
  outOfSync
}: ReplicationQueueProcessingStateBadgeProps) => {
  let badgeColor = TextBadgeColor.yellow;
  let label = "Processing";
  if (outOfSync === false) {
    badgeColor = TextBadgeColor.green;
    label = "In sync";
  } else if (outOfSync === true) {
    badgeColor = TextBadgeColor.red;
    label = "Out of sync";
  }

  return <TextBadge badgeColor={badgeColor}>{label}</TextBadge>;
};
