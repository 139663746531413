import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { IsDevelopmentEnvironment } from "@libs/config/config";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TopBarDefaultHeader,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

import { hasRolloutOccurred } from "../utils";
import { NewRolloutPackageDesiredConfigDialog } from "./configure";
import { NewRolloutPackageDialog } from "./edit";
import { RolloutPackages } from "./RolloutPackages";
import {
  useRollout,
  useRolloutPackages,
  useSoftwarePackages,
  useSoftwarePackageVersionsForRollout
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import {
  RolloutPackage,
  SoftwarePackage,
  SoftwarePackageType,
  SoftwarePackageVersion
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

// View of each software package assigned to this rollout
export interface RolloutSoftwarePackageVersionDto {
  id: string;
  code: string;
  displayName: string;
  description?: string;
  softwarePackageType: SoftwarePackageType;
  softwarePackageVersionId: string;
  softwarePackageId: string;
  version: string;
  datePublishedUtc?: DateTime;
  minimumWindowsVersion: string;
  maximumWindowsVersion: string;
  rolloutId: string;
  rolloutPackageId: string;
  eTag?: string;
  rolloutOccurred: boolean;
}

const RolloutPackageHostBase: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showConfigure, setShowConfigure] = useState<boolean>(false);
  const [configureRolloutPackageId, setConfigureRolloutPackageId] = useState<
    string
  >();

  const [softwarePackageVersionId, setSoftwarePackageVersionId] = useState<
    string
  >();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const configureRolloutPackage = (
    rolloutPackageId: string,
    softwarePackageVersionId: string
  ) => {
    setConfigureRolloutPackageId(rolloutPackageId);
    setSoftwarePackageVersionId(softwarePackageVersionId);
    setShowConfigure(true);
  };

  const { ringId, rolloutId } = useParams<{
    ringId: string;
    rolloutId: string;
  }>();

  const backToRings = () => {
    const to = generatePath("/system/plt/deployment-rings/:ringId", {
      ringId
    });
    navigate(to);
  };

  const { data: rollout, isLoading: rolloutIsLoading } = useRollout(
    rolloutId,
    ringId
  );

  const rolloutPackagesQuery = useRolloutPackages(rolloutId!);

  const rolloutOccurred =
    rolloutIsLoading === false &&
    hasRolloutOccurred(rollout!) &&
    !IsDevelopmentEnvironment;

  const {
    data: rolloutPackagesData,
    error: rolloutPackagesError,
    isLoading: rolloutPackagesIsLoading
  } = rolloutPackagesQuery;

  // Get software package versions for this rollout id
  const softwarePackageVersionsQuery = useSoftwarePackageVersionsForRollout(
    rolloutId!
  );

  const {
    data: softwarePackageVersionsData,
    error: softwarePackageVersionsError,
    isLoading: softwarePackageVersionsIsLoading
  } = softwarePackageVersionsQuery;

  // Get the software packages
  const softwarePackagesQuery = useSoftwarePackages();
  const {
    data: softwarePackagesData,
    error: softwarePackagesError,
    isLoading: softwarePackagesIsLoading
  } = softwarePackagesQuery;

  const isLoading =
    softwarePackageVersionsIsLoading ||
    rolloutPackagesIsLoading ||
    softwarePackagesIsLoading;

  const errorMessage =
    softwarePackageVersionsError?.message ??
    rolloutPackagesError?.message ??
    softwarePackagesError?.message;

  const rolloutSoftwarePackageVersions = useMemo(() => {
    if (!rolloutPackagesData) return [];
    if (!softwarePackagesData) return [];
    if (!softwarePackageVersionsData) return [];

    return getRolloutSoftwarePackageVersions({
      rolloutPackages: rolloutPackagesData,
      softwarePackages: softwarePackagesData,
      softwarePackageVersions: softwarePackageVersionsData,
      rolloutOccurred
    });
  }, [
    rolloutPackagesData,
    softwarePackagesData,
    softwarePackageVersionsData,
    rolloutOccurred
  ]);

  const existingSoftwarePackageIds =
    rolloutSoftwarePackageVersions?.map(x => x.softwarePackageId!) ?? [];

  return (
    <>
      <Stack verticalFill>
        <Stack
          verticalFill
          styles={{
            root: { padding: theme.spacing.s1, width: "100%" }
          }}
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Stack horizontal>
            <TopBarDefaultHeader
              backButtonOnClick={backToRings}
              heading="Back to rings"
            />
            <SectionTitle>{rollout?.displayName}</SectionTitle>
          </Stack>

          {rolloutOccurred && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              dismissButtonAriaLabel="Close"
            >
              This rollout has already occurred and cannot be updated. To update
              software packages, create a new rollout.
            </MessageBar>
          )}

          <Stack horizontal horizontalAlign="space-between">
            <Text variant="xLarge">Software Packages</Text>

            <Stack.Item align="end">
              <DefaultButton
                iconProps={{ iconName: "Add" }}
                onClick={() => setShowAdd(true)}
                disabled={rolloutOccurred || !hasWritePermission}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              >
                Add Software Package
              </DefaultButton>
            </Stack.Item>
          </Stack>

          <RolloutPackages
            items={rolloutSoftwarePackageVersions}
            enableShimmer={isLoading}
            errorMessage={errorMessage}
            onConfigureRolloutPackage={configureRolloutPackage}
          />
        </Stack>
      </Stack>
      <NewRolloutPackageDialog
        hidden={!showAdd}
        onDismiss={() => setShowAdd(false)}
        existingSoftwarePackageIds={existingSoftwarePackageIds}
      />
      {configureRolloutPackageId && softwarePackageVersionId && (
        <NewRolloutPackageDesiredConfigDialog
          hidden={!showConfigure}
          onDismiss={() => setShowConfigure(false)}
          rolloutPackageId={configureRolloutPackageId}
          softwarePackageVersionId={softwarePackageVersionId}
          rolloutOccurred={rolloutOccurred}
        />
      )}
    </>
  );
};

// Combine software packages and their versions into a set of dtos for this rollout device configuration
const getRolloutSoftwarePackageVersions = (options: {
  rolloutPackages: RolloutPackage[];
  softwarePackages: SoftwarePackage[];
  softwarePackageVersions: SoftwarePackageVersion[];
  rolloutOccurred: boolean;
}): RolloutSoftwarePackageVersionDto[] => {
  const {
    rolloutPackages,
    softwarePackages,
    softwarePackageVersions,
    rolloutOccurred
  } = options;
  return (
    rolloutPackages?.map(rolloutPackage => {
      const softwarePackageVersion = softwarePackageVersions?.find(
        x => x.id === rolloutPackage.softwarePackageVersionId
      );

      const softwarePackage = softwarePackages.find(
        x => x.id === softwarePackageVersion?.softwarePackageId
      );

      return {
        id: rolloutPackage.id,
        code: softwarePackage?.code,
        displayName: softwarePackage?.displayName,
        description: softwarePackage?.description,
        softwarePackageType: softwarePackage?.softwarePackageType,
        softwarePackageVersionId: softwarePackageVersion?.id,
        softwarePackageId: softwarePackage?.id,
        version: softwarePackageVersion?.version,
        datePublishedUtc: softwarePackageVersion?.datePublishedUtc,
        minimumWindowsVersion: softwarePackageVersion?.minimumWindowsVersion,
        maximumWindowsVersion: softwarePackageVersion?.maximumWindowsVersion,
        rolloutId: rolloutPackage.rolloutId,
        rolloutPackageId: rolloutPackage.id,
        eTag: rolloutPackage.eTag,
        rolloutOccurred
      } as RolloutSoftwarePackageVersionDto;
    }) ?? []
  );
};
export const RolloutPackageHost = withPermissions(
  RolloutPackageHostBase,
  [Permissions.PltFieldManagementRead, Permissions.PltFieldManagementWrite],
  "or"
);
