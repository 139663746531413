import React, { FunctionComponent, useMemo } from "react";
import {
  IColumn,
  ScrollablePane,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { ScrollListener } from "@components/tables/ScrollListener";
import { getPagesResult } from "@libs/paging/paging.utils";
import { RedirectLinkActions } from "./RedirectLinkActions";
import { CopyableGuid } from "@components/CopyableText";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { LinkUsageToggle } from "./link-usage/LinkUsageToggle";
import { useRedirectLinks } from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";
import { RedirectLinkDto } from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.dtos";

interface RedirectsListProps {
  inviteCode?: string;
  setLinkId: (linkId: string | undefined) => void;
}
export const RedirectsList: FunctionComponent<RedirectsListProps> = ({
  inviteCode,
  setLinkId
}) => {
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage
  } = useRedirectLinks({ inviteCode });

  const items = useMemo(() => getPagesResult(data), [data]);

  const columns: IColumn[] = [
    {
      key: "actions",
      name: "",
      maxWidth: 36,
      minWidth: 36,
      onRender: (item: RedirectLinkDto) => (
        <RedirectLinkActions item={item} openEditDialog={setLinkId} />
      )
    },
    {
      key: "linkId",
      name: "Link Id",
      maxWidth: 120,
      minWidth: 100,
      onRender: (item: RedirectLinkDto) => (
        <CopyableGuid value={item.linkId} short />
      )
    },
    {
      key: "redirectUrl",
      name: "Redirect Url",
      maxWidth: 200,
      minWidth: 100,
      onRender: (item: RedirectLinkDto) => item.redirectUrl
    },
    {
      key: "schema",
      name: "Schema",
      maxWidth: 70,
      minWidth: 70,
      onRender: (item: RedirectLinkDto) => item.schemaVersion
    },
    {
      key: "Before",
      name: "Not Before",
      maxWidth: 150,
      minWidth: 150,
      onRender: (item: RedirectLinkDto) =>
        item.notBeforeUtc
          ? DateTime.fromISO(item.notBeforeUtc).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : "-"
    },
    {
      key: "after",
      name: "Not After",
      maxWidth: 150,
      minWidth: 150,
      onRender: (item: RedirectLinkDto) =>
        item.notAfterUtc
          ? DateTime.fromISO(item.notAfterUtc).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : "-"
    },
    {
      key: "has-pin",
      name: "Has Pin",
      maxWidth: 60,
      minWidth: 60,
      onRender: (item: RedirectLinkDto) =>
        item.hasPin ? (
          <TextBadge
            badgeColor={TextBadgeColor.green}
            badgeSize={TextBadgeSize.small}
            styles={{ root: { width: "fit-content" } }}
          >
            Yes
          </TextBadge>
        ) : null
    },
    {
      key: "usage",
      name: "Usage",
      maxWidth: 200,
      minWidth: 200,
      onRender: (item: RedirectLinkDto) => (
        <LinkUsageToggle linkId={item.linkId} />
      )
    }
  ];

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <ShimmeredDetailsList
          items={items}
          enableShimmer={isLoading}
          errorMessage={error?.message}
          columns={columns}
          stickyHeader
        />
        <ScrollListener
          hasNextPage={true}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
