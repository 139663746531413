import { FunctionComponent, useState } from "react";
import { Pivot, PivotItem } from "@bps/fluent-ui";
import {
  ComponentDetailsTabs,
  ComponentsDefinitionsExpandedRowProps
} from "./ComponentsDefinitionsExpandedRow.types";
import { ComponentDependencies } from "./ComponentDependencies";
import { ComponentImplementations } from "./ComponentImplementations";
import { ComponentSettings } from "./ComponentSettings";
export const ComponentsDefinitionsExpandedRow: FunctionComponent<ComponentsDefinitionsExpandedRowProps> = ({
  component
}) => {
  const [selectedKey, setSelectedKey] = useState<
    ComponentDetailsTabs | undefined
  >(ComponentDetailsTabs.Dependencies);

  return (
    <Pivot
      selectedKey={selectedKey}
      styles={{ itemContainer: { padding: 8 } }}
      onLinkClick={item => {
        setSelectedKey(item?.props.itemKey as ComponentDetailsTabs);
      }}
    >
      <PivotItem
        itemKey={ComponentDetailsTabs.Dependencies}
        headerText="Dependencies"
      >
        <ComponentDependencies component={component} />
      </PivotItem>

      <PivotItem
        itemKey={ComponentDetailsTabs.Implementations}
        headerText="Implementations"
      >
        <ComponentImplementations component={component} />
      </PivotItem>

      <PivotItem itemKey={ComponentDetailsTabs.Settings} headerText="Settings">
        <ComponentSettings component={component} />
      </PivotItem>
    </Pivot>
  );
};
