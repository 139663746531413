import { Route, Routes } from "react-router-dom";
import { Users } from "./Users";
import { Roles } from "./Roles";
import { PoisonMessages } from "./Events";
import { SystemBreadcrumbs } from "../../SystemBreadcrumbs";
import { SystemEnvTabs } from "./system-env-tabs.constants";

export const SystemEnvRoutes = () => {
  return (
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemEnvTabs} />}>
        <Route path={SystemEnvTabs.User.Manage.id} element={<Users />} />
        <Route path={SystemEnvTabs.Roles.View.id} element={<Roles />} />
        <Route
          path={SystemEnvTabs.Events.PoisonMessages.id}
          element={<PoisonMessages />}
        />
      </Route>
    </Routes>
  );
};
