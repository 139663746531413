import { flatten } from "@bps/fluent-ui";

import {
  FlattenChildTenant,
  SubscriptionItemProducts
} from "./SubscriptionItems.types";
import { SubscriptionFormValues } from "./SubscriptionForm";
import {
  AddSubscriptionDto,
  CustomerProductsSubscription
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { groupBy } from "@bps/utils";

export const getSubscriptionsProducts = (
  subscription: CustomerProductsSubscription
): SubscriptionItemProducts[] => {
  return groupBy(subscription.subscriptionItemList, item => item.productId).map(
    ([productId, items]) => ({
      salesProductId: productId,
      salesProductCode: items[0].productCode,
      salesProductFamily: items[0].productFamily,
      quantity: items.reduce((acc, item) => acc + item.quantity, 0),
      scheduledQuantity: items.reduce(
        (acc, item) => acc + item.scheduledQuantity,
        0
      ),
      childTenants: items.map(item => ({
        quantity: item.quantity,
        childTenantId: item.childTenantId,
        scheduledQuantity: item.scheduledQuantity
      }))
    })
  );
};

export const getAddSubscriptionsDtos = (
  values: SubscriptionFormValues
): AddSubscriptionDto[] => {
  const flattenChildTenant: FlattenChildTenant[] = flatten(
    values.subscriptionProducts.reduce((acc, item) => {
      return [
        ...acc,
        item.childTenants.map(c => ({
          ...c,
          quantity: c.scheduledQuantity,
          salesProductId: item.salesProductId
        }))
      ];
    }, [])
  );

  return flattenChildTenant.reduce(
    (acc: AddSubscriptionDto[], item: FlattenChildTenant) => {
      const childTenant = acc.find(a => a.childTenantId === item.childTenantId);
      if (!childTenant) {
        return [
          ...acc,
          {
            promotionCode: values.promotionCode,
            childTenantId: item.childTenantId,
            items: [
              {
                salesProductId: item.salesProductId,
                quantity: Number(item.quantity)
              }
            ]
          }
        ];
      } else {
        return [
          ...acc.filter(i => i.childTenantId !== childTenant.childTenantId),
          {
            childTenantId: childTenant.childTenantId,
            promotionCode: values.promotionCode,
            items: [
              ...childTenant.items,
              {
                salesProductId: item.salesProductId,
                quantity: Number(item.quantity)
              }
            ]
          }
        ];
      }
    },
    []
  );
};
