import { dangerButtonStyles } from "@components/buttons/DangerButton";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Stack,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  useDeleteDeploymentRing,
  useDeploymentRing
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

interface DeleteRingDialogProps extends Omit<ConfirmDialogProps, "onConfirm"> {
  ringId: string;
}

export const DeleteRingDialog: React.FC<DeleteRingDialogProps> = ({
  ringId,
  ...props
}) => {
  const theme = useTheme();
  const deploymentRingQuery = useDeploymentRing(ringId);
  const { mutateAsync: deleteDeploymentRing } = useDeleteDeploymentRing();

  return (
    <ConfirmDialog
      confirmButtonProps={{ text: "Delete", styles: dangerButtonStyles(theme) }}
      cancelButtonProps={{ text: "No" }}
      onConfirm={() => deleteDeploymentRing(ringId)}
      dialogContentProps={{
        title: "Delete Ring"
      }}
      {...props}
    >
      <QueryStateIndicator {...deploymentRingQuery}>
        {deploymentRing => (
          <Stack tokens={{ childrenGap: theme.spacing.m }}>
            Are you sure you want to delete the following deployment ring?
            <Tile>
              <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                <Text variant="large">
                  {deploymentRing.displayName} - {deploymentRing.code}
                </Text>
                <Text variant="small">{deploymentRing.description}</Text>
                <Text variant="xSmall">{deploymentRing.id}</Text>
              </Stack>
            </Tile>
          </Stack>
        )}
      </QueryStateIndicator>
    </ConfirmDialog>
  );
};
