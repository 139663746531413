import React, { FunctionComponent } from "react";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { useComponentsRequests } from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  ComponentRequestDto,
  GetComponentsRequestsArgs
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { IColumn, ScrollablePane, Stack } from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { ExpandableRow } from "@components/tables/ExpandableRow";
import { useExpandedRows } from "@components/tables/useExpandedRows";
import { ComponentText } from "../../components/components/ComponentText";
import { ComponentsRequestsDetails } from "./ComponentsRequestsDetails";
import {
  ManagerWorkflowRunInstanceLink,
  ComponentDateText
} from "../../_shared-blocks";

interface ComponentsRequestsListProps {
  filter: GetComponentsRequestsArgs;
}

export const ComponentsRequestsList: FunctionComponent<ComponentsRequestsListProps> = ({
  filter
}) => {
  const { expandedIds, toggleExpandedIds } = useExpandedRows();

  const { isLoading, error, data = [] } = useComponentsRequests(filter, {
    refetchOnMount: "always"
  });

  const columns: IColumn[] = [
    {
      key: "id",
      name: "ID",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentRequestDto) => (
        <CopyableGuid value={item.id} short />
      )
    },
    {
      key: "code",
      name: "Code",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentRequestDto) => (
        <ComponentText code={item.code} />
      )
    },
    {
      key: "scope-id",
      name: "Scope ID",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentRequestDto) => (
        <CopyableGuid value={item.scopeId} short />
      )
    },
    {
      key: "action",
      name: "Action",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentRequestDto) => item.action
    },
    {
      key: "status",
      name: "Status",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentRequestDto) => item.status
    },
    {
      key: "instance-id",
      name: "WF Instance Link",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: ComponentRequestDto) =>
        item.componentManagerWorkflowRunInstanceId ? (
          <ManagerWorkflowRunInstanceLink
            id={item.componentManagerWorkflowRunInstanceId}
          />
        ) : (
          "-"
        )
    },
    {
      key: "data",
      name: "Date",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: ComponentRequestDto) => (
        <ComponentDateText changeLog={item.changeLog} />
      )
    }
  ];
  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <ShimmeredDetailsList
          enableShimmer={isLoading}
          errorMessage={error?.message}
          items={data}
          columns={columns}
          stickyHeader
          onRenderRow={(props, renderDefault) => {
            if (props && renderDefault) {
              return (
                <ExpandableRow
                  expandedIds={expandedIds}
                  toggleExpandedIds={toggleExpandedIds}
                  id={props.item.id}
                  ExpandableComponent={
                    <ComponentsRequestsDetails componentsRequest={props.item} />
                  }
                  rowProps={props}
                />
              );
            }
            return null;
          }}
        />
      </ScrollablePane>
    </Stack>
  );
};
