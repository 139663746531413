import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { useDebouncedValue } from "@libs/hooks/useDebouncedValue";
import { RolePermissions } from "modules/system/sections/plt/roles/RolePermissions";
import { useMemo, useState } from "react";

import {
  Accordion,
  AccordionItem,
  IconButton,
  NoDataTile,
  Spinner,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";
import { useSecurityRolesAndPermissions } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { SecurityRole } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { Permission } from "@libs/api/gateways/plt/plt-gateway.dtos";

const ROLECODEREGEX = /([a-zA-Z]+)\.([a-zA-Z-]+)/;

export interface ObjectPermissions {
  [key: string]: Array<Permission>;
}

interface RoleBySystem {
  [key: string]: Array<SecurityRole>;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const getObjectPermissions = (permissions: Permission[]): ObjectPermissions => {
  const objectPermissions: ObjectPermissions = {};
  for (const permission of permissions) {
    const [_object] = permission.code.split(".");
    if (_object) {
      if (!objectPermissions[_object]) {
        objectPermissions[_object] = [];
      }
      objectPermissions[_object].push(permission);
    }
  }
  return objectPermissions;
};

const SystemRolesBase: React.FC = () => {
  const theme = useTheme();

  const [filter, setFilter] = useState<string>();

  const debouncedFilter = useDebouncedValue(filter, 1000);

  const { data, isFetching } = useSecurityRolesAndPermissions();

  const roles = useMemo(() => data?.securityRoles || [], [data]);
  const permissions = useMemo(() => data?.permissions || [], [data]);

  const filteredRoles = useMemo(
    () =>
      roles.filter(role =>
        role.code.toLowerCase().includes(filter?.toLowerCase() || "")
      ),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedFilter, roles]
  );

  const unassignedRoles = useMemo(
    () => filteredRoles.filter(x => !ROLECODEREGEX.test(x.code)),
    [filteredRoles]
  );

  const correctRoles = useMemo(
    () => filteredRoles.filter(x => ROLECODEREGEX.test(x.code)),
    [filteredRoles]
  );

  const objectPermissions = useMemo(() => getObjectPermissions(permissions), [
    permissions
  ]);

  const systems = useMemo(
    () => correctRoles.map(role => role.code.split(".")[0]).filter(onlyUnique),
    [correctRoles]
  );

  const rolesBySystem = useMemo<RoleBySystem>(
    () =>
      correctRoles.reduce(
        (acc, role) => {
          const _system = role.code.split(".")[0];
          if (!acc[_system]) {
            acc[_system] = [];
          }
          acc[_system].push(role);
          return acc;
        },

        {}
      ),
    [correctRoles]
  );

  if (!data || isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack
          horizontal
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <Stack horizontal>
            <TextField
              placeholder="Filter Roles"
              value={filter}
              onChange={(e, value) => setFilter(value)}
            />
            <IconButton
              iconProps={{ iconName: "Clear" }}
              onClick={() => setFilter("")}
            />
          </Stack>
        </Stack>

        {!!unassignedRoles.length && (
          <Accordion disableAnimation>
            <AccordionItem
              title="System unassigned Security Roles"
              showTitleIcon
              titleIconName="WarningSolid"
              styles={{
                heading: {
                  background: theme.semanticColors.warningBackground
                }
              }}
            >
              <Accordion disableAnimation multiple>
                {unassignedRoles.map(role => (
                  <AccordionItem
                    title={`${role.text} (${role.code})`}
                    key={role.code}
                  >
                    <RolePermissions
                      role={role}
                      objectPermissions={objectPermissions}
                    />
                  </AccordionItem>
                ))}
              </Accordion>
            </AccordionItem>
          </Accordion>
        )}

        {!!systems.length && (
          <>
            <Text variant="xLarge">Security Roles</Text>
            <Accordion disableAnimation>
              {systems.map(system => (
                <AccordionItem key={system} title={system}>
                  <Accordion disableAnimation multiple>
                    {rolesBySystem[system].map(role => (
                      <AccordionItem
                        key={role.code}
                        title={`${role.text} (${role.code})`}
                      >
                        <RolePermissions
                          role={role}
                          objectPermissions={objectPermissions}
                        />
                      </AccordionItem>
                    ))}
                  </Accordion>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        )}
        {!unassignedRoles.length && !correctRoles.length && (
          <NoDataTile
            textProps={{ text: "No roles found<" }}
            linkProps={{ hidden: true }}
          />
        )}
      </Stack>
    </>
  );
};

export const SystemRoles = withPermissions(
  SystemRolesBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
