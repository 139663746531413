import { BusinessUsersTable } from "modules/users/BusinessUsers/BusinessUsersTable";
import { IdentityFilter } from "modules/users/components/IdentityFilter";
import React, { useState } from "react";

import {
  CenteredBox,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { withPermissions } from "@components/withPermissions";
import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useBusinessUserSearch } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

const BusinessUsersBase: React.FC = () => {
  const theme = useTheme();
  const [formValues, setFormValues] = useState<BpIdUsersSearchArgs>();
  const { data, isFetching } = useBusinessUserSearch(formValues);

  return (
    <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
      <IdentityFilter onSubmit={setFormValues} isLoading={isFetching} />
      {data?.tooManyResults === true && (
        <MessageBar messageBarType={MessageBarType.error}>
          Your search returned too many results, please refine your search
          paramters.
        </MessageBar>
      )}
      <Stack verticalFill>
        {data?.records?.length || isFetching ? (
          <BusinessUsersTable
            enableShimmer={isFetching}
            items={data?.records || []}
          />
        ) : (
          <CenteredBox>
            <Text>No Business Users found</Text>
          </CenteredBox>
        )}
      </Stack>
    </Stack>
  );
};

export const BusinessUsers = withPermissions(
  BusinessUsersBase,
  [Permissions.PltBpidUserRead, Permissions.PltBpidUserWrite],
  "or"
);
