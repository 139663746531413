import { commandButton } from "modules/tenants/sections/ttm/styles";

import { CompoundButton, FontIcon, Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import {
  usePostTtmRecalculate,
  usePostTtmSnapshot
} from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}
type IconInfo = { name: string; color: string };
const TtmBestHealthBase = ({ tenant }: Props) => {
  const theme = useTheme();
  const postSnapshot = usePostTtmSnapshot(tenant.id);
  const postRecalculate = usePostTtmRecalculate(tenant.id);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.TiCalculateWrite
  );

  const onSnapshotClick = () => {
    postSnapshot.mutate(tenant.id);
  };

  const postRecalculateClick = () => {
    postRecalculate.mutate(tenant.id);
  };

  const iconInfo: { success: IconInfo; error: IconInfo } = {
    success: { name: "SkypeCircleCheck", color: theme.palette.green },
    error: { name: "StatusErrorFull", color: theme.palette.red }
  };

  const statusIcon: IconInfo = iconInfo[postSnapshot.status];

  return (
    <Stack>
      <SectionTitle>Omni/Best Health Integration</SectionTitle>
      <Stack horizontal styles={{ root: { paddingTop: theme.spacing.m } }}>
        <Stack>
          <CompoundButton
            disabled={!hasWritePermission || postSnapshot.isLoading}
            secondaryText="Snapshot tables"
            styles={commandButton}
            onClick={onSnapshotClick}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            <FontIcon iconName="Camera" styles={{ root: { fontSize: 40 } }} />
          </CompoundButton>
          {statusIcon && (
            <FontIcon
              iconName={statusIcon.name}
              styles={{
                root: {
                  fontSize: 20,
                  color: statusIcon.color,
                  margin: "auto"
                }
              }}
            />
          )}
        </Stack>
        <CompoundButton
          disabled={!hasWritePermission || postRecalculate.isLoading}
          secondaryText="Recalculate Availability"
          styles={commandButton}
          onClick={postRecalculateClick}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        >
          <FontIcon
            iconName="CalendarReply"
            styles={{ root: { fontSize: 40 } }}
          />
        </CompoundButton>
      </Stack>
    </Stack>
  );
};

export const TtmBestHealth = withPermissions(
  TtmBestHealthBase,
  [Permissions.TiSettingsRead, Permissions.TiCalculateWrite],
  "or"
);
