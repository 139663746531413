import React, { FunctionComponent } from "react";
import { DefaultButton, Spinner, SpinnerSize } from "@bps/fluent-ui";
import { generatePath, useNavigate } from "react-router-dom";

import { TenantsTabs } from "../../../../tenants-tabs.constants";

interface NewSubscriptionButtonProps {
  isLoading: boolean;
  hasDefaultPaymentMethod: boolean;
  tenantId: string;
}
export const NewSubscriptionButton: FunctionComponent<NewSubscriptionButtonProps> = ({
  isLoading,
  tenantId,
  hasDefaultPaymentMethod
}) => {
  const navigate = useNavigate();

  const newSubscriptionPath = generatePath(
    "/tenants/:tenantId/:sectionId/:id",
    {
      tenantId,
      sectionId: TenantsTabs.Sales.Subscriptions.id,
      id: "new"
    }
  );

  const setDefaultPaymentMethodPath = generatePath(
    "/tenants/:tenantId/:sectionId/",
    {
      tenantId,
      sectionId: TenantsTabs.Sales.PaymentMethods.id
    }
  );

  return (
    <DefaultButton
      onClick={() => {
        const to = hasDefaultPaymentMethod
          ? newSubscriptionPath
          : setDefaultPaymentMethodPath;
        navigate(to);
      }}
      disabled={isLoading}
    >
      {isLoading && <Spinner size={SpinnerSize.small} />}{" "}
      {!isLoading && hasDefaultPaymentMethod
        ? "Create new Subscription"
        : "Set default payment method"}
    </DefaultButton>
  );
};
