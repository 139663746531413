import { FunctionComponent } from "react";
import { Heading, Stack, Text } from "@bps/fluent-ui";
import { ComponentRequestDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { ComponentRequestActions } from "./ComponentRequestActions";

interface ComponentsRequestsDetailsProps {
  componentsRequest: ComponentRequestDto;
}
export const ComponentsRequestsDetails: FunctionComponent<ComponentsRequestsDetailsProps> = ({
  componentsRequest
}) => {
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Heading>Status reason:</Heading>
        <Text>{componentsRequest?.statusReason ?? "-"}</Text>
      </Stack>
      <Stack.Item>
        <Heading>Actions:</Heading>
        <ComponentRequestActions
          componentActionRequestId={componentsRequest.id}
        />
      </Stack.Item>
    </Stack>
  );
};
