import { Route, Routes } from "react-router-dom";

import { FieldMgmtTabs } from "./tabs";
import { FieldManagementBreadcrumbs } from "./FieldManagementBreadcrumbs";

const FieldDeviceRoutes = () => {
  return (
    <Routes>
      <Route element={<FieldManagementBreadcrumbs />}>
        <Route path=":deviceId/*" element={<FieldMgmtTabs />} />
        <Route path="/" element={<FieldMgmtTabs />} />
      </Route>
    </Routes>
  );
};

// eslint-disable-next-line import/no-default-export
export default FieldDeviceRoutes;
export { FieldDeviceTabs } from "./field-device-tabs.constants";
