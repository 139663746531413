import React from "react";

import { Heading, Stack, Toggle, useTheme } from "@bps/fluent-ui";
import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { GeneralAppRegistration } from "./sections/GeneralAppRegistration";
import { GeneralTimeSection } from "./sections/GeneralTimeSection";
import { GeneralTokenLifeTime } from "./sections/GeneralTokenLifeTime";

interface FieldDeviceGeneralProps {
  device: FieldDevice;
}

export const FieldDeviceGeneral: React.FC<FieldDeviceGeneralProps> = ({
  device
}) => {
  const theme = useTheme();

  const onDeviceAuthenticationEnabledChanged = () => {
    // TODO
  };

  return (
    <Stack
      styles={{
        root: {
          minWidth: 470,
          borderColor: theme.semanticColors.variantBorder,
          borderWidth: 1,
          borderStyle: "solid",
          padding: theme.spacing.s1
        }
      }}
    >
      <Heading variant="section-heading">General</Heading>

      <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
        <Toggle
          id="device-authentication-enabled"
          onChange={onDeviceAuthenticationEnabledChanged}
          label="Device Authentication Enabled"
          inlineLabel={true}
          automationAttribute=""
          checked={device.authEnabled}
          disabled
        />

        <GeneralAppRegistration appRegistrationId={device.appRegistrationId} />
        <GeneralTokenLifeTime tokenExpiryUtc={device.tokenExpiryUtc} />

        <GeneralTimeSection
          heading="Latest Token Expires"
          timeUtc={device.tokenExpiryUtc}
        />

        <GeneralTimeSection
          heading="Next Token Not Before"
          timeUtc={device.notBeforeTimeUtc}
          tooltip={true}
        />

        <GeneralTimeSection
          heading="Token Issued"
          timeUtc={device.issueTimeUtc}
          tooltip={true}
          showExpired={false}
        />
      </Stack>
    </Stack>
  );
};
