import {
  MessageCreditsEvent,
  MessageCreditsEventDto
} from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";

export interface MessageCreditsEventFormValues
  extends Omit<MessageCreditsEventDto, "tenantId" | "id" | "changeLog"> {}

export type MessageCreditsEventId = string | "new" | undefined;

export interface MessageCreditsEventFormDialogProps {
  messageCreditsEventId: MessageCreditsEventId;
  messageCreditsEvent: MessageCreditsEvent | undefined;
  tenantId: string;
  closeDialog: () => void;
}

export interface MessageCreditsEventFormDialogBaseProps
  extends Omit<MessageCreditsEventFormDialogProps, "messageCreditsEventId"> {
  messageCreditsEventId: "new" | string;
}

export const newMessageCreditsEventFormValues: MessageCreditsEventFormValues = {
  units: 0,
  source: undefined,
  details: undefined
};
