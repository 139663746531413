import { FunctionComponent } from "react";
import { Link, Text } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useComponentWorkflowRunInstance } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface WorkflowRunInstanceLinkProps {
  id: string;
}
export const WorkflowRunInstanceLink: FunctionComponent<WorkflowRunInstanceLinkProps> = ({
  id
}) => {
  const workflowRunInstanceLinkQuery = useComponentWorkflowRunInstance(id);
  return (
    <QueryStateIndicator {...workflowRunInstanceLinkQuery}>
      {({ workflowInstanceUrl }) =>
        workflowInstanceUrl ? (
          <Link target="_blank" href={workflowInstanceUrl}>
            {id}
          </Link>
        ) : (
          <Text>{id}</Text>
        )
      }
    </QueryStateIndicator>
  );
};
