import AjvDraft04 from "ajv-draft-04";
import React from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { CustomRJSFSchema } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { Permissions } from "@libs/permissions/permissions.enum";
import { withTheme } from "@rjsf/core";
import { Theme as FluentUITheme } from "@rjsf/fluent-ui";
import { customizeValidator } from "@rjsf/validator-ajv8";

export interface CommandJsonFormProps<TData = unknown> {
  schema?: CustomRJSFSchema;
  onSubmit: (obj: TData) => Promise<void>;
}

const JsonForm = withTheme(FluentUITheme);

// We are using the ajv draft 04 validator which is generated by backend.
const validator = customizeValidator({ AjvClass: AjvDraft04 });

export const CommandJsonForm: React.FC<CommandJsonFormProps> = ({
  onSubmit,
  schema
}) => {
  const theme = useTheme();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <Stack
      styles={{
        root: {
          flex: 1,
          borderColor: theme.semanticColors.variantBorder,
          borderWidth: 1,
          borderStyle: "solid",
          padding: theme.spacing.s1
        }
      }}
    >
      {schema === undefined ? (
        <>Please select a command.</>
      ) : (
        <JsonForm
          schema={schema}
          onSubmit={onSubmit}
          validator={validator}
          disabled={!hasWritePermission} // Cannot send commands if user does not have write permissions
        />
      )}
    </Stack>
  );
};
