import { CallbackDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { CallbacksFilterBarValues } from "modules/tenants/sections/integrators/callbacks/CallbacksFilterBar";

export const getSortedFilteredCallbacks = (
  callbacks: CallbackDto[],
  filter: CallbacksFilterBarValues
) => {
  return Array.from(callbacks)
    .sort((a, b) => {
      if (a.isEnabled === b.isEnabled) return 0;
      return a.isEnabled ? -1 : 1;
    })
    .filter(p => {
      const matchCallbackKey = filter.key
        ? p.key?.toLowerCase().includes(filter.key.toLowerCase())
        : true;

      const showEnabled = !(!filter.isEnabled && p.isEnabled);

      return matchCallbackKey && showEnabled;
    });
};
