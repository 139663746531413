import { SystemBhRoutes } from "modules/system/sections/bh/SystemBhRoutes";
import { SystemBhSections } from "modules/system/sections/bh/SystemBhSections";

import { withPermissions } from "@components/withPermissions";
import { SideNavLayout } from "@layouts/SideNavLayout";
import { Permissions } from "@libs/permissions/permissions.enum";

const SystemBhBase = () => (
  <SideNavLayout nav={<SystemBhSections />}>
    <SystemBhRoutes />
  </SideNavLayout>
);

export const SystemBh = withPermissions(
  SystemBhBase,
  [
    Permissions.EnvSecurityRead,
    Permissions.EnvSecurityWrite,
    Permissions.BhbPracticeManagementRead
  ],
  "or"
);
