import { FunctionComponent, useState } from "react";
import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { DefaultButton, Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { ComponentsFilter } from "./components/ComponentsFilter";
import { ComponentsList } from "./components/ComponentsList";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { CreateComponentDialog } from "./components/create-component-dialog/CreateComponentDialog";

interface ComponentsScreenProps {
  tenant: Tenant;
}

const ComponentsScreenBase: FunctionComponent<ComponentsScreenProps> = ({
  tenant
}) => {
  const theme = useTheme();
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const { data: hasWritePermission } = usePermissionsCheckQuery([
    Permissions.PltComponentsWrite
  ]);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <SectionTitle>Components</SectionTitle>
        {hasWritePermission && (
          <DefaultButton onClick={() => setShowCreateDialog(true)}>
            Create
          </DefaultButton>
        )}
      </Stack>
      <ComponentsFilter>
        {({ values: { code } }) => (
          <ComponentsList code={code} tenantId={tenant.id} />
        )}
      </ComponentsFilter>
      {showCreateDialog && (
        <CreateComponentDialog
          tenantId={tenant.id}
          onDismiss={() => setShowCreateDialog(false)}
        />
      )}
    </Stack>
  );
};

export const ComponentsScreen = withPermissions(ComponentsScreenBase, [
  Permissions.PltComponentsRead
]);
