import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { guid } from "@libs/common/guid";
import { useMemo } from "react";

import {
  SelectionMode,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { DateTime, Duration } from "@bps/utils";
import { EntitySnapshot } from "@libs/api/gateways/tg/tg-ops-gateway.dtos";

interface Props {
  entitySnapshots: EntitySnapshot[];
}

interface DisplayEntitySnapshot {
  entityType: string;
  snapshotActive: DateTime | undefined;
  currentSnapshotActivatedMilliseconds: number | undefined;
  isProcessing: boolean;
  processingDurationToNowMilliseconds: number | undefined;
  processingDurationFromActiveMilliseconds: number | undefined;
  snapshotId: guid;
  processingSnapshotId: guid;
}

const getDurationText = (inputMilliseconds: number): string | undefined => {
  const activeLux = Duration.fromMillis(inputMilliseconds).shiftTo(
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );

  const durationObj = activeLux.toObject();

  let durationText = activeLux.toFormat("S'ms'");

  if (durationObj.days) {
    durationText = activeLux.toFormat("d'd' h'h'");
  }

  if (durationObj.hours) {
    durationText = activeLux.toFormat("h'h' m'm'");
  }

  if (durationObj.minutes) {
    durationText = activeLux.toFormat("m'm' s's'");
  }

  if (durationObj.seconds) {
    durationText = activeLux.toFormat("s's' S'ms'");
  }

  return durationText;
};

const ActivatedDurationCell = ({ item }: { item?: DisplayEntitySnapshot }) => {
  if (!item?.currentSnapshotActivatedMilliseconds) return <>--</>;

  const durationText = getDurationText(
    item.currentSnapshotActivatedMilliseconds
  );

  const text = `${item.snapshotActive?.toFormat(
    "LL/dd/yyyy, h:mm a"
  )} (${durationText} ago)`;

  return <Text>{text}</Text>;
};

const ProcessingDurationCell = ({ item }: { item?: DisplayEntitySnapshot }) => {
  let text = "--";

  if (
    item?.isProcessing === true &&
    item?.processingDurationToNowMilliseconds !== undefined
  ) {
    const durationText = getDurationText(
      item.processingDurationToNowMilliseconds
    );
    text = `In Progress: ${durationText}`;
  } else if (
    item?.isProcessing === false &&
    item?.processingDurationFromActiveMilliseconds !== undefined
  ) {
    text = getDurationText(item.processingDurationFromActiveMilliseconds) ?? "";
  }

  return <Text>{text}</Text>;
};

const IsProcessingCell = ({ isProcessing }: { isProcessing: boolean }) => {
  if (isProcessing) {
    return (
      <TextBadge
        badgeSize={TextBadgeSize.small}
        badgeColor={TextBadgeColor.yellow}
      >
        Processing
      </TextBadge>
    );
  }

  return (
    <TextBadge
      badgeSize={TextBadgeSize.small}
      badgeColor={TextBadgeColor.green}
    >
      Processed
    </TextBadge>
  );
};

export const EntitySnapshotTable = ({ entitySnapshots }: Props) => {
  const snapShotDisplayItems = useMemo(
    () =>
      entitySnapshots.map(es => ({
        entityType: es.entityType,
        snapshotActive: es.snapshotActive,
        currentSnapshotActivatedMilliseconds: es.snapshotActive
          ? DateTime.now()
              .diff(es.snapshotActive)
              .as("milliseconds")
          : undefined,
        isProcessing: es.snapshotId !== es.processingSnapshotId,
        processingDurationToNowMilliseconds: es.processingStarted
          ? DateTime.now()
              .diff(es.processingStarted)
              .as("milliseconds")
          : undefined,
        processingDurationFromActiveMilliseconds:
          es.processingStarted && es.snapshotActive
            ? es.snapshotActive.diff(es.processingStarted).as("milliseconds")
            : undefined,
        snapshotId: es.snapshotId,
        processingSnapshotId: es.processingSnapshotId
      })),
    [entitySnapshots]
  );

  const columns: DataTableColumn<DisplayEntitySnapshot>[] = [
    {
      key: "entityType",
      name: "Entity Type",
      minWidth: 200,
      maxWidth: 350,
      isSorted: true,
      sort: (left, right) => {
        if (left?.entityType && right?.entityType)
          return left.entityType.localeCompare(right.entityType);
        return 1;
      }
    },
    {
      key: "currentSnapshotActivated",
      name: "Current Snapshot Activated",
      minWidth: 300,
      onRender: (item: DisplayEntitySnapshot) => (
        <ActivatedDurationCell item={item} />
      ),
      sort: (left, right) => {
        if (
          left?.currentSnapshotActivatedMilliseconds &&
          right?.currentSnapshotActivatedMilliseconds
        )
          return (
            left.currentSnapshotActivatedMilliseconds -
            right.currentSnapshotActivatedMilliseconds
          );
        return 1;
      }
    },
    {
      key: "isProcessing",
      name: "Is Processing",
      minWidth: 120,
      maxWidth: 130,
      onRender: (item: DisplayEntitySnapshot) => (
        <IsProcessingCell isProcessing={item.isProcessing} />
      ),
      sort: (left, right) => {
        if (
          left?.isProcessing !== undefined &&
          right?.isProcessing !== undefined
        ) {
          if (left.isProcessing === right.isProcessing) return 0;
          return left.isProcessing ? -1 : 1;
        }

        return 1;
      }
    },
    {
      key: "processingDuration",
      name: "Processing Duration",
      minWidth: 220,
      maxWidth: 250,
      onRender: (item: DisplayEntitySnapshot) => (
        <ProcessingDurationCell item={item} />
      ),
      sort: (left, right) => {
        // check that there are values to compare
        if (
          left?.isProcessing !== undefined &&
          right?.isProcessing !== undefined
        ) {
          if (
            (left.isProcessing || right.isProcessing) &&
            left.processingDurationToNowMilliseconds !== undefined &&
            right.processingDurationToNowMilliseconds !== undefined
          ) {
            return (
              left.processingDurationToNowMilliseconds -
              right.processingDurationToNowMilliseconds
            );
          } else if (
            left.processingDurationFromActiveMilliseconds !== undefined &&
            right.processingDurationFromActiveMilliseconds !== undefined
          ) {
            return (
              left.processingDurationFromActiveMilliseconds -
              right.processingDurationFromActiveMilliseconds
            );
          }
        }

        return 1;
      }
    },
    {
      key: "snapshotId",
      name: "SnapshotId",
      minWidth: 300,
      maxWidth: 320
    },
    {
      key: "processingSnapshotId",
      name: "ProcessingSnapshotId",
      minWidth: 300,
      maxWidth: 320
    }
  ];

  const getKey = (entitySyncState: DisplayEntitySnapshot) =>
    entitySyncState.entityType;

  return (
    <DataTable
      items={snapShotDisplayItems}
      columns={columns}
      getKey={getKey}
      selectionMode={SelectionMode.none}
      onShouldVirtualize={() => false}
      styles={{
        contentWrapper: {
          "[role=gridcell]": {
            display: "flex",
            alignItems: "center"
          }
        }
      }}
    />
  );
};
