import { SectionTitle } from "@components/SectionTitle";
import { SelectionList } from "@components/SelectionList";
import { useEffect, useState } from "react";

import { Stack, StackItem, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  ReportsType,
  SiteAvailableReport,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { useSiteAvailableReportsQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { guid } from "@libs/common/guid";

interface Props {
  tenantId: guid;
  type: ReportsType;
  component: SiteComponentType;
  onReportSelected: (report: SiteAvailableReport) => void;
}

export const ReportList = ({
  tenantId,
  onReportSelected,
  type,
  component
}: Props) => {
  const theme = useTheme();
  const query = useSiteAvailableReportsQuery(tenantId, component, { type });

  const [selectedReport, setSelectedReport] = useState<
    SiteAvailableReport | undefined
  >();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (selectedReport) {
      onReportSelected(selectedReport);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  return (
    <QueryStateIndicator<SiteAvailableReport[]> {...query}>
      {reports => (
        <Stack verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
          <SectionTitle>
            {capitalizeFirstLetter(type)} {capitalizeFirstLetter(component)}{" "}
            Reports
          </SectionTitle>

          <StackItem
            grow
            styles={{
              root: {
                width: "max-content",
                padding: theme.spacing.s1
              }
            }}
          >
            <SelectionList
              items={reports}
              selectedItem={selectedReport}
              onSelectedItemChange={setSelectedReport}
            />
          </StackItem>
        </Stack>
      )}
    </QueryStateIndicator>
  );
};
