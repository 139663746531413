import React, { FunctionComponent } from "react";

import { Dialog } from "@bps/fluent-ui";
import { Form } from "@components/form/Form";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  useAddLicence,
  useLicence,
  useUpdateLicence
} from "@libs/api/gateways/plt/plt-gateway.hooks";

import {
  LicenceFormDialogBaseProps,
  LicenceFormDialogProps,
  LicenceFormValues,
  newLicenceFormValues
} from "./LicenceFormDialog.types";
import { LicenceFormFields } from "./LicenceFormFields";
import { getLicenceFormValues } from "./utils";

const LicenceFormDialogBase: FunctionComponent<Omit<
  LicenceFormDialogBaseProps,
  "licenceId"
> & {
  tenantId: string;
  licenceId: "new" | string;
}> = ({ closeDialog, licenceId, tenantId }) => {
  const isNewLicence = licenceId === "new";

  const licenceQuery = useLicence(licenceId, {
    enabled: !isNewLicence
  });

  const { mutateAsync: addLicence, error: addLicenceError } = useAddLicence();
  const {
    mutateAsync: updateLicence,
    error: updateLicenceError
  } = useUpdateLicence();

  const onSubmit = async (values: LicenceFormValues) => {
    try {
      const { expiryDate, isActive, ...rest } = values;
      if (!!expiryDate) {
        if (isNewLicence) {
          await addLicence({
            ...rest,
            tenantId,
            expiryDate: expiryDate.toISOString(),
            isInactive: !isActive
          });
        } else {
          licenceQuery.data &&
            (await updateLicence({
              ...licenceQuery.data,
              ...rest,
              expiryDate: expiryDate.toISOString(),
              isInactive: !isActive
            }));
        }
        closeDialog();
      }
    } catch (e) {
      return e;
    }
  };

  const title: string = `${isNewLicence ? "New" : "Edit"} Licence`;

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title,
        showCloseButton: true,
        onDismiss: closeDialog
      }}
      minWidth={320}
      styles={{ root: { minHeight: 480 } }}
    >
      <QueryStateIndicator {...licenceQuery} allowNullOrUndefined>
        {data => {
          const defaultValues = isNewLicence
            ? newLicenceFormValues
            : getLicenceFormValues(data);

          return (
            <Form<LicenceFormValues>
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              error={addLicenceError ?? updateLicenceError}
            >
              <LicenceFormFields
                tenantId={tenantId}
                isManagedLicence={data?.source !== undefined}
              />
            </Form>
          );
        }}
      </QueryStateIndicator>
    </Dialog>
  );
};

export const LicenceFormDialog: FunctionComponent<LicenceFormDialogProps> = props => {
  if (!props.licenceId) return null;
  return (
    <LicenceFormDialogBase
      {...props}
      licenceId={props.licenceId}
      isManagedLicence={props.source !== undefined}
    />
  );
};
