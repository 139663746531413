import { SectionTitle } from "@components/SectionTitle";
import { PremSettingsTable } from "modules/tenants/sections/bp-premier/prem-settings/prem-settings-table";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { PremSettings } from "@libs/api/gateways/tg/tg-ops-gateway.dtos";
import { usePremSettingsQuery } from "@libs/api/gateways/tg/tg-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}

const PremSettingsPageBase = ({ tenant }: Props) => {
  const premSettingsQuery = usePremSettingsQuery(tenant.id);
  const { data: hasPreRelease } = usePermissionsCheckQuery(
    Permissions.OpsConsolePreRelease
  );
  return (
    <>
      <SectionTitle>Bp Premier Settings</SectionTitle>
      <QueryStateIndicator<PremSettings[]>
        {...premSettingsQuery}
        loadingTitle="Loading settings from prem."
      >
        {premSettings =>
          hasPreRelease && (
            <PremSettingsTable premSettings={premSettings} siteId={tenant.id} />
          )
        }
      </QueryStateIndicator>
    </>
  );
};

export const PremSettingsPage = withPermissions(
  PremSettingsPageBase,
  [
    Permissions.ProsSiteManagementRead,
    Permissions.ProsSiteManagementWrite,
    Permissions.OpsConsolePreRelease
  ],
  "or"
);
