import { Dialog, IDialogProps } from "@bps/fluent-ui";

import { EditRolloutPackageDesiredConfig } from "./EditRolloutPackageDesiredConfig";
import {
  useRolloutPackageDesiredConfig,
  useSoftwarePackageVersionConfig
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

interface NewRolloutPackageDesiredConfigDialogProps extends IDialogProps {
  rolloutPackageId: string;
  softwarePackageVersionId: string;
  onDismiss: () => void;
  rolloutOccurred: boolean;
}

export const NewRolloutPackageDesiredConfigDialog: React.FC<NewRolloutPackageDesiredConfigDialogProps> = ({
  rolloutPackageId,
  softwarePackageVersionId,
  onDismiss,
  rolloutOccurred,
  ...props
}) => {
  const rolloutPackageDesiredConfigQuery = useRolloutPackageDesiredConfig(
    rolloutPackageId
  );

  const softwarePackageVersionConfigQuery = useSoftwarePackageVersionConfig(
    softwarePackageVersionId
  );

  const {
    data: rolloutPackageDesiredConfigData,
    isLoading: rolloutPackageDesiredConfigIsLoading,
    error: rolloutPackageDesiredConfigError
  } = rolloutPackageDesiredConfigQuery;

  const {
    data: softwarePackageVersionConfigData,
    isLoading: softwarePackageVersionConfigIsLoading,
    error: softwarePackageVersionConfigError
  } = softwarePackageVersionConfigQuery;

  const isLoading =
    rolloutPackageDesiredConfigIsLoading ||
    softwarePackageVersionConfigIsLoading;

  const errorMessage =
    rolloutPackageDesiredConfigError?.message ??
    softwarePackageVersionConfigError?.message;

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Edit Rollout Package Desired Configuration"
      }}
      minWidth="90%"
      maxWidth="90%"
      onDismiss={onDismiss}
      {...props}
    >
      <EditRolloutPackageDesiredConfig
        rolloutPackageId={rolloutPackageId}
        items={softwarePackageVersionConfigData ?? []}
        desiredConfigs={rolloutPackageDesiredConfigData ?? []}
        enableShimmer={isLoading}
        errorMessage={errorMessage}
        onDismiss={onDismiss}
        rolloutOccurred={rolloutOccurred}
      />
    </Dialog>
  );
};
