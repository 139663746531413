import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { useMemo } from "react";

import { SelectionMode } from "@bps/fluent-ui";
import { AppointmentStats } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface TotalBookingStatsTableProps {
  stats: AppointmentStats[];
}

export const TotalBookingStatsTable = ({
  stats
}: TotalBookingStatsTableProps) => {
  const total = useMemo(() => {
    const propertiesToSum = [
      "todayCount",
      "yesterdayCount",
      "lastSevenDaysCount",
      "previousSevenDayCount",
      "thisMonthCount",
      "previousMonthCount",
      "thisYearCount",
      "previousYearCount"
    ] as const;

    const sumProperties = (prop: keyof AppointmentStats) =>
      stats.reduce(
        (a, { [prop]: value }) => a + (typeof value === "number" ? value : 0),
        0
      );

    return propertiesToSum.reduce(
      (result, prop) => ({ ...result, [prop]: sumProperties(prop) }),
      {} as AppointmentStats
    );
  }, [stats]);

  const columns: DataTableColumn<AppointmentStats>[] = [
    {
      key: "tenantName",
      name: "Clinic (Tenant Name)",
      minWidth: 300,
      maxWidth: 1100,
      sort: true,
      isSorted: true,
      filterable: true,
      onRender: () => {
        return "Total";
      }
    },
    {
      key: "todayCount",
      name: "Today",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.todayCount.toLocaleString();
      }
    },
    {
      key: "yesterdayCount",
      name: "Yesterday",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.yesterdayCount.toLocaleString();
      }
    },
    {
      key: "lastSevenDaysCount",
      name: "Last seven days",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.lastSevenDaysCount.toLocaleString();
      }
    },
    {
      key: "previousSevenDayCount",
      name: "Prior seven days",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.previousSevenDayCount.toLocaleString();
      }
    },
    {
      key: "thisMonthCount",
      name: "This month",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.thisMonthCount.toLocaleString();
      }
    },
    {
      key: "previousMonthCount",
      name: "Last Month",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.previousMonthCount.toLocaleString();
      }
    },
    {
      key: "thisYearCount",
      name: "This Year",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.thisYearCount.toLocaleString();
      }
    },
    {
      key: "previousYearCount",
      name: "Last Year",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: AppointmentStats) => {
        return item.previousYearCount.toLocaleString();
      }
    }
  ];

  const getKey = (appointmentStats: AppointmentStats) =>
    appointmentStats.tenantId;

  return (
    <DataTable
      items={[total]}
      columns={columns}
      getKey={getKey}
      selectionMode={SelectionMode.none}
      rightAlignColumns={true}
    />
  );
};
