import React, { FunctionComponent, useState } from "react";

import {
  IColumn,
  ScrollablePane,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { CopyableGuid } from "@components/CopyableText";
import {
  InvitationsArgs,
  UserInviteDto
} from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useInvites } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { InvitationsActions } from "./InvitationsActions";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { ScrollListener } from "@components/tables/ScrollListener";
import { getPagesResult } from "@libs/paging/paging.utils";

interface InvitationsListProps {
  args: InvitationsArgs;
}

enum InvitationsListKeys {
  Actions = "actions",
  UserId = "user-id",
  TenantId = "tenant-id",
  TenantName = "tenant-name",
  Name = "name",
  Email = "email",
  Valid = "valid",
  RedeemedDate = "redeemed-date",
  ExpireDate = "expire-date",
  HasPin = "has-pin"
}

export const InvitationsList: FunctionComponent<InvitationsListProps> = ({
  args
}) => {
  const theme = useTheme();
  const [sortField, setSortField] = useState<InvitationsArgs["sortField"]>();
  const [sortAscending, setSortAscending] = useState<
    InvitationsArgs["sortAscending"]
  >();

  const { data, hasNextPage, fetchNextPage, isLoading, error } = useInvites({
    ...args,
    sortAscending,
    sortField
  });

  const invites = getPagesResult(data);

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  const { data: hasInvitePermission } = usePermissionsCheckQuery([
    Permissions.PltInvitationWrite
  ]);

  const sortIconsStyles = {
    sortIcon: { color: theme.palette.themePrimary, fontWeight: 600 }
  } as any;

  const columns: IColumn[] = [
    {
      key: InvitationsListKeys.Actions,
      name: "",
      onRender: (item: UserInviteDto) => (
        <InvitationsActions invitation={item} />
      ),
      minWidth: 50,
      maxWidth: 50
    },
    {
      key: InvitationsListKeys.TenantName,
      name: "Tenant Name",
      onRender: (item: UserInviteDto) =>
        item.tenantName ? item.tenantName : "-",
      minWidth: 100,
      maxWidth: 120
    },
    {
      key: InvitationsListKeys.Name,
      name: "Name",
      onRender: (item: UserInviteDto) =>
        item.firstName && item.lastName
          ? `${item.firstName ?? ""} ${item.lastName ?? ""}`
          : "-",
      minWidth: 100,
      maxWidth: 150,
      isSorted: true,
      isSortedDescending: sortField === "Firstname" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("Firstname");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles
    },
    {
      key: InvitationsListKeys.TenantId,
      name: "TenantId",
      onRender: (item: UserInviteDto) =>
        item.tenantId ? <CopyableGuid value={item.tenantId} short /> : "-",
      minWidth: 100,
      maxWidth: 120
    },
    {
      key: InvitationsListKeys.UserId,
      name: "UserId",
      onRender: (item: UserInviteDto) =>
        item.userId ? <CopyableGuid value={item.userId} short /> : "-",
      minWidth: 100,
      maxWidth: 120
    },
    {
      key: InvitationsListKeys.Email,
      name: "Email",
      onRender: (item: UserInviteDto) => item.signInEmail,
      minWidth: 100,
      maxWidth: 300
    },
    {
      key: InvitationsListKeys.Valid,
      name: "Status",
      onRender: (item: UserInviteDto) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={item.isValid ? TextBadgeColor.green : TextBadgeColor.red}
        >
          {item.isValid ? "Valid" : "Invalid"}
        </TextBadge>
      ),
      minWidth: 50,
      maxWidth: 50
    },
    {
      key: InvitationsListKeys.HasPin,
      name: "Has Pin",
      onRender: (item: UserInviteDto) =>
        item.hasPin ? (
          <TextBadge
            badgeColor={TextBadgeColor.green}
            badgeSize={TextBadgeSize.small}
          >
            Yes
          </TextBadge>
        ) : (
          ""
        ),
      minWidth: 70,
      maxWidth: 70
    },
    {
      key: InvitationsListKeys.RedeemedDate,
      name: "Redeemed Date",
      onRender: (item: UserInviteDto) =>
        item.redeemedOn
          ? DateTime.fromISO(item.redeemedOn).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : "-",
      minWidth: 150,
      maxWidth: 150,
      isSorted: true,
      isSortedDescending: sortField === "RedeemedOnDate" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("RedeemedOnDate");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles
    },
    {
      key: InvitationsListKeys.ExpireDate,
      name: "Expire Date",
      onRender: (item: UserInviteDto) =>
        item.expiresAfter
          ? DateTime.fromISO(item.expiresAfter).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : "-",
      minWidth: 150,
      maxWidth: 150,
      isSorted: true,
      isSortedDescending: sortField === "ExpiresAfter" && !sortAscending,
      onColumnClick: (_evt, column: IColumn) => {
        setSortField("ExpiresAfter");
        setSortAscending(column.isSortedDescending);
      },
      styles: sortIconsStyles
    }
  ].filter(col => {
    // exclude actions from the columns if a user has not a permission for initiations managing
    return !(col.key === InvitationsListKeys.Actions && !hasInvitePermission);
  });

  return (
    <Stack styles={{ root: { position: "relative" } }} grow>
      <ScrollablePane>
        <ShimmeredDetailsList
          items={invites}
          columns={columns}
          enableShimmer={isLoading}
          errorMessage={error?.message}
          stickyHeader
          onShouldVirtualize={() => true}
        />
        <ScrollListener
          hasNextPage={hasNextPage ?? false}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
