import { FunctionComponent } from "react";
import { ComponentDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { LabeledText, LabeledTextGroup, Stack } from "@bps/fluent-ui";
import { ComponentText } from "../ComponentText";

interface ComponentInfoProps {
  component: ComponentDto;
}
export const ComponentInfo: FunctionComponent<ComponentInfoProps> = ({
  component
}) => {
  return (
    <Stack>
      <LabeledTextGroup>
        <LabeledText label="Text">
          <ComponentText code={component.code} />
        </LabeledText>
        <LabeledText label="Scope">{component.scopeId}</LabeledText>
        <LabeledText label="Implementations allowed">-</LabeledText>
      </LabeledTextGroup>
    </Stack>
  );
};
