import { useRef, useState } from "react";

import {
  DefaultButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import {
  AssignSelectorFilter,
  AssignSelectorFilterValues
} from "./AssignSelectorFilter";
import { AssignSelectorTable } from "./AssignSelectorTable";
import { useUpdateDeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import {
  DeploymentRing,
  SelectorDto
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface AssignSelectorProps extends ShimmeredDetailsListProps {
  deploymentRing: DeploymentRing;
  onDismiss: () => void;
}

export const AssignSelector = ({
  deploymentRing,
  onDismiss,
  items,
  ...props
}: AssignSelectorProps) => {
  const theme = useTheme();
  const [selectedSelectorId, setSelectedSelectorId] = useState<
    string | undefined
  >();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutateAsync: updateDeploymentRing } = useUpdateDeploymentRing();

  const selection = useRef(
    new Selection({
      onSelectionChanged() {
        const dtos = selection.current.getSelection() as SelectorDto[];
        if (dtos.length > 0) setSelectedSelectorId(dtos[0].id);
        else setSelectedSelectorId(undefined);
      }
    })
  );

  const save = async () => {
    setIsSubmitting(true);

    const { id, eTag, selectorId, ...rest } = deploymentRing;
    updateDeploymentRing({
      deploymentRingId: id,
      eTag: eTag!,
      selectorId: selectedSelectorId,
      ...rest
    });

    setIsSubmitting(false);
    close();
  };

  const close = () => {
    // reset the selection
    selection.current.setItems([], true);
    onDismiss();
  };

  return (
    <Stack tokens={{ childrenGap: theme.spacing.m }}>
      <AssignSelectorFilter>
        {({ values }) => {
          return (
            <AssignSelectorTable
              items={filterAndSortSelectors(items, values)}
              selectionPreservedOnEmptyClick={true}
              selectionMode={SelectionMode.single}
              selection={selection.current}
              setKey="assign-selector-table"
              {...props}
            />
          );
        }}
      </AssignSelectorFilter>

      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.s1 }}
        horizontalAlign="end"
      >
        <DefaultButton
          disabled={isSubmitting || !selectedSelectorId}
          onClick={save}
        >
          Assign
        </DefaultButton>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
      </Stack>
    </Stack>
  );
};

const filterAndSortSelectors = (
  selectors: SelectorDto[],
  filter: AssignSelectorFilterValues
): SelectorDto[] => {
  return selectors
    .filter(
      d =>
        filter.name === "" ||
        d.name.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort((a: SelectorDto, b: SelectorDto) => {
      return a.name < b.name ? 1 : -1;
    });
};
