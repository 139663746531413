import qs from "query-string";
import React, { useState } from "react";

import {
  ActivityItem,
  DefaultButton,
  FontIcon,
  IStackTokens,
  Link,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  useCreateOutboundEmailRequest,
  useCreateOutboundSmsRequest
} from "@libs/api/gateways/plt-comms/plt-comms-gateway.hooks";
import { config } from "@libs/config/config";
import { Permissions } from "@libs/permissions/permissions.enum";

import {
  CommsSmsTestingSendDialog,
  CommsSmsTestingSendFormValues
} from "./CommsSmsTestingSendDialog";
import {
  CreateEmailRequestDialog,
  CreateEmailRequestFormValues
} from "./CreateEmailRequestDialog";

interface ICommRequest {
  id: string;
  tenantId: string;
  type: string;
  to: string;
  subject?: string;
  message: string;
  sourceId: string;
}

const CommsTestingBase: React.FC = () => {
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCommsWrite
  );

  const [activity, setActivity] = useState<ICommRequest[]>([]);
  const [showNewSms, setShowNewSms] = useState<boolean>(false);
  const [showNewEmail, setShowNewEmail] = useState<boolean>(false);

  const {
    mutateAsync: createSms,
    error: smsError
  } = useCreateOutboundSmsRequest();

  const {
    mutateAsync: createEmail,
    error: emailError
  } = useCreateOutboundEmailRequest();

  async function handleSendSmsDialogSubmit(
    smsRequest: CommsSmsTestingSendFormValues
  ) {
    await createSms(
      {
        sourceTenantId: smsRequest.tenantId,
        phone: smsRequest.phoneNumber,
        text: smsRequest.message
      },
      {
        onSuccess: r => {
          setShowNewSms(false);
          setActivity([
            ...activity,
            {
              type: "SMS",
              id: r.id,
              tenantId: r.sourceTenantId,
              to: r.phone,
              message: r.text,
              sourceId: r.sourceId
            }
          ]);
        }
      }
    );
  }

  async function handleSendEmailDialogSubmit(
    emailRequest: CreateEmailRequestFormValues
  ) {
    await createEmail(
      {
        sourceTenantId: emailRequest.tenantId,
        to: emailRequest.to,
        subject: emailRequest.subject,
        body: emailRequest.message
      },
      {
        onSuccess: r => {
          setShowNewEmail(false);
          setActivity([
            ...activity,
            {
              type: "Email",
              id: r.id,
              tenantId: r.sourceTenantId,
              to: r.to,
              subject: r.subject,
              message: r.body,
              sourceId: r.sourceId
            }
          ]);
        }
      }
    );
  }

  const theme = useTheme();

  const classNames = mergeStyleSets({
    messageBar: {
      marginTop: theme.spacing.m
    },
    buttonStack: {
      marginTop: theme.spacing.m
    },
    activityItem: {
      marginTop: theme.spacing.m
    },
    nameText: {
      fontWeight: "bold"
    },
    subjectText: {
      fontStyle: "italic"
    }
  });

  const buttonStackTokens: IStackTokens = {
    childrenGap: theme.spacing.s2
  };

  return (
    <>
      <Stack verticalFill>
        <CommsSmsTestingSendDialog
          hidden={!showNewSms}
          error={smsError}
          onSubmit={handleSendSmsDialogSubmit}
          onDismiss={() => setShowNewSms(false)}
        />
        <CreateEmailRequestDialog
          hidden={!showNewEmail}
          error={emailError}
          onSubmit={handleSendEmailDialogSubmit}
          onDismiss={() => setShowNewEmail(false)}
        />

        <SectionTitle>Testing</SectionTitle>
        <Text>Send an SMS or email for testing vendor integrations</Text>
        <MessageBar
          className={classNames.messageBar}
          messageBarType={MessageBarType.warning}
        >
          If the development gateway is enabled, phone and email addresses need
          to be whitelisted
        </MessageBar>

        <Stack
          className={classNames.buttonStack}
          horizontal
          tokens={buttonStackTokens}
        >
          <DefaultButton
            disabled={!hasWritePermission}
            iconProps={{ iconName: "Send" }}
            onClick={() => setShowNewSms(true)}
          >
            Send SMS
          </DefaultButton>
          <DefaultButton
            disabled={!hasWritePermission}
            iconProps={{ iconName: "Send" }}
            onClick={() => setShowNewEmail(true)}
          >
            Send Email
          </DefaultButton>
        </Stack>

        <Stack verticalFill>
          {activity.map(item => (
            <ActivityItem
              {...item}
              key={item.id}
              className={classNames.activityItem}
              activityIcon={<FontIcon iconName="Send" />}
              activityDescription={
                <>
                  <Text className={classNames.nameText}>{item.type}</Text>
                  <Text>{" sent to "}</Text>
                  <Text className={classNames.nameText}>{item.to}</Text>
                  <Text>{" on behalf of tenant "}</Text>
                  <Text className={classNames.nameText}>{item.tenantId}</Text>
                </>
              }
              comments={
                <>
                  {item.subject && (
                    <>
                      <Text className={classNames.subjectText}>
                        {item.subject}
                      </Text>
                      <br />
                    </>
                  )}
                  <Text>{item.message}</Text>
                  <br />
                  <Link
                    href={`${config.seqUrl}?${qs.stringify({
                      filter: `BpTraceRootId = "${item.sourceId}"`
                    })}`}
                    target="_blank"
                  >
                    Seq logs
                  </Link>
                </>
              }
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export const CommsTesting = withPermissions(
  CommsTestingBase,
  [
    Permissions.PltCatalogOpsRead,
    Permissions.PltCatalogOpsWrite,
    Permissions.PltCommsWrite
  ],
  "or"
);
