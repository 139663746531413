import { FunctionComponent, useState } from "react";
import { Stack } from "@bps/fluent-ui";
import { IpRangeTable } from "./IpRangeTable";
import { IpRangeFormDialog } from "./IpRangeFormDialog";
import { IpRanges } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface IpRangeFormSectionProps {
  hasWritePermission: boolean;
}
export const IpRangeFormSection: FunctionComponent<IpRangeFormSectionProps> = ({
  hasWritePermission
}) => {
  const [ipRangeFormData, setIpRangeFormData] = useState<
    null | undefined | IpRanges
  >(undefined);

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <IpRangeTable
        setShowAddIpRangeDialog={setIpRangeFormData}
        hasWritePermission={hasWritePermission}
      />
      {hasWritePermission && typeof ipRangeFormData === "object" && (
        <IpRangeFormDialog
          ipRanges={ipRangeFormData}
          onDismiss={() => setIpRangeFormData(undefined)}
        />
      )}
    </Stack>
  );
};
