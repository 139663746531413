import React, { FunctionComponent, useState } from "react";

import { PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { LicenceFormDialog } from "./licence-form-dialog/LicenceFormDialog";
import { LicenceId } from "./licence-form-dialog/LicenceFormDialog.types";
import { LicencesFilter } from "./licences-filter/LicencesFilter";
import { LicencesList } from "./licences-list/LicencesList";
import { NEW_LICENCE_TEXT } from "./licences-list/LicencesList.types";

interface LicencingScreenProps {
  tenant: Tenant;
}

const LicencingScreenBase: FunctionComponent<LicencingScreenProps> = ({
  tenant: { id: tenantId }
}) => {
  const theme = useTheme();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltLicencingWrite
  );

  const [licenceId, setLicenceId] = useState<LicenceId>(undefined);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Licencing</SectionTitle>
        <LicenceFormDialog
          tenantId={tenantId}
          licenceId={licenceId}
          closeDialog={() => setLicenceId(undefined)}
        />
        <PrimaryButton
          onClick={() => setLicenceId("new")}
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        >
          {NEW_LICENCE_TEXT}
        </PrimaryButton>
      </Stack>

      <Stack.Item grow>
        <LicencesFilter tenantId={tenantId}>
          {({ values: filter }) => (
            <LicencesList
              filter={filter}
              tenantId={tenantId}
              setLicenceId={setLicenceId}
            />
          )}
        </LicencesFilter>
      </Stack.Item>
    </Stack>
  );
};

export const LicencingScreen = withPermissions(
  LicencingScreenBase,
  [Permissions.PltLicencingRead, Permissions.PltLicencingWrite],
  "or"
);
