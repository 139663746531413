import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { useNavigate } from "react-router-dom";

import { Link, SelectionMode } from "@bps/fluent-ui";
import { useTenantsQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { AppointmentStats } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface BookingStatsTotalTableProps {
  stats: AppointmentStats[];
}

interface BookingStatsTableData extends AppointmentStats {
  tenantName: string;
}

export const ClinicBookingStatsTable = ({
  stats
}: BookingStatsTotalTableProps) => {
  const { data: tenants } = useTenantsQuery();
  const navigate = useNavigate();

  // To filter out any tenants without names (mostly for old deva tenants)
  const filteredStats = stats
    .filter(stat => {
      const tenant = tenants?.find(tenant => tenant.id === stat.tenantId);
      return tenant?.name !== undefined;
    })
    // Map to BookingStatsTableData object as we require tenantName as key for alphabetical sort order sort
    .map(({ tenantId, ...rest }) => ({
      ...rest,
      tenantName: tenants?.find(tenant => tenant.id === tenantId)?.name ?? "",
      tenantId
    })) as BookingStatsTableData[];

  const columns: DataTableColumn<BookingStatsTableData>[] = [
    {
      key: "tenantName",
      name: "Clinic (Tenant Name)",
      minWidth: 300,
      maxWidth: 1100,
      sort: true,
      isSorted: true,
      filterable: true,
      onRender: (item: BookingStatsTableData) => {
        return (
          <Link
            onClick={() => navigate(`/tenants/${item.tenantId}/plt-details`)}
          >
            {item.tenantName}
          </Link>
        );
      }
    },

    {
      key: "todayCount",
      name: "Today",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.todayCount.toLocaleString();
      }
    },
    {
      key: "yesterdayCount",
      name: "Yesterday",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.yesterdayCount.toLocaleString();
      }
    },
    {
      key: "lastSevenDaysCount",
      name: "Last seven days",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.lastSevenDaysCount.toLocaleString();
      }
    },
    {
      key: "previousSevenDayCount",
      name: "Prior seven days",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.previousSevenDayCount.toLocaleString();
      }
    },
    {
      key: "thisMonthCount",
      name: "This month",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.thisMonthCount.toLocaleString();
      }
    },
    {
      key: "previousMonthCount",
      name: "Last Month",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.previousMonthCount.toLocaleString();
      }
    },
    {
      key: "thisYearCount",
      name: "This Year",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.thisYearCount.toLocaleString();
      }
    },
    {
      key: "previousYearCount",
      name: "Last Year",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: BookingStatsTableData) => {
        return item.previousYearCount.toLocaleString();
      }
    }
  ];

  const getKey = (appointmentStats: BookingStatsTableData) =>
    appointmentStats.tenantId;

  return (
    <DataTable
      items={filteredStats}
      columns={columns}
      getKey={getKey}
      selectionMode={SelectionMode.none}
      rightAlignColumns={true}
    />
  );
};
