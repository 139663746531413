import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import { Pivot, PivotItem } from "@bps/fluent-ui";

export interface SysComVersionsTabsProps {
  selectedTab: string | undefined;
  setSelectedTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  selection: any;
}

export const SysComVersionsTabs = ({
  selectedTab,
  setSelectedTab,
  selection
}: SysComVersionsTabsProps) => {
  return (
    <Pivot
      selectedKey={selectedTab}
      defaultSelectedKey={selectedTab}
      onLinkClick={item => {
        // Update selected tab
        setSelectedTab(item?.props.itemKey?.toString());
        // Reset selected items in table
        selection.current.setItems([]);
      }}
      styles={{ linkContent: { width: 200 } }}
    >
      <PivotItem
        itemKey={SiteComponentType.SiteAgent}
        headerText="Site Agent"
      />
      <PivotItem
        itemKey={SiteComponentType.SiteManager}
        headerText="Site Manager"
      />
    </Pivot>
  );
};
