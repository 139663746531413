import { useDebouncedValue } from "@libs/hooks/useDebouncedValue";
import { BhaProfileList } from "modules/users/BhaProfile/BhaProfileList";
import React, { useEffect, useMemo, useState } from "react";

import {
  CenteredBox,
  FontIcon,
  mergeStyles,
  Spinner,
  Stack,
  TextField,
  useTheme
} from "@bps/fluent-ui";
import { useConsumerUserProfileSearch } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";

export const CUProfiles: React.FC = () => {
  const theme = useTheme();
  const [search, setSearch] = useState<string>();
  const [searching, setSearching] = useState<boolean>(false);

  const debouncedSearch = useDebouncedValue(search, 1000);
  const { data, isFetching, error, isFetched } = useConsumerUserProfileSearch(
    debouncedSearch || ""
  );

  useEffect(() => {
    if (!isFetching || !debouncedSearch) {
      setSearching(false);
    }
  }, [isFetching, debouncedSearch]);

  const textField = useMemo(
    () => (
      <Stack>
        <TextField
          autoFocus
          value={search}
          placeholder="User Search"
          onChange={(e, value) => {
            setSearch(value);
            setSearching(true);
          }}
          iconProps={{
            iconName: "Search"
          }}
        />
      </Stack>
    ),
    [search]
  );

  const getErrorText = () => {
    if (isFetched && !data?.records?.length) {
      return "No Profiles Found";
    }

    return error ? String(error) : "";
  };

  return (
    <Stack verticalFill>
      {data?.records?.length ? (
        <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
          <Stack horizontal horizontalAlign="start">
            {textField}
          </Stack>
          <Stack
            styles={{
              root: {
                flex: 1,
                overflowY: "scroll"
              }
            }}
          >
            <BhaProfileList profiles={data?.records || []} />
          </Stack>
        </Stack>
      ) : (
        <CenteredBox
          verticalFill
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <FontIcon
            iconName="ProfileSearch"
            className={mergeStyles({
              fontSize: 48
            })}
          />
          <Stack>Search by name, mobile or email</Stack>
          {textField}
          {searching ? (
            <Spinner />
          ) : (
            <Stack styles={{ root: { height: 20 } }}>{getErrorText()}</Stack>
          )}
        </CenteredBox>
      )}
    </Stack>
  );
};
