import { withPermissions } from "@components/withPermissions";
import { PoisonMessagesTable } from "modules/system/sections/env/components/PoisonMessagesTable";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { PoisonMessage } from "@libs/api/gateways/mon/ops-mon-gateway.dtos";
import { usePoisonMessagesQuery } from "@libs/api/gateways/mon/ops-mon-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

const PoisonMessagesBase = () => {
  const remotePoisonMessages = usePoisonMessagesQuery();

  return (
    <QueryStateIndicator<PoisonMessage[]> {...remotePoisonMessages}>
      {poisonMessageData => (
        <PoisonMessagesTable messages={poisonMessageData} />
      )}
    </QueryStateIndicator>
  );
};

export const PoisonMessages = withPermissions(
  PoisonMessagesBase,
  [
    Permissions.OpsEventHubPoisonMessageRead,
    Permissions.OpsEventHubPoisonMessageWrite,
    Permissions.OpsEventHubPoisonMessageValueRead
  ],
  "or"
);
