import React, { useState } from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { FieldDeviceFilterArgs } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useFieldDevices } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { getPagesResult } from "@libs/paging/paging.utils";

import { FieldDevicesFilter } from "./FieldMgmtFilter";
import { FieldMgmtTable } from "./FieldMgmtTable";

export const FieldMgmtSearch: React.FC = () => {
  const theme = useTheme();
  const [filtersArgs, setFilterArgs] = useState<FieldDeviceFilterArgs>();

  const {
    data: deviceData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useFieldDevices(filtersArgs);

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      return void (await fetchNextPage());
    }
  };

  const devices = getPagesResult(deviceData);

  return (
    <Stack
      styles={{
        root: {
          overflow: "hidden",
          height: "100%",
          background: theme.semanticColors.bodyBackground,
          padding: theme.spacing.s1
        }
      }}
    >
      <FieldDevicesFilter setFilterArgs={setFilterArgs} />

      <Stack
        styles={{
          root: { alignItems: "end", display: "flex", margin: 4 }
        }}
      >
        <Text
          styles={{
            root: {
              background: theme.semanticColors.listItemBackgroundChecked,
              width: "fit-content",
              padding: 2
            }
          }}
        >
          {deviceData?.pages[0]?.results !== null
            ? deviceData?.pages[0]?.total
            : 0}{" "}
          records found
        </Text>
      </Stack>

      {devices.length === 0 && !isLoading ? (
        <Stack horizontalAlign="center">
          <Text variant="small">
            Your search returned no results, please refine your search term.
          </Text>
        </Stack>
      ) : (
        <FieldMgmtTable
          devices={devices ?? []}
          hasNextPage={hasNextPage}
          handleScrolledToBottom={handleScrolledToBottom}
          error={error ?? undefined}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};
