import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { useState } from "react";

import { Heading, IconButton, Stack, StackItem, Toggle } from "@bps/fluent-ui";

import { AuthActionList } from "./AuthActionList";
import { CreateAuthActionDialog } from "./CreateAuthActionDialog";
import { useFieldDeviceAuthActions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface FieldDeviceGeneralProps {
  device: FieldDevice;
}

export const FieldDeviceActions: React.FC<FieldDeviceGeneralProps> = ({
  device
}) => {
  const [showExecuted, setShowExecuted] = useState<boolean>(true);
  const onClickShowExecuted = () => {
    setShowExecuted(!showExecuted);
  };

  const [open, setOpen] = useState<boolean>(false);

  const { data: authActions } = useFieldDeviceAuthActions(
    device.id,
    showExecuted
  );

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <Stack
      styles={{
        root: {
          width: "100%"
        }
      }}
    >
      <CreateAuthActionDialog
        onDismiss={() => setOpen(false)}
        fieldDeviceId={device.id}
        hidden={!open}
      />
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          <Heading variant="section-heading">Actions</Heading>
          <Toggle
            id="show-executed-enabled"
            onChange={onClickShowExecuted}
            label="Show executed"
            inlineLabel
            automationAttribute="click-show-executed"
            checked={showExecuted}
          />
        </Stack>
        <IconButton
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          onClick={() => setOpen(true)} // Add Auth Action Modal
          iconProps={{ iconName: "Add" }}
        />
      </Stack>
      <StackItem
        styles={{
          root: {
            overflowY: "auto",
            maxHeight: 500
          }
        }}
      >
        <AuthActionList authActions={authActions} />
      </StackItem>
    </Stack>
  );
};
