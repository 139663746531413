import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import React, { FunctionComponent, useState } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";

import { AddRedirectLinkDialog } from "./components/add-redirect-link-dialog/AddRedirectLinkDialog";
import { RedirectsFilter } from "./components/RedirectsFilter";
import { RedirectsList } from "./components/RedirectsList";
import { SystemPltTabs } from "../system-plt-tabs.constants";

const RedirectsScreenBase: FunctionComponent = () => {
  const theme = useTheme();

  const [linkId, setLinkId] = useState<string | null | undefined>();

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>
          {SystemPltTabs.Redirects.LinkShortenerSettings.name}
        </SectionTitle>
        <AddRedirectLinkDialog setLinkId={setLinkId} linkId={linkId} />
      </Stack>
      <RedirectsFilter>
        {({ values }) => (
          <RedirectsList setLinkId={setLinkId} inviteCode={values.inviteCode} />
        )}
      </RedirectsFilter>
    </Stack>
  );
};

export const RedirectsScreen = withPermissions(
  RedirectsScreenBase,
  [Permissions.PltRedirectRead, Permissions.PltRedirectWrite],
  "or"
);
