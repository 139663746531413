import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { observer } from "mobx-react";
import { IconButton, Stack, StackItem, Tile, TileStyles } from "@bps/fluent-ui";
import { SiteComponentVersionBadge } from "modules/tenants/sections/pros/dashboard/site-deployment/SiteComponentVersionBadge";
import { useUpdateSiteComponentVersionMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { CurrentSiteVersion } from "@libs/api/gateways/sia/models/CurrentSiteVersion";
import { SiteComponentVersion } from "@libs/api/gateways/sia/models/SiteComponentVersion";

export interface SiteComponentVersionStatusProps {
  siteId: string;
  siteVersion: CurrentSiteVersion;
  siteComponent?: SiteComponentVersion;
}

const tileStyles: Partial<TileStyles> = {
  root: {
    display: "flex",
    flexDirection: "row",
    flex: 1
  },
  content: {
    display: "flex",
    flex: 1
  }
};

const iconProps = {
  iconName: "trash"
};

export const SiteComponentVersionStatus = observer(
  ({ siteId, siteVersion, siteComponent }: SiteComponentVersionStatusProps) => {
    const commandRunner = useCommandRunner();
    const mutation = useUpdateSiteComponentVersionMutation();

    const handleResetDesiredVersion = async () => {
      commandRunner.runMutation("Reset desired version", mutation, {
        siteId,
        component: siteVersion.componentType,
        version: ""
      });
    };

    const hasDesiredVersion = !!siteVersion.desiredVersion;

    return (
      <Stack styles={{ root: { minWidth: 250 } }}>
        <Tile styles={tileStyles}>
          <Stack tokens={{ childrenGap: 5 }}>
            <span>
              <b>{siteVersion.displayText}</b>
            </span>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <StackItem>
                Current Version: {siteVersion.version ?? "--"}
              </StackItem>
              <SiteComponentVersionBadge
                siteVersion={siteVersion}
                siteComponent={siteComponent}
              />
            </Stack>
            <span>
              Desired Version: {siteVersion.desiredVersion ?? "--"}
              {hasDesiredVersion && (
                <IconButton
                  title="Reset desired version"
                  disabled={commandRunner.isBusy}
                  iconProps={iconProps}
                  onClick={handleResetDesiredVersion}
                />
              )}
            </span>
          </Stack>
        </Tile>
      </Stack>
    );
  }
);
