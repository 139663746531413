import React from "react";
import { useFormContext } from "react-hook-form";

import { DefaultButton, Option } from "@bps/fluent-ui";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SoftwarePackageVersionFormDropDown } from "../common/SoftwarePackageVersionFormDropDown";
import { SoftwarePackageDefaultSummaryDto } from "./";

interface SoftwarePackageDefaultFormFieldProps {
  item: SoftwarePackageDefaultSummaryDto;
  softwarePackageVersions: SoftwarePackageVersion[];
}

export const SoftwarePackageDefaultFormFields: React.FC<SoftwarePackageDefaultFormFieldProps> = ({
  item,
  softwarePackageVersions
}) => {
  const { watch } = useFormContext();
  const selectedSoftwarePackageVersionId = watch("softwarePackageVersionId");

  const versionOptions = softwarePackageVersions.map(version => ({
    key: version?.id,
    text: version?.version,
    data: version
  })) as Option<SoftwarePackageVersion>[];

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const buttonEnabled =
    (!selectedSoftwarePackageVersionId ||
      item.softwarePackageVersionId !== selectedSoftwarePackageVersionId) &&
    hasWritePermission;

  return (
    <>
      <SoftwarePackageVersionFormDropDown
        name="softwarePackageVersionId"
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        styles={{ root: { width: 200 } }}
        defaultSelectedKey={selectedSoftwarePackageVersionId}
        options={versionOptions}
      />
      <DefaultButton type="submit" disabled={!buttonEnabled}>
        Set Default Version
      </DefaultButton>
    </>
  );
};
