import { Dialog, IDialogProps } from "@bps/fluent-ui";

import { AuthActionForm, AuthActionFormValues } from "./AuthActionForm";
import { useCreateFieldDeviceAuthActions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

interface CreateAuthActionDialogProps extends IDialogProps {
  fieldDeviceId: string;
  onDismiss: () => void;
}

export const CreateAuthActionDialog: React.FC<CreateAuthActionDialogProps> = ({
  fieldDeviceId,
  onDismiss,
  ...props
}) => {
  const { mutateAsync: createAuthAction } = useCreateFieldDeviceAuthActions();

  const onSubmit = async (formData: AuthActionFormValues) => {
    await createAuthAction({
      fieldDeviceId,
      deviceAction: formData.deviceAction,
      deviceIdentifierType: formData.deviceIdentifierType,
      deviceIdentifierValue: formData.deviceIdentifierValue
    });

    onDismiss();
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Add Action"
      }}
      minWidth={550}
      onDismiss={onDismiss}
      {...props}
    >
      <AuthActionForm fieldDeviceId={fieldDeviceId} onSubmit={onSubmit} />
    </Dialog>
  );
};
