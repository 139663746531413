import React from "react";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import {
  useCancelCustomerSubscriptions,
  useUndoCancellationCustomerSubscription
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { CancelCancelCustomerSubscriptionsArgs } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface CancelRestoreDialogState {
  subscriptionId: string;
  customerTenantId: string;
  operationType: "cancel" | "restore";
}

interface CancelRestoreSubscriptionDialogProps
  extends Omit<ConfirmDialogProps, "onConfirm"> {
  state: CancelRestoreDialogState | undefined;
}

export const CancelRestoreSubscriptionDialog: React.FC<CancelRestoreSubscriptionDialogProps> = ({
  state,
  onCancel,
  confirming,
  ...props
}) => {
  const theme = useTheme();

  const {
    mutateAsync: cancelSubscription,
    isLoading: isLoadingCancelSubscriptions
  } = useCancelCustomerSubscriptions();

  const {
    mutateAsync: undoCancellation,
    isLoading: isLoadingRestoreSubscriptions
  } = useUndoCancellationCustomerSubscription();

  const operationLabel =
    state && state.operationType === "cancel" ? "Cancel" : "Restore";

  const onConfirm = async () => {
    try {
      if (state) {
        const args: CancelCancelCustomerSubscriptionsArgs = {
          customerTenantId: state?.customerTenantId,
          subscriptionId: state?.subscriptionId
        };
        if (state?.operationType === "cancel") {
          await cancelSubscription(args);
        } else {
          await undoCancellation(args);
        }
      }
      onCancel?.();
    } catch (e) {}
  };

  return (
    <ConfirmDialog
      dialogContentProps={{
        title: `Confirm ${operationLabel} Subscription`
      }}
      confirmButtonProps={{
        text: `${operationLabel} Subscription`,

        disabled: isLoadingCancelSubscriptions || isLoadingRestoreSubscriptions
      }}
      cancelButtonProps={{ text: "No" }}
      {...props}
      hidden={false}
      confirming={
        confirming ||
        isLoadingCancelSubscriptions ||
        isLoadingRestoreSubscriptions
      }
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Text>
          Are you sure you want to {state?.operationType} the subscription?
        </Text>
      </Stack>
    </ConfirmDialog>
  );
};
