import { FunctionComponent, memo, useMemo } from "react";
import { useFormState, useWatch } from "react-hook-form";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import { SubscriptionFormValues } from "./SubscriptionForm";

interface SubscriptionNotesProps {
  name: string;
  subIndex: number;
  childTenantIdx: number;
  currentPeriodEnd: string | undefined;
}

export const SubscriptionNotes: FunctionComponent<SubscriptionNotesProps> = memo(
  ({ name, currentPeriodEnd, childTenantIdx, subIndex }) => {
    const scheduledQuantity: string | undefined = useWatch({ name });
    const { defaultValues } = useFormState<SubscriptionFormValues>();

    const note = useMemo(() => {
      const subscriptionProducts = defaultValues?.subscriptionProducts ?? [];

      const subscription = subscriptionProducts[subIndex];
      const currentQuantity = (subscription?.childTenants ?? [])[childTenantIdx]
        ?.quantity;

      const date = currentPeriodEnd
        ? DateTime.fromISO(currentPeriodEnd).toFormat(
            DATE_FORMATS.DAY_DEFAULT_FORMAT
          )
        : undefined;

      const scheduledQuantityNumber = Number(scheduledQuantity ?? 0);
      const currentQuantityNumber = Number(currentQuantity ?? 0);

      if (scheduledQuantityNumber < currentQuantityNumber)
        return `Decrease ${currentQuantityNumber} to ${scheduledQuantityNumber} on ${date}`;
      return undefined;
    }, [
      childTenantIdx,
      currentPeriodEnd,
      defaultValues?.subscriptionProducts,
      scheduledQuantity,
      subIndex
    ]);

    if (!note) return null;
    return (
      <MessageBar
        messageBarType={MessageBarType.warning}
        styles={{ root: { width: "fit-content" } }}
      >
        {note}
      </MessageBar>
    );
  }
);
