import { FunctionComponent } from "react";
import { ChangeLogDto } from "@libs/api/types/common-dtos";
import { DATE_FORMATS, DateTime } from "@bps/utils";

interface ComponentDateTextProps {
  changeLog: ChangeLogDto;
}
export const ComponentDateText: FunctionComponent<ComponentDateTextProps> = ({
  changeLog
}) => {
  const date = changeLog.updatedDate ?? changeLog.createdDate;
  if (!date) return <>-</>;
  return (
    <>{DateTime.fromISO(date).toFormat(DATE_FORMATS.DETAILED_DATE_TIME)}</>
  );
};
