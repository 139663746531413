import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {
  DefaultButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import { RolloutPackageSoftwarePackageSummaryDto } from "./";
import {
  SoftwarePackageVersionFilter,
  SoftwarePackageVersionFilterValues
} from "./SoftwarePackageVersionFilter";
import { SoftwarePackageVersionTable } from "./SoftwarePackageVersionTable";
import { useCreateRolloutPackage } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { RolloutPackageCreateRequest } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface RolloutPackageSelectionDto {
  softwarePackageId: string;
  softwarePackageVersionId: string;
}

interface NewRolloutPackageProps extends ShimmeredDetailsListProps {
  onDismiss: () => void;
}

export const NewRolloutPackage = ({
  items,
  onDismiss,
  ...props
}: NewRolloutPackageProps) => {
  const theme = useTheme();

  const { rolloutId } = useParams<{
    rolloutId: string;
  }>();

  const [selectedSoftwarePackages, setSelectedSoftwarePackages] = useState<
    RolloutPackageSoftwarePackageSummaryDto[]
  >([]);

  const [selectedPackageVersions, setSelectedPackageVersions] = useState<
    RolloutPackageSelectionDto[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { mutateAsync: createRolloutPackage } = useCreateRolloutPackage();

  const updateSoftwarePackageVersion = (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => {
    const updatedSoftwareVersions = [...selectedPackageVersions];
    const existingSoftwareVersion = updatedSoftwareVersions.find(
      x => x.softwarePackageId === softwarePackageId
    );

    if (!existingSoftwareVersion) {
      updatedSoftwareVersions.push({
        softwarePackageId,
        softwarePackageVersionId
      });
    } else {
      existingSoftwareVersion.softwarePackageVersionId = softwarePackageVersionId;
    }

    setSelectedPackageVersions(updatedSoftwareVersions);
  };

  const selection = useRef(
    new Selection({
      onSelectionChanged() {
        const dtos = selection.current.getSelection() as RolloutPackageSoftwarePackageSummaryDto[];
        setSelectedSoftwarePackages(dtos);
      }
    })
  );

  const filteredSelections = selectedPackageVersions.filter(
    x =>
      !!selectedSoftwarePackages.find(
        selectedPackage =>
          selectedPackage.softwarePackageId === x.softwarePackageId
      )
  );

  const save = async () => {
    setIsSubmitting(true);

    const addPromises = Array.from(filteredSelections).map(
      async selectedSoftwarePackage => {
        const { softwarePackageVersionId } = selectedSoftwarePackage;
        const dto = {
          softwarePackageVersionId,
          rolloutId
        } as RolloutPackageCreateRequest;

        await createRolloutPackage(dto);
      }
    );

    await Promise.all(addPromises);
    setIsSubmitting(false);
    close();
  };

  const close = () => {
    // reset the selected rollout device configs
    setSelectedPackageVersions([]);
    selection.current.setItems([], true);
    onDismiss();
  };

  return (
    <Stack tokens={{ childrenGap: theme.spacing.m }}>
      <SoftwarePackageVersionFilter>
        {({ values }) => {
          return (
            <SoftwarePackageVersionTable
              onUpdateSoftwarePackageVersion={updateSoftwarePackageVersion}
              items={filterAndSortSoftwarePackages(items, values)}
              selectionPreservedOnEmptyClick={true}
              selectionMode={SelectionMode.multiple}
              selection={selection.current}
              selections={selectedPackageVersions}
              setKey="software-package-version-table"
              {...props}
            />
          );
        }}
      </SoftwarePackageVersionFilter>

      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.s1 }}
        horizontalAlign="end"
      >
        <DefaultButton
          disabled={isSubmitting || filteredSelections.length === 0}
          onClick={save}
        >
          <span>
            Add
            {filteredSelections.length > 0
              ? ` (${filteredSelections.length})`
              : ""}
          </span>
        </DefaultButton>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
      </Stack>
    </Stack>
  );
};

const filterAndSortSoftwarePackages = (
  softwarePackagesData: RolloutPackageSoftwarePackageSummaryDto[],
  filter: SoftwarePackageVersionFilterValues
): RolloutPackageSoftwarePackageSummaryDto[] => {
  return softwarePackagesData
    .filter(
      d =>
        filter.name === "" ||
        d.displayName.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort(
      (
        a: RolloutPackageSoftwarePackageSummaryDto,
        b: RolloutPackageSoftwarePackageSummaryDto
      ) => {
        return a.displayName < b.displayName ? 1 : -1;
      }
    );
};
