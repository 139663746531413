import { BestHealthAppointmentStats } from "modules/tenants/sections/best-health/appointments";
import { BestHealthSyncState } from "modules/tenants/sections/best-health/entity-sync-state";
import { BestHealthLocations } from "modules/tenants/sections/best-health/locations";
import { BestHealthOperations } from "modules/tenants/sections/best-health/operations";
import { BestHealthProviders } from "modules/tenants/sections/best-health/providers";
import { PremSettingsPage } from "modules/tenants/sections/bp-premier/prem-settings";
import { Logs } from "modules/tenants/sections/ops/logging";
import { TenantDetails } from "modules/tenants/sections/plt/details";
import { TenantTags } from "modules/tenants/sections/plt/tags";
import { TenantFeatures } from "modules/tenants/sections/plt/TenantFeatures";
import { SiteDashboard } from "modules/tenants/sections/pros/dashboard/SiteDashboard";
import { DataSync } from "modules/tenants/sections/pros/data-sync";
import { Reports } from "modules/tenants/sections/pros/reports";
import { TtmAppointmentReminders } from "modules/tenants/sections/ttm/appointment-reminders";
import { TtmBestHealth } from "modules/tenants/sections/ttm/bestHealth/TtmBestHealth";
import { FunctionComponent } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import { ExpandableNav, ExpandableNavItem } from "@components/ExpandableNav";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SideNavLayout } from "@layouts/SideNavLayout";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  ReportsType,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import { TenantsTabs } from "../tenants-tabs.constants";
import { ServiceAgreementsScreen } from "./agreements/service-agreements";
import { IntegApplications } from "./integrators/applications";
import { IntegCallbacks } from "./integrators/callbacks";
import { IntegCertificates } from "./integrators/certificates";
import { IntegConsents } from "./integrators/consents";
import { OutboundSmsAuditScreen } from "./plt/comms-audit";
import { ComponentsScreen } from "./plt/components";
import { ComponentsActionsRequestsScreen } from "./plt/components-requests";
import { LicencingScreen } from "./plt/licencing";
import { MessageCredits } from "./plt/message-credits";
import { UsersRoutes } from "./plt/users";
import { TenantUsersAuth } from "./plt/users-auth";
import { InvoicesScreen } from "./sales/invoices";
import { PaymentMethodsScreen } from "./sales/payment-methods";
import { StripeConfigScreen } from "./sales/stripe-config";
import { SubscriptionsRouter } from "./sales/subscriptions";
import { TtmForms } from "./ttm/forms/TtmForms";
import { TtmReports } from "./ttm/reporting/TtmReports";
import { TtmTenantConfiguration } from "./ttm/tenant-configuration";

interface Props {
  tenantId: string;
  section?: string;
  onSectionChange: (sectionId: string) => void;
}

export const TenantSections: FunctionComponent<Props> = ({
  onSectionChange
}) => {
  const params = useParams();
  const sectionId = params["*"];

  const tenantQuery = useTenantQuery(params.tenantId!);

  return (
    <QueryStateIndicator {...tenantQuery}>
      {tenant => {
        const routes = [
          // *****************************************
          // Platform
          {
            path: TenantsTabs.Platform.Details.id,
            element: <TenantDetails tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.Tags.id,
            element: <TenantTags tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.Features.id,
            element: <TenantFeatures tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.Components.id,
            element: <ComponentsScreen tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.ComponentsRequests.id,
            element: <ComponentsActionsRequestsScreen tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.Licencing.id,
            element: <LicencingScreen tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.UsersAuth.id,
            element: <TenantUsersAuth tenant={tenant} />
          },
          {
            path: `${TenantsTabs.Platform.Users.id}/*`,
            element: <UsersRoutes tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.MessageCredits.id,
            element: <MessageCredits tenant={tenant} />
          },
          {
            path: TenantsTabs.Platform.OutboundSmsAudit.id,
            element: <OutboundSmsAuditScreen tenant={tenant} />
          },
          // *****************************************
          // Integrators
          {
            path: TenantsTabs.Integrators.Applications.id,
            element: <IntegApplications tenant={tenant} />
          },
          {
            path: TenantsTabs.Integrators.Certificates.id,
            element: <IntegCertificates tenant={tenant} />
          },
          {
            path: TenantsTabs.Integrators.Consents.id,
            element: <IntegConsents tenant={tenant} />
          },
          {
            path: TenantsTabs.Integrators.Callbacks.id,
            element: <IntegCallbacks tenant={tenant} />
          },
          // *****************************************
          // Operations
          {
            path: TenantsTabs.Operations.Logs.id,
            element: <Logs tenant={tenant} />,
            hasPermission:
              !!tenant.application &&
              tenant.application === ApplicationType.PROS
          },
          // *****************************************
          // Premier Online
          {
            path: TenantsTabs.PrOS.Dashboard.id,
            element: <SiteDashboard tenant={tenant} />
          },
          {
            path: TenantsTabs.PrOS.ManagerServerReports.id,
            element: (
              <Reports
                tenant={tenant}
                component={SiteComponentType.SiteManager}
                type={ReportsType.server}
              />
            )
          },
          {
            path: TenantsTabs.PrOS.ManagerSqlReports.id,
            element: (
              <Reports
                tenant={tenant}
                component={SiteComponentType.SiteManager}
                type={ReportsType.sql}
              />
            )
          },
          {
            path: TenantsTabs.PrOS.AgentServerReports.id,
            element: (
              <Reports
                tenant={tenant}
                component={SiteComponentType.SiteAgent}
                type={ReportsType.server}
              />
            )
          },
          {
            path: TenantsTabs.PrOS.DataSync.id,
            element: <DataSync tenant={tenant} />
          },
          // *****************************************
          // Best Health
          {
            path: TenantsTabs.BestHealth.BookingStats.id,
            element: <BestHealthAppointmentStats tenant={tenant} />
          },
          {
            path: TenantsTabs.BestHealth.BookingProviders.id,
            element: <BestHealthProviders tenant={tenant} />
          },
          {
            path: TenantsTabs.BestHealth.BookingLocations.id,
            element: <BestHealthLocations tenant={tenant} />
          },
          {
            path: TenantsTabs.BestHealth.BookingSyncState.id,
            element: <BestHealthSyncState tenant={tenant} />
          },
          {
            path: TenantsTabs.BestHealth.BookingOperations.id,
            element: <BestHealthOperations tenant={tenant} />
          },
          // *****************************************
          // BP Premier
          {
            path: TenantsTabs.Premier.PremSettings.id,
            element: <PremSettingsPage tenant={tenant} />
          },
          // *****************************************
          // OMNI
          {
            path: TenantsTabs.Ttm.TenantConfiguration.id,
            element: <TtmTenantConfiguration tenant={tenant} />
          },
          {
            path: TenantsTabs.Ttm.AppointmentReminders.id,
            element: <TtmAppointmentReminders tenant={tenant} />
          },
          {
            path: TenantsTabs.Ttm.Reports.id,
            element: <TtmReports tenant={tenant} />
          },
          {
            path: TenantsTabs.Ttm.Forms.id,
            element: <TtmForms tenant={tenant} />
          },
          {
            path: TenantsTabs.Ttm.BestHealth.id,
            element: <TtmBestHealth tenant={tenant} />
          },
          {
            path: TenantsTabs.Ttm.Reports.id,
            element: <TtmReports tenant={tenant} />
          },
          // *****************************************
          // Sales
          {
            path: TenantsTabs.Sales.Stripe.id,
            element: <StripeConfigScreen tenant={tenant} />,
            hasPermission: !!tenant.crmId
          },
          {
            path: TenantsTabs.Sales.PaymentMethods.id,
            element: <PaymentMethodsScreen tenant={tenant} />,
            hasPermission: !!tenant.stripeCustomerId
          },
          {
            path: TenantsTabs.Sales.Invoices.id,
            element: <InvoicesScreen tenant={tenant} />,
            hasPermission: !!tenant.stripeCustomerId
          },
          {
            path: `${TenantsTabs.Sales.Subscriptions.id}/*`,
            element: <SubscriptionsRouter tenant={tenant} />,
            hasPermission: !!tenant.stripeCustomerId
          },
          // *****************************************
          // Agreements
          {
            path: TenantsTabs.Agreements.ServiceAgreements.id,
            element: <ServiceAgreementsScreen />
          }
        ].filter(
          r => r.hasPermission === undefined || r.hasPermission === true
        );

        const items: ExpandableNavItem[] = [
          {
            name: "Platform",
            links: Object.values(TenantsTabs.Platform).filter(t => {
              // Filter the menu items returned
              // Only show the Licences menu for Non CAM Tenants
              const licencesId = TenantsTabs.Platform.Licencing.id;
              if (t.id === licencesId) {
                return tenant.application !== ApplicationType.CAM;
              }
              return true;
            })
          },
          {
            name: "Integrators",
            links: Object.values(TenantsTabs.Integrators).filter(x =>
              x.applicationRestriction
                ? tenant.application === x.applicationRestriction
                : true
            )
          },
          {
            name: "Operations",
            links: Object.values(TenantsTabs.Operations),
            hasPermission: tenant.application === ApplicationType.PROS
          },
          {
            name: "Sales",
            links: Object.values(TenantsTabs.Sales).filter(t => {
              const paymentsId = TenantsTabs.Sales.PaymentMethods.id;
              const invoicesId = TenantsTabs.Sales.Invoices.id;
              const subscriptionsId = TenantsTabs.Sales.Subscriptions.id;
              // hidden for child tenants
              return !(
                (t.id === paymentsId ||
                  t.id === invoicesId ||
                  t.id === subscriptionsId) &&
                !tenant.stripeCustomerId
              );
            }),
            hasPermission: !!tenant.crmId
          },
          {
            name: "Agreements",
            links: Object.values(TenantsTabs.Agreements)
          },
          {
            name: "Premier Online",
            links: Object.values(TenantsTabs.PrOS),
            hasPermission: tenant.application === ApplicationType.PROS
          },
          {
            name: "Best Health",
            links: Object.values(TenantsTabs.BestHealth),
            hasPermission: tenant.application === ApplicationType.PROS
          },
          {
            name: "BP Premier",
            links: Object.values(TenantsTabs.Premier),
            hasPermission: tenant.application === ApplicationType.PROS
          },
          {
            name: "Omni",
            links: Object.values(TenantsTabs.Ttm),
            hasPermission: tenant.application === ApplicationType.OMNI
          }
        ].filter(
          menu =>
            !(menu.name === "Agreements" && !tenant.stripeCustomerId) &&
            menu.hasPermission !== false
        );

        return (
          <SideNavLayout
            nav={
              <ExpandableNav
                selectedKey={sectionId}
                onSectionChange={onSectionChange}
                items={items}
              />
            }
          >
            <Routes>
              {routes.map(r => (
                <Route key={r.path} path={r.path} element={r.element} />
              ))}
            </Routes>
          </SideNavLayout>
        );
      }}
    </QueryStateIndicator>
  );
};
