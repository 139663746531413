import React from "react";

import {
  Dialog,
  IDialogProps,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { useBhaProfile } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";

interface BhaDevicesDialogProps extends IDialogProps {
  profileId: string;
}

export const BhaDevicesDialog: React.FC<BhaDevicesDialogProps> = ({
  profileId,
  ...props
}) => {
  const theme = useTheme();
  const { data: profile, isFetching } = useBhaProfile(profileId);

  return (
    <Dialog
      {...props}
      dialogContentProps={{
        title: "Devices",
        showCloseButton: true
      }}
    >
      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        {isFetching && <Spinner />}
        {!isFetching && profile?.devices?.length ? (
          profile?.devices.map(device => (
            <Stack
              key={device.deviceId}
              tokens={{ childrenGap: theme.spacing.s2 }}
              styles={{
                root: {
                  "&:not(:last-child)": {
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.semanticColors.variantBorder,
                    paddingBottom: theme.spacing.s1
                  }
                }
              }}
            >
              <Stack>
                <Text variant="small" styles={{ root: { fontWeight: "bold" } }}>
                  Account Id:
                </Text>
                <Text variant="xSmall">{device.accountId}</Text>
              </Stack>
              <Stack>
                <Text variant="small" styles={{ root: { fontWeight: "bold" } }}>
                  Device Id:
                </Text>
                <Text>{device.deviceId}</Text>
              </Stack>
              <Stack>
                <Text variant="small" styles={{ root: { fontWeight: "bold" } }}>
                  Last Seen Time:
                </Text>
                <Text variant="xSmall">
                  {device.lastSeenDateTimeUtc?.toISO()}
                </Text>
              </Stack>
            </Stack>
          ))
        ) : (
          <Text>No Devices Found</Text>
        )}
      </Stack>
    </Dialog>
  );
};
