import React, { FunctionComponent } from "react";
import {
  FilterBar,
  NoDataTile,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";

interface PermissionsAndRolesItemProps {
  items: string[];
}
export const PermissionsAndRolesItem: FunctionComponent<PermissionsAndRolesItemProps> = ({
  items
}) => {
  const theme = useTheme();

  const getFilteredItems = (search: string) => {
    if (!search) return items;
    return items.filter(i => i.toLowerCase().includes(search.toLowerCase()));
  };

  if (!items.length) {
    return (
      <NoDataTile
        showBoxShadow={false}
        textProps={{ text: "No data!" }}
        linkProps={{ hidden: true }}
      />
    );
  }

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <FilterBar<{ search: string }>
        initialValues={{ search: "" }}
        items={[
          {
            type: "searchBox",
            stickItem: true,
            name: "search",
            props: {
              placeholder: "Search by name",
              removeSpecialCharacters: false
            }
          }
        ]}
      >
        {state => (
          <Stack
            horizontal
            disableShrink
            wrap
            tokens={{ childrenGap: theme.spacing.s1 }}
          >
            {getFilteredItems(state.values.search).map(permission => (
              <TextBadge
                key={permission}
                badgeColor={TextBadgeColor.lightGrey}
                badgeSize={TextBadgeSize.small}
                styles={{ root: { display: "inline-block" } }}
              >
                {permission}
              </TextBadge>
            ))}
          </Stack>
        )}
      </FilterBar>
    </Stack>
  );
};
