import React, { useMemo } from "react";

import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

interface InstallStatusProps {
  status?: string;
}

export const InstallStatus: React.FC<InstallStatusProps> = ({ status }) => {
  const badgeType = useMemo((): TextBadgeColor => {
    let type = TextBadgeColor.grey;
    switch (status) {
      case undefined:
        type = TextBadgeColor.red;
        break;
      case "OK":
        type = TextBadgeColor.green;
        break;
    }
    return type;
  }, [status]);

  return (
    <TextBadge badgeSize={TextBadgeSize.small} badgeColor={badgeType}>
      {status ?? "Unknown"}
    </TextBadge>
  );
};
