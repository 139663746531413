import { SelectionMode, ShimmeredDetailsListProps } from "@bps/fluent-ui";

import { SelectorsTable } from "./SelectorsTable";

interface SelectorsProps extends ShimmeredDetailsListProps {}

export const Selectors = ({ ...props }: SelectorsProps) => {
  return (
    <SelectorsTable
      selectionPreservedOnEmptyClick
      selectionMode={SelectionMode.none}
      setKey="selector-package-table"
      {...props}
    />
  );
};
