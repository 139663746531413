import {
  AddRedirectLinkCreatePayload,
  RedirectLinkDto
} from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.dtos";

export type LinkId = string | null | undefined;

export interface AddRedirectLinkDialogProps {
  setLinkId: (code: LinkId) => void;
  linkId: LinkId;
}

export interface AddRedirectLinkFormProps {
  redirectLink: RedirectLinkDto | undefined;
  onDismiss: () => void;
}

export interface RedirectLinkFormValues
  extends Omit<AddRedirectLinkCreatePayload, "notAfterUtc" | "notBeforeUtc"> {
  notBeforeUtc: Date | undefined;
  notAfterUtc: Date | undefined;
}

export const ADD_LINK_TITLE = "Add redirect link";
export const EDIT_LINK_TITLE = "Edit redirect link";
