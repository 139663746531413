import { ComponentType, ExoticComponent, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Spinner } from "@bps/fluent-ui";

interface ModuleRoute {
  path: string;
  resolveComponent: () => Promise<{ default: ComponentType }>;
}

const modules: ModuleRoute[] = [
  {
    path: "/tenants/*",
    resolveComponent: () => import("modules/tenants")
  },
  {
    path: "/users/*",
    resolveComponent: () => import("modules/users")
  },
  {
    path: "/system/*",
    resolveComponent: () => import("modules/system")
  },
  {
    path: "/field-mgmt/*",
    resolveComponent: () => import("modules/field-mgmt")
  },
  {
    path: "/cam/*",
    resolveComponent: () => import("modules/cam")
  }
];

interface LazyModuleProps {
  component: ExoticComponent;
}

const LazyModule = ({ component: Component }: LazyModuleProps) => {
  return <Component />;
};

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner>Loading, please wait...</Spinner>}>
      <Routes>
        {modules.map(({ path, resolveComponent }) => (
          <Route
            key={path}
            path={path}
            element={<LazyModule component={lazy(resolveComponent)} />}
          />
        ))}

        <Route path="/" element={<Navigate to="/tenants" replace={true} />} />
      </Routes>
    </Suspense>
  );
};
