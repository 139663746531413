import { useState } from "react";

import { FontIcon, Stack, Text, useTheme } from "@bps/fluent-ui";

export interface TreeItem {
  id: string;
  name: string;
  children?: TreeItem[];
}

interface TreeItemProps {
  item: TreeItem;
  level: number;
}

const TreeItemComponent: React.FC<TreeItemProps> = ({ item, level }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  const getIconName = () => {
    if (!item.children?.length) return "CalculatorSubtract";
    return expanded ? "ChevronDown" : "ChevronRight";
  };
  return (
    <Stack
      styles={{
        root: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.palette.neutralDark
        }
      }}
    >
      <Stack
        styles={{
          root: {
            padding: theme.spacing.s1,
            "&:hover": {
              backgroundColor: theme.palette.neutralQuaternaryAlt,
              cursor: "pointer"
            }
          }
        }}
        onClick={() => item.children?.length && setExpanded(!expanded)}
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <FontIcon iconName={getIconName()} />
        <Stack>
          {item.name} ({item.children?.length || "0"})
        </Stack>
      </Stack>
      {item.children?.length ? (
        <Stack
          styles={{
            root: {
              padding: theme.spacing.s1,
              display: expanded ? "block" : "none"
            }
          }}
        >
          <Text
            styles={{
              root: {
                fontWeight: "bold"
              }
            }}
          >
            Dependencies:
          </Text>
          <Stack
            styles={{
              root: {
                paddingTop: theme.spacing.s2
              }
            }}
            tokens={{ childrenGap: theme.spacing.s1 }}
          >
            {item.children.map(item => (
              <TreeItemComponent
                key={`${item.id}_${item.name}_${level}`}
                item={item}
                level={level + 1}
              />
            ))}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export const Tree: React.FC<{ items: any }> = ({ items }) => {
  const theme = useTheme();
  const LEVEL = 1;
  return (
    <Stack
      tokens={{
        childrenGap: theme.spacing.s1
      }}
      styles={{
        root: {
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: theme.palette.neutralDark
        }
      }}
    >
      {items.map(item => (
        <TreeItemComponent
          key={`${item.id}_${item.key}_${LEVEL}`}
          item={item}
          level={LEVEL + 1}
        />
      ))}
    </Stack>
  );
};
