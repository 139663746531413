import { Permissions } from "@libs/permissions/permissions.enum";

import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IconButton } from "@bps/fluent-ui";

import { EditCardDetailsDialog } from "./EditCardDetailsDialog";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { PaymentMethodDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useDeleteTenantPaymentMethods,
  useTenantPaymentMethodsAsDefault
} from "@libs/api/gateways/plt/plt-gateway.hooks";

interface PaymentCardActionsProps {
  card: PaymentMethodDto;
}

enum CardActions {
  remove = "remove",
  edit = "edit",
  setDefault = "set-default"
}

export const PaymentCardActions: FunctionComponent<PaymentCardActionsProps> = ({
  card
}) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tenantId) {
      navigate(-1);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const [hidden, setHidden] = useState<boolean>(true);
  const { mutateAsync: deleteCard } = useDeleteTenantPaymentMethods(tenantId!);

  const { mutateAsync: setDefaultCard } = useTenantPaymentMethodsAsDefault(
    tenantId!
  );

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltSalesWrite
  );

  return (
    <>
      <IconButton
        styles={{ root: { justifySelf: "end" } }}
        menuIconProps={{ iconName: "more" }}
        menuProps={{
          items: [
            {
              key: CardActions.edit,
              text: "Edit",
              disabled: !hasWritePermission,
              onClick: () => setHidden(false)
            },

            {
              key: CardActions.remove,
              text: "Remove",
              disabled: !hasWritePermission,
              onClick: () => deleteCard(card.id)
            },
            {
              key: CardActions.setDefault,
              text: "Set as default",
              disabled: !hasWritePermission,
              onClick: () => setDefaultCard(card.id)
            }
          ].filter(
            // hide Set as Default option if a card is default one already and hide Edit if not type "card"
            i =>
              !(i.key === CardActions.setDefault && card.isDefault) &&
              !(i.key === CardActions.edit && card.type !== "card")
          )
        }}
      />
      {!hidden && (
        <EditCardDetailsDialog onDismiss={() => setHidden(true)} data={card} />
      )}
    </>
  );
};
