import { useState } from "react";

import { PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useApplicationsQuery } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { Application } from "./Application";
import { CreateApplicationDialog } from "./CreateApplicationDialog";

interface IntegApplicationProps {
  tenant: Tenant;
}

const IntegApplicationsBase: React.FC<IntegApplicationProps> = ({ tenant }) => {
  const theme = useTheme();

  const applicationsQuery = useApplicationsQuery(tenant.id);
  const [
    showCreateApplicationDialog,
    setshowCreateApplicationDialog
  ] = useState<boolean>(false);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegAppWrite
  );

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Applications</SectionTitle>
        <PrimaryButton
          disabled={!hasWritePermission}
          onClick={() => setshowCreateApplicationDialog(true)}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        >
          Create Application
        </PrimaryButton>
        {showCreateApplicationDialog && (
          <CreateApplicationDialog
            tenant={tenant}
            onDismiss={() => {
              setshowCreateApplicationDialog(false);
              applicationsQuery.refetch();
            }}
          />
        )}
      </Stack>
      <Stack tokens={{ childrenGap: 16 }}>
        <QueryStateIndicator
          {...applicationsQuery}
          isLoading={applicationsQuery.isLoading}
          error={applicationsQuery.error}
        >
          {data =>
            data.length
              ? data?.map(i => (
                  <Application
                    key={i.id}
                    item={i}
                    tenant={tenant}
                    onApplicationUpdate={() => applicationsQuery.refetch()}
                  />
                ))
              : "There are no existing applications."
          }
        </QueryStateIndicator>
      </Stack>
    </Stack>
  );
};

export const IntegApplications = withPermissions(
  IntegApplicationsBase,
  [Permissions.PltIntegAppRead, Permissions.PltIntegAppWrite],
  "or"
);
