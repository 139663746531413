import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  useTenantDefaultUserAuth,
  useTenantQuery,
  useUser,
  useUserDefaultAuth
} from "@libs/api/gateways/plt/plt-gateway.hooks";

import { UserScreenWrapper } from "./components/UserScreenWrapper";

export const UserScreen: FunctionComponent = () => {
  const { tenantId, userId } = useParams();
  const theme = useTheme();
  const userQuery = useUser(tenantId!, userId!);
  const tenantAuthQuery = useTenantDefaultUserAuth(tenantId!);
  const userAuthQuery = useUserDefaultAuth(tenantId!, userId!);
  const tenantQuery = useTenantQuery(tenantId!);
  return (
    <QueryStateIndicator
      data={{
        user: userQuery.data,
        tenantAuth: tenantAuthQuery.data,
        userAuth: userAuthQuery.data,
        tenant: tenantQuery.data
      }}
      isLoading={
        userQuery?.isLoading ||
        tenantAuthQuery?.isLoading ||
        userAuthQuery.isLoading ||
        tenantQuery.isLoading
      }
      error={
        userQuery?.error ??
        tenantAuthQuery?.error ??
        userAuthQuery?.error ??
        tenantQuery?.error
      }
    >
      {({ user, tenantAuth, userAuth, tenant }) => (
        <Stack
          verticalFill
          styles={{
            root: {
              padding: theme.spacing.s1,
              overflowY: "auto",
              overflowX: "hidden"
            }
          }}
          tokens={{ childrenGap: theme.spacing.m }}
        >
          {user && (
            <UserScreenWrapper
              user={user}
              tenantAuth={tenantAuth}
              userAuth={userAuth}
              application={tenant?.application}
            />
          )}
        </Stack>
      )}
    </QueryStateIndicator>
  );
};
