import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";
import { FunctionComponent } from "react";
import { InvoiceStatus } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface InvoiceStatusBadgeProps {
  status?: InvoiceStatus;
}
export const InvoiceStatusBadge: FunctionComponent<InvoiceStatusBadgeProps> = ({
  status
}) => {
  const getColor = () => {
    switch (status) {
      case InvoiceStatus.Paid:
        return TextBadgeColor.green;
      case InvoiceStatus.Void:
      case InvoiceStatus.Uncollectible:
        return TextBadgeColor.red;
      default:
        return TextBadgeColor.yellow;
    }
  };
  return (
    <TextBadge
      className="cam-InvoiceStatusBadge"
      badgeSize={TextBadgeSize.small}
      badgeColor={getColor()}
      styles={{ root: { textTransform: "capitalize", maxWidth: 100 } }}
    >
      {status}
    </TextBadge>
  );
};
