import { Tabs } from "@components/tabs-nav/TabNav.types";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const TenantsTabs: Tabs = {
  Platform: {
    Details: { id: "plt-details", name: "Tenant Details" },
    Tags: { id: "plt-tags", name: "Tenant Tags" },
    Features: { id: "plt-features", name: "Features" },
    Licencing: { id: "plt-licencing", name: "Licencing" },
    UsersAuth: { id: "plt-users-auth", name: "Users Login Settings" },
    Users: { id: "plt-users", name: "Users" },
    MessageCredits: { id: "plt-comms-messagecredits", name: "Comms Credits" },
    OutboundSmsAudit: {
      id: "plt-comms-outboundsmsaudit",
      name: "Comms Outbound SMS"
    },
    Components: { id: "plt-components", name: "Components" },
    ComponentsRequests: {
      id: "plt-components-requests",
      name: "Components requests"
    }
  },

  Integrators: {
    Applications: {
      id: "integ-applications",
      name: "Applications",
      applicationRestriction: ApplicationType.CAM
    },
    Certificates: {
      id: "integ-certificates",
      name: "Certificates",
      applicationRestriction: ApplicationType.CAM
    },
    Consents: {
      id: "integ-consents",
      name: "Consents",
      applicationRestriction: ""
    },
    Callbacks: {
      id: "integ-callbacks",
      name: "Callbacks",
      applicationRestriction: ""
    }
  },

  Sales: {
    Stripe: { id: "sales-stripe", name: "Stripe" },
    PaymentMethods: { id: "sales-payment-methods", name: "Payment Methods" },
    Invoices: { id: "sales-invoices", name: "Invoices" },
    Subscriptions: { id: "sales-subscriptions", name: "Subscriptions" }
  },

  Operations: {
    Logs: {
      id: "ops-logs",
      name: "Logs",
      applicationRestriction: ApplicationType.PROS
    }
  },

  Agreements: {
    ServiceAgreements: {
      id: "agreements-service-agreements",
      name: "Service Agreements"
    }
  },

  PrOS: {
    Dashboard: { id: "pros-dashboard", name: "Dashboard" },
    ManagerServerReports: {
      id: "pros-manager-server-reports",
      name: "Server Reports"
    },
    ManagerSqlReports: { id: "pros-manager-sql-reports", name: "SQL Reports" },
    AgentServerReports: {
      id: "pros-agent-server-reports",
      name: "Agent Reports"
    },
    DataSync: { id: "pros-data-sync", name: "Data Sync" }
  },

  BestHealth: {
    BookingStats: { id: "bh-booking-stats", name: "Booking Stats" },
    BookingProviders: { id: "bh-booking-providers", name: "Booking Providers" },
    BookingLocations: { id: "bh-booking-locations", name: "Booking Locations" },
    BookingSyncState: {
      id: "bh-booking-sync-state",
      name: "Booking Sync State"
    },
    BookingOperations: {
      id: "bh-booking-operations",
      name: "Booking Operations"
    }
  },
  Premier: {
    PremSettings: { id: "prem-settings", name: "Settings" }
  },

  Ttm: {
    TenantConfiguration: {
      id: "ttm-tenant-config",
      name: "Tenant Configuration"
    },
    AppointmentReminders: {
      id: "ttm-appointment-reminders",
      name: "Appointment Reminders"
    },
    BestHealth: { id: "ttm-besthealth", name: "Best Health Booking" },
    Reports: {
      id: "ttm-reports",
      name: "Reports"
    },
    Forms: {
      id: "ttm-forms",
      name: "Forms"
    }
  }
};
