import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { FunctionComponent, useState } from "react";

import {
  IconButton,
  LabeledText,
  LabeledTextGroup,
  Stack,
  Text,
  Tile
} from "@bps/fluent-ui";

import { CallbackTypeDialog } from "./CallbackTypesFormDialog";
import { CallbackTypesDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";

interface CallbackTypesProps {
  item: CallbackTypesDto;
  shouldDeleteItem?: boolean;
  onCallbackTypeUpdate: () => void;
}

export const CallbackTypes: FunctionComponent<CallbackTypesProps> = ({
  item,
  shouldDeleteItem,
  onCallbackTypeUpdate
}) => {
  const [showCallbackTypeDialog, setCallbackTypeDialog] = useState<boolean>(
    false
  );

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegAppWrite
  );

  return (
    <Tile>
      <Text block styles={{ root: { marginBottom: 16, fontWeight: "bold" } }}>
        {item.description}
      </Text>
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal tokens={{ childrenGap: 120 }}>
          <LabeledTextGroup>
            <LabeledText label="Code">{item.code}</LabeledText>
          </LabeledTextGroup>
          <LabeledTextGroup
            styles={{
              root: {
                alignItems: "start"
              }
            }}
          >
            {item.ipRanges?.map((value, index) => (
              <LabeledText
                label={`Ip Range ${index + 1}`}
                key={value.ipRangeBegin}
              >
                {value.ipRangeBegin} to {value.ipRangeEnd}
              </LabeledText>
            ))}
          </LabeledTextGroup>
        </Stack>
        <IconButton
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          iconProps={{ iconName: !shouldDeleteItem ? "Edit" : "Delete" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => setCallbackTypeDialog(true)}
        />
        {showCallbackTypeDialog && (
          <CallbackTypeDialog
            callbackType={item}
            onDismiss={() => {
              setCallbackTypeDialog(false);
              onCallbackTypeUpdate();
            }}
          />
        )}
      </Stack>
    </Tile>
  );
};
