import { FunctionComponent } from "react";
import { FilterBar, FilterBarProps, Item } from "@bps/fluent-ui";

interface CustomersFilterProps
  extends Pick<FilterBarProps<CustomersFilterValues>, "children"> {}
interface CustomersFilterValues {
  crmId: string;
}
export const CustomersFilter: FunctionComponent<CustomersFilterProps> = ({
  children
}) => {
  const items: Item<CustomersFilterValues>[] = [
    {
      name: "crmId",
      type: "searchBox",
      stickItem: true,
      props: {
        placeholder: "Search by customer ID",
        removeSpecialCharacters: false
      }
    }
  ];
  return (
    <FilterBar<CustomersFilterValues>
      initialValues={{ crmId: "" }}
      items={items}
      children={children}
    />
  );
};
