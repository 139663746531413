import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";

import { FieldDeviceFilterArgs } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface FieldDevicesFilterValues {
  searchTerm: string;
}

interface FieldDevicesFilterProps extends PropsWithChildren<any> {
  setFilterArgs: React.Dispatch<
    React.SetStateAction<FieldDeviceFilterArgs | undefined>
  >;
}

export const FieldDevicesFilter = ({
  children,
  setFilterArgs
}: FieldDevicesFilterProps) => {
  // Filter Options
  const filterOptions: Item<FieldDevicesFilterValues>[] = [
    {
      type: "searchBox",
      name: "searchTerm",
      stickItem: true,
      props: {
        placeholder: "Filter by keyword",
        removeSpecialCharacters: false,
        styles: { root: { width: 500 } }
      }
    }
  ];

  return (
    <FilterBar<FieldDevicesFilterValues>
      items={filterOptions}
      initialValues={{
        searchTerm: ""
      }}
      onChange={filter => {
        if (
          filter.values.searchTerm.length >= 4 ||
          filter.values.searchTerm.length === 0
        ) {
          setFilterArgs({ ...filter.values });
        }
      }}
    >
      {children}
    </FilterBar>
  );
};
