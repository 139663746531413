import { SectionTitle } from "@components/SectionTitle";
import React from "react";

import { PropertiesFilter, PropertiesFilterValues } from "./PropertiesFilter";
import { PropertiesTable } from "./PropertiesTable";
import { useFieldDeviceProperties } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import {
  FieldDevice,
  FieldDeviceProperty
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface FieldDevicePropertiesProps {
  device: FieldDevice;
}

export const FieldDeviceProperties: React.FC<FieldDevicePropertiesProps> = ({
  device
}) => {
  const propertiesQuery = useFieldDeviceProperties(device.id);
  const { data, isLoading, error } = propertiesQuery;
  const errorMessage = error?.message;

  return (
    <>
      <SectionTitle>Device Properties</SectionTitle>

      <PropertiesFilter>
        {({ values }) => {
          return (
            <PropertiesTable
              items={filterAndSortProperties(data, values)}
              enableShimmer={isLoading}
              errorMessage={errorMessage}
            />
          );
        }}
      </PropertiesFilter>
    </>
  );
};

const filterAndSortProperties = (
  properties: FieldDeviceProperty[] | undefined,
  filter: PropertiesFilterValues
): FieldDeviceProperty[] => {
  if (!properties) return [];

  return properties
    .filter(
      d =>
        filter.name === "" ||
        d.propertyType.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0 ||
        (d.propertyValue &&
          d.propertyValue.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0)
    )
    .sort((a: FieldDeviceProperty, b: FieldDeviceProperty) => {
      return a.propertyType < b.propertyType ? 1 : -1;
    });
};
