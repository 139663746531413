import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useState } from "react";

import { Stack, Text, Toggle } from "@bps/fluent-ui";
import { usePutPracticeOutageQuery } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface PracticeOutageToggleProps {
  tenant: Tenant;
  practiceOutage?: boolean;
}

export const PracticeOutageToggle = ({
  tenant,
  practiceOutage
}: PracticeOutageToggleProps) => {
  const putPracticeOutageMutation = usePutPracticeOutageQuery(tenant.id);
  const [state, setState] = useState(
    practiceOutage === undefined ? false : practiceOutage
  );

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.BhbPracticeWrite
  );

  const onSubmit = async (practiceOutage: boolean | undefined) => {
    if (practiceOutage === undefined) {
      return null;
    }
    setState(practiceOutage);
    await putPracticeOutageMutation.mutateAsync({
      practiceOutage
    });
  };

  return (
    <>
      <Stack>
        <Text variant="large">Practice Outage</Text>
        <Text variant="small">
          Turning ON practice outage will prevent patients from booking via BHB.
          They will be presented with a "Practice is Offline" page if they
          attempt to book.
        </Text>
      </Stack>
      <Toggle
        checked={state}
        onChange={(_, checked) => {
          onSubmit(checked);
        }}
        automationAttribute="practice-outage-toggle"
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      />
    </>
  );
};
