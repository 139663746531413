import { Route, Routes } from "react-router-dom";

import { DeployRings } from "./DeployRings";
import { RolloutPackageHost } from "./rollout-package";

export const DeploymentRingsRouter = () => {
  return (
    <Routes>
      <Route
        path=":ringId/rollouts/:rolloutId"
        element={<RolloutPackageHost />}
      />
      <Route path=":ringId" element={<DeployRings />} />
      <Route path="*" element={<DeployRings />} />
    </Routes>
  );
};
