import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { TextField } from "@bps/fluent-ui";

import { ConfigValueInputProps } from "./";

interface StringConfigValueInputProps extends ConfigValueInputProps {}

export const StringConfigValueInput = ({
  value,
  onUpdate,
  rolloutOccurred,
  readonly
}: StringConfigValueInputProps) => {
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <TextField
      value={value}
      onChange={(e, value) => onUpdate(value)}
      disabled={rolloutOccurred || readonly || !hasWritePermission}
      title={
        !hasWritePermission && !readonly ? NO_ACTION_PERMISSION : undefined
      }
    />
  );
};
