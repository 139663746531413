import { FunctionComponent, useMemo } from "react";
import { Text } from "@bps/fluent-ui";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface ComponentTextProps {
  code: string;
}
export const ComponentText: FunctionComponent<ComponentTextProps> = ({
  code
}) => {
  const { data = [] } = useComponentsDefs();
  const text = useMemo(() => data.find(d => d.code === code)?.text, [
    code,
    data
  ]);
  return <Text>{text}</Text>;
};
