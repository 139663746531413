import React, { useCallback, useState } from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

import {
  ChannelPreference,
  DeviceCommand,
  FieldDevice,
  FieldDeviceComponentConfig,
  FieldDeviceReportedConfig,
  SoftwarePackageType
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useDispatchDeviceCommand,
  useSoftwarePackageCommandJson
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SendCommandDialog } from "./SendCommandDialog";
import { SoftwarePackageCommandsBody } from "./SoftwarePackageCommandsBody";
import {
  SelectedVersionState,
  SoftwarePackageHeader
} from "./SoftwarePackageHeader";

interface SoftwarePackageCommandsProps {
  reportedConfig: FieldDeviceReportedConfig | undefined;
  desiredConfig: FieldDeviceComponentConfig | undefined;
  device?: FieldDevice;
}

const MESSAGE_TYPE_TEMPLATE =
  "Bps.Platform.FieldManagement.OnSite.FieldController.Models.{type}, Bps.Platform.FieldManagement.OnSite.FieldController, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null";

export const SoftwarePackageCommands: React.FC<SoftwarePackageCommandsProps> = ({
  desiredConfig,
  reportedConfig,
  device
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { softwarePackageCode } = useParams();

  // State
  const [dispatchFormShown, setDispatchFormShown] = useState(false);
  const [formData, setFormData] = useState<DeviceCommand>();
  const [selectedVersion, setSelectedVersion] = useState<SelectedVersionState>(
    reportedConfig !== undefined
      ? SelectedVersionState.ReportedConfig
      : SelectedVersionState.DesiredConfig
  );

  // Queries
  const { data: commandJson } = useSoftwarePackageCommandJson(
    selectedVersion === SelectedVersionState.ReportedConfig
      ? reportedConfig?.softwarePackageVersionId
      : desiredConfig?.id
  );

  const {
    mutateAsync: deviceCommandDispatch,
    data: dispatchResponse
  } = useDispatchDeviceCommand();

  const packageType =
    desiredConfig?.type === SoftwarePackageType.Service
      ? "services"
      : "modules";

  const match = matchPath(
    `/field-mgmt/:fieldDeviceId/${packageType}/:softwarePackageCode/:command`,
    location.pathname
  );

  const commandArg = match?.params?.command;
  const getMessageTypeString = () => {
    const command = commandJson?.find(command => command.title === commandArg);

    return (
      command?.["x-messageType"] ??
      MESSAGE_TYPE_TEMPLATE.replace("{type}", commandArg!)
    );
  };

  const onCommandChange = useCallback(
    (command: string) => {
      navigate(
        `/field-mgmt/${device?.id}/${packageType}/${softwarePackageCode}/${command}`
      );
    },
    [navigate, device?.id, packageType, softwarePackageCode]
  );

  const onSubmit = async ({ formData }: any) => {
    // We explicitly have any type as form data type changes with command type.
    const command = {
      fieldDeviceId: device?.id!,
      channelPreference: ChannelPreference.Queued, // Default to queued command
      targetSoftwarePackageCode: softwarePackageCode!,
      routedMessageData: {
        messageType: getMessageTypeString(),
        value: JSON.stringify({ ...formData })
      }
    };

    setFormData(command);
    setDispatchFormShown(true);
  };

  const onDispatch = useCallback(async () => {
    try {
      await deviceCommandDispatch(formData!);
    } catch (error) {
    } finally {
      setDispatchFormShown(false);
    }
  }, [deviceCommandDispatch, formData]);

  return (
    <>
      <SoftwarePackageHeader
        desiredConfig={desiredConfig}
        reportedConfig={reportedConfig}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
      />

      <SoftwarePackageCommandsBody
        commandJson={commandJson}
        commandArg={commandArg}
        handleSubmit={onSubmit}
        handleCommandChange={onCommandChange}
        response={dispatchResponse}
      />

      <SendCommandDialog
        isShown={dispatchFormShown}
        formData={formData}
        onDismiss={() => {
          setDispatchFormShown(false);
        }}
        onDispatch={onDispatch}
        setFormData={setFormData}
      />
    </>
  );
};
