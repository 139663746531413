import React from "react";

import { CopyToClipboardButton, Text } from "@bps/fluent-ui";

import { EMPTY_LABEL } from "../general-utils";
import { GeneralSection } from "../GeneralSection";

interface GeneralAppRegistrationProps {
  appRegistrationId?: string;
}

export const GeneralAppRegistration: React.FC<GeneralAppRegistrationProps> = ({
  appRegistrationId
}) => {
  if (appRegistrationId === undefined) {
    return (
      <GeneralSection heading="App Registration ID">
        {EMPTY_LABEL}
      </GeneralSection>
    );
  }

  return (
    <GeneralSection heading="App Registration ID">
      <CopyToClipboardButton clipboardContent={appRegistrationId} />
      <Text>{appRegistrationId}</Text>
    </GeneralSection>
  );
};
