import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { Stack, Text, useTheme } from "@bps/fluent-ui";

import { Form } from "@components/form/Form";
import { NUMBER_FORMAT_REGEX } from "@libs/validation/validation-rules.constants";
import { BpMobileMaintenanceSettings } from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";

export interface BpMobileMaintenanceFormSubmitValues {
  maintenanceMode: boolean;
  maintenanceModeMessage: string;
  forceUpgradeVersionNumber: string;
  forceUpgradeMessage: string;
}

interface BpMobileMaintenanceFormProps {
  onSubmit: (values: BpMobileMaintenanceFormSubmitValues) => Promise<void>;
  settings: BpMobileMaintenanceSettings;
  isLoading?: boolean;
}

export const BpMobileMaintenanceForm: React.FC<BpMobileMaintenanceFormProps> = ({
  onSubmit,
  settings,
  isLoading
}) => {
  const form = useForm<BpMobileMaintenanceFormSubmitValues>({});

  const defaultValues: BpMobileMaintenanceFormSubmitValues = useMemo(() => {
    return {
      maintenanceMode: settings?.maintenanceMode ?? false,
      maintenanceModeMessage: settings?.maintenanceModeMessage ?? "",
      forceUpgradeVersionNumber: settings?.forceUpgradeVersionNumber ?? "",
      forceUpgradeMessage: settings?.forceUpgradeMessage ?? ""
    };
  }, [settings]);

  const theme = useTheme();

  return (
    <Form<BpMobileMaintenanceFormSubmitValues>
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    >
      <Stack as="form" tokens={{ childrenGap: theme.spacing.s1 }}>
        <Text variant="xLarge">BP Mobile App Settings</Text>

        <FormSwitch
          name="maintenanceMode"
          label="Maintenance Mode"
          disabled={form.formState.isSubmitting || isLoading}
        />

        <FormTextField
          name="maintenanceModeMessage"
          label="Maintenance Mode Message"
          disabled={form.formState.isSubmitting || isLoading}
          multiline
        />

        <FormTextField
          name="forceUpgradeVersionNumber"
          rules={{
            required: "Version number is required",
            pattern: {
              value: NUMBER_FORMAT_REGEX,
              message: "Version must be in the format X.YY (e.g., 1.00)"
            }
          }}
          label="Force Upgrade Version Number"
          disabled={isLoading}
        />

        <FormTextField
          name="forceUpgradeMessage"
          label="Force Upgrade Message"
          disabled={form.formState.isSubmitting || isLoading}
          multiline
        />
      </Stack>
    </Form>
  );
};
