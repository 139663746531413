import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FunctionComponent } from "react";

import {
  Card,
  FontSizes,
  Link,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";

import {
  HostName,
  ProvisioningStatus,
  TenantProvisionSignupUrl
} from "./TenantConfigurationCard.types";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 16
      }
    }
  }
};

const TenantConfigurationCardBase: FunctionComponent<Props> = ({ tenant }) => {
  const theme = useTheme();

  const { data: hasProvisionPermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogTenantProvision
  );

  const { id } = tenant;

  const hostName = window.location.hostname;

  const tenantIdPrepend = `&tid=${id}`;

  const getProvisionTenantUrl = (hostname: string) => {
    if (hostname.startsWith(HostName.Proda)) {
      return TenantProvisionSignupUrl.proda;
    } else if (hostname.startsWith(HostName.Uata)) {
      return TenantProvisionSignupUrl.uata;
    } else {
      return TenantProvisionSignupUrl.deva;
    }
  };

  const url = `${getProvisionTenantUrl(hostName)}${tenantIdPrepend}`;

  const hasResources = (tenant.resources ?? []).length > 0;

  const getProvisioningStatus = (tenant: Tenant) => {
    if (tenant.isInactive && hasResources) return ProvisioningStatus.InProgress;
    else if (tenant.isInactive && !hasResources)
      return ProvisioningStatus.Unprovisioned;
    else return ProvisioningStatus.Provisioned;
  };

  const getProvisioningStatusBadge = (status: ProvisioningStatus) => {
    let colour;

    switch (status) {
      case ProvisioningStatus.InProgress:
        colour = TextBadgeColor.yellow;
        break;
      case ProvisioningStatus.Unprovisioned:
        colour = TextBadgeColor.red;
        break;
      default:
        colour = TextBadgeColor.green;
        break;
    }

    return (
      <TextBadge
        badgeColor={colour}
        styles={{
          root: { width: "fit-content" }
        }}
      >
        {status}{" "}
      </TextBadge>
    );
  };

  const provisioningStatus = getProvisioningStatus(tenant);

  return (
    <Card
      iconName="Settings"
      headingLevel="section-heading"
      heading="Provisioning"
      styles={cardStyles}
    >
      <Stack tokens={{ childrenGap: 16 }}>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <Text bold styles={{ root: { marginTop: 6 } }}>
            Status:
          </Text>
          {getProvisioningStatusBadge(provisioningStatus)}
        </Stack>

        {provisioningStatus === ProvisioningStatus.Unprovisioned && (
          <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
            <TooltipHost
              content={
                !hasProvisionPermission ? NO_ACTION_PERMISSION : undefined
              }
            >
              <Link
                href={url}
                styles={{ root: { width: "fit-content" } }}
                target="_blank"
                disabled={!hasProvisionPermission}
              >
                Provision Tenant
              </Link>
            </TooltipHost>
            {hasProvisionPermission && (
              <Text styles={{ root: { fontSize: FontSizes.size12 } }}>
                {"(Right click > Open link in Incognito/InPrivate window)"}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export const TenantConfigurationCard = withPermissions(
  TenantConfigurationCardBase,
  [Permissions.TiSettingsRead]
);
