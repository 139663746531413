import { ComponentDefDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface ComponentsDefinitionsExpandedRowProps {
  component: ComponentDefDto;
}

export enum ComponentDetailsTabs {
  Dependencies = "dependencies",
  Implementations = "implementations",
  Settings = "settings"
}
