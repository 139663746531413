import React from "react";

import { Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import humanizer from "@libs/common/humanize-duration";

import { EMPTY_LABEL, getRootStyles, humanizeOptions } from "../general-utils";
import { GeneralSection } from "../GeneralSection";

interface GeneralLatestTokenExpiryProps {
  timeUtc?: DateTime;
  heading: string;
  tooltip?: boolean;
  showExpired?: boolean;
}

export const GeneralTimeSection: React.FC<GeneralLatestTokenExpiryProps> = ({
  timeUtc,
  heading,
  tooltip,
  showExpired
}) => {
  const theme = useTheme();
  const now = DateTime.now();

  if (timeUtc === undefined) {
    return <GeneralSection heading={heading}>{EMPTY_LABEL}</GeneralSection>;
  }

  const timePassed = now >= timeUtc;
  const timePassedHumanized = humanizer.humanize(
    timeUtc.diff(now).milliseconds,
    humanizeOptions
  );

  const toolTipContent =
    timePassed === true
      ? `${timePassedHumanized} ago`
      : `In ${timePassedHumanized}`;

  return (
    <GeneralSection
      heading={heading}
      toolTipConent={tooltip === true ? toolTipContent : undefined}
    >
      <Text styles={getRootStyles(theme, timePassed, showExpired)}>
        {timeUtc.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)}
      </Text>
    </GeneralSection>
  );
};
