import React from "react";

import { Shimmer, Stack, Text } from "@bps/fluent-ui";
import { useSiteComponentVersionsQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  ProsTenant,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

interface SiteManagementCellProps {
  tenant: ProsTenant;
}

export const SiteManagementCell: React.FC<SiteManagementCellProps> = ({
  tenant
}) => {
  const { isFetching, data } = useSiteComponentVersionsQuery(tenant.id);
  const versionData = data?.find(
    item => item.componentType === SiteComponentType.SiteManager
  );

  return (
    <Stack>
      <Shimmer isDataLoaded={!isFetching}>
        <Text variant="small">
          Current Version: {versionData?.version ?? "Not installed"}
        </Text>
      </Shimmer>
      <Shimmer isDataLoaded={!isFetching}>
        <Text variant="small">
          Desired Version: {versionData?.desiredVersion ?? "None selected"}
        </Text>
      </Shimmer>
    </Stack>
  );
};
