import {
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";

export interface VirusScanFailedBadgeProps {
  virusScanIssueCount: number;
}

export const VirusScanFailedBadge = ({
  virusScanIssueCount
}: VirusScanFailedBadgeProps) => {
  return (
    <TooltipHost
      content={`VirusScanIssueCount is ${virusScanIssueCount}`}
      styles={{ root: { display: "flex" } }}
    >
      <TextBadge
        badgeColor={TextBadgeColor.red}
        badgeSize={TextBadgeSize.small}
        styles={{ root: { padding: "2px 8px" } }}
      >
        Virus scan failed
      </TextBadge>
    </TooltipHost>
  );
};
