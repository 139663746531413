import React, { useState } from "react";

import { DefaultButton, Stack, Text, useTheme } from "@bps/fluent-ui";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useRollouts } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { NewRolloutDialog } from "./NewRolloutDialog";
import { RolloutListItem } from "./RolloutListItem";

interface RolloutListProps {
  deploymentRingId: string;
}

export const RolloutList: React.FC<RolloutListProps> = ({
  deploymentRingId
}) => {
  const theme = useTheme();
  const [showNew, setShowNew] = useState<boolean>(false);
  const { data: rollouts } = useRollouts(deploymentRingId);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <Stack
      styles={{
        root: { flex: 1 }
      }}
    >
      <NewRolloutDialog
        deploymentRingId={deploymentRingId}
        hidden={!showNew}
        onDismiss={() => setShowNew(false)}
      />
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Text variant="xLarge">Rollouts</Text>
          <Stack.Item align="end">
            <DefaultButton
              disabled={!hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              iconProps={{ iconName: "Add" }}
              onClick={() => setShowNew(true)}
            >
              Add Rollout
            </DefaultButton>
          </Stack.Item>
        </Stack>
        <Stack
          tokens={{ childrenGap: theme.spacing.s2 }}
          styles={{ root: { height: 0 } }}
        >
          {rollouts?.map(rollout => (
            <RolloutListItem key={rollout.id} rollout={rollout} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
