import { FunctionComponent } from "react";
import { FilterBarProps, Item } from "@components/filter-bar/FilterBar.types";
import { FilterBar } from "@components/filter-bar/FilterBar";
import { ComponentsDefinitionsFilterValues } from "./ComponentsDefinitionsFilter.types";

interface ComponentsDefinitionsFilterProps
  extends Pick<FilterBarProps<ComponentsDefinitionsFilterValues>, "children"> {}
export const ComponentsDefinitionsFilter: FunctionComponent<ComponentsDefinitionsFilterProps> = props => {
  const items: Item<ComponentsDefinitionsFilterValues>[] = [
    {
      name: "code",
      stickItem: true,
      type: "searchBox",
      props: {
        placeholder: "Search by code",
        removeSpecialCharacters: false,
        styles: { root: { width: 250 } }
      }
    },
    {
      name: "scope",
      type: "optionsSelect",
      props: {
        options: [
          { key: "PROS", text: "PROS" },
          { key: "TI", text: "TI" },
          { key: "BH", text: "BH" }
        ],
        multiSelect: true,
        hideSearchOption: true,
        placeholder: "Search by code",
        styles: { root: { width: 250 } }
      }
    }
  ];
  return (
    <FilterBar<ComponentsDefinitionsFilterValues>
      items={items}
      initialValues={{ code: "", scope: [] }}
      {...props}
    />
  );
};
