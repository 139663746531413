import { useQueryClient } from "react-query";

import {
  DefaultButton,
  Heading,
  IconButton,
  IModalProps,
  Modal,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  DeviceConfigurationDiff,
  FieldDevice
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  FieldOpsCacheKeys,
  useDeviceConfigurationDiff
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { ConfigDiffView } from "./ConfigDiffView";

interface ConfigDiffModalProps extends IModalProps {
  show: boolean;
  device: FieldDevice;
  onDismiss: () => void;
}

export const ConfigDiffModal: React.FC<ConfigDiffModalProps> = ({
  device,
  show,
  onDismiss,
  ...props
}) => {
  const deviceConfigurationDiffQuery = useDeviceConfigurationDiff(device.id);

  const queryClient = useQueryClient();
  const invalidateQueryCache = async () => {
    await queryClient.invalidateQueries([
      FieldOpsCacheKeys.DeviceConfigurationDiff
    ]);
  };

  const theme = useTheme();
  return (
    <Modal
      isOpen={show}
      {...props}
      styles={{ scrollableContent: { maxHeight: "none" } }}
    >
      <Stack
        verticalFill
        styles={{
          root: { padding: theme.spacing.l1, maxHeight: 800 }
        }}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Heading variant="modal-heading">Configuration Diff</Heading>
          <IconButton onClick={onDismiss} iconProps={{ iconName: "Cancel" }} />
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: theme.spacing.s1 }}
        >
          <Text styles={{ root: { width: "50%" } }}>Desired</Text>
          <Text styles={{ root: { width: "50%" } }}>Reported</Text>
        </Stack>

        <Stack
          verticalFill
          styles={{
            root: { maxHeight: 600, overflow: "auto", maxWidth: 1200 }
          }}
        >
          <QueryStateIndicator<DeviceConfigurationDiff>
            {...deviceConfigurationDiffQuery}
          >
            {diff => <ConfigDiffView configDiff={diff} />}
          </QueryStateIndicator>
        </Stack>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <DefaultButton onClick={invalidateQueryCache}>Reload</DefaultButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
