import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { useMemo } from "react";

import {
  DetailsRow,
  IColumn,
  ShimmeredDetailsListProps,
  Stack,
  Text
} from "@bps/fluent-ui";
import { SelectorDto } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface AssignSelectorTableProps extends ShimmeredDetailsListProps {}

export const AssignSelectorTable = ({ ...props }: AssignSelectorTableProps) => {
  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "name",
        name: "Name",
        minWidth: 150,
        maxWidth: 800,
        isResizable: true,
        onRender: (selector: SelectorDto) => (
          <Stack>
            <Text>{selector.name}</Text>
            <Text variant="small">Description: {selector.description}</Text>
          </Stack>
        )
      }
    ];
  }, []);

  return (
    <ShimmeredDetailsList
      shimmerLines={5}
      columns={columns}
      onShouldVirtualize={() => true}
      onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
      {...props}
    />
  );
};
