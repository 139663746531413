import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { DetailsRowExpand } from "@components/DetailsRowExpand";

import { Stack, useTheme } from "@bps/fluent-ui";

import { PoisonMessageCard } from "./PoisonMessageCard";
import { PoisonMessage } from "@libs/api/gateways/mon/ops-mon-gateway.dtos";

interface Props {
  messages: PoisonMessage[];
}

const getKey = (message: PoisonMessage) => message?.body?.tenantId ?? "";

export const PoisonMessagesTable = ({ messages }: Props) => {
  const theme = useTheme();

  const columns: DataTableColumn<PoisonMessage>[] = [
    {
      key: "appRole",
      name: "Application Role",
      minWidth: 300,
      filter: [],
      onRender: (message: PoisonMessage) =>
        message?.appRole ?? message?.functionType ?? "Unknown Application"
    },
    {
      key: "partitionId",
      name: "Partition ID",
      minWidth: 100,
      onRender: (message: PoisonMessage) => message?.partitionId ?? ""
    },
    {
      key: "enqueuedDate",
      name: "Enqueued",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (message: PoisonMessage) =>
        message?.enqueuedTimeUtc?.toString() ?? ""
    },
    {
      key: "poisonDate",
      name: "Last Processed",
      minWidth: 200,
      isSorted: true,
      sort: true,
      filter: [],
      onRender: (message: PoisonMessage) =>
        message?.poisonedTimeUtc?.toString() ?? ""
    },
    {
      key: "skipMessage",
      name: "Skipped",
      minWidth: 100,
      isSorted: true,
      sort: true,
      filter: [],
      onRender: (message: PoisonMessage) =>
        (message?.skipMessage ?? false).toString()
    }
  ];

  return (
    <Stack
      verticalFill
      tokens={{
        childrenGap: theme.spacing.s2
      }}
      styles={{
        root: {
          flex: "1 1 0",
          overflowY: "auto",
          backgroundColor: theme.semanticColors.bodyBackground,
          padding: theme.spacing.s1
        }
      }}
    >
      <DataTable
        onRenderRow={row => (
          <DetailsRowExpand
            row={row}
            expand={<PoisonMessageCard message={row?.item} />}
          />
        )}
        items={messages}
        columns={columns}
        getKey={getKey}
        onShouldVirtualize={() => true}
      />
    </Stack>
  );
};
