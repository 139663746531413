import { useParams } from "react-router-dom";

import {
  confirm,
  SelectionMode,
  ShimmeredDetailsListProps
} from "@bps/fluent-ui";

import { RolloutSoftwarePackageVersionDto } from "./";
import {
  RolloutPackageFilter,
  RolloutPackageFilterValues
} from "./RolloutPackageFilter";
import { RolloutPackageTable } from "./RolloutPackageTable";
import { useDeleteRolloutPackage } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { RolloutPackage } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface RolloutPackagesProps extends ShimmeredDetailsListProps {
  onConfigureRolloutPackage: (
    rolloutPackageId: string,
    softwarePackageVersionId: string
  ) => void;
}

export const RolloutPackages = ({ items, ...props }: RolloutPackagesProps) => {
  const { rolloutId } = useParams<{
    rolloutId: string;
  }>();

  const { mutateAsync: deleteRolloutPackage } = useDeleteRolloutPackage(
    rolloutId!
  );

  // Delete the software package version
  const onDelete = async (rolloutPackage: RolloutPackage) => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Delete"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: "Delete software package",
        subText:
          "Are you sure you want to remove this software package from this rollout?"
      }
    });

    if (isConfirmed) {
      try {
        await deleteRolloutPackage(rolloutPackage.id!);
      } catch (e) {
        return e;
      }
    }
  };

  return (
    <RolloutPackageFilter>
      {({ values }) => {
        return (
          <RolloutPackageTable
            items={filterAndSortRolloutSoftwarePackageVersions(items, values)}
            onDelete={onDelete}
            selectionPreservedOnEmptyClick
            selectionMode={SelectionMode.none}
            setKey="rollout-package-table"
            {...props}
          />
        );
      }}
    </RolloutPackageFilter>
  );
};

const filterAndSortRolloutSoftwarePackageVersions = (
  rolloutSoftwarePackageVersions: RolloutSoftwarePackageVersionDto[],
  filter: RolloutPackageFilterValues
): RolloutSoftwarePackageVersionDto[] => {
  return rolloutSoftwarePackageVersions
    .filter(
      d =>
        filter.name === "" ||
        d.displayName.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0
    )
    .sort(
      (
        a: RolloutSoftwarePackageVersionDto,
        b: RolloutSoftwarePackageVersionDto
      ) => {
        return a.displayName < b.displayName ? 1 : -1;
      }
    );
};
