import { withPermissions } from "@components/withPermissions";
import { SideNavLayout } from "@layouts/SideNavLayout";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SystemOmniRoutes } from "./SystemOmniRoutes";
import { SystemOmniSections } from "./SystemOmniSections";

const SystemOmniBase = () => (
  <SideNavLayout nav={<SystemOmniSections />}>
    <SystemOmniRoutes />
  </SideNavLayout>
);

export const SystemOmni = withPermissions(
  SystemOmniBase,
  [Permissions.TiReportsRead, Permissions.TiReportsWrite],
  "or"
);
