import React from "react";

import { FontIcon, mergeStyles, Stack, Text, useTheme } from "@bps/fluent-ui";
import { ConsumerUser } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";

interface BhaSignInListProps {
  identity?: ConsumerUser;
}

const getIconName = (signInType: string) => {
  switch (signInType) {
    case "phoneNumber":
      return "CellPhone";
    case "emailAddress":
      return "Mail";
    case "userPrincipalName":
      return "Contact";
  }
  return "Unknown";
};

export const BhaSignInList: React.FC<BhaSignInListProps> = ({ identity }) => {
  const theme = useTheme();
  return (
    <>
      {identity?.signInIdentities?.map(device => (
        <Stack
          key={`${device.signInType}_${device.issuer}`}
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.s1 }}
        >
          <FontIcon
            iconName={getIconName(device.signInType)}
            className={mergeStyles({
              fontSize: 24
            })}
          />
          <Text block nowrap>
            {device.issuerAssignedId}
          </Text>
        </Stack>
      ))}
    </>
  );
};
