import { ExpandableNav, ExpandableNavItem } from "@components/ExpandableNav";
import {
  matchPath,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";
import { SystemPltTabs } from "./system-plt-tabs.constants";

export const SystemPltSections: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSectionChange = (nextId: string) => {
    navigate(nextId);
  };

  const match = matchPath("/system/plt/:selectedTab/*", location.pathname);
  const selectedTab = match?.params?.selectedTab;

  const isTabValid = Object.entries(SystemPltTabs).some(([, value]) =>
    Object.entries(value).some(([, value]) => value.id === selectedTab)
  );

  if (isTabValid === false) {
    return <Navigate to={SystemPltTabs.Sales.Product.id} replace />;
  }

  const items: ExpandableNavItem[] = [
    {
      name: "Sales",
      links: Object.values(SystemPltTabs.Sales)
    },
    {
      name: "Catalog",
      links: Object.values(SystemPltTabs.Catalog)
    },
    {
      name: "Field Management",
      links: Object.values(SystemPltTabs.FieldMgmt)
    },
    {
      name: "Comms",
      links: Object.values(SystemPltTabs.Comms)
    },
    {
      name: "Integrators",
      links: Object.values(SystemPltTabs.Integrators)
    },
    {
      name: "Redirects",
      links: Object.values(SystemPltTabs.Redirects)
    },
    {
      name: "BpID",
      links: Object.values(SystemPltTabs.BpId)
    }
  ];

  return (
    <ExpandableNav
      selectedKey={selectedTab}
      onSectionChange={onSectionChange}
      items={items}
    />
  );
};
