import { CopyableGuid } from "@components/CopyableText";
import React from "react";

import { DetailsList, IColumn } from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { Provider } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface ProvidersTableProps {
  providers: Provider[];
}

export const ProvidersTable: React.FC<ProvidersTableProps> = ({
  providers
}) => {
  const columns: IColumn[] = [
    {
      key: "providerId",
      name: "Provider Id",
      fieldName: "providerId",
      minWidth: 310,
      maxWidth: 310,
      onRender: (item: Provider) => <CopyableGuid value={item.providerId} />
    },
    {
      key: "title",
      name: "Title",
      fieldName: "title",
      minWidth: 80,
      maxWidth: 80
    },
    {
      key: "firstName",
      name: "First Name",
      fieldName: "firstName",
      minWidth: 100,
      maxWidth: 200
    },
    {
      key: "lastName",
      name: "Last Name",
      fieldName: "lastName",
      minWidth: 100,
      maxWidth: 200
    },
    {
      key: "firstAvailable",
      name: "First Available",
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: Provider) =>
        item.firstAvailable?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
    },
    {
      key: "lastAvailable",
      name: "Last Available",
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: Provider) =>
        item.lastAvailable?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
    },
    {
      key: "availabilityCount",
      name: "Availability Count",
      fieldName: "availabilityCount",
      minWidth: 100,
      maxWidth: 200
    }
  ];
  return (
    <DetailsList
      columns={columns}
      items={providers}
      styles={{
        contentWrapper: {
          "[role=gridcell]": {
            display: "flex",
            alignItems: "center"
          }
        }
      }}
    />
  );
};
