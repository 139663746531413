import { ProductFormValues } from "./ProductFormDialog.types";
import { SalesProductDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const getProductFormValues = (
  dto: SalesProductDto
): ProductFormValues => {
  const { id, changeLog, ...restDto } = dto;
  return {
    ...restDto,
    isActive: !dto.isInactive
  };
};
