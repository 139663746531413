import { withPermissions } from "@components/withPermissions";
import { CopyableGuid } from "@components/CopyableText";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { Permissions } from "@libs/permissions/permissions.enum";
import { IColumn, IconButton, Stack, Text, useTheme } from "@bps/fluent-ui";
import { TenantTag } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { TenantSectionProps } from "modules/tenants/sections/plt/types";
import { NewTagDialogButton } from "./components/NewTagDialogButton";
import {
  useTenantTagQuery,
  useDeleteTenantTag
} from "@libs/api/gateways/plt/plt-gateway.hooks";

const TenantTagsBase = ({ tenant }: TenantSectionProps) => {
  const theme = useTheme();

  const tenantTagQuery = useTenantTagQuery(tenant.id!);

  const { mutateAsync: deleteTenantTag } = useDeleteTenantTag();

  const columns: IColumn[] = [
    {
      key: "actions",
      name: "",
      maxWidth: 32,
      minWidth: 32,
      onRender: (item: TenantTag) => (
        <IconButton
          styles={{ root: { justifySelf: "end" } }}
          menuIconProps={{ iconName: "more" }}
          menuProps={{
            items: [
              {
                key: "delete",
                text: "Delete",
                onClick: () => {
                  deleteTenantTag(item);
                }
              }
            ]
          }}
        />
      )
    },
    {
      key: "tag",
      name: "",
      maxWidth: 100,
      minWidth: 100,
      onRender: (tag: TenantTag) => tag.tag
    }
  ];

  const header = tenantTags => (
    <Stack horizontal horizontalAlign="space-between">
      <Text variant="xLarge">Tenant Tags</Text>
      <NewTagDialogButton tenantId={tenant.id} tenantTags={tenantTags} />
    </Stack>
  );

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.s1 }}
    >
      <SectionTitle>{tenant.name}</SectionTitle>
      <CopyableGuid
        value={tenant.id}
        variant="small"
        color={theme.palette.themeSecondary}
      />

      <QueryStateIndicator {...tenantTagQuery} NoDataNode={header([])}>
        {tenantTags => {
          return (
            <Stack>
              {header(tenantTags)}
              <ShimmeredDetailsList
                items={tenantTags ?? []}
                columns={columns}
                setKey="plt_tags_id"
              />
            </Stack>
          );
        }}
      </QueryStateIndicator>
    </Stack>
  );
};

export const TenantTags = withPermissions(
  TenantTagsBase,
  [Permissions.PltCatalogTenantRead, Permissions.PltCatalogTenantWrite],
  "or"
);
