import { SiteDeployment } from "modules/tenants/sections/pros/dashboard/site-deployment";
import { SiteVersions } from "modules/tenants/sections/pros/dashboard/site-deployment/SiteVersions";
import { SiteCommands } from "modules/tenants/sections/pros/dashboard/SiteCommands";
import { SiteLogs } from "modules/tenants/sections/pros/dashboard/SiteLogs";

import {
  FontIcon,
  Heading,
  mergeStyleSets,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { SectionTitle } from "@components/SectionTitle";
import { SecurableComponent } from "@components/SecurableComponent";
import { withPermissions } from "@components/withPermissions";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useSIATenantQuery,
  useSiteActivity
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

const flexContainerStyle = mergeStyleSets({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "300px"
  }
});

interface Props {
  tenant: Tenant;
}

const SiteDashboardBase = ({ tenant }: Props) => {
  const theme = useTheme();
  const { id: tenantId } = tenant;
  const { data: lastPingData } = useSiteActivity(tenantId);
  const { data: SIATenant } = useSIATenantQuery(tenantId);
  const lastPingDate = DateTime.fromISO(lastPingData ?? "");
  // If not active in last 90 minutes -> turn red
  const isActiveTenant = DateTime.now().minus({ hours: 1.5 }) < lastPingDate;

  return (
    <Stack
      key={tenantId}
      verticalFill
      styles={{
        root: {
          paddingLeft: 10,
          paddingRight: 10
        }
      }}
      tokens={{ childrenGap: 8 }}
    >
      <Stack.Item>
        <SectionTitle>Site Control Center</SectionTitle>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <Heading
            styles={{
              root: {
                fontSize: "1rem",
                textTransform: "upperCase",
                color: theme.palette.orangeLighter
              }
            }}
          >
            {tenant?.name} / {tenant?.id} / {SIATenant?.siteId}
          </Heading>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <TextBadge
              badgeColor={
                isActiveTenant ? TextBadgeColor.green : TextBadgeColor.red
              }
              badgeSize={TextBadgeSize.small}
            >
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
              >
                <FontIcon iconName="Globe" />
                <Stack.Item>
                  {lastPingDate.toFormat("ccc, LL/dd/yyyy, HH:mm a")}
                </Stack.Item>
              </Stack>
            </TextBadge>
          </Stack>
        </SecurableComponent>
      </Stack.Item>
      <Stack.Item>
        <SiteCommands siteId={tenantId} />
      </Stack.Item>
      <Stack.Item>
        <SiteDeployment siteId={tenantId} />
      </Stack.Item>
      <Stack.Item styles={{ root: { paddingTop: 16 } }}>
        <SiteVersions siteId={tenantId} />
      </Stack.Item>
      <Stack.Item grow={1} shrink={1} styles={flexContainerStyle}>
        <SecurableComponent permissions={[Permissions.ProsSiteManagementRead]}>
          <SiteLogs siteId={tenantId} />
        </SecurableComponent>
      </Stack.Item>
    </Stack>
  );
};

export const SiteDashboard = withPermissions(
  SiteDashboardBase,
  [Permissions.ProsSiteManagementRead, Permissions.ProsSiteManagementWrite],
  "or"
);
