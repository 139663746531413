import { ContextualMenuItemType, IContextualMenuProps } from "@bps/fluent-ui";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { useLogLevelUpdateMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

import {
  LogLevels,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import { getShortComponentTypeName } from "@libs/api/gateways/sia/sia-ops-gateway.utils";

export const UpdateLogLevelCommandButton = (props: { siteId: string }) => {
  const { siteId } = props;

  const commandRunner = useCommandRunner();
  const updateLogLevelMutation = useLogLevelUpdateMutation();

  const handleUpdateLogLevel = (
    componentType: SiteComponentType,
    logLevel: LogLevels
  ) => () =>
    commandRunner.runMutation(
      `Update site ${getShortComponentTypeName(componentType)} log level to '${
        LogLevels[logLevel]
      }'`,
      updateLogLevelMutation,
      {
        siteId,
        componentType,
        logLevel
      }
    );

  const createSiteComponentMenuProps = (component: SiteComponentType) => {
    return [
      {
        key: component,
        itemType: ContextualMenuItemType.Header,
        text: `Site ${component} log level`
      },
      ...Object.keys(LogLevels).map((level: LogLevels) => ({
        key: `${component}-${level}`,
        text: LogLevels[level],
        onClick: handleUpdateLogLevel(component, level)
      }))
    ];
  };

  const logLevelMenuProps: IContextualMenuProps = {
    items: [
      ...createSiteComponentMenuProps(SiteComponentType.SiteAgent),
      ...createSiteComponentMenuProps(SiteComponentType.SiteManager)
    ]
  };

  return (
    <SiteCommandButton
      commandName="Update log level"
      menuProps={logLevelMenuProps}
      iconName="FileBug"
    />
  );
};
