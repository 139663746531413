import { FunctionComponent, useState } from "react";
import { Dialog, Stack } from "@bps/fluent-ui";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { ApiCallButton } from "@components/buttons/ApiCallButton";
import { useLogoutAllSessions } from "@libs/api/gateways/bp-id/bp-id.hooks";

interface LogoutAllSessionsDialogButtonProps {
  user: PltUser;
}

export const LogoutAllSessionsDialogButton: FunctionComponent<LogoutAllSessionsDialogButtonProps> = ({
  user
}) => {
  const {
    mutateAsync: logoutAllSessions,
    isLoading,
    error
  } = useLogoutAllSessions();

  const [
    showLogoutAllSessionsDialog,
    setShowLogoutAllSessionsDialog
  ] = useState<boolean>(false);

  return (
    <>
      {showLogoutAllSessionsDialog && (
        <Dialog
          hidden={false}
          dialogContentProps={{
            subText:
              "We have expired the logged in sessions for your login. Please note that it can take up to 1-hour to take effect on all devices for all the access tokens to expire.",
            title: "Logout all sessions"
          }}
        >
          <Stack horizontalAlign="end">
            <ApiCallButton
              isLoading={isLoading}
              error={error}
              onClick={() => {
                setShowLogoutAllSessionsDialog(false);
              }}
              styles={{ root: { width: "fit-content" } }}
            >
              Close
            </ApiCallButton>
          </Stack>
        </Dialog>
      )}
      <ApiCallButton
        isLoading={isLoading}
        error={error}
        onClick={() => {
          logoutAllSessions(user?.bpIdUserId!);
          setShowLogoutAllSessionsDialog(true);
        }}
        disabled={!user?.bpIdUserId}
      >
        Logout all sessions
      </ApiCallButton>
    </>
  );
};
