import { VerticalTabs } from "@components/VerticalTabs";
import {
  matchPath,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";

import { Stack, useTheme } from "@bps/fluent-ui";
import { SystemProsTabs } from "./system-pros-tabs.constants";

export const SystemProsSections: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const onSectionChange = (nextId: string) => {
    navigate(nextId);
  };

  const match = matchPath("/system/pros/:selectedTab", location.pathname);
  const selectedTab = match?.params?.selectedTab;

  if (!selectedTab) {
    return (
      <Navigate to={SystemProsTabs.SiteIntegration.BuildMgmt.id} replace />
    );
  }

  return (
    <Stack
      tokens={{
        childrenGap: theme.spacing.m
      }}
    >
      <VerticalTabs
        title="Operations"
        tabs={Object.values(SystemProsTabs.Operations)}
        selectedTab={selectedTab}
        onSelectedTabChange={onSectionChange}
      />
      <VerticalTabs
        title="Site Integration"
        tabs={Object.values(SystemProsTabs.SiteIntegration)}
        selectedTab={selectedTab}
        onSelectedTabChange={onSectionChange}
      />
    </Stack>
  );
};
