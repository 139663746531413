import { FormSwitch } from "@components/form/fields/FormSwitch";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  useTheme,
  VerticalDivider
} from "@bps/fluent-ui";
import { usePutLocation } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import {
  PracticeLocation,
  PracticeLocationUpdate
} from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

const toggleStyles = {
  root: {
    marginBottom: 0
  }
};

interface PracticeLocationConfigProps {
  location: PracticeLocation;
}
export const PracticeLocationConfig: React.FC<PracticeLocationConfigProps> = ({
  location
}) => {
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.BhbPracticeManagementWrite
  );

  const putMutation = usePutLocation(location.tenantId);
  const [error, setError] = useState<string>();

  const form = useForm<PracticeLocationUpdate>({
    defaultValues: {
      onlineBookingsEnabled: location.bookingSettings?.enabled,
      createNewPatientRecord: location.createNewPatientRecord,
      allowCancellationsEnabled: location.allowCancellationsEnabled,
      maximumAppointmentsPerDay: location.maximumAppointmentsPerDay,
      minimumTimeToBook: location.minimumTimeToBook
    }
  });

  const onSubmit = async (updates: PracticeLocationUpdate) => {
    try {
      await putMutation.mutateAsync({
        locationId: location.id,
        updates
      });
      setError(undefined);
      form.reset(updates);
    } catch (e) {
      setError(String(e));
    }
  };

  const theme = useTheme();
  return (
    <FormProvider {...form}>
      <Stack
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{
            root: {
              flex: 1
            }
          }}
          tokens={{
            childrenGap: theme.spacing.l2
          }}
        >
          <Stack
            tokens={{
              childrenGap: theme.spacing.s1
            }}
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Text>Accepts New Patients</Text>
              <Text>
                <FormSwitch
                  name="onlineBookingsEnabled"
                  styles={toggleStyles}
                  disabled={!hasWritePermission}
                  title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
                />
              </Text>
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Text>Allow Create Patient Record</Text>
              <FormSwitch
                name="createNewPatientRecord"
                styles={toggleStyles}
                disabled={!hasWritePermission}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              />
            </Stack>
          </Stack>
          <VerticalDivider />
          <Stack
            tokens={{
              childrenGap: theme.spacing.s1
            }}
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Text>Allow Cancel</Text>
              <FormSwitch
                name="allowCancellationsEnabled"
                styles={toggleStyles}
                disabled={!hasWritePermission}
                title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
              />
            </Stack>
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Text>Min Time To Book</Text>
              <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
                <Controller
                  name="minimumTimeToBook"
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type="number"
                      disabled={
                        !hasWritePermission || form.formState.isSubmitting
                      }
                      styles={{
                        field: {
                          width: 48
                        }
                      }}
                      suffix="mins"
                      value={value ? `${value}` : undefined}
                      onChange={onChange}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <VerticalDivider />
          <Stack
            tokens={{
              childrenGap: theme.spacing.s1
            }}
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            <Stack horizontal horizontalAlign="space-between">
              <Text>Max Appts Per Day</Text>
              <Controller
                name="maximumAppointmentsPerDay"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    type="number"
                    disabled={
                      !hasWritePermission || form.formState.isSubmitting
                    }
                    styles={{
                      root: {
                        width: 100
                      }
                    }}
                    value={value ? `${value}` : undefined}
                    onChange={onChange}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
        )}
        {form.formState.isDirty && (
          <Stack horizontal>
            <PrimaryButton type="submit">
              {form.formState.isSubmitting ? <Spinner /> : "Save"}
            </PrimaryButton>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
};
