import { Dialog, IDialogProps } from "@bps/fluent-ui";

import { RingForm, RingFormValues } from "./RingForm";
import { useUpdateDeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface EditRingDialogProps extends IDialogProps {
  ring: DeploymentRing;
  onDismiss: () => void;
}

export const EditRingDialog: React.FC<EditRingDialogProps> = ({
  ring,
  onDismiss,
  ...props
}) => {
  const { mutateAsync: updateDeploymentRing } = useUpdateDeploymentRing();

  const onSubmit = async (formData: RingFormValues) => {
    await updateDeploymentRing({
      code: formData.code,
      description: formData.description,
      displayName: formData.displayName,
      isPublic: formData.isPublic,
      deploymentRingId: ring.id,
      eTag: ring.eTag!
    });
    onDismiss();
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Edit Ring"
      }}
      onDismiss={onDismiss}
      {...props}
    >
      <RingForm ring={ring} onSubmit={onSubmit} />
    </Dialog>
  );
};
