import { observer } from "mobx-react";
import { Stack, Tile } from "@bps/fluent-ui";
import { tileStyles } from "./commonDashboardStyles";
import { useCommandRunner } from "../../_shared-models/CommandRunner";

export const CommandRunnerStatusBar = observer(() => {
  const commandRunner = useCommandRunner();
  if (!commandRunner.commandName) return null;

  return (
    <Stack horizontal>
      {commandRunner.isBusy && (
        <Tile styles={tileStyles}>
          <b>Running command: &nbsp;</b> {commandRunner.commandName}
        </Tile>
      )}
      {!commandRunner.isBusy && (
        <Tile styles={tileStyles}>
          <b>{commandRunner.commandName} result: &nbsp;</b>{" "}
          {commandRunner.status}
        </Tile>
      )}
    </Stack>
  );
});
