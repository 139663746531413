import { TenantTabs } from "modules/tenants/tabs";
import { Route, Routes } from "react-router-dom";
import { TenantBreadcrumbs } from "./TenantBreadcrumbs";

const TenantsRoutes = () => {
  return (
    <Routes>
      <Route element={<TenantBreadcrumbs />}>
        <Route path=":tenantId/*" element={<TenantTabs />} />
        <Route path="/" element={<TenantTabs />} />
      </Route>
    </Routes>
  );
};

// eslint-disable-next-line import/no-default-export
export default TenantsRoutes;
