import { useTabsNavContext } from "@components/tabs-nav";
import { FunctionComponent, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  FontSizes,
  LabeledText,
  LabeledTextGroup,
  LabeledTextStyles,
  Link,
  Shimmer,
  Stack
} from "@bps/fluent-ui";

import { AssignTenantForm } from "./AssignTenantForm";
import {
  ItemsKeys,
  LineItem,
  TenantDetailsTableProps
} from "./TenantDetailsTable.types";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { ErrorAlert } from "@components/errors/ErrorAlert";
import { PltTenantApplicationBadge } from "../../../../../_shared-blocks/PltTenantApplicationBadge";
import {
  useChildTenants,
  useTenantQuery
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { TenantsTabs } from "../../../../tenants-tabs.constants";

const labeledTextStyles: Partial<LabeledTextStyles> = {
  root: {
    gridTemplateColumns: "0.1fr 1fr",
    minHeight: 32,
    columnGap: "8px"
  },
  label: { fontSize: FontSizes.medium }
};
export const TenantDetailsTable: FunctionComponent<TenantDetailsTableProps> = ({
  tenant
}) => {
  const isCamTenant = tenant.application === ApplicationType.CAM;
  const { addTab } = useTabsNavContext();
  const navigate = useNavigate();
  const {
    data: childTenants = [],
    error: errorChildTenants,
    isLoading: isLoadingChildTenants
  } = useChildTenants(tenant.id, { enabled: !tenant.customerTenantId });

  const {
    data: customerTenant,
    error: errorCustomerTenants,
    isLoading: isLoadingCustomerTenants
  } = useTenantQuery(tenant.customerTenantId!, {
    enabled: !!tenant.customerTenantId
  });

  const setSelectedTab = (childTenantId: string, name: string) => {
    addTab({
      id: childTenantId,
      name,
      data: { sectionId: TenantsTabs.Platform.Details.id }
    });
  };

  const selectCustomer = (crmId?: string) => {
    const to = generatePath("/cam/:crmId/ca-crmclientdetails", {
      crmId
    });
    navigate(to);
  };

  const renderRow = (item: LineItem) => {
    const { section, value } = item;

    if (section === ItemsKeys.Type) {
      return <PltTenantApplicationBadge applicationCode={value} />;
    }

    if (section === ItemsKeys.CrmId) {
      return (
        <Link key={item.name} onClick={() => selectCustomer(item.value)}>
          {item.value}
        </Link>
      );
    }

    if (section === ItemsKeys.RelatedTenants) {
      return (
        <Stack tokens={{ childrenGap: 8 }}>
          {!tenant.customerTenantId && (
            <AssignTenantForm
              tenantId={tenant.id}
              country={tenant.country}
              assignChildTenant={isCamTenant}
              excludeKeys={childTenants.map(tenant => tenant.id)}
            />
          )}

          {value?.map(item => (
            <Link
              key={item.id}
              onClick={() => setSelectedTab(item.id, item.name)}
            >
              {item.name}
            </Link>
          ))}
        </Stack>
      );
    }

    if (!Array.isArray(value)) return <>{value}</>;
    if (!value.length) return <>--</>;

    return (
      <Stack
        tokens={{ maxHeight: 400 }}
        styles={{ root: { overflowY: "auto" } }}
      >
        {value.map(item => (
          <div key={item}>{item}</div>
        ))}
      </Stack>
    );
  };

  const items = useMemo((): LineItem[] => {
    const getRelatedTenantValue = () => {
      if (isCamTenant) return childTenants;
      return customerTenant ? [customerTenant] : [];
    };

    return [
      { section: ItemsKeys.Name, value: tenant.name, name: "Name" },
      { section: ItemsKeys.CrmId, value: tenant.crmId, name: "Crm Client Id" },
      {
        section: ItemsKeys.Type,
        value: tenant.application,
        name: "Application"
      },
      {
        section: ItemsKeys.Country,
        value: tenant.country,
        name: "Country"
      },
      {
        section: ItemsKeys.RelatedTenants,
        value: getRelatedTenantValue(),
        name: isCamTenant ? "Child tenants" : "Customer tenant"
      },
      {
        section: ItemsKeys.UserLimit,
        value: tenant.userLimit,
        name: "Users limit"
      },
      {
        section: ItemsKeys.Permissions,
        value: tenant.permissions,
        name: "Permissions"
      }
    ];
  }, [
    childTenants,
    customerTenant,
    isCamTenant,
    tenant.application,
    tenant.country,
    tenant.crmId,
    tenant.name,
    tenant.permissions,
    tenant.userLimit
  ]);

  if (errorChildTenants ?? errorCustomerTenants)
    return <ErrorAlert error={errorChildTenants ?? errorCustomerTenants} />;

  return (
    <LabeledTextGroup>
      {items.map(item => (
        <LabeledText
          key={item.section}
          label={item.name}
          wrap
          styles={labeledTextStyles}
        >
          {isLoadingChildTenants || isLoadingCustomerTenants ? (
            <Shimmer styles={{ shimmerWrapper: { height: 16 } }} width={100} />
          ) : (
            renderRow(item)
          )}
        </LabeledText>
      ))}
    </LabeledTextGroup>
  );
};
