import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from "mobx";
import { useLocalObservable } from "mobx-react";
import { createContext, PropsWithChildren, useContext } from "react";
import { UseMutationResult } from "react-query";

class CommandRunner {
  private _isBusy = false;
  private _commandName = "";
  private _status = "";

  constructor() {
    makeObservable<CommandRunner, "_isBusy" | "_commandName" | "_status">(
      this,
      {
        _isBusy: observable,
        _commandName: observable,
        _status: observable,

        isBusy: computed,
        commandName: computed,
        status: computed,

        runMutation: action.bound
      }
    );
  }

  public get isBusy(): boolean {
    return this._isBusy;
  }

  public get commandName(): string {
    return this._commandName;
  }

  public get status(): string {
    return this._status;
  }

  public runMutation<TArgs>(
    commandName: string,
    mutation: UseMutationResult<string, Error, TArgs>,
    args: TArgs
  ) {
    this._isBusy = true;
    this._commandName = commandName;
    this._status = "Running";

    mutation.mutate(args, {
      onSettled: (result: string, error: Error) => {
        runInAction(() => {
          this._status = error?.message
            ? `Error - ${error.message}`
            : result ?? "No result";

          this._isBusy = false;
        });
      }
    });
  }
}

const CommandRunnerContext = createContext<CommandRunner | undefined>(
  undefined
);

export const CommandRunnerProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const store = useLocalObservable(() => new CommandRunner());

  return (
    <CommandRunnerContext.Provider value={store}>
      {children}
    </CommandRunnerContext.Provider>
  );
};

export const useCommandRunner = () => {
  const store = useContext(CommandRunnerContext);
  if (store) return store;

  throw new Error("No command runner store context found.");
};
