import {
  Country,
  StripeCustomerAddress
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export const nzAddressText = (
  address: Partial<StripeCustomerAddress> & { country?: Country }
): string => {
  const { addressLine1, addressLine2, city, postcode, country } = address;
  return [
    addressLine2 && `${addressLine2}, `,
    addressLine1 && `${addressLine1}, `,
    city,
    postcode,
    country
  ]
    .filter(Boolean)
    .join(" ");
};

export const addressText = (
  address: (Partial<StripeCustomerAddress> & { country?: Country }) | undefined
): string => {
  if (!address) return "";
  if (address.country === Country.NewZealand) {
    return nzAddressText(address);
  }

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    postcode,
    country
  } = address;
  return [
    addressLine1 && `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ""}`,
    city,
    state,
    postcode,
    country
  ]
    .filter(Boolean)
    .join(" ");
};
