import { ConsumerUserCard } from "modules/users/BhaProfile/BhaIdentityCard";
import React from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { ConsumerUser } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";

interface ConsumerUserIdentitiesExpandProps {
  identity?: ConsumerUser;
}

export const ConsumerUserIdentitiesExpand: React.FC<ConsumerUserIdentitiesExpandProps> = ({
  identity
}) => {
  const theme = useTheme();
  return (
    <Stack
      styles={{
        root: { padding: theme.spacing.s1 }
      }}
    >
      <ConsumerUserCard identity={identity} />
    </Stack>
  );
};
