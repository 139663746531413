import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";

import { FunctionComponent, useState } from "react";

import { CompoundButton, FontIcon, Stack, useTheme } from "@bps/fluent-ui";

import { commandButton } from "../styles";
import { SnapshotOptionsModal } from "./SnapshotOptionsModal";
import {
  usePostTtmReportBillingSnapshot,
  usePostTtmReportDataIngest,
  usePostTtmReportSnapshot
} from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { TtmReportingSnapshotOptions } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

const TtmReportsBase: FunctionComponent<Props> = ({ tenant }) => {
  const postSnapshot = usePostTtmReportSnapshot(tenant.id);
  const postBillingSnapshot = usePostTtmReportBillingSnapshot(tenant.id);
  const postDataIngestion = usePostTtmReportDataIngest(tenant.id, "All");
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.TiReportsWrite
  );

  const [showSnapshotModal, setShowSnapshotModal] = useState<{
    callback: (options: TtmReportingSnapshotOptions) => void;
  }>();

  const theme = useTheme();

  const onSnapshotClick = (options: TtmReportingSnapshotOptions) => {
    postSnapshot.mutate(options);
  };

  const onBillingSnapshotClick = (options: TtmReportingSnapshotOptions) => {
    postBillingSnapshot.mutate(options);
  };

  const onDataIngestionClick = () => {
    postDataIngestion.mutate(tenant.id);
  };

  return (
    <>
      <Stack>
        <SectionTitle>Reports</SectionTitle>
        <Stack horizontal styles={{ root: { paddingTop: theme.spacing.m } }}>
          <CompoundButton
            disabled={postSnapshot.isLoading || !hasWritePermission}
            secondaryText="Snapshot daily reports"
            styles={commandButton}
            onClick={() => setShowSnapshotModal({ callback: onSnapshotClick })}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            <FontIcon iconName="Camera" styles={{ root: { fontSize: 40 } }} />
          </CompoundButton>
          <CompoundButton
            disabled={postSnapshot.isLoading || !hasWritePermission}
            secondaryText="Ingest daily report data"
            styles={commandButton}
            onClick={onDataIngestionClick}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            <FontIcon
              iconName="DatabaseSync"
              styles={{ root: { fontSize: 40 } }}
            />
          </CompoundButton>
          <CompoundButton
            disabled={postSnapshot.isLoading || !hasWritePermission}
            secondaryText="Snapshot real time reports"
            styles={commandButton}
            onClick={() =>
              setShowSnapshotModal({ callback: onBillingSnapshotClick })
            }
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            <FontIcon iconName="Camera" styles={{ root: { fontSize: 40 } }} />
          </CompoundButton>
        </Stack>
      </Stack>
      {showSnapshotModal && (
        <SnapshotOptionsModal
          onDismiss={() => setShowSnapshotModal(undefined)}
          onSubmit={async values => {
            showSnapshotModal.callback(values);
            setShowSnapshotModal(undefined);
          }}
        />
      )}
    </>
  );
};

export const TtmReports = withPermissions(
  TtmReportsBase,
  [Permissions.TiSettingsRead, Permissions.TiReportsWrite],
  "or"
);
