import { SideNavLayout } from "@layouts/SideNavLayout";
import { ProsBanner } from "modules/system/sections/pros/operations/banner";
import { PrOSOpsSysComponents } from "modules/system/sections/pros/operations/system-components";
import { ProsBuildMgmt } from "modules/system/sections/pros/site-intergration/build-management";
import { ProsDeployMgmt } from "modules/system/sections/pros/site-intergration/deploy-management";
import { ProsSiteComponents } from "modules/system/sections/pros/site-intergration/site-components";
import { ProsTenantSyncState } from "modules/system/sections/pros/site-intergration/tenant-sync-state";
import { SystemProsSections } from "modules/system/sections/pros/SystemProsSections";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { PrOSOpsEventHubs } from "./operations/event-hubs";
import { SystemBreadcrumbs } from "../../SystemBreadcrumbs";
import { SystemProsTabs } from "./system-pros-tabs.constants";

export const SystemProsRoutes: React.FC = () => (
  <SideNavLayout nav={<SystemProsSections />}>
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemProsTabs} />}>
        {/* Operations Tabs */}
        <Route
          path={SystemProsTabs.Operations.Banner.id}
          element={<ProsBanner />}
        />
        <Route
          path={SystemProsTabs.Operations.SysComp.id}
          element={<PrOSOpsSysComponents />}
        />
        <Route
          path={SystemProsTabs.Operations.EventHubs.id}
          element={<PrOSOpsEventHubs />}
        />

        {/* Site Intergration Tabs */}
        <Route
          path={SystemProsTabs.SiteIntegration.BuildMgmt.id}
          element={<ProsBuildMgmt />}
        />
        <Route
          path={SystemProsTabs.SiteIntegration.DeployMgmt.id}
          element={<ProsDeployMgmt />}
        />
        <Route
          path={SystemProsTabs.SiteIntegration.SiteComponents.id}
          element={<ProsSiteComponents />}
        />
        <Route
          path={SystemProsTabs.SiteIntegration.TenantSyncState.id}
          element={<ProsTenantSyncState />}
        />
      </Route>
    </Routes>
  </SideNavLayout>
);
