import React from "react";
import { useParams } from "react-router-dom";

import { Spinner, Stack, useTheme } from "@bps/fluent-ui";

import { SoftwarePackageCommands } from "./SoftwarePackageCommands";
import {
  useFieldDeviceConfiguration,
  useFieldDeviceReportedConfig
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface SoftwarePackagePageProps {
  device?: FieldDevice;
}

export const SoftwarePackagePage: React.FC<SoftwarePackagePageProps> = ({
  device
}) => {
  const theme = useTheme();

  const { softwarePackageCode } = useParams();
  const {
    data: deviceReportedConfig,
    isLoading: reportedLoading
  } = useFieldDeviceReportedConfig(device?.id!);

  const {
    data: deviceDesiredConfig,
    isLoading: desiredLoading
  } = useFieldDeviceConfiguration(device?.id!);

  const reportedConfig = deviceReportedConfig?.find(
    config => config.softwarePackageCode === softwarePackageCode
  );

  const desiredConfig =
    deviceDesiredConfig?.componentsToInstall?.find(
      component => component.code === softwarePackageCode
    ) ??
    deviceDesiredConfig?.componentsToInstall
      ?.map(
        component =>
          component.modules && component.modules[softwarePackageCode!]
      )
      .filter(c => c !== undefined)[0] ??
    undefined;

  return (
    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
      {!desiredLoading && !reportedLoading ? (
        <SoftwarePackageCommands
          reportedConfig={reportedConfig}
          desiredConfig={desiredConfig}
          device={device}
        />
      ) : (
        <Spinner />
      )}
    </Stack>
  );
};
