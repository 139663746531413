import { PracticeOutageToggle } from "modules/tenants/sections/best-health/operations/PracticeOutageToggle";

import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { useGetPracticeOutageQuery } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { Permissions } from "@libs/permissions/permissions.enum";

interface BestHealthOperationsProps {
  tenant: Tenant;
}

const BestHealthOperationsBase = ({ tenant }: BestHealthOperationsProps) => {
  const { data: practiceOutage } = useGetPracticeOutageQuery(tenant.id);

  return (
    <>
      <SectionTitle>Booking Operations</SectionTitle>
      <PracticeOutageToggle tenant={tenant} practiceOutage={practiceOutage} />
    </>
  );
};

export const BestHealthOperations = withPermissions(
  BestHealthOperationsBase,
  [
    Permissions.BhbPracticeWrite,
    Permissions.BhbPracticeRead,
    Permissions.OpsConsolePreRelease
  ],
  "or"
);
