import { compareVersions } from "compare-versions";

import { Option, Text } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  RolloutPackageUpdateRequest,
  SoftwarePackageVersion
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useSoftwarePackageVersions,
  useUpdateRolloutPackage
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { SoftwarePackageVersionDropDown } from "../../common/SoftwarePackageVersionDropDown";
import { RolloutSoftwarePackageVersionDto } from "./";

interface RolloutPackageVersionSelectProps {
  rolloutSoftwarePackageVersion: RolloutSoftwarePackageVersionDto;
}

export const RolloutPackageVersionSelect: React.FC<RolloutPackageVersionSelectProps> = ({
  rolloutSoftwarePackageVersion
}) => {
  const {
    error: updateError,
    mutateAsync: updateRolloutPackage
  } = useUpdateRolloutPackage();

  const softwarePackageVersionsQuery = useSoftwarePackageVersions({
    softwarePackageId: rolloutSoftwarePackageVersion.softwarePackageId
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const {
    data: softwarePackageVersionsData,
    error: softwarePackageVersionsError,
    isLoading: softwarePackageVersionsIsLoading
  } = softwarePackageVersionsQuery;

  // Update the software package version
  const onUpdateVersion = async (softwarePackageVersionId: string) => {
    const request = {
      id: rolloutSoftwarePackageVersion.id,
      rolloutId: rolloutSoftwarePackageVersion.rolloutId,
      softwarePackageVersionId,
      eTag: rolloutSoftwarePackageVersion.eTag
    } as RolloutPackageUpdateRequest;

    try {
      await updateRolloutPackage(request);
    } catch (e) {
      return e;
    }
  };

  const getVersions = (): Option<SoftwarePackageVersion>[] => {
    if (!softwarePackageVersionsData) return [];

    return (
      softwarePackageVersionsData
        .sort((a, b) => compareVersions(b.version, a.version))
        .map(softwarePackageVersion => {
          return {
            key: softwarePackageVersion.id,
            data: softwarePackageVersion,
            text: softwarePackageVersion.version
          };
        }) ?? []
    );
  };

  return (
    <QueryStateIndicator {...softwarePackageVersionsQuery}>
      {() => {
        if (softwarePackageVersionsIsLoading) return <Text>Loading...</Text>;

        return (
          <SoftwarePackageVersionDropDown
            errorMessage={
              updateError?.message ?? softwarePackageVersionsError?.message
            }
            disabled={
              rolloutSoftwarePackageVersion.rolloutOccurred ||
              !hasWritePermission
            }
            name="RolloutPackageVersionSelect"
            selectedKey={rolloutSoftwarePackageVersion.softwarePackageVersionId}
            options={getVersions()}
            onChange={(_, option) => {
              onUpdateVersion(String(option?.key));
            }}
          />
        );
      }}
    </QueryStateIndicator>
  );
};
