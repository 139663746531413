import { useRootStore } from "@stores/StoresProvider";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import React from "react";

import { DefaultButton, IButtonProps } from "@bps/fluent-ui";
import { useCheckUpdateMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { DeploymentAction } from "@stores/deployment-store/deployment-store.types";

interface SetDesiredVersionButtonProps extends IButtonProps {
  componentType?: SiteComponentType;
  desiredVersion?: string;
}

export const ApplyManagerUpdateButton: React.FC<SetDesiredVersionButtonProps> = ({
  componentType,
  children,
  ...props
}) => {
  const { deployment } = useRootStore();
  const mutation = useCheckUpdateMutation();

  const onClick = async () => {
    if (!componentType || !deployment.selectedTenants.length) {
      return;
    }
    deployment.clearDeployments();

    const promises = deployment.selectedTenants.map(tenant =>
      deployment.addDeployment(
        DeploymentAction.ManagerCheckUpdate,
        mutation,
        tenant.id
      )
    );
    await Promise.all(promises);
  };

  return (
    <DefaultButton
      onClick={onClick}
      disabled={
        !deployment.selectedTenants.length ||
        !componentType ||
        deployment.isDeploying
      }
      {...props}
    >
      {children || "Check Manager Update"}
    </DefaultButton>
  );
};
