import { DangerButton } from "@components/buttons/DangerButton";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { config } from "@libs/config/config";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React from "react";

import { IColumn, Link, Stack, Text, useTheme } from "@bps/fluent-ui";
import { useSkipPoisonMessagesMutation } from "@libs/api/gateways/mon/ops-mon-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { PoisonMessage } from "@libs/api/gateways/mon/ops-mon-gateway.dtos";

interface PoisonMessageCardProps {
  message: PoisonMessage;
}

type LineItem = {
  name?: string;
  value: string;
  link?: string;
  wrap?: boolean;
};

export const PoisonMessageCard: React.FC<PoisonMessageCardProps> = ({
  message
}) => {
  const theme = useTheme();
  const onSkipMessage = async message => {
    await skipMessageMutation.mutateAsync(message);
  };

  const skipMessageMutation = useSkipPoisonMessagesMutation();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.OpsEventHubPoisonMessageWrite
  );

  //candidate for share function somewhere?
  const getCorrelationParts = (correlationId: string) => {
    const parts = correlationId?.split(":");
    if (!Array.isArray(parts) || !parts.length || parts.length !== 2)
      return { bpTraceRootId: undefined, bpTraceOperationId: undefined };

    return { bpTraceRootId: parts[0], bpTraceOperationId: parts[1] };
  };

  const { bpTraceRootId, bpTraceOperationId } = getCorrelationParts(
    message.body?.correlationId
  );

  const getItems = () => {
    const items: LineItem[] = [
      {
        value: `${message.eventHubNamespace}.${message.eventHubName}`,
        name: "eventHub:"
      },
      { value: message.consumerGroup, name: "consumerGroup:" },
      { value: message.partitionId, name: "partitionId:" },
      { value: message.sequenceNumber.toString(), name: "sequenceNumber:" },
      { value: message.body?.subject, name: "subject:" },
      { value: message.body?.action, name: "action:" },
      { value: message.body?.tenantId, name: "tenantId:" },
      { value: message.body?.partitionKey, name: "partitionKey:" },
      {
        name: "eventId",
        value: `${message.body?.eventId} (Seq link)`,
        link: `${config.seqUrl}?filter=${message.body?.eventId}`
      },
      { value: message.body?.correlationId, name: "correlationId:" },
      {
        name: "bpTraceOperationId",
        value: `${bpTraceOperationId} (Seq link)`,
        link: `${config.seqUrl}?filter=BpTraceOperationId%20%3D%20%22${bpTraceOperationId}%22`
      },
      {
        name: "bpTraceRootId",
        value: `${bpTraceRootId} (Seq link)`,
        link: `${config.seqUrl}?filter=BpTraceRootId%20%3D%20%22${bpTraceRootId}%22`
      },
      { value: message.exception, name: "exception:", wrap: true },
      {
        value: JSON.stringify(message.body?.value ?? {}, null, 2),
        name: "value:",
        wrap: true
      },
      { value: message.source, name: "source:" },
      { value: message.partitionKey, name: "sourcePartitionKey:" },
      { value: message.rowKey, name: "sourceRowKey:" }
    ];
    return items;
  };

  const linkable = (line: string) => {
    //exampleLine
    //   at Bps.Titanium.Comms.RenderSenderProcessor.Receivers.OutboundCommEventCreatedReceiver.ReceiveAsync(EventData`1 eventData) in /__w/1/s/Bps.Titanium.Comms.RenderSenderProcessor/Receivers/OutboundCommEventCreatedReceiver.cs:line 27

    //Match1 Bps.Titanium.Comms.RenderSenderProcessor.Receivers.OutboundCommEventCreatedReceiver.ReceiveAsync
    //Match2 ReceiveAsync
    const matchMethodName = line.match("Bps.[^(]+")?.[0].match("\\w+$");

    //Match3 /Bps.Titanium.Comms.RenderSenderProcessor/Receivers/OutboundCommEventCreatedReceiver.cs
    //Substring(1) to remove leading "/"
    const matchPath = line.match("/Bps.[^:]+")?.[0].substring(1);

    //example link https://dev.azure.com/bpcloud-dev/_search?action=contents&type=code&text=path%3ABps.Titanium.Comms.RenderSenderProcessor/RenderSenderProcessor.cs%20method%3ARun
    const DEV_OPS_URL =
      "https://dev.azure.com/bpcloud-dev/_search?action=contents&type=code";

    const linkUrl = `${DEV_OPS_URL}&text=path%3A${matchPath}%20method%3A${matchMethodName}`;

    return matchMethodName && matchPath ? linkUrl : undefined;
  };

  const renderValue = (item: LineItem) => {
    const { link, value, wrap } = item;
    if (link)
      return (
        <Link href={link} target="_blank" rel="noreferrer">
          {value}
        </Link>
      );
    if (wrap) {
      const lines = value.split("\n");
      return (
        <>
          {lines.map(item => (
            <div key={item} style={{ whiteSpace: "pre-wrap" }}>
              {linkable(item) && (
                <Link href={linkable(item)} target="_blank" rel="noreferrer">
                  {item}
                </Link>
              )}
              {!linkable(item) && <Text>{item}</Text>}
            </div>
          ))}
        </>
      );
    }

    return <>{value}</>;
  };

  const columns: IColumn[] = [
    {
      key: "key",
      fieldName: "name",
      name: "name",
      minWidth: 100,
      maxWidth: 150
    },
    {
      key: "value",
      fieldName: "value",
      name: "Value",
      minWidth: 100,
      maxWidth: 100,
      onRender: renderValue
    }
  ];

  return (
    <Stack
      styles={{
        root: { padding: theme.spacing.s1 }
      }}
    >
      <Stack
        tokens={{
          padding: theme.spacing.s2,
          childrenGap: theme.spacing.s2
        }}
      >
        <Text variant="medium" bold>
          Details
        </Text>
        <ShimmeredDetailsList
          items={getItems()}
          columns={columns}
          isHeaderVisible={false}
          enableShimmer={false}
        />
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <Text variant="medium" bold>
            Click this and there is no going back
          </Text>
          {message.skipMessage === true && (
            <DangerButton disabled styles={{ root: { width: "fit-content" } }}>
              Pending
            </DangerButton>
          )}
          {!message.skipMessage && (
            <DangerButton
              onClick={() => onSkipMessage(message)}
              primary
              styles={{ root: { width: "fit-content" } }}
              disabled={!hasWritePermission}
              title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            >
              Skip
            </DangerButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
