import { DangerButton } from "@components/buttons/DangerButton";
import { SectionTitle } from "@components/SectionTitle";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { useState } from "react";

import { DefaultButton, Stack, TooltipHost, useTheme } from "@bps/fluent-ui";

import { ConfigDiffModal } from "./ConfigDiffModal";
import { DesiredConfigModal } from "./DesiredConfigModal";
import { ReportedConfigModal } from "./ReportedConfigModal";
import { useClearDeviceFieldConfigurationCache } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

const CLEAR_CACHE_TOOLTIP =
  "Clears the field configuration distributed cache and request cache for this device.";

interface FieldDeviceConfigurationProps {
  device: FieldDevice;
}

export const FieldDeviceConfiguration: React.FC<FieldDeviceConfigurationProps> = ({
  device
}) => {
  const theme = useTheme();
  const [showDesiredConfig, setShowDesiredConfig] = useState<boolean>(false);
  const [showReportedConfig, setShowReportedConfig] = useState<boolean>(false);
  const [showConfigDiff, setShowConfigDiff] = useState<boolean>(false);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const clearDeviceFieldConfigurationCache = useClearDeviceFieldConfigurationCache(
    device.id
  );

  return (
    <>
      <SectionTitle>Device Configuration</SectionTitle>
      <Stack tokens={{ childrenGap: theme.spacing.m }} horizontalAlign="start">
        <DefaultButton onClick={() => setShowDesiredConfig(true)}>
          Desired Field Configuration Preview
        </DefaultButton>
        <DefaultButton onClick={() => setShowReportedConfig(true)}>
          Reported Field Configuration Preview
        </DefaultButton>
        <DefaultButton onClick={() => setShowConfigDiff(true)}>
          Configuration Diff
        </DefaultButton>

        <TooltipHost content={CLEAR_CACHE_TOOLTIP}>
          <DangerButton
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            onClick={clearDeviceFieldConfigurationCache}
          >
            Clear Cached Field Configuration
          </DangerButton>
        </TooltipHost>
      </Stack>
      <DesiredConfigModal
        show={showDesiredConfig}
        device={device}
        onDismiss={() => setShowDesiredConfig(false)}
      />
      <ReportedConfigModal
        show={showReportedConfig}
        device={device}
        onDismiss={() => setShowReportedConfig(false)}
      />
      <ConfigDiffModal
        show={showConfigDiff}
        device={device}
        onDismiss={() => setShowConfigDiff(false)}
      />
    </>
  );
};
