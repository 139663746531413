import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { WarningIconTooltip } from "modules/cam/WarningIconTooltip";
import React, { useState } from "react";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  Stack,
  Text,
  TextField,
  useTheme
} from "@bps/fluent-ui";

export interface TenantIsTemporaryChangeDialogProps
  extends Omit<ConfirmDialogProps, "onConfirm"> {
  tenantName: string;
  changeIsTemporaryToValue: boolean;
  onChange: () => void;
}

export const TenantIsTemporaryChangeDialog: React.FC<TenantIsTemporaryChangeDialogProps> = ({
  tenantName,
  changeIsTemporaryToValue,
  onChange,
  onCancel,
  confirming,
  ...props
}) => {
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>("");
  const [changing, setChanging] = useState<boolean>(false);

  const onConfirm = async () => {
    setChanging(true);
    try {
      onChange();
      onCancel?.();
    } catch (e) {}
    setChanging(false);
  };

  const modeName =
    changeIsTemporaryToValue === true
      ? "Mark as Temporary"
      : "Remove Temporary status";

  const title = `Confirm ${modeName}`;

  return (
    <ConfirmDialog
      dialogContentProps={{
        title
      }}
      confirmButtonProps={{
        text: modeName,
        styles: dangerButtonStyles(theme),
        disabled: name !== tenantName || changing
      }}
      cancelButtonProps={{ text: "No" }}
      {...props}
      confirming={confirming || changing}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Stack tokens={{ childrenGap: theme.spacing.m }}>
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          <Text>Please enter the tenant name to confirm:</Text>
          <Text>{tenantName}</Text>
        </Stack>
        <TextField value={name} onChange={(e, value) => setName(value)} />
        <Stack horizontal>
          <WarningIconTooltip content="This action is important" />
          <Text>
            Note: this action may have consequences for the behaviour and/or
            lifetime of the tenant.
          </Text>
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
