import { withPermissions } from "@components/withPermissions";
import { SideNavLayout } from "@layouts/SideNavLayout";
import { Permissions } from "@libs/permissions/permissions.enum";
import { SystemPltRoutes } from "modules/system/sections/plt/SystemPltRoutes";
import { SystemPltSections } from "modules/system/sections/plt/SystemPltSections";

const SystemPltBase = () => (
  <SideNavLayout nav={<SystemPltSections />}>
    <SystemPltRoutes />
  </SideNavLayout>
);

export const SystemPlt = withPermissions(
  SystemPltBase,
  [
    Permissions.PltCatalogTenantRead,
    Permissions.PltCatalogTenantWrite,
    Permissions.PltCatalogOpsWrite,
    Permissions.PltCatalogOpsRead
  ],
  "or"
);
