import React, { FunctionComponent } from "react";

import { FilterBar, useTheme } from "@bps/fluent-ui";
import {
  ApplicationType,
  TenantSearchArgs
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface TenantFilterValues
  extends Pick<TenantSearchArgs, "isInactive" | "name" | "applications"> {}

interface TenantsFilterProps {
  setArgs: (values: TenantFilterValues) => void;
}
export const TenantsFilter: FunctionComponent<TenantsFilterProps> = ({
  setArgs
}) => {
  const theme = useTheme();
  return (
    <FilterBar<TenantFilterValues>
      maxItemWidth={150}
      initialValues={{ name: "", isInactive: false }}
      items={[
        {
          type: "searchBox",
          name: "name",
          props: {
            placeholder: "Search tenants",
            removeSpecialCharacters: false
          },
          stickItem: true
        },
        {
          type: "optionsSelect",
          name: "applications",
          props: {
            styles: { root: { minWidth: 150 } },
            placeholder: "Application",
            hideSearchOption: true,
            multiSelect: true,
            options: [
              { key: ApplicationType.OMNI, text: "Omni" },
              { key: ApplicationType.PROS, text: "Pros" },
              { key: ApplicationType.CAM, text: "Cam" }
            ]
          }
        }
      ]}
      onChange={({ values }) => {
        setArgs(values);
      }}
      presets={[
        {
          name: "isInactive",
          iconName: "BlockContact",
          iconColor: theme.semanticColors.errorIcon,
          text: "Show inactive",
          textPressed: "Show active"
        }
      ]}
    />
  );
};
