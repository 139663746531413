import { TabsNav } from "@components/tabs-nav/TabsNav";
import { TenantSections } from "modules/tenants/sections";
import { TenantSelector } from "modules/tenants/tabs/TenantSelector";
import React, { FunctionComponent } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { TenantTabHeader } from "./TenantTabHeader";
import { TenantsTabs } from "../tenants-tabs.constants";

const TenantTabsBase: FunctionComponent = () => {
  const { tenantId } = useParams();

  const navigate = useNavigate();

  return (
    <TabsNav<{ sectionId: string }>
      tabKey="tenants"
      selectedTab={tenantId}
      navigatePath={details => {
        return generatePath("/tenants/:tenantId/:sectionId", {
          tenantId: details.id,
          sectionId: details.sectionId ?? TenantsTabs.Platform.Details.id
        });
      }}
      defaultPath="/tenants"
      renderTab={({ id }, setSectionId) => (
        <TenantSections
          tenantId={id}
          onSectionChange={sectionId => {
            const toSectionId = sectionId || TenantsTabs.Platform.Details.id;
            const to = generatePath("/tenants/:tenantId/:sectionId", {
              tenantId: id,
              sectionId: toSectionId
            });
            setSectionId(toSectionId);
            navigate(to);
          }}
        />
      )}
      renderDefault={() => <TenantSelector />}
      renderLink={({ details, isSelected }) => (
        <TenantTabHeader details={details} isSelected={isSelected} />
      )}
    />
  );
};

export const TenantTabs = () => {
  return <TenantTabsBase />;
};
