import { PropsWithChildren } from "react";

import { Stack, StackItem, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { AppointmentStats } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface Props {
  stats: AppointmentStats;
}

export const Row = ({
  label,
  children
}: PropsWithChildren<{ label: string }>) => (
  <Stack horizontal>
    <StackItem basis="14em">{label}</StackItem>
    <StackItem grow shrink>
      {children}
    </StackItem>
  </Stack>
);

export const AppointmentStatsTable = ({ stats }: Props) => {
  const theme = useTheme();

  const today = DateTime.now().startOf("day");

  return (
    <Stack>
      <Text styles={{ root: { marginBottom: theme.spacing.m } }}>
        The most recent appointment for this tenant was created at:{" "}
        <strong>
          {stats.lastCreated?.toFormat(
            DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
          )}
        </strong>
      </Text>

      <Text variant="large">Created appointment tallies</Text>
      <Row label="Today">{stats.todayCount}</Row>
      <Row label="Yesterday">{stats.yesterdayCount}</Row>
      <Row label="Last seven days">{stats.lastSevenDaysCount}</Row>
      <Row label="Prior seven days">{stats.previousSevenDayCount}</Row>
      <Row label={`This month (${today.toFormat("MMM yyyy")})`}>
        {stats.thisMonthCount}
      </Row>
      <Row
        label={`Last month (${today
          .minus({ months: 1 })
          .toFormat("MMMM yyyy")})`}
      >
        {stats.previousMonthCount}
      </Row>
      <Row label={`This year (${today.toFormat("yyyy")})`}>
        {stats.thisYearCount}
      </Row>
      <Row label={`Last year (${today.minus({ years: 1 }).toFormat("yyyy")})`}>
        {stats.previousYearCount}
      </Row>
    </Stack>
  );
};
