import {
  confirm,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { IsDevelopmentEnvironment } from "@libs/config/config";

interface ConfirmNewRolloutPastProps {
  changeState: () => void;
  downloadStartUtc: DateTime;
}

export const confirmRolloutPast = async ({
  changeState,
  downloadStartUtc
}: ConfirmNewRolloutPastProps) => {
  const dialogLabels = {
    title: "Confirm creating a rollout in the past.",
    buttons: {
      yes: "Confirm",
      no: "Back"
    }
  };

  const rolloutOccurred =
    downloadStartUtc < DateTime.now().setZone("utc") &&
    !IsDevelopmentEnvironment;

  if (rolloutOccurred) {
    const isConfirm = await confirm({
      children: (
        <Text>
          This rollout occurrs in the past. If you create or edit this ring, it
          will immediately become a
          <TextBadge
            badgeColor={TextBadgeColor.lightGrey}
            badgeSize={TextBadgeSize.small}
            styles={{
              root: { display: "inline", maxWidth: "max-content", margin: 5 }
            }}
          >
            Read Access
          </TextBadge>
          rollout and you will not be able to configure this ring.
        </Text>
      ),
      cancelButtonProps: {
        text: dialogLabels.buttons.no
      },
      confirmButtonProps: {
        text: dialogLabels.buttons.yes
      },
      dialogContentProps: {
        title: dialogLabels.title
      },
      minWidth: "550px"
    });
    if (isConfirm) {
      changeState();
    }
  } else {
    changeState();
  }
};
