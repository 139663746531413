import React from "react";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { AppointmentStats } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";
import { useAllAppointmentStatsQuery } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { ClinicBookingStatsTable } from "./ClinicBookingStatsTable";
import { TotalBookingStatsTable } from "./TotalBookingStatsTable";

const BookingStatsTotalBase: React.FC = () => {
  const query = useAllAppointmentStatsQuery();

  return (
    <>
      <SectionTitle>Total Appointment Statistics Summary</SectionTitle>
      <QueryStateIndicator<AppointmentStats[]> {...query}>
        {data => <TotalBookingStatsTable stats={data} />}
      </QueryStateIndicator>
      <SectionTitle>Clinic Appointment Statistics Summary</SectionTitle>
      <QueryStateIndicator<AppointmentStats[]> {...query}>
        {data => <ClinicBookingStatsTable stats={data} />}
      </QueryStateIndicator>
    </>
  );
};

export const BookingStatsTotal = withPermissions(
  BookingStatsTotalBase,
  [
    Permissions.BhbPracticeManagementRead,
    Permissions.BhbPracticeManagementWrite
  ],
  "or"
);
