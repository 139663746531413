export const AID_KEY = "C82DFE7CEA2441A58971BA81B3BE126B";
export const TenantProvisionSignupUrl = {
  uata: `https://uata.bptitanium.net/signup?aid=${AID_KEY}`,
  deva: `https://deva.bptitanium.net/signup?aid=${AID_KEY}`,
  proda: `https://allied.bestpractice.online/signup?aid=${AID_KEY}`
};

export enum ProvisioningStatus {
  Provisioned = "Provisioned",
  InProgress = "In progress",
  Unprovisioned = "Unprovisioned"
}

export enum HostName {
  LocalHost = "localhost",
  Deva = "deva.bpcloud.dev",
  Uata = "uata.bpcloud.dev",
  Proda = "bpcloud.io"
}
