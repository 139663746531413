import React, { useMemo } from "react";

import {
  IStackProps,
  IStyle,
  mergeStyles,
  Stack,
  useTheme
} from "@bps/fluent-ui";

interface SelectedBoxProps extends IStackProps {
  hasError?: boolean;
  isSelected?: boolean;
}

export const SelectedBox: React.FC<SelectedBoxProps> = ({
  isSelected,
  hasError,
  ...props
}) => {
  const theme = useTheme();

  const defaultStyle: IStyle = useMemo(
    () => ({
      padding: theme.spacing.s1,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "transparent"
    }),
    [theme]
  );

  const selectedStyle: IStyle = useMemo(
    () => ({
      borderColor: theme.semanticColors.variantBorder,
      background: theme.semanticColors.listItemBackgroundHovered
    }),
    [theme]
  );

  const errorStyle: IStyle = useMemo(
    () => ({
      borderColor: theme.semanticColors.errorText,
      background: theme.semanticColors.errorBackground
    }),
    [theme]
  );
  return (
    <Stack
      className={mergeStyles(
        defaultStyle,
        isSelected && selectedStyle,
        isSelected && hasError && errorStyle
      )}
      {...props}
    />
  );
};
