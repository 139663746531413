import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormProvider, useForm } from "react-hook-form";

import { IStackProps, PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface RingFormValues {
  displayName: string;
  code: string;
  isPublic: boolean;
  description: string;
}

interface RingFormProps extends Omit<IStackProps, "onSubmit"> {
  ring?: DeploymentRing;
  onSubmit: (values: RingFormValues) => void;
}

export const RingForm: React.FC<RingFormProps> = ({
  ring,
  onSubmit,
  ...props
}) => {
  const form = useForm<RingFormValues>({ defaultValues: ring });
  const theme = useTheme();

  return (
    <FormProvider {...form}>
      <Stack
        as="form"
        tokens={{ childrenGap: theme.spacing.s1 }}
        {...props}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormTextField label="Display Name" required name="displayName" />
        <FormTextField label="Code" required name="code" />
        <FormSwitch label="Public Opt-In" name="isPublic" />
        <FormTextField
          label="Description"
          required
          multiline
          name="description"
        />
        <Stack horizontal>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
