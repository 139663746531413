import React from "react";

import { useSoftwarePackageVersions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SoftwarePackageDefaultSummaryDto } from "./";
import { SoftwarePackageDefaultForm } from "./SoftwarePackageDefaultForm";

interface SoftwarePackageDefaultHostProps {
  softwarePackage: SoftwarePackageDefaultSummaryDto;
}

export const SoftwarePackageDefaultHost: React.FC<SoftwarePackageDefaultHostProps> = ({
  softwarePackage
}) => {
  const { softwarePackageId } = softwarePackage;
  const softwarePackageVersionsQuery = useSoftwarePackageVersions({
    softwarePackageId
  });

  const { data: softwarePackageVersions } = softwarePackageVersionsQuery;

  if (!softwarePackageVersions) return null;

  return (
    <SoftwarePackageDefaultForm
      softwarePackage={softwarePackage}
      softwarePackageVersions={softwarePackageVersions}
    />
  );
};
