import React, { FunctionComponent } from "react";
import {
  CopyToClipboardButton,
  Heading,
  Link,
  NativeList,
  Stack,
  Text
} from "@bps/fluent-ui";
import { addressText } from "./utils";
import { stripeAccountBaseUrl } from "@libs/stripe/stripe.utils";
import { StripeCustomerDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface StripeAccountDetailsProps {
  data: StripeCustomerDto;
}
export const StripeAccountDetails: FunctionComponent<StripeAccountDetailsProps> = ({
  data
}) => {
  return (
    <Stack tokens={{ childrenGap: 32 }}>
      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-heading">Account Details:</Heading>
        <NativeList>
          <Stack tokens={{ childrenGap: 8 }}>
            {data.country && (
              <li>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Text bold>Stripe instance id:</Text>
                  <Text>{data.country}</Text>
                </Stack>
              </li>
            )}

            {data.stripeCustomerId && (
              <li>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 4 }}
                >
                  <Text bold>Id:</Text>
                  <Text>{data.stripeCustomerId}</Text>
                  <CopyToClipboardButton
                    clipboardContent={data.stripeCustomerId}
                  />
                </Stack>
              </li>
            )}
            <li>
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                <Text bold>Stripe Dashboard:</Text>
                {data.stripeCustomerId && (
                  <Link
                    target="_blank"
                    href={`${stripeAccountBaseUrl()}customers/${
                      data.stripeCustomerId
                    }`}
                  >
                    Go to customer Stripe account
                  </Link>
                )}
              </Stack>
            </li>
          </Stack>
        </NativeList>
      </Stack>

      <Stack tokens={{ childrenGap: 8 }}>
        <Heading variant="section-heading">Billing Details:</Heading>
        <NativeList>
          <Stack tokens={{ childrenGap: 8 }}>
            <li>
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                <Text bold>Account Name:</Text>
                <Text>{data.name}</Text>
              </Stack>
            </li>

            <li>
              <Stack horizontal tokens={{ childrenGap: 4 }}>
                <Text bold>Account Email:</Text>
                <Text>{data.contact?.email}</Text>
              </Stack>
            </li>

            {!!data?.billingAddress && !!data?.country && (
              <li>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Text bold>Billing Address:</Text>
                  <Text>
                    {addressText({
                      ...data.billingAddress,
                      country: data.country
                    })}
                  </Text>
                </Stack>
              </li>
            )}
          </Stack>
        </NativeList>
      </Stack>
    </Stack>
  );
};
