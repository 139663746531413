import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import { NoDataTile, Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  useCallbackTypes,
  useCallbackTypesQuery
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { CallbackTypes } from "./CallbackTypes";

const CallbackTypesScreenBase: FunctionComponent = () => {
  const callbackTypes = useCallbackTypes();
  const applicationsQuery = useCallbackTypesQuery();

  return (
    <Stack tokens={{ childrenGap: 16 }}>
      <Stack.Item>
        <SectionTitle>Callback Types</SectionTitle>
        <QueryStateIndicator {...callbackTypes}>
          {data =>
            data.length ? (
              data?.map(i => (
                <CallbackTypes
                  key={i.code}
                  item={i}
                  onCallbackTypeUpdate={() => applicationsQuery.refetch()}
                />
              ))
            ) : (
              <NoDataTile
                showBoxShadow={false}
                textProps={{ text: "No callback types have been found!" }}
                linkProps={{ hidden: true }}
              />
            )
          }
        </QueryStateIndicator>
      </Stack.Item>
    </Stack>
  );
};

export const CallbackTypesScreen = withPermissions(
  CallbackTypesScreenBase,
  [Permissions.PltIntegConsentRead, Permissions.PltIntegConsentWrite],
  "or"
);
