import { CopyableGuid } from "@components/CopyableText";
import { DetailsRowExpand } from "@components/DetailsRowExpand";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { YesOrNoIcon } from "@components/YesOrNoIcon";
import { BpIdUserDto } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { BusinessUserCard } from "modules/users/BusinessUsers/BusinessUserCard";
import React from "react";

import {
  IColumn,
  mergeStyles,
  SelectionMode,
  ShimmeredDetailsListProps,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

const getSignInTypeId = (
  signInIdentities: BpIdUserDto["signInIdentities"],
  signInType: string
) => {
  return signInIdentities?.find(x => x.signInType === signInType)
    ?.issuerAssignedId;
};

const DEFAULT_DISPLAY_NAME = "unknown";

export const BusinessUsersTable: React.FC<ShimmeredDetailsListProps> = props => {
  const columns: IColumn[] = [
    {
      key: "id",
      name: "Id",
      minWidth: 100,
      maxWidth: 150,
      onRender: ({ id }) => (id ? <CopyableGuid short value={id} /> : "")
    },
    {
      key: "name",
      name: "Name",
      fieldName: "displayName",
      minWidth: 100,
      maxWidth: 350,
      onRender: ({ displayName, firstName, lastName }: BpIdUserDto) =>
        displayName && displayName !== DEFAULT_DISPLAY_NAME
          ? displayName
          : `${firstName} ${lastName}`
    },
    {
      key: "email",
      name: "Email",
      minWidth: 100,
      maxWidth: 300,
      onRender: ({ signInIdentities }) => (
        <Text nowrap>{getSignInTypeId(signInIdentities, "emailAddress")}</Text>
      )
    },
    {
      key: "createdDateTime",
      name: "Created",
      minWidth: 200,
      maxWidth: 200,
      onRender: ({ createdDate }: BpIdUserDto) =>
        createdDate &&
        DateTime.fromISO(createdDate).toFormat(DATE_FORMATS.DETAILED_DATE_TIME)
    },
    {
      key: "accountEnabled",
      name: "Enabled",
      minWidth: 100,
      maxWidth: 100,
      onRender: ({ accountEnabled }) => <YesOrNoIcon when={accountEnabled} />
    }
  ];
  return (
    <ShimmeredDetailsList
      selectionMode={SelectionMode.none}
      columns={columns}
      className={mergeStyles({
        "& [role=gridcell]": {
          display: "flex",
          alignItems: "center"
        }
      })}
      onRenderRow={row => (
        <DetailsRowExpand
          row={row}
          expand={<BusinessUserCard identity={row?.item} />}
        />
      )}
      {...props}
    />
  );
};
