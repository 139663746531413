import { FunctionComponent } from "react";
import { FilterBar } from "@components/filter-bar/FilterBar";
import { Item } from "@components/filter-bar/FilterBar.types";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  ComponentsActionsRequestsFilterProps,
  ComponentsActionsRequestsFilterValues
} from "./ComponentsRequestsFilter.types";
import {
  ComponentRequestActions,
  ComponentRequestStatuses
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export const ComponentsRequestsFilter: FunctionComponent<ComponentsActionsRequestsFilterProps> = props => {
  const { data = [] } = useComponentsDefs();
  const items: Item<ComponentsActionsRequestsFilterValues>[] = [
    {
      name: "componentCode",
      stickItem: true,
      type: "optionsSelect",
      props: {
        options: data.map(d => ({ key: d.code, text: d.text })),
        placeholder: "Code",
        styles: { root: { width: 250 } }
      }
    },
    {
      name: "componentAction",
      type: "optionsSelect",
      props: {
        options: Object.values(ComponentRequestActions).map(s => ({
          key: s,
          text: s
        })),
        placeholder: "Action",
        hideSearchOption: true,
        styles: { root: { width: 200 } }
      }
    },
    {
      name: "status",
      type: "optionsSelect",
      props: {
        options: Object.values(ComponentRequestStatuses).map(s => ({
          key: s,
          text: s
        })),
        hideSearchOption: true,
        placeholder: "Status",
        styles: { root: { width: 200 } }
      }
    }
  ];
  return (
    <FilterBar<ComponentsActionsRequestsFilterValues>
      items={items}
      {...props}
    />
  );
};
