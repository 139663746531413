import { withPermissions } from "@components/withPermissions";
import React from "react";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { PowerBiDataset } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";
import { useGetDatasets } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { ReportScreen } from "./components/ReportScreen";

const SystemOmniReportsBase: React.FC = () => {
  const queryGet = useGetDatasets();
  return (
    <QueryStateIndicator<PowerBiDataset[]> {...queryGet}>
      {data => <ReportScreen datasets={data} />}
    </QueryStateIndicator>
  );
};

export const SystemOmniReports = withPermissions(
  SystemOmniReportsBase,
  [Permissions.TiReportsRead, Permissions.TiReportsWrite],
  "or"
);
