import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { MouseEvent, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  IconButton,
  Link,
  mergeStyleSets,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { ConfirmUnassignSelectorDialog } from "./ConfirmUnassignSelectorDialog";

import {
  DeploymentRing,
  SelectorDto
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface DeploymentRingSelectorItemProps {
  selector: SelectorDto;
  deploymentRing: DeploymentRing;
}

export const DeploymentRingSelectorItem: React.FC<DeploymentRingSelectorItemProps> = ({
  selector,
  deploymentRing
}) => {
  const theme = useTheme();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const navigateToSelectors = () => {
    const to = generatePath("/system/plt/selectors");

    navigate(to);
  };

  const onShowDelete = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowDelete(true);
  };

  return (
    <>
      <Stack
        tokens={{ childrenGap: theme.spacing.s1 }}
        styles={{
          root: {
            borderColor: theme.semanticColors.variantBorder,
            borderWidth: 1,
            borderStyle: "solid",
            padding: theme.spacing.s1
          }
        }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
            <Stack styles={{ root: { width: "500px" } }}>
              <Text variant="large">
                <Link onClick={navigateToSelectors}>{selector.name}</Link>
              </Text>
              <Text variant="small">{selector.description}</Text>
            </Stack>
            <Stack>
              <Text>Devices</Text>
              <Text>{selector.deviceCount}</Text>
            </Stack>
          </Stack>
          <IconButton
            onClick={onShowDelete}
            styles={mergeStyleSets(dangerButtonStyles(theme), {
              root: {
                background: "transparent"
              }
            })}
            iconProps={{
              iconName: "Cancel"
            }}
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          />
        </Stack>
      </Stack>

      <ConfirmUnassignSelectorDialog
        deploymentRing={deploymentRing}
        selector={selector}
        hidden={!showDelete}
        onCancel={() => setShowDelete(false)}
      />
    </>
  );
};
