import { CommandRunnerProvider } from "../../../../../tenants/sections/_shared-models/CommandRunner";
import { useRootStore } from "@stores/StoresProvider";
import { SiteComponentDropdown } from "@components/pickers/SiteComponentDropdown";
import { SiteComponentVersionDropdown } from "@components/pickers/SiteComponentVersionDropdown";
import { observer } from "mobx-react";
import { SetDesiredVersionButton } from "modules/system/sections/pros/site-intergration/deploy-management/buttons/SetDesiredVersionButton";
import { SiteAgentStateButton } from "modules/system/sections/pros/site-intergration/deploy-management/buttons/SiteAgentStateButton";
import React, { useState } from "react";

import {
  DropdownProps,
  Heading,
  IconButton,
  ProgressIndicator,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import { ApplyManagerUpdateButton } from "./buttons/ApplyManagerUpdateButton";
import { PingSiteComponentButton } from "./buttons/PingSiteComponentButton";
import { ProsDatabaseControls } from "./ProsDatabaseControls";
import { ProsDeployLogs } from "./ProsDeployLogs";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { DeploymentAction } from "@stores/deployment-store/deployment-store.types";

interface ProsDeployControlsProps {
  onClear: () => void;
}

export const ProsDeployControls: React.FC<ProsDeployControlsProps> = observer(
  ({ onClear }) => {
    const theme = useTheme();

    const { deployment } = useRootStore();
    const [componentType, setComponentType] = useState<SiteComponentType>();
    const [desiredVersion, setDesiredVersion] = useState<string>();

    const onChangeComponentType: DropdownProps["onChange"] = (event, item) => {
      setComponentType(item?.key as SiteComponentType);
      setDesiredVersion("");
    };

    const onClearSelectedTenants = () => {
      deployment.setSelectedTenents([]);
      onClear();
    };

    const hasTenants = deployment.selectedTenants.length;

    if (!deployment.selectedTenants) {
      return null;
    }

    return (
      <CommandRunnerProvider>
        <Stack>
          <Stack
            tokens={{
              childrenGap: theme.spacing.m
            }}
            styles={{
              root: {
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.palette.neutralDark,
                padding: theme.spacing.m
              }
            }}
          >
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{
                childrenGap: theme.spacing.l2
              }}
            >
              <SiteComponentDropdown
                placeholder="Select Component Type"
                withNoEmptyOption
                selectedKey={componentType}
                onChange={onChangeComponentType}
                styles={{
                  root: {
                    width: 250
                  }
                }}
              />
              <Stack
                styles={{
                  root: {
                    flex: 1
                  }
                }}
              >
                {deployment.progressTotal ? (
                  <ProgressIndicator
                    description={`Commands Run: ${deployment.progressCompleted}/${deployment.progressTotal}`}
                    value={deployment.progressCompleted}
                    max={deployment.progressTotal}
                  />
                ) : null}
              </Stack>
              <Stack
                horizontal
                verticalAlign="center"
                styles={{
                  root: {
                    background: hasTenants
                      ? theme.semanticColors.listItemBackgroundChecked
                      : theme.semanticColors.infoBackground
                  }
                }}
              >
                <Stack
                  styles={{
                    root: {
                      paddingLeft: theme.spacing.s1,
                      paddingRight: theme.spacing.s1
                    }
                  }}
                >
                  {deployment.selectedTenants.length || "0"} tenant
                  {hasTenants === 1 ? "" : "s"} selected
                </Stack>
                <IconButton
                  iconProps={{ iconName: "Clear" }}
                  onClick={onClearSelectedTenants}
                />
              </Stack>
            </Stack>
            <Stack
              horizontal
              tokens={{
                childrenGap: theme.spacing.l2
              }}
            >
              <Stack
                tokens={{
                  childrenGap: theme.spacing.s1
                }}
              >
                <SiteComponentVersionDropdown
                  label="Desired Version"
                  disabled={
                    !deployment.selectedTenants.length || !componentType
                  }
                  placeholder="Select Component Version"
                  componentType={componentType}
                  selectedKey={desiredVersion}
                  onChange={item => setDesiredVersion(item?.key as string)}
                  styles={{
                    root: {
                      width: 250
                    }
                  }}
                />
                <SetDesiredVersionButton
                  componentType={componentType}
                  desiredVersion={desiredVersion}
                />
              </Stack>
              <Stack>
                <Heading labelPaddings>Modify PrOS Database</Heading>
                <Stack
                  tokens={{
                    childrenGap: theme.spacing.s1
                  }}
                >
                  <ProsDatabaseControls />
                </Stack>
              </Stack>
              {componentType === SiteComponentType.SiteAgent && (
                <Stack>
                  <Heading labelPaddings>Remote Agent Commands</Heading>
                  <Stack
                    tokens={{
                      childrenGap: theme.spacing.s1
                    }}
                  >
                    <SiteAgentStateButton
                      componentType={componentType}
                      deploymentAction={
                        DeploymentAction.AgentDownloadDesiredVersion
                      }
                    />

                    <Stack
                      horizontal
                      tokens={{
                        childrenGap: theme.spacing.s1
                      }}
                    >
                      <SiteAgentStateButton
                        componentType={componentType}
                        deploymentAction={
                          DeploymentAction.AgentInstallDesiredVersion
                        }
                      />
                      <SiteAgentStateButton
                        componentType={componentType}
                        deploymentAction={
                          DeploymentAction.AgentUpdateDesiredVersion
                        }
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {componentType === SiteComponentType.SiteManager && (
                <Stack>
                  <Heading labelPaddings>Remote Manager Commands</Heading>
                  <Stack
                    tokens={{
                      childrenGap: theme.spacing.s1
                    }}
                  >
                    <ApplyManagerUpdateButton componentType={componentType} />
                  </Stack>
                </Stack>
              )}
              {(componentType === SiteComponentType.SiteManager ||
                componentType === SiteComponentType.SiteAgent) && (
                <Stack>
                  <Heading labelPaddings>Site Component Health</Heading>
                  <Stack
                    tokens={{
                      childrenGap: theme.spacing.s1
                    }}
                  >
                    <PingSiteComponentButton componentType={componentType} />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
          <ProsDeployLogs
            styles={{
              root: {
                minHeight: 150
              }
            }}
          />
        </Stack>
      </CommandRunnerProvider>
    );
  }
);
