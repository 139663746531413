import { useRootStore } from "@stores/StoresProvider";
import React from "react";

import { DefaultButton, IButtonProps } from "@bps/fluent-ui";
import { useUpdateSiteComponentVersionMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { DeploymentAction } from "@stores/deployment-store/deployment-store.types";

interface SetDesiredVersionButtonProps extends IButtonProps {
  componentType?: SiteComponentType;
  desiredVersion?: string;
}

export const SetDesiredVersionButton: React.FC<SetDesiredVersionButtonProps> = ({
  componentType,
  desiredVersion,
  children,
  ...props
}) => {
  const { deployment } = useRootStore();
  const mutation = useUpdateSiteComponentVersionMutation();

  const onClick = async () => {
    if (
      !desiredVersion ||
      !componentType ||
      !deployment.selectedTenants.length
    ) {
      return;
    }
    deployment.clearDeployments();

    const promises = deployment.selectedTenants.map(tenant =>
      deployment.addDeployment(DeploymentAction.SetDesiredVersion, mutation, {
        siteId: tenant.id,
        component: componentType,
        version: desiredVersion
      })
    );
    await Promise.all(promises);
  };

  return (
    <DefaultButton
      onClick={onClick}
      disabled={
        !deployment.selectedTenants.length ||
        !componentType ||
        !desiredVersion ||
        deployment.isDeploying
      }
      {...props}
    >
      {children || "Set Desired Version"}
    </DefaultButton>
  );
};
