import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { EntitySyncStateTable } from "modules/tenants/sections/best-health/entity-sync-state/EntitySyncStateTable";

import {
  combineQueries,
  QueryStateIndicator
} from "@components/QueryStateIndicator";
import { EntitySequence } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";
import { useBhbEntitySyncStateQuery } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { EntitySyncState } from "@libs/api/gateways/tg/model/EntitySyncState";
import { useProsEntitySyncStateQuery } from "@libs/api/gateways/tg/tg-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}

const BestHealthSyncStateBase = ({ tenant }: Props) => {
  const bestHealthSyncStateQuery = useBhbEntitySyncStateQuery(tenant.id);
  const prosEntitySyncStateQuery = useProsEntitySyncStateQuery(tenant.id);

  const combinedQuery = combineQueries({
    bhb: bestHealthSyncStateQuery,
    pros: prosEntitySyncStateQuery
  });

  return (
    <>
      <SectionTitle>Booking Sync State</SectionTitle>
      <QueryStateIndicator<{ [key: string]: unknown }> {...combinedQuery}>
        {data => (
          <EntitySyncStateTable
            bhbSyncState={data.bhb as EntitySequence[]}
            prosEntitySyncState={data.pros as EntitySyncState}
          />
        )}
      </QueryStateIndicator>
    </>
  );
};

export const BestHealthSyncState = withPermissions(
  BestHealthSyncStateBase,
  [
    Permissions.BhbPracticeManagementWrite,
    Permissions.BhbPracticeManagementRead,
    Permissions.OpsConsolePreRelease
  ],
  "or"
);
