import { useRef, useState } from "react";

import { Selection, SelectionMode, Stack, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import {
  SiteComponentType,
  SiteComponentVersionDto,
  SiteComponentVersionResponse
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import {
  useApprovedComponentVersionsMutation,
  useApprovedComponentVersionsQuery,
  useAvailableComponentVersionsQuery,
  useDeleteComponentVersionsMutation
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { useRootStore } from "@stores/StoresProvider";

import { SysComVersionsApprove } from "./SysComVersionsApprove";
import {
  SysComVersionFilterValues,
  SysComVersionsFilter
} from "./SysComVersionsFilter";
import { SysComVersionsTable } from "./SysComVersionsTable";
import { SysComVersionsTabs } from "./SysComVersionsTabs";

const PrOSOpsSysComponentsBase = () => {
  const { feedback } = useRootStore();
  const availableVersions = useAvailableComponentVersionsQuery();
  const approveMutation = useApprovedComponentVersionsMutation();
  const deleteMutation = useDeleteComponentVersionsMutation();
  const { data: approvedVersions } = useApprovedComponentVersionsQuery();

  const [selectedVersions, setSelectedVersions] = useState<
    SiteComponentVersionResponse[]
  >([]);

  const [selectedTab, setSelectedTab] = useState<SiteComponentType | undefined>(
    SiteComponentType.SiteAgent
  );

  const selection = useRef(
    new Selection({
      onSelectionChanged() {
        const selectedVersions = selection.current.getSelection() as SiteComponentVersionResponse[];
        setSelectedVersions(selectedVersions);
      }
    })
  );

  const versionData = availableVersions.data?.find(
    x => x.component === selectedTab
  );

  const handleAdd = async () => {
    if (selectedTab === undefined || selectedVersions === undefined) {
      return;
    }

    // Approve all selected versions
    const approvalPromises = Array.from(selectedVersions).map(
      async selectedVersion => {
        const approvedVer = approvedVersions?.find(
          version => version.componentVersion === selectedVersion.version
        );
        if (approvedVer !== undefined) {
          feedback.error(
            `Version ${selectedVersion.version} is already approved`
          );
          return;
        }

        const approvalData = await approveMutation.mutate({
          componentType: selectedTab,
          componentVersion: selectedVersion.version
        });
        feedback.success(
          `Version ${selectedVersion.version} has been approved.`
        );
        return approvalData;
      }
    );
    await Promise.all(approvalPromises);

    // Clear selected
    selection.current.setItems([]);
  };

  const handleRemove = async () => {
    if (selectedTab === undefined || selectedVersions === undefined) {
      return;
    }

    // Approve all selected versions
    const approvalPromises = Array.from(selectedVersions).map(
      async selectedVersion => {
        const approvedVer = approvedVersions?.find(
          version => version.componentVersion === selectedVersion.version
        );

        if (approvedVer === undefined) {
          feedback.error(
            `Version ${selectedVersion.version} is not approved and can not be set as unapproved.`
          );
          return;
        }

        const deleteData = await deleteMutation.mutate(approvedVer?.id);
        feedback.success(
          `Version ${selectedVersion.version} has been unapproved.`
        );
        return deleteData;
      }
    );

    await Promise.all(approvalPromises);

    // Clear selected
    selection.current.setItems([]);
  };

  const theme = useTheme();
  return (
    <>
      <SectionTitle>System Components</SectionTitle>
      Approve site agent or site manager versions from the last year.
      <Stack
        styles={{ root: { paddingTop: 24 } }}
        tokens={{ childrenGap: theme.spacing.m }}
      >
        <QueryStateIndicator<SiteComponentVersionDto[]> {...availableVersions}>
          <>
            <Stack
              horizontal
              styles={{
                root: { justifyContent: "space-between", alignItems: "center" }
              }}
            >
              <SysComVersionsTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selection={selection}
              />

              <SysComVersionsApprove
                selectedVersions={selectedVersions}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
              />
            </Stack>
            <SysComVersionsFilter>
              {filter => {
                const filteredItems = getFilteredItems(
                  filter.values,
                  versionData?.versions!
                );
                return (
                  <SysComVersionsTable
                    selectionPreservedOnEmptyClick
                    selectionMode={SelectionMode.multiple}
                    selection={selection.current}
                    setKey="siteManager-Table"
                    detailsListStyles={{
                      contentWrapper: {
                        ":hover": {
                          background: theme.palette.neutralQuaternaryAlt
                        }
                      }
                    }}
                    items={filteredItems}
                  />
                );
              }}
            </SysComVersionsFilter>
          </>
        </QueryStateIndicator>
      </Stack>
    </>
  );
};

// Apply Filter Logic
const getFilteredItems = (
  filter: SysComVersionFilterValues,
  versionData: SiteComponentVersionResponse[]
) => {
  return versionData.filter(version => {
    if (!version) {
      return null;
    }

    const containsName = version.version
      ?.toLowerCase()
      .includes(filter.search.toLocaleLowerCase());

    const withinLastYear =
      version.createdDate > DateTime.now().minus({ year: 1 });

    return containsName && withinLastYear;
  });
};

export const PrOSOpsSysComponents = withPermissions(
  PrOSOpsSysComponentsBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
