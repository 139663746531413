import { useRootStore } from "@stores/StoresProvider";
import { observer } from "mobx-react";
import React, { useState } from "react";

import {
  IconButton,
  IStackProps,
  ITextStyles,
  mergeStyleSets,
  ScrollablePane,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DeploymentLogLevel } from "@stores/deployment-store/deployment-store.types";

interface ProsDeployLogsProps extends IStackProps {
  logs?: string[];
}

export const ProsDeployLogs: React.FC<ProsDeployLogsProps> = observer(
  ({ logs, styles, ...props }) => {
    const theme = useTheme();
    const { deployment } = useRootStore();
    const [fullscreen, setFullscreen] = useState<boolean>();

    const logTextInfoStyle: ITextStyles = {
      root: {
        color: theme.palette.white
      }
    };

    const logTextErrorStyle: ITextStyles = {
      root: {
        color: theme.palette.red
      }
    };

    return (
      <Stack
        verticalFill
        {...props}
        styles={mergeStyleSets(
          {
            root: {
              top: 0,
              left: 0,
              position: fullscreen ? "absolute" : "relative",
              height: fullscreen ? "100%" : undefined,
              width: fullscreen ? "100%" : undefined,
              padding: theme.spacing.s1,
              background: theme.palette.themeDarker,
              color: "white"
            }
          },
          styles
        )}
      >
        <ScrollablePane>
          <Stack
            styles={{
              root: {
                padding: theme.spacing.s1
              }
            }}
          >
            {deployment.logs?.map(log => (
              <Text
                key={log.text}
                styles={
                  log.level === DeploymentLogLevel.Error
                    ? logTextErrorStyle
                    : logTextInfoStyle
                }
              >
                {log.text}
              </Text>
            ))}
          </Stack>
        </ScrollablePane>
        <Stack
          horizontal
          styles={{
            root: {
              position: "absolute",
              top: theme.spacing.s1,
              right: theme.spacing.s1
            }
          }}
        >
          <IconButton
            onClick={() => deployment.setLogs([])}
            styles={{
              icon: {
                color: theme.palette.white
              },
              iconHovered: {
                color: theme.palette.neutralDark
              }
            }}
            iconProps={{ iconName: "StatusCircleBlock" }}
          />
          <IconButton
            onClick={() => setFullscreen(_fullscreen => !_fullscreen)}
            styles={{
              icon: {
                color: theme.palette.white
              },
              iconHovered: {
                color: theme.palette.neutralDark
              }
            }}
            iconProps={{ iconName: fullscreen ? "BackToWindow" : "FullScreen" }}
          />
        </Stack>
      </Stack>
    );
  }
);
