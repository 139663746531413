import React, { FunctionComponent, useState } from "react";
import { Pivot, PivotItem } from "@bps/fluent-ui";
import { PermissionsAndRolesItem } from "./PermissionsAndRolesItem";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";

enum SelectedKeys {
  permissions = "permissions",
  roles = "roles",
  businessRoles = "businessRoles"
}

interface PermissionsAndRolesProps {
  user: PltUser;
}

export const PermissionsAndRoles: FunctionComponent<PermissionsAndRolesProps> = ({
  user
}) => {
  const [selectedKey, setSelectedKey] = useState<SelectedKeys>(
    SelectedKeys.permissions
  );
  return (
    <Pivot
      selectedKey={selectedKey}
      onLinkClick={item =>
        item?.props.itemKey &&
        setSelectedKey(item.props.itemKey as SelectedKeys)
      }
      style={{ width: "100%" }}
      styles={{ root: { paddingBottom: 16 } }}
    >
      <PivotItem itemKey={SelectedKeys.permissions} headerText="Permissions">
        <PermissionsAndRolesItem items={user?.permissions ?? []} />
      </PivotItem>
      <PivotItem headerText="Roles" itemKey={SelectedKeys.roles}>
        <PermissionsAndRolesItem items={(user?.roles ?? []).map(r => r.code)} />
      </PivotItem>
      <PivotItem
        headerText="Business roles"
        itemKey={SelectedKeys.businessRoles}
      >
        <PermissionsAndRolesItem items={user?.businessRoles ?? []} />
      </PivotItem>
    </Pivot>
  );
};
