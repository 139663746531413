import React, { FunctionComponent, useMemo } from "react";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { useComponentsDefs } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { IColumn, ScrollablePane, Stack } from "@bps/fluent-ui";
import { ComponentDefDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { getFilteredComponentsDefinitions } from "./components-definitions-list.utils";
import { ComponentsDefinitionsFilterValues } from "./ComponentsDefinitionsFilter.types";
import { ExpandableRow } from "@components/tables/ExpandableRow";
import { useExpandedRows } from "@components/tables/useExpandedRows";
import { ComponentsDefinitionsExpandedRow } from "./components-definitions-details/ComponentsDefinitionsExpandedRow";

interface ComponentsDefinitionsListProps {
  filter: ComponentsDefinitionsFilterValues;
}
export const ComponentsDefinitionsList: FunctionComponent<ComponentsDefinitionsListProps> = ({
  filter
}) => {
  const { expandedIds, toggleExpandedIds } = useExpandedRows();

  const { data = [], isLoading, error } = useComponentsDefs();

  const items = useMemo(() => getFilteredComponentsDefinitions(data, filter), [
    data,
    filter
  ]);

  const columns: IColumn[] = [
    {
      key: "code",
      name: "Code",
      minWidth: 100,
      maxWidth: 300,
      onRender: (item: ComponentDefDto) => item.code
    },
    {
      key: "text",
      name: "Name",
      minWidth: 100,
      maxWidth: 400,
      onRender: (item: ComponentDefDto) => item.text
    },
    {
      key: "scope",
      name: "Scope",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentDefDto) => item.scope
    },
    {
      key: "implementations-allowed",
      name: "Implementations allowed",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentDefDto) => item.implementationsLimit
    },
    {
      key: "source",
      name: "Source",
      minWidth: 100,
      maxWidth: 200,
      onRender: (item: ComponentDefDto) => item.source
    }
  ];

  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <ShimmeredDetailsList
          items={items}
          columns={columns}
          enableShimmer={isLoading}
          errorMessage={error?.message}
          stickyHeader
          onRenderRow={(props, renderDefault) => {
            if (props && renderDefault) {
              return (
                <ExpandableRow
                  expandedIds={expandedIds}
                  toggleExpandedIds={toggleExpandedIds}
                  ExpandableComponent={
                    <ComponentsDefinitionsExpandedRow component={props.item} />
                  }
                  rowProps={props}
                  id={props.item.code}
                />
              );
            }
            return null;
          }}
        />
      </ScrollablePane>
    </Stack>
  );
};
