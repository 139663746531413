import React from "react";

import {
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { CommandResponse } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface CommandResultProps {
  response?: CommandResponse;
}

export const CommandResult: React.FC<CommandResultProps> = ({ response }) => {
  const theme = useTheme();
  return (
    <Stack
      tokens={{ childrenGap: theme.spacing.s1 }}
      styles={{
        root: {
          flex: 1,
          borderColor: theme.semanticColors.variantBorder,
          borderWidth: 1,
          borderStyle: "solid",
          padding: theme.spacing.s1
        }
      }}
    >
      <Text variant="xLarge">Command Result</Text>
      {response && (
        <>
          <MessageBar
            messageBarType={
              response.Status?.toString().startsWith("2") // 200 level status codes are successful
                ? MessageBarType.success
                : MessageBarType.error
            }
          >
            <Stack styles={{ root: { width: "100%" } }}>
              <Text>
                <b>Status:</b> {response.Status ?? "null"}
              </Text>
              <Text>
                <b>ErrorMessage:</b> {response.ErrorMessage ?? "null"}
              </Text>
              <Text>
                <b>Value:</b> {response.Value ?? "null"}
              </Text>
              <Text>
                <b>ValueType:</b> {response.ValueType ?? "null"}
              </Text>
            </Stack>
          </MessageBar>

          <Stack
            styles={{
              root: { alignContent: "end", alignItems: "end", flexWrap: "wrap" }
            }}
          >
            <Text variant="xSmall">{DateTime.now().toFormat("FFFF")}</Text>
          </Stack>
        </>
      )}
    </Stack>
  );
};
