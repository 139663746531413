import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { AppointmentReminderFilter } from "modules/tenants/sections/ttm/appointment-reminders/AppointmentReminderFilter";
import { AppointmentRemindersTable } from "modules/tenants/sections/ttm/appointment-reminders/AppointmentRemindersTable";
import { FunctionComponent } from "react";

import { Card } from "@bps/fluent-ui";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

const AppointmentRemindersScreenBase: FunctionComponent<Props> = ({
  tenant
}) => {
  return (
    <Card
      iconName="SearchCalendar"
      headingLevel="section-heading"
      heading="Reminders Search"
      styles={cardStyles}
    >
      <AppointmentReminderFilter>
        {({ values: filters }) => (
          <AppointmentRemindersTable tenant={tenant} filters={filters} />
        )}
      </AppointmentReminderFilter>
    </Card>
  );
};

export const AppointmentRemindersScreen = withPermissions(
  AppointmentRemindersScreenBase,
  [Permissions.TiSettingsRead]
);
