import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";
import { SelectorArgs } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface SelectorsFilterValues {
  name: string;
}

interface SelectorsFilterProps extends PropsWithChildren<any> {
  setFilterArgs: React.Dispatch<React.SetStateAction<SelectorArgs | undefined>>;
}

export const SelectorsFilter = ({
  children,
  setFilterArgs
}: SelectorsFilterProps) => {
  const filterItems: Item<SelectorsFilterValues>[] = [
    {
      name: "name",
      type: "searchBox",
      stickItem: true,
      props: { placeholder: "Search by name", removeSpecialCharacters: false }
    }
  ];

  return (
    <FilterBar<SelectorsFilterValues>
      items={filterItems}
      initialValues={{
        name: ""
      }}
      onChange={filter => setFilterArgs(filter.values)}
    >
      {children}
    </FilterBar>
  );
};
