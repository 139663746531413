import { useCommandRunner } from "../../../_shared-models/CommandRunner";

import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { useState } from "react";
import { UseMutationResult } from "react-query";

import {
  DatePicker,
  Heading,
  IContextualMenuProps,
  Stack,
  TimePicker,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  SiteCommandUpdateArgs,
  useSiteCommandUpdateMutation
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  SiteCommand,
  SiteComponentState,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

interface Props {
  commandName: string;
  iconName: string;
  scheduleDate: Date | undefined;
  scheduleTime: string | undefined;
  mutation: UseMutationResult<string, Error, SiteCommandUpdateArgs>;
  siteId: string;
}

const UpdateCommandButton = ({
  siteId,
  commandName,
  iconName,
  mutation,
  scheduleDate,
  scheduleTime
}: Props) => {
  const commandRunner = useCommandRunner();

  let scheduled: DateTime | undefined;
  if (scheduleDate && scheduleTime) {
    const [scheduleHour, scheduleMinute] = scheduleTime.split(":");
    scheduled = DateTime.fromJSDate(scheduleDate).set({
      hour: Number(scheduleHour),
      minute: Number(scheduleMinute)
    });
  }

  const handleRunForComponent = (
    componentType: string,
    commandType: string,
    subCommandType?: string
  ) => () => {
    return commandRunner.runMutation("Site Update Command", mutation, {
      tenantId: siteId,
      commandType,
      componentType,
      subCommandType,
      scheduled: scheduled?.setZone("utc")
    });
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Start",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Start
        )
      },
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Stop",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Stop
        )
      },
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Download",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Download
        )
      },
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Install",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Install
        )
      },
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Restart",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Restart
        )
      },
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Uninstall",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Uninstall
        )
      },
      {
        key: SiteComponentType.SiteAgent,
        text: "Agent - Update",
        onClick: handleRunForComponent(
          SiteComponentType.SiteAgent,
          SiteCommand.ChangeState,
          SiteComponentState.Update
        )
      },
      {
        key: "CONFIGURATION",
        text: "Configuration - Upload Log",
        onClick: handleRunForComponent(
          SiteCommand.Configuration,
          SiteCommand.UploadLog
        )
      }
    ]
  };

  return (
    <SiteCommandButton
      commandName={commandName}
      menuProps={menuProps}
      iconName={iconName}
    />
  );
};

export const SiteUpdateCommandButton = (props: { siteId: string }) => {
  const siteCommandUpdateMutation = useSiteCommandUpdateMutation();
  const theme = useTheme();

  const [scheduleDate, setScheduleDate] = useState<Date | undefined>();
  const [scheduleTime, setScheduleTime] = useState<string | undefined>();

  return (
    <>
      <Stack
        horizontal
        tokens={{ childrenGap: theme.spacing.m }}
        verticalAlign="start"
      >
        <Stack.Item align="center">
          <Heading labelPaddings>Schedule Date</Heading>
          <DatePicker
            value={scheduleDate}
            onChange={(date): void => {
              setScheduleDate(date);
            }}
          />
        </Stack.Item>

        <Stack.Item align="center">
          <Heading labelPaddings>Schedule Time</Heading>
          <TimePicker
            value={scheduleTime}
            onChange={(time): void => {
              setScheduleTime(time);
            }}
          />
        </Stack.Item>

        <UpdateCommandButton
          {...props}
          commandName="Schedule Commands"
          iconName="AddIn"
          scheduleDate={scheduleDate}
          scheduleTime={scheduleTime}
          mutation={siteCommandUpdateMutation}
        />
      </Stack>
    </>
  );
};
