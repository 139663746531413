import { FunctionComponent } from "react";
import { SideNavLayout } from "@layouts/SideNavLayout";
import {
  generatePath,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import { CRMClientDetailsSection } from "./CRMClientDetailsSection";
import { ApplicationsSection } from "./ApplicationsSection";
import { CustomerAccountSection } from "./CustomerAccountSection";
import { useTheme, Stack } from "@bps/fluent-ui";
import { VerticalTabs } from "@components/VerticalTabs";
import { CustomersTabs } from "./customers-tabs.constants";

interface CustomerPageProps {
  crmId: string;
}

export const CustomerPage: FunctionComponent<CustomerPageProps> = ({
  crmId
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { selectedTab } = useParams<{ selectedTab: string }>();
  return (
    <SideNavLayout
      nav={
        <Stack tokens={{ childrenGap: theme.spacing.m }}>
          <VerticalTabs
            title="Customer"
            tabs={Object.values(CustomersTabs.Customer)}
            selectedTab={
              selectedTab ?? CustomersTabs.Customer.CRMClientDetails.id
            }
            onSelectedTabChange={nextId => {
              const path = generatePath("/cam/:crmId/:selectedTab", {
                selectedTab: nextId,
                crmId
              });
              navigate(path);
            }}
          />
        </Stack>
      }
    >
      <Routes>
        <Route
          path={`:camId/${CustomersTabs.Customer.CRMClientDetails.id}`}
          element={<CRMClientDetailsSection />}
        />
        <Route path=":camId" element={<CRMClientDetailsSection />} />
        <Route
          path={`:camId/${CustomersTabs.Customer.Applications.id}`}
          element={<ApplicationsSection />}
        />
        <Route
          path={`:camId/${CustomersTabs.Customer.CustomerAccount.id}`}
          element={<CustomerAccountSection />}
        />
      </Routes>
    </SideNavLayout>
  );
};
