import React, { FunctionComponent } from "react";
import { DetailsRow, ScrollablePane, Stack, useTheme } from "@bps/fluent-ui";
import { WarningIconTooltip } from "./WarningIconTooltip";
import { useTabsNavContext } from "../../components/tabs-nav";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { useCustomerAccountsQuery } from "@libs/api/gateways/cam/cam-gateway.hooks";
import { CustomerAccount } from "@libs/api/gateways/cam/cam-gateway.dtos";

interface CustomersListProps {
  crmId: string;
}

const NA_TEXT = "N/A";
const NO_TEXT = "NO";
const YES_TEXT = "YES";
const DEFAULT_TENANTS_NUMBER = 0;
const MIN_TENANTS_NUMBER = 1;

export const CustomersList: FunctionComponent<CustomersListProps> = ({
  crmId
}) => {
  const theme = useTheme();
  const { addTab } = useTabsNavContext();
  const {
    data: customerAccounts = [],
    isLoading,
    error
  } = useCustomerAccountsQuery({
    crmId
  });

  const getHasBpCloudCustomerAccountText = (item: CustomerAccount) => {
    if (item.headOfficeId && item.caTenants.length === DEFAULT_TENANTS_NUMBER)
      return NA_TEXT;

    if (!item.headOfficeId && item.caTenants.length === DEFAULT_TENANTS_NUMBER)
      return NO_TEXT;

    if (item.caTenants.length > DEFAULT_TENANTS_NUMBER) return YES_TEXT;
    return NA_TEXT;
  };

  const getHasBpCloudCustomerAccountIcon = (item: CustomerAccount) => {
    if (item.headOfficeId && item.caTenants.length > DEFAULT_TENANTS_NUMBER)
      return (
        <WarningIconTooltip content="We display a warning when there are more than zero CA AppTypes associated with this Customer Id, and CRM Head Office Id != null" />
      );

    if (!item.headOfficeId && item.caTenants.length > MIN_TENANTS_NUMBER)
      return (
        <WarningIconTooltip content="We display a warning when there are more than one CA AppTypes associated with this Customer Id" />
      );
    return null;
  };

  const crmIdSortPredicate = (a: CustomerAccount, b: CustomerAccount) => {
    const crmIdNumberA = a.crmId.split("-")[1];
    const crmIdNumberB = b.crmId.split("-")[1];
    return crmIdNumberA.localeCompare(crmIdNumberB);
  };

  const columns: DataTableColumn<CustomerAccount>[] = [
    {
      key: "crmId",
      name: "CRM Id",
      minWidth: 80,
      maxWidth: 100,
      onRender: (customerAccount: CustomerAccount) => customerAccount.crmId,
      sort: crmIdSortPredicate,
      isSorted: true
    },
    {
      key: "existsInCrm",
      name: "Exists in CRM",
      minWidth: 80,
      maxWidth: 100,
      onRender: (customerAccount: CustomerAccount) => {
        if (customerAccount.existsInCrm) return YES_TEXT;
        return (
          <>
            {NO_TEXT}
            <WarningIconTooltip content="We display a warning when we find a Customer Id in BpCloud that does not exist in Dynamics" />
          </>
        );
      },
      sort: true,
      isSorted: true
    },
    {
      key: "businessName",
      name: "CRM Name",
      minWidth: 200,
      maxWidth: 300,
      onRender: (customerAccount: CustomerAccount) =>
        customerAccount.existsInCrm ? customerAccount.businessName : NA_TEXT,
      sort: true,
      isSorted: true
    },
    {
      key: "headOfficeCrmId",
      name: "CRM Parent Id",
      minWidth: 120,
      maxWidth: 160,
      onRender: (customerAccount: CustomerAccount) =>
        customerAccount.existsInCrm ? customerAccount.headOfficeCrmId : NA_TEXT,
      sort: true,
      isSorted: true
    },
    {
      key: "billingClientCrmId",
      name: "CRM Billing Id",
      minWidth: 120,
      maxWidth: 160,
      onRender: (customerAccount: CustomerAccount) =>
        customerAccount.existsInCrm
          ? customerAccount.billingClientCrmId
          : NA_TEXT,
      sort: true,
      isSorted: true
    },
    {
      key: "bpCloudAppCount",
      name: "BpCloud App Count",
      minWidth: 140,
      maxWidth: 180,
      onRender: (customerAccount: CustomerAccount) =>
        customerAccount.tenants?.length ?? DEFAULT_TENANTS_NUMBER,
      sort: true,
      isSorted: true
    },
    {
      key: "hasBpCloudCustomerAccount",
      name: "Has BpCloud Customer Account",
      minWidth: 140,
      maxWidth: 180,
      onRender: (customerAccount: CustomerAccount) => (
        <>
          {getHasBpCloudCustomerAccountText(customerAccount)}
          {getHasBpCloudCustomerAccountIcon(customerAccount)}
        </>
      ),
      sort: true,
      isSorted: true
    }
  ];

  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <DataTable
          stickyHeader
          isSelectedOnFocus
          enableShimmer={isLoading}
          errorMessage={error?.message}
          columns={columns}
          items={customerAccounts}
          onRenderRow={row =>
            row ? (
              <Stack
                onClick={() => {
                  const customerAccount: CustomerAccount = row.item;
                  addTab({
                    id: customerAccount.crmId,
                    name: customerAccount.businessName,
                    data: customerAccount
                  });
                }}
                styles={{
                  root: {
                    "&:hover": {
                      backgroundColor:
                        theme.semanticColors.listItemBackgroundHovered,
                      cursor: "pointer"
                    }
                  }
                }}
              >
                <DetailsRow {...row} />
              </Stack>
            ) : null
          }
        />
      </ScrollablePane>
    </Stack>
  );
};
