import { FunctionComponent, useRef } from "react";
import { FilterBarProps, Item } from "@components/filter-bar/FilterBar.types";
import { FilterBar } from "@components/filter-bar/FilterBar";

interface UserSignInLogsFilterProps
  extends Pick<FilterBarProps<UserSignInLogsFilterValues>, "children"> {}

interface UserSignInLogsFilterValues {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

export const UserSignInLogsFilter: FunctionComponent<UserSignInLogsFilterProps> = props => {
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 1);
  const initialValues = useRef<UserSignInLogsFilterValues>({
    startDate: defaultStartDate,
    endDate: new Date()
  });

  const items: Item<UserSignInLogsFilterValues>[] = [
    {
      type: "datesRangePicker",
      name: "datesRangePicker",
      stickItem: true,
      props: {
        id: "usersigninlogs-filter-dates-range",
        startDateProps: { id: "usersigninlogs-filter-start-date" },
        endDateProps: { id: "usersigninlogs-filter-end-date" },
        startDateName: "startDate",
        endDateName: "endDate",
        placeholder: "Date(s)"
      }
    }
  ];

  return (
    <FilterBar {...props} items={items} initialValues={initialValues.current} />
  );
};
