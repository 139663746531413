import { useMemo, useState } from "react";

import { Option, PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";
import { Form } from "@components/form/Form";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  useCertificatesQuery,
  useTenantSettingQuery,
  useUpdateTenantSetting
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import {
  ApplicationType,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantsPltQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { getPagesResult } from "@libs/paging/paging.utils";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { IntegCertificate } from "./Certificate";
import { RegisterCertificateDialog } from "./RegisterCertificateDialog";

interface IntegCertificateProps {
  tenant: Tenant;
}
interface CertificateProxySettingFormValues {
  proxyTenantId?: string;
}

const IntegCertificatesBase: React.FC<IntegCertificateProps> = ({ tenant }) => {
  const theme = useTheme();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegAppWrite
  );

  const certificatesQuery = useCertificatesQuery(tenant.id);
  const [
    showCreateApplicationDialog,
    setshowCreateApplicationDialog
  ] = useState<boolean>(false);

  const tenantSettingQuery = useTenantSettingQuery(tenant.id);
  const { data } = useTenantsPltQuery({ applications: [ApplicationType.CAM] });

  const tenants = useMemo(() => getPagesResult(data), [data]);

  const proxiableTenants: Option[] = !tenants
    ? []
    : [
        {
          key: "",
          text: "None"
        }
      ].concat(
        tenants
          .filter(x => x.id !== tenant.id)
          .map(tenant => ({
            key: tenant.id,
            text: tenant.name!
          }))
      );

  const {
    mutateAsync: updateTenantSetting,
    error: updateTenantSettingError
  } = useUpdateTenantSetting();

  const onSubmit = async (formData: CertificateProxySettingFormValues) => {
    await updateTenantSetting({
      tenantId: tenant.id,
      allowCertificateFromTenantId: formData.proxyTenantId
    });
  };

  return (
    <>
      <Stack
        verticalFill
        styles={{ root: { padding: theme.spacing.s1 } }}
        tokens={{ childrenGap: theme.spacing.l2 }}
      >
        <Stack horizontalAlign="space-between" horizontal>
          <SectionTitle>Client Certificates</SectionTitle>
          <PrimaryButton
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            onClick={() => setshowCreateApplicationDialog(true)}
          >
            Register Public Certificate
          </PrimaryButton>
          {showCreateApplicationDialog && (
            <RegisterCertificateDialog
              tenant={tenant}
              onDismiss={() => {
                setshowCreateApplicationDialog(false);
              }}
            />
          )}
        </Stack>
        <Stack tokens={{ childrenGap: 16 }}>
          <QueryStateIndicator
            {...certificatesQuery}
            isLoading={certificatesQuery.isLoading}
            error={certificatesQuery.error}
          >
            {data =>
              data.length
                ? data?.map(i => (
                    <IntegCertificate
                      key={i.id}
                      certificate={i}
                      tenant={tenant}
                    />
                  ))
                : "There are no existing certificates."
            }
          </QueryStateIndicator>
        </Stack>
      </Stack>
      <Stack
        verticalFill
        styles={{ root: { padding: theme.spacing.s1 } }}
        tokens={{ childrenGap: theme.spacing.l2 }}
      >
        <Form<CertificateProxySettingFormValues>
          onSubmit={onSubmit}
          defaultValues={{}}
          error={updateTenantSettingError}
        >
          <FormDropdown
            name="proxyTenantId"
            placeholder="Select tenant"
            defaultSelectedKey={
              tenantSettingQuery.data?.value?.allowCertificateFromTenantId
            }
            label="Allow this tenant's applications to be used with another tenant's certificates?"
            options={proxiableTenants}
            withNoEmptyOption={true}
            disabled={!hasWritePermission}
          />
        </Form>
      </Stack>
    </>
  );
};

export const IntegCertificates = withPermissions(
  IntegCertificatesBase,
  [Permissions.PltIntegAppRead, Permissions.PltIntegAppWrite],
  "or"
);
