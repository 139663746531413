import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import React from "react";
import { CustomersFilter } from "./CustomersFilter";
import { CustomersList } from "./CustomersList";
import { Tile } from "@bps/fluent-ui";

const CustomerSearchPageBase: React.FC = () => {
  return (
    <Tile styles={{ root: { height: "100%" } }}>
      <CustomersFilter>
        {({ values: { crmId } }) => <CustomersList crmId={crmId} />}
      </CustomersFilter>
    </Tile>
  );
};

export const CustomerSearchPage = withPermissions(
  CustomerSearchPageBase,
  [Permissions.PltCustomerRead, Permissions.PltCustomerWrite],
  "or"
);
