import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";

import { guid } from "@libs/common/guid";
import { confirmPremSettings } from "modules/tenants/sections/bp-premier/prem-settings/prem-settings-modal";
import { useFormContext, UseFormReturn } from "react-hook-form";
import {
  PremSettings,
  PremSettingsUpdate
} from "@libs/api/gateways/tg/tg-ops-gateway.dtos";
import { usePremSettingsMutation } from "@libs/api/gateways/tg/tg-ops-gateway.hooks";
import { DATE_FORMATS } from "@bps/utils";

interface PremSettingsTableProps {
  premSettings: PremSettings[];
  siteId: guid;
}

export interface PremSettingFormValues {
  updateValues: PremSettingsUpdate[];
}

const SettingValueColumn = (premSettings: PremSettingsUpdate) => {
  const { watch } = useFormContext();
  const currentValue: PremSettingsUpdate[] = watch("updateValues");
  const settingValueCol = `updateValues[${currentValue.findIndex(
    value => value.settingName === premSettings.settingName
  )}].settingValue`;

  return <FormTextField name={settingValueCol} />;
};

export const PremSettingsTable = ({
  premSettings,
  siteId
}: PremSettingsTableProps) => {
  const {
    mutateAsync: updateSettings,
    error: updateLicenceError
  } = usePremSettingsMutation();

  const defaultValues: PremSettingFormValues = {
    updateValues: premSettings.map(p => ({
      settingName: p.settingName,
      settingValue: p.settingValue
    }))
  };

  const changeState = async (
    values: PremSettingFormValues,
    form: UseFormReturn,
    changedValues: PremSettingsUpdate[]
  ) => {
    try {
      await updateSettings({
        siteId,
        updates: changedValues
      });
      form.reset(values);
    } catch (e) {
      return e;
    }
  };

  const onSubmit = async (
    values: PremSettingFormValues,
    form: UseFormReturn
  ) => {
    confirmPremSettings({ changeState, values, form, premSettings });
  };

  const columns: DataTableColumn<PremSettings>[] = [
    {
      key: "settingName",
      name: "Setting Name",
      minWidth: 350,
      maxWidth: 400,
      sort: true,
      isSorted: true,
      filterable: true
    },
    {
      key: "settingValue",
      name: "Setting Value",
      minWidth: 200,
      maxWidth: 700,
      onRender: (item: PremSettings) => (
        <SettingValueColumn
          settingValue={item.settingValue}
          settingName={item.settingName}
        />
      )
    },
    {
      key: "created",
      name: "Created Date",
      minWidth: 200,
      sort: true,
      isSorted: true,
      onRender: (item: PremSettings) =>
        item?.created && item?.created.isValid
          ? item.created.toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT_MONTH_AHEAD
            )
          : "-"
    },
    {
      key: "updated",
      name: "Updated Date",
      minWidth: 200,
      sort: true,
      isSorted: true,
      onRender: (item: PremSettings) =>
        item?.updated && item?.updated.isValid
          ? item?.updated.toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT_MONTH_AHEAD
            )
          : "-"
    }
  ];

  const getKey = (PremSettings: PremSettings) => PremSettings.settingName;
  return (
    <Form<PremSettingFormValues>
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      submitButtonText="Update"
      buttonsRootStyles={{ root: { marginLeft: 16, alignItems: "end" } }}
      formStyles={{
        root: { flexFlow: "column-reverse", alignItems: "inherit" }
      }}
      error={updateLicenceError}
    >
      <DataTable
        items={premSettings}
        columns={columns}
        getKey={getKey}
        styles={{
          contentWrapper: {
            "[role=gridcell]": {
              display: "flex",
              alignItems: "center"
            }
          }
        }}
      />
    </Form>
  );
};
