import { HumanizeDurationOptions } from "humanize-duration-ts";

import { Theme } from "@bps/fluent-ui";

export const EMPTY_LABEL = "(not set)";

export const humanizeOptions = {
  units: ["h", "m"],
  round: true
} as HumanizeDurationOptions;

export const getRootStyles = (
  theme: Theme,
  timePassed: boolean,
  showExpired?: boolean
) => {
  if (showExpired === false) return undefined;

  return {
    root: {
      color: timePassed ? theme.palette.red : theme.palette.green
    }
  };
};
