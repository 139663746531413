import { Form } from "@components/form/Form";

import { FunctionComponent } from "react";

import { Dialog } from "@bps/fluent-ui";

import { CreateSecretForm } from "./CreateSecretForm";
import {
  ApplicationDto,
  CloudApplicationSecret
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { useCreateApplicationSecret } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface CreateSecretDialogProps {
  application: ApplicationDto;
  tenant: Tenant;
  onDismiss: (createSuccesful: boolean, secretText?: string) => void;
}
export const CreateSecretDialog: FunctionComponent<CreateSecretDialogProps> = ({
  application,
  tenant,
  onDismiss
}) => {
  const {
    mutateAsync: createApplicationSecret,
    error: createApplicationSecretError
  } = useCreateApplicationSecret();

  const onSubmit = async (formData: CloudApplicationSecret) => {
    const secretResponse = await createApplicationSecret({
      applicationId: application.id,
      tenantId: tenant.id,
      secret: formData
    });
    onDismiss(true, secretResponse.secretText);
  };

  const endDateDefaultValue = new Date();
  endDateDefaultValue.setMonth(new Date().getMonth() + 12);

  const defaultValue = {
    displayName: "",
    startDateTime: new Date(),
    endDateTime: endDateDefaultValue
  };
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Create Secret",
        showCloseButton: true,
        onDismiss: () => onDismiss(false)
      }}
      minWidth={450}
    >
      <Form<CloudApplicationSecret>
        onSubmit={onSubmit}
        defaultValues={defaultValue}
        error={createApplicationSecretError}
        submitButtonText="Create"
      >
        <CreateSecretForm
          application={application}
          tenant={tenant}
          onDismiss={() => onDismiss(false)}
        />
      </Form>
    </Dialog>
  );
};
