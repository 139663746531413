import { FunctionComponent } from "react";
import {
  ComponentDefDto,
  ComponentDefSettingDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  IColumn,
  NoDataTile,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";

interface ComponentSettingsProps {
  component: ComponentDefDto;
}
export const ComponentSettings: FunctionComponent<ComponentSettingsProps> = ({
  component
}) => {
  const columns: IColumn[] = [
    {
      key: "key",
      name: "Key",
      minWidth: 100,
      maxWidth: 300,
      onRender: (item: ComponentDefSettingDto) => item.key
    },
    {
      key: "required",
      name: "Required",
      minWidth: 100,
      maxWidth: 400,
      onRender: (item: ComponentDefSettingDto) =>
        item.isRequired ? (
          <TextBadge
            badgeColor={TextBadgeColor.green}
            badgeSize={TextBadgeSize.small}
            styles={{ root: { width: "fit-content" } }}
          >
            Yes
          </TextBadge>
        ) : (
          "-"
        )
    }
  ];

  if (!component?.settings?.length)
    return (
      <NoDataTile
        textProps={{ text: "No settings" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
      />
    );
  return (
    <ShimmeredDetailsList
      items={component?.settings}
      columns={columns}
      compact
    />
  );
};
