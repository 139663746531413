import React from "react";

import {
  Dialog,
  IDialogProps,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";
import { useBhaProfile } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";

interface DependantDialogProps extends IDialogProps {
  profileId: string;
}

export const BhaDependantDialog: React.FC<DependantDialogProps> = ({
  profileId,
  ...props
}) => {
  const theme = useTheme();
  const { data: profile, isFetching } = useBhaProfile(profileId);

  return (
    <Dialog
      {...props}
      dialogContentProps={{
        title: "Dependants",
        showCloseButton: true
      }}
    >
      <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
        {isFetching && <Spinner />}
        {!isFetching && profile?.dependants?.length ? (
          profile.dependants.map(dependant => {
            const addressArray = [
              dependant.street1,
              dependant.suburb,
              dependant.postCode
            ].filter(x => !!x);
            return (
              <Stack
                key={dependant.id}
                styles={{
                  root: {
                    "&:not(:last-child)": {
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      borderBottomColor: theme.semanticColors.variantBorder,
                      paddingBottom: theme.spacing.s1
                    }
                  }
                }}
              >
                <Text variant="xSmall">{dependant.id}</Text>
                <Text>
                  {dependant.lastName}, {dependant.firstName}
                </Text>
                {addressArray.length && <Text>{addressArray.join(", ")}</Text>}
                <Text variant="small">{dependant.mobile}</Text>
                <Text variant="small">
                  {dependant.dOB?.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)}
                </Text>
              </Stack>
            );
          })
        ) : (
          <Text>No Dependants Found</Text>
        )}
      </Stack>
    </Dialog>
  );
};
