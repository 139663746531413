import { stripeAccountBaseUrl } from "@libs/stripe/stripe.utils";
import React, { FunctionComponent } from "react";

import { Link, useTheme } from "@bps/fluent-ui";
import { DisplayGuid } from "@components/DisplayGuid";

interface SubscriptionLinkProps {
  subscriptionId: string | undefined;
  short?: boolean;
}
export const SubscriptionLink: FunctionComponent<SubscriptionLinkProps> = ({
  subscriptionId,
  short
}) => {
  const theme = useTheme();
  if (!subscriptionId) return null;
  return (
    <Link
      href={`${stripeAccountBaseUrl()}subscriptions/${subscriptionId}`}
      target="_blank"
      onClick={evt => evt.stopPropagation()}
    >
      <DisplayGuid
        short={short}
        styles={{ root: { color: theme.semanticColors.link } }}
      >
        {subscriptionId}
      </DisplayGuid>
    </Link>
  );
};
