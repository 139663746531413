import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { useState } from "react";

import {
  IconButton,
  mergeStyleSets,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { DeleteRingDialog } from "./edit/DeleteRingDialog";
import { EditRingDialog } from "./edit/EditRingDialog";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface RingListItemProps {
  ring: DeploymentRing;
}

export const RingListItem: React.FC<RingListItemProps> = ({ ring }) => {
  const theme = useTheme();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  return (
    <Stack>
      <EditRingDialog
        ring={ring}
        hidden={!showEdit}
        onDismiss={() => setShowEdit(false)}
      />
      <DeleteRingDialog
        ringId={ring.id}
        hidden={!showDelete}
        onCancel={() => setShowDelete(false)}
      />
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        styles={{
          root: {
            "& .RingListItemActions": {
              opacity: 0
            },
            "&:hover .RingListItemActions": {
              opacity: 1
            }
          }
        }}
      >
        <Text>{ring.displayName}</Text>
        <Stack
          className="RingListItemActions"
          horizontal
          tokens={{ childrenGap: theme.spacing.s2 }}
        >
          <IconButton
            as="div"
            onClick={e => {
              e.stopPropagation();
              setShowEdit(true);
            }}
            iconProps={{
              iconName: "Edit"
            }}
            styles={{
              rootHovered: {
                borderColor:
                  theme.semanticColors.listItemBackgroundCheckedHovered,
                background:
                  theme.semanticColors.listItemBackgroundCheckedHovered
              }
            }}
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          />
          <IconButton
            onClick={e => {
              e.stopPropagation();
              setShowDelete(true);
            }}
            styles={mergeStyleSets(dangerButtonStyles(theme), {
              root: {
                background: "transparent"
              }
            })}
            iconProps={{
              iconName: "Cancel"
            }}
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
