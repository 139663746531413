import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";

export interface SysComVersionFilterValues {
  search: string;
}

export const SysComVersionsFilter = ({ children }: PropsWithChildren<any>) => {
  const filterItems: Item<SysComVersionFilterValues>[] = [
    {
      type: "searchBox",
      name: "search",
      stickItem: true,
      props: {
        placeholder: "Filter by version",
        removeSpecialCharacters: false
      }
    }
  ];

  return (
    <FilterBar<SysComVersionFilterValues>
      items={filterItems}
      initialValues={{
        search: ""
      }}
    >
      {children}
    </FilterBar>
  );
};
