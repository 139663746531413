import { PropsWithChildren } from "react";

import { Heading, Stack, TooltipHost } from "@bps/fluent-ui";

interface GeneralSectionProps {
  heading: string;
  toolTipConent?: string;
}

export const GeneralSection = ({
  children,
  heading,
  toolTipConent
}: PropsWithChildren<GeneralSectionProps>) => {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={{
        root: {
          height: 30
        }
      }}
    >
      <Heading>{heading}</Heading>

      {toolTipConent ? (
        <TooltipHost id={`${heading}-tooltip-id`} content={toolTipConent}>
          {children}
        </TooltipHost>
      ) : (
        children
      )}
    </Stack>
  );
};
