import React, { FunctionComponent, useMemo } from "react";

import { Dialog, Stack } from "@bps/fluent-ui";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { Country, CountryText } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useAddLicenceType,
  useLicenceType,
  useUpdateLicenceType
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";

import {
  ADD_TYPE_TITLE,
  AddLicenceTypeFormProps,
  AddLicenceTypeFormValues,
  EDIT_TYPE_TITLE
} from "./AddLicenceTypeDialog.types";

export const AddLicenceTypeForm: FunctionComponent<AddLicenceTypeFormProps> = ({
  onDismiss,
  code
}) => {
  const title = code ? EDIT_TYPE_TITLE : ADD_TYPE_TITLE;

  const { data: licenceType } = useLicenceType(code!, { enabled: !!code });
  const { error: addError, mutateAsync: addLicenceType } = useAddLicenceType();
  const {
    error: editError,
    mutateAsync: editLicenceType
  } = useUpdateLicenceType();

  const defaultValues: AddLicenceTypeFormValues = useMemo(() => {
    if (licenceType) {
      return {
        code: licenceType.code,
        text: licenceType.text,
        isActive: !licenceType.isInactive,
        countryCode: licenceType.countryCode,
        source: licenceType.source,
        sortOrder: licenceType.sortOrder,
        gracePeriod: licenceType.gracePeriod
      };
    }
    return {
      code: "",
      text: "",
      isActive: true,
      countryCode: Country.Australia,
      source: "",
      sortOrder: 0,
      gracePeriod: 0
    };
  }, [licenceType]);

  const onSubmit = async ({
    isActive,
    sortOrder,
    gracePeriod,
    ...rest
  }: AddLicenceTypeFormValues) => {
    const dto = {
      ...rest,
      isInactive: !isActive,
      sortOrder: sortOrder ? Number(sortOrder) : undefined,
      gracePeriod: gracePeriod ? Number(gracePeriod) : undefined
    };
    try {
      if (licenceType) {
        await editLicenceType(dto);
      } else {
        await addLicenceType(dto);
      }

      onDismiss();
    } catch (e) {
      return e;
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title,
        showCloseButton: true,
        onDismiss
      }}
      minWidth={370}
    >
      <Form<AddLicenceTypeFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={addError ?? editError}
      >
        <FormTextField
          name="code"
          label="Code"
          readOnly={!!licenceType}
          required
          description={!!licenceType ? "Not editable" : undefined}
          rules={{
            required: ValidationMessages.required,
            maxLength: 50
          }}
        />
        <FormTextField
          name="text"
          label="Description"
          required
          rules={{ required: ValidationMessages.required }}
        />
        <FormTextField
          name="source"
          label="Source"
          required
          rules={{ required: ValidationMessages.required }}
        />
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          horizontalAlign="space-between"
        >
          <FormSpinNumberInput
            name="gracePeriod"
            label="Grace period"
            rules={{
              min: { value: 0, message: "Grace period can not be less than 0." }
            }}
            prefix="Days"
            filedItemStyles={{ root: { width: "50%" } }}
          />
          <FormSpinNumberInput
            name="sortOrder"
            label="Sorting order"
            rules={{
              min: { value: 0, message: "Sort order can not be less than 0." }
            }}
            filedItemStyles={{ root: { width: "50%" } }}
          />
        </Stack>

        <Stack
          horizontal
          tokens={{ childrenGap: 16 }}
          horizontalAlign="space-between"
        >
          <FormDropdown
            options={[
              { key: Country.Australia, text: CountryText[Country.Australia] },
              { key: Country.NewZealand, text: CountryText[Country.NewZealand] }
            ]}
            name="countryCode"
            label="Country"
            fieldItemStyles={{ root: { minWidth: 200 } }}
          />
          <FormSwitch
            name="isActive"
            label="Status"
            offText="Inactive"
            onText="Active"
            styles={{
              root: { margin: 0, flexGrow: 1 },
              container: { marginTop: 4 }
            }}
          />
        </Stack>
      </Form>
    </Dialog>
  );
};
