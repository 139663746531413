import { useRootStore } from "@stores/StoresProvider";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  IColumn,
  Link,
  ShimmeredDetailsListProps,
  Stack,
  Text
} from "@bps/fluent-ui";

import { SiteAgentCell } from "./SiteAgentCell";
import { SiteManagementCell } from "./SiteManagementCell";
import { ProsTenant } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

interface PropsDeployTableProps extends ShimmeredDetailsListProps {
  items: ProsTenant[];
}

export const ProsDeployTable: React.FC<PropsDeployTableProps> = props => {
  const { deployment } = useRootStore();
  const navigate = useNavigate();

  const columns: IColumn[] = [
    {
      key: "tenant",
      name: "Tenant",
      minWidth: 100,
      onRender: (tenant: ProsTenant) => (
        <Stack>
          {tenant.name ? (
            <Link
              onClick={() => navigate(`/tenants/${tenant.id}/plt-details`)}
              styles={{ root: { width: "fit-content" } }}
            >
              {tenant.name}
            </Link>
          ) : (
            <em>(name not specified)</em>
          )}
          <Text variant="small">{tenant.id}</Text>
        </Stack>
      )
    },
    {
      key: "site-manager",
      name: "Site Manager",
      minWidth: 300,
      onRender: (tenant: ProsTenant) => <SiteManagementCell tenant={tenant} />
    },
    {
      key: "site-agent",
      name: "Site Agent",
      minWidth: 300,
      onRender: (tenant: ProsTenant) => <SiteAgentCell tenant={tenant} />
    }
  ];

  return (
    <ShimmeredDetailsList
      shimmerLines={10}
      columns={columns}
      onShouldVirtualize={() => true}
      disableSelectionZone={deployment.isDeploying}
      {...props}
    />
  );
};
