import React, { useMemo } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import { IBreadcrumbItem } from "@bps/fluent-ui";

import { getDefaultBreadcrumbItems } from "@components/breadcrumbs/breadcrumbs.utils";
import { Breadcrumbs } from "@components/breadcrumbs/Breadcrumbs";
import { useFieldDevices } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { FieldDeviceDetails } from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { FieldDeviceTabs } from "./field-device-tabs.constants";

export const FieldManagementBreadcrumbs: React.FC = () => {
  const { pathname } = useLocation();

  const fieldDeviceId = getFieldDevicePathId(pathname);

  const { data: devices } = useFieldDevices({ searchTerm: fieldDeviceId! });

  const items = useMemo<IBreadcrumbItem[]>(() => {
    const flattenedDevices = devices?.pages.reduce(
      (acc: FieldDeviceDetails[], curVal) => {
        return curVal.results !== null && curVal.results !== undefined
          ? acc.concat(...curVal.results)
          : [];
      },
      []
    );

    return getFieldManagementItems(pathname, flattenedDevices);
  }, [pathname, devices]);

  return (
    <>
      <Breadcrumbs items={items} />
      <Outlet />
    </>
  );
};

const getFieldDevicePathId = (pathname: string) => {
  const baseMatch = matchPath("/field-mgmt/:deviceId/*", pathname);

  return baseMatch?.params?.deviceId;
};

const getFieldManagementItems = (
  pathname: string,
  devices?: FieldDeviceDetails[]
) => {
  let _items: IBreadcrumbItem[] = [
    {
      key: "field-mgmt",
      text: "Field Management"
    }
  ];

  const baseMatch = matchPath("/field-mgmt/:deviceId/*", pathname);

  const deviceId = baseMatch?.params?.deviceId;

  if (deviceId) {
    const device = devices?.find(x => x.id === deviceId);
    _items.push({
      key: "deviceId",
      text: device?.computerName ?? device?.id ?? "..."
    });
  }

  const subPath = baseMatch?.params["*"];
  if (!!subPath) {
    const subItems = getDefaultBreadcrumbItems(FieldDeviceTabs, `/${subPath}`);
    _items = _items.concat(subItems);
  }

  return _items;
};
