import { SystemBh } from "modules/system/sections/bh/SystemBh";
import { SystemEnv } from "modules/system/sections/env/SystemEnv";
import { SystemPlt } from "modules/system/sections/plt/SystemPlt";
import { SystemProsRoutes } from "./sections/pros/SystemProsRoutes";
import { Route, Routes } from "react-router-dom";

import { SystemBpMobile } from "./sections/bp-mobile/SystemBpMobile";
import { SystemOmni } from "./sections/omni/SystemOmni";

const SystemRoutes = () => {
  return (
    <Routes>
      <Route path="/plt/*" element={<SystemPlt />} />
      <Route path="/env/*" element={<SystemEnv />} />
      <Route path="/pros/*" element={<SystemProsRoutes />} />
      <Route path="/bh/*" element={<SystemBh />} />
      <Route path="/omni/*" element={<SystemOmni />} />
      <Route path="/bp-mobile/*" element={<SystemBpMobile />} />
    </Routes>
  );
};

// eslint-disable-next-line import/no-default-export
export default SystemRoutes;
