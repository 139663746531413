import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { SoftwarePackageDefault } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSoftwarePackageVersions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { VirusScanFailedBadge } from "./VirusScanFailedBadge";

const NO_VERSION_SELECTED = "No version selected";

export interface SoftwarePackageDefaultCurrentVersionProps {
  softwarePackage: SoftwarePackageDefault;
}

export const SoftwarePackageDefaultCurrentVersion = ({
  softwarePackage
}: SoftwarePackageDefaultCurrentVersionProps) => {
  const theme = useTheme();
  const { softwarePackageId, softwarePackageVersionId } = softwarePackage;

  const softwarePackageVersionsQuery = useSoftwarePackageVersions({
    softwarePackageId
  });

  const { data: softwarePackageVersions } = softwarePackageVersionsQuery;

  if (!softwarePackageVersions) return null;

  const currentVersion = softwarePackageVersions.find(
    x => x.id === softwarePackageVersionId
  );

  if (!currentVersion) return <>{NO_VERSION_SELECTED}</>;

  return (
    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
      <Text>{currentVersion.version}</Text>
      {currentVersion.virusScanIssueCount > 0 && (
        <VirusScanFailedBadge
          virusScanIssueCount={currentVersion.virusScanIssueCount}
        />
      )}
    </Stack>
  );
};
