import { Form } from "@components/form/Form";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormUsersSelect } from "@components/form/fields/FormUsersSelect";
import { FunctionComponent } from "react";

import { Dialog, Option, Stack, StackItem, Text } from "@bps/fluent-ui";
import { OrgUnitDto, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { CallbackDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import {
  useCallbackTypes,
  useUpdateCallback
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";

export interface UpdateCallbackDialogProps {
  tenant: Tenant;
  callback: CallbackDto;
  orgUnits: OrgUnitDto[];
  onDismiss: () => void;
}

export interface UpdateCallbackFormValues {
  id: string;
  key: string;
  type: string;
  isEnabled: boolean;
  tenantId: string;
  locationId: string;
  userId: string;
  propertyBag: string;
}

export const UpdateCallbackDialog: FunctionComponent<UpdateCallbackDialogProps> = ({
  tenant,
  callback,
  orgUnits,
  onDismiss
}) => {
  const {
    mutateAsync: updateCallback,
    error: updateCallbackError
  } = useUpdateCallback();

  const callbackTypes = useCallbackTypes();

  const onSubmit = async (formData: UpdateCallbackFormValues) => {
    await updateCallback({
      id: callback.id,
      tenantId: tenant.id,
      type: formData.type,
      key: formData.key,
      locationId: formData.locationId,
      userId: formData.userId,
      propertyBag: formData.propertyBag,
      isEnabled: formData.isEnabled
    });
    onDismiss();
  };

  const defaultValues = {
    type: callback.type,
    key: callback.key,
    locationId: callback.locationId,
    userId: callback.userId,
    propertyBag: callback.propertyBag,
    isEnabled: callback.isEnabled,
    id: callback.id,
    tenantId: tenant.id
  };

  const callbackTypesOptions: Option[] = !callbackTypes
    ? []
    : [
        {
          key: "",
          text: "None"
        }
      ].concat(
        callbackTypes.data?.map(t => {
          return { key: t.code, text: t.code };
        }) ?? []
      );

  const locationOrgUniIdOptions: Option[] = !orgUnits
    ? []
    : [
        {
          key: "",
          text: "None"
        }
      ].concat(
        orgUnits?.map(t => {
          return { key: t.id, text: t.name };
        }) ?? []
      );
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Configure Callback",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<UpdateCallbackFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={updateCallbackError}
      >
        <FormDropdown
          name="type"
          placeholder="Select callback type"
          defaultSelectedKey=""
          label="Callback Type"
          options={callbackTypesOptions}
          withNoEmptyOption={true}
        />
        <Text>Callback Key</Text>
        <FormTextField name="key" placeholder="Callback Key" />
        <FormDropdown
          name="locationId"
          placeholder="Select location org unit"
          defaultSelectedKey=""
          label="Location OrgUnit"
          options={locationOrgUniIdOptions}
          withNoEmptyOption={true}
        />
        <FormUsersSelect label="User" name="userId" tenantId={tenant.id} />
        <Text>Property Bag Json Data</Text>
        <FormTextField
          name="propertyBag"
          placeholder="Property bag json data"
          multiline
        />
        <Stack tokens={{ childrenGap: 8 }} verticalAlign="space-between">
          <StackItem>
            <FormSwitch
              name="isEnabled"
              label="Enabled"
              styles={{
                root: { margin: 0, flexGrow: 1 },
                container: { marginTop: 4 }
              }}
            />
          </StackItem>
        </Stack>
      </Form>
    </Dialog>
  );
};
