import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";

export interface RolloutPackageDesiredConfigFilterValues {
  name: string;
}

export const RolloutPackageDesiredConfigFilter = ({
  children
}: PropsWithChildren<any>) => {
  const filterItems: Item<RolloutPackageDesiredConfigFilterValues>[] = [
    {
      name: "name",
      type: "searchBox",
      stickItem: true,
      props: { placeholder: "Search by name", removeSpecialCharacters: false }
    }
  ];

  return (
    <FilterBar<RolloutPackageDesiredConfigFilterValues>
      items={filterItems}
      initialValues={{
        name: ""
      }}
    >
      {children}
    </FilterBar>
  );
};
