import { useState } from "react";

import { NoDataTile, PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { HierarchyTypes } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import {
  useCallbacksQuery,
  useOrgUnitsQuery
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { Callback } from "./Callback";
import { CallbacksFilterBar } from "./CallbacksFilterBar";
import { CreateCallbackDialog } from "./CreateCallbackDialog";
import { getSortedFilteredCallbacks } from "./utils";

interface IntegCallbacksPros {
  tenant: Tenant;
}

const IntegCallbacksBase: React.FC<IntegCallbacksPros> = ({ tenant }) => {
  const theme = useTheme();
  const callbacksQuery = useCallbacksQuery(tenant.id);
  const orgUnitsQuery = useOrgUnitsQuery({
    tenantId: tenant.id,
    hierarchyType: HierarchyTypes.Location
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegConsentWrite
  );

  const [showCreateCallbackDialog, setshowCreateCallbackDialog] = useState<
    boolean
  >(false);
  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Callbacks</SectionTitle>
        <Stack
          horizontalAlign="space-between"
          horizontal
          tokens={{ childrenGap: 8 }}
        >
          <PrimaryButton
            disabled={!hasWritePermission}
            onClick={() => setshowCreateCallbackDialog(true)}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            Create Callback
          </PrimaryButton>
          {showCreateCallbackDialog && (
            <CreateCallbackDialog
              tenant={tenant}
              callbacks={callbacksQuery.data ?? []}
              orgUnits={orgUnitsQuery.data ?? []}
              onDismiss={() => {
                setshowCreateCallbackDialog(false);
              }}
            />
          )}
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: 16 }}>
        <QueryStateIndicator {...callbacksQuery}>
          {callback =>
            callback?.length ? (
              <Stack grow>
                <CallbacksFilterBar callbacks={callback}>
                  {({ values: filter }) => (
                    <Stack
                      styles={{
                        root: { position: "relative", width: "100%" }
                      }}
                      grow
                    >
                      <Stack tokens={{ childrenGap: 8 }}>
                        {getSortedFilteredCallbacks(callback, filter).map(i => (
                          <Callback
                            key={i.id}
                            item={i}
                            orgUnits={orgUnitsQuery.data ?? []}
                            tenant={tenant}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </CallbacksFilterBar>
              </Stack>
            ) : (
              <NoDataTile
                showBoxShadow={false}
                textProps={{ text: "No callbacks found!" }}
                linkProps={{ hidden: true }}
              />
            )
          }
        </QueryStateIndicator>
      </Stack>
    </Stack>
  );
};

export const IntegCallbacks = withPermissions(
  IntegCallbacksBase,
  [Permissions.PltIntegConsentRead, Permissions.PltIntegConsentWrite],
  "or"
);
