import Highlight from "react-highlight";
import { useQueryClient } from "react-query";

import {
  DefaultButton,
  Heading,
  IconButton,
  IModalProps,
  Modal,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  FieldDevice,
  FieldDeviceReportedConfig
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  FieldOpsCacheKeys,
  useFieldDeviceReportedConfig
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

interface ReportedConfigModalProps extends IModalProps {
  show: boolean;
  device: FieldDevice;
  onDismiss: () => void;
}

export const ReportedConfigModal: React.FC<ReportedConfigModalProps> = ({
  device,
  show,
  onDismiss,
  ...props
}) => {
  const fieldDeviceReportedConfigQuery = useFieldDeviceReportedConfig(
    device.id
  );

  const queryClient = useQueryClient();
  const invalidateQueryCache = async () => {
    await queryClient.invalidateQueries([
      FieldOpsCacheKeys.FieldDeviceReportedConfig
    ]);
  };

  const theme = useTheme();
  return (
    <Modal
      isOpen={show}
      {...props}
      styles={{ scrollableContent: { maxHeight: "none" } }}
    >
      <Stack
        verticalFill
        styles={{
          root: { padding: theme.spacing.l1, maxHeight: 800 }
        }}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Heading variant="modal-heading">
            Reported Field Configuration Preview
          </Heading>
          <IconButton onClick={onDismiss} iconProps={{ iconName: "Cancel" }} />
        </Stack>

        <Stack
          verticalFill
          styles={{
            root: { maxHeight: 600, overflow: "auto", maxWidth: 1200 }
          }}
        >
          <QueryStateIndicator<FieldDeviceReportedConfig[]>
            {...fieldDeviceReportedConfigQuery}
          >
            {json => (
              <Highlight className="json">
                {JSON.stringify(json, null, 2)}
              </Highlight>
            )}
          </QueryStateIndicator>
        </Stack>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <DefaultButton onClick={invalidateQueryCache}>Reload</DefaultButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
