import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import {
  LabeledText,
  LabeledTextGroup,
  NativeList,
  Separator,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { CopyableGuid } from "@components/CopyableText";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useBpIdUserDetails } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { LogoutAllSessionsDialogButton } from "./LogoutAllSessionsDialogButton";
import { SendInviteDialogButton } from "./SendInviteDialogButton";

interface UserBpIdProps {
  user: PltUser | undefined;
}
export const UserBpId: FunctionComponent<UserBpIdProps> = ({ user }) => {
  const { tenantId } = useParams<{ tenantId: string }>();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltInvitationWrite
  );

  const tenantMismatch = user?.tenantId !== tenantId;

  const disableAccess = tenantMismatch || !hasWritePermission;

  const userDetailsQuery = useBpIdUserDetails(user?.bpIdUserId!, {
    enabled: !!user?.bpIdUserId
  });

  if (!user) return null;

  return (
    <Stack>
      <Separator />
      <QueryStateIndicator
        {...userDetailsQuery}
        noDataText={
          !user.bpIdUserId ? "User does not have Bp Id account" : undefined
        }
      >
        {bpUserDetails => {
          return (
            <LabeledTextGroup styles={{ root: { marginBottom: 24 } }}>
              <LabeledText wrap={false} label="Bp Id">
                <Stack horizontal>
                  <CopyableGuid value={bpUserDetails.id} short />
                  <TextBadge
                    badgeSize={TextBadgeSize.small}
                    badgeColor={
                      bpUserDetails.accountEnabled
                        ? TextBadgeColor.green
                        : TextBadgeColor.red
                    }
                  >
                    {bpUserDetails.accountEnabled ? "Enabled" : "Disabled"}
                  </TextBadge>
                </Stack>
              </LabeledText>

              <LabeledText wrap={false} label="First name">
                {bpUserDetails.firstName}
              </LabeledText>

              <LabeledText wrap={false} label="Last name">
                {bpUserDetails.lastName}
              </LabeledText>

              <LabeledText wrap={false} label="Sign-in email">
                {bpUserDetails.signInEmail}
              </LabeledText>

              <LabeledText wrap={false} label="Identities">
                <NativeList>
                  {bpUserDetails.signInIdentities?.map(identity => (
                    <li key={identity.signInType}>
                      <Text bold>{identity.signInType}</Text>:{" "}
                      <Text>{identity.issuerAssignedId}</Text>
                    </li>
                  ))}
                </NativeList>
              </LabeledText>

              <LabeledText wrap={false} label="MFA methods">
                <NativeList>
                  {bpUserDetails.mfaMethods?.map(method => (
                    <li key={method.mfaMethod}>
                      <Text bold>{method.name}</Text>:{" "}
                      <Text>{method.value}</Text>
                    </li>
                  ))}
                </NativeList>
              </LabeledText>
            </LabeledTextGroup>
          );
        }}
      </QueryStateIndicator>

      <Stack horizontal tokens={{ childrenGap: 8 }}>
        {!disableAccess && <LogoutAllSessionsDialogButton user={user} />}
        {!disableAccess ? (
          <SendInviteDialogButton user={user} />
        ) : (
          <TextBadge
            badgeSize={TextBadgeSize.large}
            badgeColor={TextBadgeColor.red}
            styles={{
              root: { alignSelf: "start", justifySelf: "center" }
            }}
          >
            {tenantMismatch
              ? "CAM User cannot be invited to Child Tenant"
              : NO_ACTION_PERMISSION}
          </TextBadge>
        )}
      </Stack>
    </Stack>
  );
};
