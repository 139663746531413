import React, { useMemo } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import { IBreadcrumbItem } from "@bps/fluent-ui";

import { getDefaultBreadcrumbItems } from "@components/breadcrumbs/breadcrumbs.utils";
import { Breadcrumbs } from "@components/breadcrumbs/Breadcrumbs";
import {
  useDeploymentRings,
  useRollouts
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import {
  DeploymentRing,
  Rollout
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { Tabs } from "@components/tabs-nav/TabNav.types";

interface SystemBreadcrumbsProps {
  tabs: Tabs;
}
export const SystemBreadcrumbs: React.FC<SystemBreadcrumbsProps> = ({
  tabs
}) => {
  const { pathname } = useLocation();

  const { data: deploymentRings } = useDeploymentRings();

  const deploymentRingId = getDeploymentRingPathId(pathname);

  const { data: rollouts } = useRollouts(deploymentRingId);

  const items = useMemo<IBreadcrumbItem[]>(() => {
    return getSystemItems({ pathname, deploymentRings, rollouts, tabs });
  }, [pathname, deploymentRings, rollouts, tabs]);
  return (
    <>
      <Breadcrumbs items={items} />
      <Outlet />
    </>
  );
};

const getDeploymentRingPathId = (pathname: string) => {
  const baseMatch = matchPath(
    "/system/plt/deployment-rings/:deploymentRingId/*",
    pathname
  );

  return baseMatch?.params?.deploymentRingId;
};

const getSystemItems = (options: {
  pathname: string;
  deploymentRings?: DeploymentRing[];
  rollouts?: Rollout[];
  tabs: Tabs;
}) => {
  const { pathname, deploymentRings, rollouts, tabs } = options;
  const isDeploymentRingsRoute = pathname.startsWith(
    "/system/plt/deployment-rings"
  );

  if (!isDeploymentRingsRoute) return getDefaultBreadcrumbItems(tabs, pathname);

  const _items: IBreadcrumbItem[] = [
    {
      key: "system",
      text: "System"
    },
    {
      key: "plt",
      text: "Platform"
    },
    {
      key: "deployment-rings",
      text: "Deployment Rings"
    }
  ];

  const baseMatch = matchPath(
    "/system/plt/deployment-rings/:deploymentRingId/*",
    pathname
  );

  const rolloutMatch = matchPath(
    "/system/plt/deployment-rings/:deploymentRingId/rollouts/:rolloutId",
    pathname
  );

  const deploymentRingId =
    baseMatch?.params?.deploymentRingId ??
    rolloutMatch?.params?.deploymentRingId;

  const rolloutId = rolloutMatch?.params.rolloutId;

  if (deploymentRingId) {
    const deploymentRing = deploymentRings?.find(
      x => x.id === deploymentRingId
    );
    _items.push({
      key: "deploymentRingId",
      text: deploymentRing?.displayName ?? deploymentRing?.id ?? "..."
    });

    const rollout = rollouts?.find(config => config.id === rolloutId);

    if (rolloutId) {
      _items.push({
        key: "rollouts",
        text: "Rollout"
      });

      _items.push({
        key: rolloutId,
        text: rollout?.displayName ?? rolloutId
      });
    }
  }

  return _items;
};
