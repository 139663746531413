import { CopyableGuid } from "@components/CopyableText";
import React, { FunctionComponent } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  confirm,
  FontIcon,
  FontSizes,
  Grid,
  Heading,
  IconButton,
  Shimmer,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { CustomerProductsSubscription } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useSalesProduct } from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SubscriptionItemProducts } from "./SubscriptionItems.types";

interface SubscriptionItemsHeaderProps {
  subscription: CustomerProductsSubscription | undefined;
  data: SubscriptionItemProducts;
}
export const SubscriptionItemsHeader: FunctionComponent<SubscriptionItemsHeaderProps> = ({
  subscription,
  data
}) => {
  const theme = useTheme();
  const productQuery = useSalesProduct(data.salesProductId);
  const { getValues, control } = useFormContext();
  const { remove } = useFieldArray({ name: "subscriptionProducts", control });

  const removeSubscriptionItem = async () => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Remove"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: "Remove this product from the subscription",
        subText: "Are you sure you want to remove this product?"
      }
    });

    const index = getValues().subscriptionProducts.indexOf(
      s => s.salesProductId === data.salesProductId
    );
    if (isConfirmed) {
      remove(index);
    }
  };

  return (
    <Grid templateColumns="2fr 1fr 1fr 1fr" childrenTokens={{ columnGap: 16 }}>
      <QueryStateIndicator
        {...productQuery}
        LoadingComponent={() => <Shimmer />}
      >
        {product => (
          <>
            <Stack tokens={{ childrenGap: 4 }}>
              <TooltipHost content={product.description}>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Heading>{product.name}</Heading>
                  <FontIcon
                    styles={{
                      root: {
                        fontSize: FontSizes.xSmall,
                        color: theme.palette.themePrimary
                      }
                    }}
                    iconName="Info"
                  />
                </Stack>
              </TooltipHost>

              {product.sourceProductId && (
                <CopyableGuid short value={product.sourceProductId} />
              )}
            </Stack>

            <Stack
              tokens={{ childrenGap: 4 }}
              styles={{ root: { width: "min-content" } }}
            >
              <TooltipHost
                content={
                  <Stack>
                    <Text>{product.productCode}</Text>
                    <Text>{product.displayName}</Text>
                  </Stack>
                }
              >
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  <Heading>{product.productFamily}</Heading>
                  <FontIcon
                    styles={{
                      root: {
                        fontSize: FontSizes.xSmall,
                        color: theme.palette.themePrimary
                      }
                    }}
                    iconName="Info"
                  />
                </Stack>
              </TooltipHost>
              <CopyableGuid short value={data.salesProductId} />
            </Stack>
            <Stack.Item styles={{ root: { justifySelf: "right" } }}>
              <Heading>Total quantity: {data.quantity}</Heading>
            </Stack.Item>

            {!subscription && (
              <IconButton
                iconProps={{ iconName: "Cancel" }}
                styles={{ root: { alignSelf: "center", justifySelf: "end" } }}
                onClick={async evt => {
                  evt.stopPropagation();
                  await removeSubscriptionItem();
                }}
              />
            )}
          </>
        )}
      </QueryStateIndicator>
    </Grid>
  );
};
