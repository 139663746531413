import { ComponentDefDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const getFilteredComponentsDefinitions = (
  items: ComponentDefDto[],
  filter: { code: string; scope: string[] }
) => {
  return items.filter(item => {
    const matchCode = filter.code
      ? item.code.toLowerCase().includes(filter.code.toLowerCase())
      : true;

    const matchScope = filter.scope.length
      ? filter.scope.includes(item.scope)
      : true;
    return matchCode && matchScope;
  });
};
