import { SectionTitle } from "@components/SectionTitle";
import React from "react";

import { FieldDeviceDeploymentRingList } from "./FieldDeviceDeploymentRingList";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface FieldDeviceDeploymentRingsProps {
  device: FieldDevice;
}

export const FieldDeviceDeploymentRings: React.FC<FieldDeviceDeploymentRingsProps> = ({
  device
}) => {
  return (
    <>
      <SectionTitle>Device Deployment Rings</SectionTitle>

      <FieldDeviceDeploymentRingList device={device} />
    </>
  );
};
