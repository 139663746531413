import { Route, Routes } from "react-router-dom";

import { BpMobileApp } from "./SystemBpMobileApp";
import { SystemBreadcrumbs } from "../../SystemBreadcrumbs";
import { SystemBpMobileTabs } from "./system-bp-mobile-tabs.constants";

export const SystemBpMobileRoutes = () => {
  return (
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemBpMobileTabs} />}>
        <Route
          path={SystemBpMobileTabs.Operations.Settings.id}
          element={<BpMobileApp />}
        />
      </Route>
    </Routes>
  );
};
