import { FunctionComponent, useMemo } from "react";

import {
  FilterBar,
  FilterBarProps,
  Item,
  Option,
  PresetButtonType
} from "@bps/fluent-ui";
import { isDefined, unique } from "@bps/utils";
import {
  Country,
  CountryText,
  SalesProductDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface ProductsFilterBarValues {
  name: string;
  code: string;
  applicationCodes: string[];
  showInactive: boolean;
  country: string | undefined;
}
interface ProductsFilterBarProps extends Pick<FilterBarProps, "children"> {
  products: SalesProductDto[];
}
export const ProductsFilterBar: FunctionComponent<ProductsFilterBarProps> = ({
  children,
  products
}) => {
  const applicationsCodes: Option[] = useMemo(
    () =>
      unique(products.map(p => p.applicationCode))
        .filter(isDefined)
        .map(up => ({ key: up, text: up })),
    [products]
  );

  const items: Item<ProductsFilterBarValues>[] = [
    {
      type: "searchBox",
      name: "name",
      stickItem: true,
      props: { placeholder: "Search by name", removeSpecialCharacters: false }
    },
    {
      type: "searchBox",
      name: "code",
      props: { placeholder: "Search by code", removeSpecialCharacters: false }
    },
    {
      type: "optionsSelect",
      name: "applicationCodes",
      props: {
        placeholder: "Application codes",
        multiSelect: true,
        hideSearchOption: true,
        hideSelectAllButton: true,
        options: applicationsCodes,
        styles: { field: { width: 150 } }
      }
    },
    {
      type: "optionsSelect",
      name: "country",
      props: {
        placeholder: "Country",
        hideSearchOption: true,
        hideSelectAllButton: true,
        options: [
          { key: Country.NewZealand, text: CountryText.NZ },
          { key: Country.Australia, text: CountryText.AU }
        ],
        styles: { field: { width: 150 } }
      }
    }
  ];

  const presets: PresetButtonType<ProductsFilterBarValues>[] = [
    {
      name: "showInactive",
      text: "Show inactive",
      iconName: "GlobalNavButtonActive"
    }
  ];
  return (
    <FilterBar<ProductsFilterBarValues>
      initialValues={{
        name: "",
        code: "",
        applicationCodes: [],
        showInactive: false,
        country: undefined
      }}
      children={children}
      items={items}
      presets={presets}
    />
  );
};
