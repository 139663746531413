import { Tabs } from "@components/tabs-nav/TabNav.types";

export const SystemProsTabs: Tabs = {
  Operations: {
    SysComp: { id: "ops-system-components", name: "System Components" },
    EventHubs: { id: "ops-event-hubs", name: "Event Hubs" },
    Banner: { id: "ops-banner", name: "Banner" }
  },
  SiteIntegration: {
    BuildMgmt: { id: "pros-build-mgmt", name: "Build Management" },
    DeployMgmt: { id: "pros-deploy-mgmt", name: "Deploy Management" },
    SiteComponents: { id: "pros-site-components", name: "Site Components" },
    TenantSyncState: { id: "pros-tenant-sync-state", name: "Tenant Sync State" }
  }
};
