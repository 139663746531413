import { QueryStateIndicator } from "components/QueryStateIndicator";
import { withPermissions } from "components/withPermissions";
import { AzureLogs } from "modules/tenants/sections/ops/logging/AzureLogs";
import { SeqLogs } from "modules/tenants/sections/ops/logging/SeqLogs";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import {
  ApplicationType,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { SIATenantDetails } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { useSIATenantQuery } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}

export const LogsBase = ({ tenant }: Props) => {
  const theme = useTheme();
  const query = useSIATenantQuery(tenant.id);

  return (
    <>
      {tenant.application === ApplicationType.PROS ? (
        <QueryStateIndicator<SIATenantDetails> {...query} error={query.error}>
          {tenantDetails => (
            <Stack verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
              <Stack.Item>
                <SeqLogs tenant={tenantDetails} />
              </Stack.Item>
              <Stack.Item>
                <AzureLogs tenant={tenantDetails} />
              </Stack.Item>
            </Stack>
          )}
        </QueryStateIndicator>
      ) : (
        <Text styles={{ root: { textAlign: "center" } }}>
          Logs are not available for a Non PrOS Tenant
        </Text>
      )}
    </>
  );
};

export const Logs = withPermissions(
  LogsBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
