import { DataTable, DataTableColumn } from "@components/tables/DataTable";

import { SelectionMode } from "@bps/fluent-ui";
import { LocationAppointmentStats } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";

interface BookingStatsTotalTableProps {
  stats: LocationAppointmentStats[];
}

export const LocationStatsTable = ({ stats }: BookingStatsTotalTableProps) => {
  const columns: DataTableColumn<LocationAppointmentStats>[] = [
    {
      key: "locationName",
      name: "Location",
      minWidth: 300,
      maxWidth: 1100,
      sort: true,
      isSorted: true,
      filterable: true,
      onRender: (item: LocationAppointmentStats) => {
        return <>{item.locationName}</>;
      }
    },
    {
      key: "todayCount",
      name: "Today",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.todayCount.toLocaleString();
      }
    },
    {
      key: "yesterdayCount",
      name: "Yesterday",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.yesterdayCount.toLocaleString();
      }
    },
    {
      key: "lastSevenDaysCount",
      name: "Last seven days",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.lastSevenDaysCount.toLocaleString();
      }
    },
    {
      key: "previousSevenDayCount",
      name: "Prior seven days",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.previousSevenDayCount.toLocaleString();
      }
    },
    {
      key: "thisMonthCount",
      name: "This month",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.thisMonthCount.toLocaleString();
      }
    },
    {
      key: "previousMonthCount",
      name: "Last Month",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.previousMonthCount.toLocaleString();
      }
    },
    {
      key: "thisYearCount",
      name: "This Year",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.thisYearCount.toLocaleString();
      }
    },
    {
      key: "previousYearCount",
      name: "Last Year",
      minWidth: 70,
      maxWidth: 130,
      sort: true,
      isSorted: true,
      onRender: (item: LocationAppointmentStats) => {
        return item.previousYearCount.toLocaleString();
      }
    }
  ];

  const getKey = (appointmentStats: LocationAppointmentStats) =>
    appointmentStats.locationId;

  return (
    <DataTable
      items={stats}
      columns={columns}
      getKey={getKey}
      selectionMode={SelectionMode.none}
      rightAlignColumns={true}
    />
  );
};
