import { useRootStore } from "@stores/StoresProvider";
import { dangerButtonStyles } from "@components/buttons/DangerButton";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  CopyToClipboardButton,
  IconButton,
  IDragDropEvents,
  Link,
  mergeStyles,
  mergeStyleSets,
  ShimmeredDetailsListProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { useUpdateFieldDeviceDeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import {
  FieldDevice,
  FieldDeviceDeploymentRingDetail
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface FieldDeviceDeploymentRingTableProps
  extends ShimmeredDetailsListProps {
  device: FieldDevice;
  items: FieldDeviceDeploymentRingDetail[];
  onRemoveItem: (
    fieldDeviceDeploymentRingDetail: FieldDeviceDeploymentRingDetail
  ) => void;
}

export const FieldDeviceDeploymentRingTable: React.FC<FieldDeviceDeploymentRingTableProps> = ({
  onRemoveItem,
  device,
  ...props
}) => {
  const theme = useTheme();
  const {
    mutateAsync: updateDeviceDeploymentRing
  } = useUpdateFieldDeviceDeploymentRing(device.id);

  const navigate = useNavigate();
  const { feedback } = useRootStore();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const draggedItem = useRef<FieldDeviceDeploymentRingDetail | undefined>(
    undefined
  );

  const draggedIndex = useRef<number>(-1);
  const dragEnterClass = mergeStyles({
    // override both the background and the color otherwise the default styles are applied
    background: theme.palette.neutralLight,
    backgroundColor: theme.palette.neutralLight
  });

  const insertBeforeItem = async (item: FieldDeviceDeploymentRingDetail) => {
    const draggedItems = [draggedItem.current!];

    const insertIndex = props.items.findIndex(i => i.id === item.id);
    const updatedItems = props.items.filter(
      itm => draggedItems.indexOf(itm) === -1
    );

    updatedItems.splice(insertIndex, 0, ...draggedItems);

    const updatePromises = Array.from(updatedItems).map(
      async (updatedItem, index) => {
        await updateDeviceDeploymentRing({
          id: updatedItem.id,
          deploymentRingId: updatedItem.deploymentRingId,
          sortOrder: index + 1,
          eTag: updatedItem.eTag,
          fieldDeviceId: updatedItem.fieldDeviceId
        });
      }
    );
    await Promise.all(updatePromises);
    feedback.success("The deployment rings have been reordered");
  };

  const getDragDropEvents = (): IDragDropEvents => {
    return {
      canDrop: () => true,
      canDrag: () => true,
      onDragEnter: () => dragEnterClass,
      onDragLeave: () => {},
      onDrop: (draggedItem?: any) => {
        if (draggedItem) {
          insertBeforeItem(draggedItem);
        }
      },
      onDragStart: (item?: any, itemIndex?: number) => {
        draggedItem.current = item;
        draggedIndex.current = itemIndex!;
      },
      onDragEnd: () => {
        draggedItem.current = undefined;
        draggedIndex.current = -1;
      }
    };
  };

  const columns: DataTableColumn<FieldDeviceDeploymentRingDetail>[] = [
    {
      key: "sortOrder",
      name: "Order",
      minWidth: 100,
      maxWidth: 100,
      isSorted: true,
      isSortedDescending: true
    },
    {
      key: "name",
      name: "Deployment Ring Name",
      minWidth: 200,
      maxWidth: 300,
      onRender: (
        fieldDeviceDeploymentRingDetail: FieldDeviceDeploymentRingDetail
      ) => (
        <Link
          onClick={() =>
            navigate(
              `/system/plt/deployment-rings/${fieldDeviceDeploymentRingDetail.deploymentRingId}`
            )
          }
        >
          {fieldDeviceDeploymentRingDetail.name}
        </Link>
      )
    },
    {
      key: "description",
      name: "Deployment Ring Description",
      minWidth: 400
    },
    {
      key: "deploymentRingId",
      name: "Deployment Ring Id",
      minWidth: 300,
      maxWidth: 300,
      onRender: (
        fieldDeviceDeploymentRingDetail: FieldDeviceDeploymentRingDetail
      ) => (
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <CopyToClipboardButton
            clipboardContent={fieldDeviceDeploymentRingDetail.deploymentRingId}
          />
          <Text>{fieldDeviceDeploymentRingDetail.deploymentRingId}</Text>
        </Stack>
      )
    },
    {
      key: "code",
      name: "Deployment Ring Code",
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: "isPublic",
      name: "Public?",
      minWidth: 60,
      maxWidth: 60,
      onRender: (
        fieldDeviceDeploymentRingDetail: FieldDeviceDeploymentRingDetail
      ) =>
        fieldDeviceDeploymentRingDetail.isPublic ? <>Public</> : <>Private</>
    },
    {
      key: "id",
      name: "",
      minWidth: 32,
      onRender: (
        fieldDeviceDeploymentRingDetail: FieldDeviceDeploymentRingDetail
      ) => (
        <IconButton
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          onClick={() => onRemoveItem(fieldDeviceDeploymentRingDetail)}
          iconProps={{ iconName: "Cancel" }}
          styles={mergeStyleSets(dangerButtonStyles(theme), {
            root: {
              background: "transparent"
            }
          })}
        />
      ),
      sort: false
    }
  ];

  return (
    <DataTable
      shimmerLines={10}
      columns={columns}
      onShouldVirtualize={() => true}
      dragDropEvents={getDragDropEvents()}
      {...props}
    />
  );
};
