import { DataTable, DataTableColumn } from "@components/tables/DataTable";

import { OrgUnitDto, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent, useState } from "react";

import {
  CollapsibleCard,
  confirm,
  Heading,
  IconButton,
  LabeledText,
  LabeledTextGroup,
  PrimaryButton,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { AddLocationDialog } from "./AddLocationDialog";
import { UpdateConsentDialog } from "./UpdateConsentDialog";
import {
  ConsentDto,
  ConsentLocation
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import {
  useApplicationsQuery,
  useConsentsQuery,
  usePatchConsentLocation
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";

interface ConsentProps {
  tenant: Tenant;
  item: ConsentDto;
  orgUnits: OrgUnitDto[];
  shouldDeleteItem?: boolean;
}

export const Consent: FunctionComponent<ConsentProps> = ({
  tenant,
  item,
  orgUnits
}) => {
  const theme = useTheme();

  const applicationsQuery = useApplicationsQuery(tenant.id);
  const consentsQuery = useConsentsQuery(tenant.id);

  const applicationName = applicationsQuery.data?.find(
    application => application.id === item.applicationId
  )?.name;

  const [showUpdateConsentDialog, setshowUpdateConsentDialog] = useState<
    boolean
  >(false);

  const [showAddLocationDialog, setshowAddLocationDialog] = useState<boolean>(
    false
  );

  const { mutateAsync: deleteConsentLocation } = usePatchConsentLocation();

  const onDeleteLocation = async (id: string) => {
    const locations = item.locations.filter(locationId => locationId !== id);
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Remove"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: "Remove location?",
        subText: "Are you sure you want to remove this location?"
      }
    });

    if (isConfirmed) {
      await deleteConsentLocation({
        locations,
        tenantId: tenant.id,
        id: item.id
      });
    }
  };

  const consentLocations =
    item && item.locations
      ? item.locations.map(locationId => {
          const consentLocationsResult = orgUnits.find(
            orgUnit => orgUnit.id === locationId
          );

          return {
            locationId,
            locationName: consentLocationsResult?.name || ""
          };
        })
      : [];

  const locationTableColumns: DataTableColumn<ConsentLocation>[] = [
    {
      key: "id",
      name: "OrgUnitId",
      minWidth: 200,
      isSorted: true,
      sort: true,
      onRender: (consent: ConsentLocation) => <Text>{consent.locationId}</Text>
    },
    {
      key: "name",
      name: "OrgUnitName",
      minWidth: 200,
      onRender: (consent: ConsentLocation) => (
        <Text>{consent.locationName}</Text>
      ),
      sort: true
    },
    {
      key: "delete",
      name: "",
      minWidth: 100,
      onRender: (consent: ConsentLocation) => (
        <IconButton
          iconProps={{ iconName: "Delete" }}
          styles={{ root: { justifySelf: "end", alignSelf: "start" } }}
          onClick={() => onDeleteLocation(consent.locationId)}
        />
      ),
      sort: true
    }
  ];

  const heading = (
    <Stack verticalAlign="space-between" tokens={{ childrenGap: 10 }}>
      <Stack verticalAlign="start">
        <Text>{applicationName}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <Text bold>Application(Client) Id:</Text>
          <Text>{item.applicationId}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <Text bold>All Location Access: </Text>
          <Text>{item.hasAllLocationAccess ? "Enabled" : "Disabled"}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <Text bold>Application Enabled: </Text>
          <Text> {item.isEnabled ? "Enabled" : "Disabled"}</Text>
        </Stack>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 4 }}>
          <PrimaryButton onClick={() => setshowUpdateConsentDialog(true)}>
            Update Consent
          </PrimaryButton>
          {showUpdateConsentDialog && (
            <UpdateConsentDialog
              tenant={tenant}
              applications={applicationsQuery.data ?? []}
              consents={consentsQuery.data ?? []}
              consent={item}
              onDismiss={() => {
                setshowUpdateConsentDialog(false);
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <CollapsibleCard
      heading={heading}
      styles={{
        header: { padding: 16 },
        heading: { display: "block", flexGrow: 1 },
        collapseContent: { padding: 16 },
        headerWrapper: { backgroundColor: theme.palette.neutralLighter }
      }}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 1 }}
        horizontalAlign="space-between"
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack>
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              horizontalAlign="space-between"
            >
              <Heading>Properties</Heading>
            </Stack>
            <Stack>
              <LabeledTextGroup>
                <LabeledText label="Created On">
                  {item.changeLog.createdDate}
                </LabeledText>
                <LabeledText label="Created By">
                  {item.changeLog.createdBy}
                </LabeledText>
                <LabeledText label="Updated On">
                  {item.changeLog.updatedDate}
                </LabeledText>
                <LabeledText label="Updated By">
                  {item.changeLog.updatedBy}
                </LabeledText>
              </LabeledTextGroup>
              {!item.changeLog && "There are no properties exists."}
            </Stack>
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack>
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              horizontalAlign="space-between"
            >
              <Heading>Locations</Heading>
              <PrimaryButton
                onClick={() => {
                  setshowAddLocationDialog(true);
                }}
              >
                Add Location
              </PrimaryButton>
              {showAddLocationDialog && (
                <AddLocationDialog
                  tenant={tenant}
                  item={item}
                  orgUnits={orgUnits ?? []}
                  onDismiss={() => {
                    setshowAddLocationDialog(false);
                  }}
                />
              )}
            </Stack>
            <Stack>
              <DataTable
                items={consentLocations}
                columns={locationTableColumns}
                getKey={(consent: ConsentLocation) => consent.locationId}
              />

              {!consentLocations.length && "There are no locations exists."}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CollapsibleCard>
  );
};
