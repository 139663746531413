import { useCommandRunner } from "../_shared-models/CommandRunner";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { DefaultButton } from "@bps/fluent-ui";
import { useEncryptTenantResourcesMutation } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const EncryptTenantResourcesButton = (props: Tenant) => {
  const commandRunner = useCommandRunner();
  const encryptTenantResourcesMutation = useEncryptTenantResourcesMutation();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  const onClick = () => {
    commandRunner.runMutation(
      "Encrypt Tenant Resources",
      encryptTenantResourcesMutation,
      { tenantId: props.id }
    );
  };

  return (
    <DefaultButton
      text="Encrypt Tenant Resource Connection Strings"
      onClick={onClick}
      disabled={!hasWritePermission}
      styles={{ root: { width: "fit-content" } }}
      title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
    />
  );
};
