import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import React, { useMemo } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";

import { IBreadcrumbItem } from "@bps/fluent-ui";

import { Breadcrumbs } from "@components/breadcrumbs/Breadcrumbs";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { getBreadcrumbTabName } from "@components/breadcrumbs/breadcrumbs.utils";

import { TenantsTabs } from "./tenants-tabs.constants";

export const TenantBreadcrumbs: React.FC = () => {
  const { pathname } = useLocation();
  const baseMatch = matchPath("/tenants/:tenantId/:sectionId", pathname);
  const subMatch = matchPath("/tenants/:tenantId/:sectionId/:id", pathname);
  const tenantId = baseMatch?.params?.tenantId ?? subMatch?.params.tenantId;
  const sectionId = baseMatch?.params?.sectionId ?? subMatch?.params.sectionId;
  const id = subMatch?.params.id;

  const { data: tenant } = useTenantQuery(tenantId!, { enabled: !!tenantId });

  const items = useMemo<IBreadcrumbItem[]>(() => {
    return getTenantItems(tenant, { id, tenantId, sectionId });
  }, [id, sectionId, tenant, tenantId]);

  return (
    <>
      <Breadcrumbs items={items} />
      <Outlet />
    </>
  );
};

const getTenantItems = (
  tenant: Tenant | undefined,
  data: { id?: string; tenantId?: string; sectionId?: string }
) => {
  const { id, tenantId, sectionId } = data;
  const _items: IBreadcrumbItem[] = [];
  _items.push({
    key: "tenants",
    text: "Tenants"
  });

  if (tenantId) {
    _items.push({
      key: "tenantId",
      text: !tenant?.name ? "..." : `${tenant.name}`
    });
  }
  if (sectionId) {
    _items.push({
      key: "sectionId",
      text: getBreadcrumbTabName(TenantsTabs, sectionId)
    });
  }

  if (id) {
    _items.push({
      key: "id",
      text: id
    });
  }
  return _items;
};
