import React, { FunctionComponent } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantDefaultUserAuth } from "@libs/api/gateways/plt/plt-gateway.hooks";

import { TenantUsersAuthForm } from "./components/TenantUsersAuthForm";

interface TenantUsersAuthProps {
  tenant: Tenant;
}

export const TenantUsersAuth: FunctionComponent<TenantUsersAuthProps> = ({
  tenant: { id: tenantId }
}) => {
  const theme = useTheme();
  const tenantInfoQuery = useTenantDefaultUserAuth(tenantId);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack.Item grow styles={{ root: { overflowY: "auto" } }}>
        <QueryStateIndicator {...tenantInfoQuery} allowNullOrUndefined>
          {tenantInfo => (
            <TenantUsersAuthForm tenantInfo={tenantInfo} tenantId={tenantId} />
          )}
        </QueryStateIndicator>
      </Stack.Item>
    </Stack>
  );
};
