import React, { useState } from "react";

import { withPermissions } from "@components/withPermissions";
import { useBhBannerMutation } from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import {
  BannerForm,
  BannerFormOnSubmit
} from "../../../_shared-blocks/BannerForm";

const BhBannerBase: React.FC = () => {
  const mutation = useBhBannerMutation();
  const [isLoading, setIsLoading] = useState<boolean>();

  const onSubmit: BannerFormOnSubmit = async () => {
    setIsLoading(true);
    try {
      await mutation.mutate("");
    } catch (e) {}
    setIsLoading(false);
  };

  return <BannerForm onSubmit={onSubmit} isLoading={isLoading} />;
};

export const BhBanner = withPermissions(
  BhBannerBase,
  [Permissions.EnvSecurityRead, Permissions.EnvSecurityWrite],
  "or"
);
