import { useRootStore } from "@stores/StoresProvider";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import React from "react";

import { DefaultButton, IButtonProps } from "@bps/fluent-ui";
import { usePingMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { DeploymentAction } from "@stores/deployment-store/deployment-store.types";

interface PingSiteComponentButtonProps extends IButtonProps {
  componentType?: SiteComponentType;
  desiredVersion?: string;
}

export const PingSiteComponentButton: React.FC<PingSiteComponentButtonProps> = ({
  componentType,
  children,
  ...props
}) => {
  const { deployment } = useRootStore();
  const sendPingMutation = usePingMutation();

  const onClick = async () => {
    if (!componentType || !deployment.selectedTenants.length) {
      return;
    }
    deployment.clearDeployments();

    const promises = deployment.selectedTenants.map(tenant =>
      deployment.addDeployment(DeploymentAction.SendPing, sendPingMutation, {
        siteId: tenant.id,
        componentType
      })
    );
    await Promise.all(promises);
  };

  return (
    <DefaultButton
      onClick={onClick}
      disabled={
        !deployment.selectedTenants.length ||
        !componentType ||
        deployment.isDeploying
      }
      {...props}
    >
      {children || "Send Ping"}
    </DefaultButton>
  );
};
