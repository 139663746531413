import { Tabs } from "@components/tabs-nav/TabNav.types";

export const SystemEnvTabs: Tabs = {
  User: {
    Manage: { id: "user-manage", name: "Manage Users" }
  },
  Roles: {
    View: { id: "view-roles", name: "Roles" }
  },
  Events: {
    PoisonMessages: { id: "poison-messages", name: "Poison Messages" }
  }
};
