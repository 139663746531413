import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useMemo } from "react";

import {
  DefaultButton,
  DetailsRow,
  IColumn,
  ShimmeredDetailsListProps,
  Text
} from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import { ConfigValueInput } from "./data-types";
import { RolloutPackageConfigurationDto } from "./EditRolloutPackageDesiredConfig";

export interface RolloutPackageDesiredConfigTableProps
  extends ShimmeredDetailsListProps {
  onUpdateRolloutPackageDesiredConfig: (
    key: string,
    value: string | undefined
  ) => void;
  rolloutOccurred?: boolean;
}

export const RolloutPackageDesiredConfigTable = ({
  onUpdateRolloutPackageDesiredConfig,
  rolloutOccurred,
  ...props
}: RolloutPackageDesiredConfigTableProps) => {
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "key",
        name: "Key",
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
        onRender: (dto: RolloutPackageConfigurationDto) => (
          <Text>{dto.key}</Text>
        )
      },
      {
        key: "value",
        name: "Value",
        minWidth: 150,
        maxWidth: 300,
        isResizable: true,
        onRender: (dto: RolloutPackageConfigurationDto) => (
          <ConfigValueInput
            rolloutOccurred={rolloutOccurred}
            dataType={dto.dataType}
            value={dto.value}
            onUpdate={val => onUpdateRolloutPackageDesiredConfig(dto.key, val)}
          />
        )
      },
      {
        key: "defaultValue",
        name: "Default Value",
        minWidth: 100,
        maxWidth: 350,
        isResizable: true,
        onRender: (dto: RolloutPackageConfigurationDto) => (
          <ConfigValueInput
            readonly={true}
            dataType={dto.dataType}
            value={dto.defaultValue}
            onUpdate={() => {}}
          />
        )
      },
      {
        key: "setDefault",
        name: "",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: (dto: RolloutPackageConfigurationDto) => {
          const cannotUpdate =
            dto.value === dto.defaultValue || rolloutOccurred;

          return (
            <DefaultButton
              disabled={cannotUpdate || !hasWritePermission}
              onClick={() =>
                onUpdateRolloutPackageDesiredConfig(dto.key, dto.defaultValue)
              }
              title={
                !hasWritePermission && !cannotUpdate
                  ? NO_ACTION_PERMISSION
                  : undefined
              }
            >
              Set to Default
            </DefaultButton>
          );
        }
      },
      {
        key: "description",
        name: "Description",
        minWidth: 100,
        maxWidth: 350,
        isResizable: true,
        onRender: (dto: RolloutPackageConfigurationDto) => (
          <Text>{dto.description}</Text>
        )
      },
      {
        key: "updatedDate",
        name: "Last Updated",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: (dto: RolloutPackageConfigurationDto) => (
          <Text>
            {dto.changeLog?.updatedDate
              ? DateTime.fromISO(dto.changeLog?.updatedDate).toFormat(
                  DATE_FORMATS.LONG_DATE_TIME_FORMAT
                )
              : ""}
          </Text>
        )
      }
    ];
  }, [
    onUpdateRolloutPackageDesiredConfig,
    rolloutOccurred,
    hasWritePermission
  ]);

  return (
    <ShimmeredDetailsList
      shimmerLines={5}
      columns={columns}
      onShouldVirtualize={() => true}
      onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
      {...props}
    />
  );
};
