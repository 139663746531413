import { useCommandRunner } from "../../../_shared-models/CommandRunner";

import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";
import { UseMutationResult } from "react-query";
import {
  useDeleteEntityEventsMutation,
  useHealthCheckMutation
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  useRecalculateAvailabilityMutation,
  useSnapshotTablesMutation
} from "@libs/api/gateways/tg/tg-ops-gateway.hooks";

interface Props {
  commandName: string;
  mutationName?: string;
  iconName: string;
  mutation: UseMutationResult<string, Error, string>;
  siteId: string;
}

// A command button that runs a mutation, where the mutation only takes the
// siteId as an argument.
const RunSiteMutationCommandButton = ({
  siteId,
  mutationName,
  commandName,
  iconName,
  mutation
}: Props) => {
  const commandRunner = useCommandRunner();

  const handleClick = () =>
    commandRunner.runMutation(mutationName ?? commandName, mutation, siteId);

  return (
    <SiteCommandButton
      commandName={commandName}
      onClick={handleClick}
      iconName={iconName}
    />
  );
};

export const HealthCheckCommandButton = (props: { siteId: string }) => {
  const mutation = useHealthCheckMutation();

  return (
    <RunSiteMutationCommandButton
      {...props}
      commandName="Health check"
      iconName="Health"
      mutation={mutation}
    />
  );
};

export const SnapshotTablesCommandButton = (props: { siteId: string }) => {
  const mutation = useSnapshotTablesMutation();

  return (
    <RunSiteMutationCommandButton
      {...props}
      commandName="Snapshot tables"
      mutationName="Trigger snapshot"
      iconName="Camera"
      mutation={mutation}
    />
  );
};

export const RecalculateAvailabilityCommandButton = (props: {
  siteId: string;
}) => {
  const mutation = useRecalculateAvailabilityMutation();

  return (
    <RunSiteMutationCommandButton
      {...props}
      commandName="Recalculate Availability"
      mutationName="Recalculate Availability"
      iconName="CalendarReply"
      mutation={mutation}
    />
  );
};

export const DeleteEntityEventsSiteCommandButton = (props: {
  siteId: string;
}) => {
  const deleteEntityEventsMutation = useDeleteEntityEventsMutation();

  return (
    <RunSiteMutationCommandButton
      {...props}
      commandName="Delete Queued Entity Events"
      mutationName="Delete Queued Entity Events"
      iconName="Delete"
      mutation={deleteEntityEventsMutation}
    />
  );
};
