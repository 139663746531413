import "json-diff-kit/dist/viewer.css";

import { Differ, Viewer } from "json-diff-kit";

import { DeviceConfigurationDiff } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface ConfigDiffViewProps {
  configDiff: DeviceConfigurationDiff;
}

export const ConfigDiffView: React.FC<ConfigDiffViewProps> = ({
  configDiff
}) => {
  if (!configDiff) return null;

  const differ = new Differ({
    detectCircular: true, // default `true`
    maxDepth: Infinity, // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: "lcs" // default `"normal"`, but `"lcs"` may be more useful
  });

  const diff = differ.diff(configDiff.desiredConfig, configDiff.reportedConfig);
  return (
    <Viewer
      diff={diff}
      indent={4}
      lineNumbers={false}
      highlightInlineDiff={true}
      inlineDiffOptions={{
        mode: "word",
        wordSeparator: "" // default `""`, but `" "` is more useful for sentences
      }}
    />
  );
};
