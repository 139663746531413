import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { CurrentSiteVersion } from "@libs/api/gateways/sia/models/CurrentSiteVersion";
import { observer } from "mobx-react";

import { PrimaryButton } from "@bps/fluent-ui";
import { useUpdateSiteComponentVersionMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

type Props = {
  component?: CurrentSiteVersion;
  version?: string;
  siteId: string;
};

export const ApproveBuildButton = observer(
  ({ siteId, component, version }: Props) => {
    const commandRunner = useCommandRunner();
    const mutation = useUpdateSiteComponentVersionMutation();

    const isDisabled =
      commandRunner.isBusy ||
      !(component && version) ||
      component.desiredVersion === version;

    const handleClick = async () => {
      commandRunner.runMutation(`Set Desired Version ${version}`, mutation, {
        siteId,
        component: component?.componentType,
        version: version!
      });
    };

    return (
      <PrimaryButton
        disabled={isDisabled}
        text="Set Desired Version"
        onClick={handleClick}
      />
    );
  }
);
