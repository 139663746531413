import { SiteSyncState } from "@libs/api/gateways/sia/models/SiteSyncState";

import { Text } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime, DurationUnit } from "@bps/utils";

const opts: DurationUnit = { unitDisplay: "short" };

export const OutOfSyncSince = ({ state }: { state: SiteSyncState }) => {
  if (!state?.outOfSync) return null;

  const time = DateTime.fromISO(state.recoveryModeDateUtc!);
  const duration = DateTime.now().diff(time);
  //.diff(time).shiftTo("hours", "minutes").toGo();

  return (
    <Text>
      Tenant entered recovery mode at{" "}
      {time.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT_MONTH_AHEAD)}&nbsp; (
      {duration.toHuman(opts)} ago)
    </Text>
  );
};
