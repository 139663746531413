import React, { FunctionComponent, useMemo } from "react";
import { IColumn, ScrollablePane, Stack } from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { ScrollListener } from "@components/tables/ScrollListener";
import { getPagesResult } from "@libs/paging/paging.utils";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { useUserSignInLogs } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { BpIdUserSignInLog } from "@libs/api/gateways/bp-id/bp-id.dtos";

interface UserSignInLogsListProps {
  userId?: string;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}
export const UserSignInLogsList: FunctionComponent<UserSignInLogsListProps> = ({
  userId,
  startDate,
  endDate
}) => {
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage
  } = useUserSignInLogs({ userId, startDate, endDate });

  const items = useMemo(() => getPagesResult(data), [data]);

  const columns: IColumn[] = [
    {
      key: "userPrincipalName",
      name: "Sign-in Email",
      maxWidth: 250,
      minWidth: 200,
      onRender: (item: BpIdUserSignInLog) => item.userPrincipalName
    },
    {
      key: "userDisplayName",
      name: "Bp Id User Name",
      maxWidth: 180,
      minWidth: 150,
      onRender: (item: BpIdUserSignInLog) => item.userDisplayName
    },
    {
      key: "createdDateTime",
      name: "On",
      maxWidth: 180,
      minWidth: 150,
      onRender: (item: BpIdUserSignInLog) =>
        item.createdDateTime
          ? DateTime.fromISO(item.createdDateTime).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : "-"
    },
    {
      key: "ipAddress",
      name: "IP Address",
      maxWidth: 120,
      minWidth: 100,
      onRender: (item: BpIdUserSignInLog) => item.ipAddress
    },
    {
      key: "errorCode",
      name: "Error Code",
      maxWidth: 100,
      minWidth: 80,
      onRender: (item: BpIdUserSignInLog) => item.status.errorCode
    },
    {
      key: "failureReason",
      name: "Failure Reason",
      maxWidth: 500,
      minWidth: 400,
      onRender: (item: BpIdUserSignInLog) => item.status.failureReason
    }
  ];

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <Stack styles={{ root: { position: "relative", height: 800 } }}>
      <ScrollablePane>
        <ShimmeredDetailsList
          items={items}
          enableShimmer={isLoading}
          errorMessage={error?.message}
          columns={columns}
          stickyHeader
        />
        <ScrollListener
          hasNextPage={true}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
