import { FunctionComponent, useState } from "react";
import { DefaultButton } from "@bps/fluent-ui";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { InviteFormDialog } from "modules/_shared-blocks/InviteFormDialog";

interface SendInviteDialogButtonProps {
  user: PltUser;
}

export const SendInviteDialogButton: FunctionComponent<SendInviteDialogButtonProps> = ({
  user
}) => {
  const { data: hasInvitePermission } = usePermissionsCheckQuery([
    Permissions.PltInvitationWrite
  ]);

  const [showSendInviteDialog, setShowSendInviteDialog] = useState<boolean>(
    false
  );

  return (
    <>
      {showSendInviteDialog && (
        <InviteFormDialog
          tenantId={user.tenantId}
          userId={user.id}
          bpIdUserId={user.bpIdUserId}
          firstName={user.firstName}
          lastName={user.lastName}
          onDismiss={() => setShowSendInviteDialog(false)}
        />
      )}
      <DefaultButton
        onClick={() => {
          setShowSendInviteDialog(true);
        }}
        disabled={!hasInvitePermission || user?.bpIdUserId !== undefined}
        title={!hasInvitePermission ? NO_ACTION_PERMISSION : undefined}
      >
        Send invite
      </DefaultButton>
    </>
  );
};
