import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { FunctionComponent } from "react";

import { IconButton } from "@bps/fluent-ui";
import { RedirectLinkDto } from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.dtos";
import { useDeleteRedirectLink } from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";

interface RedirectLinkActionsProps {
  item: RedirectLinkDto;
  openEditDialog: (linkId: string | undefined) => void;
}
export const RedirectLinkActions: FunctionComponent<RedirectLinkActionsProps> = ({
  item,
  openEditDialog
}) => {
  const {
    isLoading: deleteIsLoading,
    mutateAsync: deleteLink
  } = useDeleteRedirectLink();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  return (
    <IconButton
      disabled={deleteIsLoading}
      iconProps={{ iconName: "More" }}
      onRenderMenuIcon={() => null}
      menuProps={{
        items: [
          {
            key: "edit",
            text: "Edit",
            disabled: !hasWritePermission,
            title: !hasWritePermission ? NO_ACTION_PERMISSION : undefined,
            onClick: () => openEditDialog(item.linkId)
          },
          {
            key: "remove",
            text: "Remove",
            disabled: !hasWritePermission,
            title: !hasWritePermission ? NO_ACTION_PERMISSION : undefined,
            onClick: async () => {
              if (item.linkId) {
                await deleteLink(item.linkId);
              }
            }
          }
        ]
      }}
      styles={{
        root: { width: "32px", height: "36px", padding: 0 },
        flexContainer: { width: "32px", height: "36px" }
      }}
    />
  );
};
