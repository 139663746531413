import { useCommandRunner } from "../../../_shared-models/CommandRunner";
import { IContextualMenuProps } from "@bps/fluent-ui";
import { SiteCommandButton } from "modules/tenants/sections/pros/dashboard/site-commands/SiteCommandButton";

import { UseMutationResult } from "react-query";
import {
  SiteComponentArgs,
  useLogFilesUploadMutation,
  usePingMutation
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import { getShortComponentTypeName } from "@libs/api/gateways/sia/sia-ops-gateway.utils";

interface Props {
  commandName: string;
  mutationPrefix: string;
  iconName: string;
  mutation: UseMutationResult<string, Error, SiteComponentArgs>;
  siteId: string;
}

// A command button that runs a mutation, where the mutation takes the
// siteId and the component as an argument.
const RunSiteComponentMutationCommandButton = ({
  siteId,
  commandName,
  mutationPrefix,
  iconName,
  mutation
}: Props) => {
  const commandRunner = useCommandRunner();

  const handleRunForComponent = (componentType: SiteComponentType) => () => {
    return commandRunner.runMutation(
      `${mutationPrefix} ${getShortComponentTypeName(componentType)}`,
      mutation,
      {
        siteId,
        componentType
      }
    );
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: SiteComponentType.SiteAgent,
        text: `${mutationPrefix} agent`,
        onClick: handleRunForComponent(SiteComponentType.SiteAgent)
      },
      {
        key: SiteComponentType.SiteManager,
        text: `${mutationPrefix} manager`,
        onClick: handleRunForComponent(SiteComponentType.SiteManager)
      }
    ]
  };

  return (
    <SiteCommandButton
      commandName={commandName}
      menuProps={menuProps}
      iconName={iconName}
    />
  );
};

export const UploadLogsCommandButton = (props: { siteId: string }) => {
  const uploadLogsMutation = useLogFilesUploadMutation();

  return (
    <RunSiteComponentMutationCommandButton
      {...props}
      commandName="Upload logs"
      mutationPrefix="Upload logs via site"
      iconName="BulkUpload"
      mutation={uploadLogsMutation}
    />
  );
};

export const PingSiteCommandButton = (props: { siteId: string }) => {
  const sendPingMutation = usePingMutation();

  return (
    <RunSiteComponentMutationCommandButton
      {...props}
      commandName="Send ping"
      mutationPrefix="Ping site"
      iconName="AzureServiceEndpoint"
      mutation={sendPingMutation}
    />
  );
};
