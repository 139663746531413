import { FunctionComponent } from "react";
import { PresetButtonType } from "@bps/fluent-ui";
import {
  LicencesFilterProps,
  LicencesFilterValues
} from "./LicencesFilter.types";
import { FilterBar } from "@components/filter-bar/FilterBar";
import { Item } from "@components/filter-bar/FilterBar.types";
import { useLicenceTypes } from "@libs/api/gateways/plt/plt-gateway.hooks";

export const LicencesFilter: FunctionComponent<LicencesFilterProps> = ({
  tenantId,
  ...filterProps
}) => {
  const { data: licencesTypes = [] } = useLicenceTypes();
  const items: Item<LicencesFilterValues>[] = [
    {
      type: "usersSelect",
      name: "userIds",
      stickItem: true,
      props: {
        placeholder: "Select users",
        multiSelect: true,
        tenantId,
        styles: { root: { minWidth: 200 } }
      }
    },
    {
      type: "optionsSelect",
      name: "licenceType",
      stickItem: true,
      props: {
        options: licencesTypes.map(type => ({
          key: type.code,
          text: type.text
        })),
        placeholder: "Licence type code",
        styles: { root: { minWidth: 200 } }
      }
    }
  ];

  const presets: PresetButtonType<LicencesFilterValues>[] = [
    {
      name: "isInactive",
      text: "Show inactive",
      iconName: "GlobalNavButtonActive"
    },
    { name: "isExpired", text: "Show expired", iconName: "DateTime" },
    { name: "doNotRenew", text: "Do Not Renew", iconName: "BuildQueueNew" }
  ];

  return (
    <FilterBar<LicencesFilterValues>
      {...filterProps}
      items={items}
      presets={presets}
      initialValues={{
        isExpired: false,
        isInactive: false,
        doNotRenew: false
      }}
    />
  );
};
