import { withPermissions } from "@components/withPermissions";
import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FunctionComponent } from "react";

import { Stack } from "@bps/fluent-ui";

import { TenantConfigurationCard } from "./TenantConfigurationCard";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

const TtmTenantConfigurationBase: FunctionComponent<Props> = ({ tenant }) => {
  return (
    <Stack
      verticalFill
      styles={{
        root: {
          paddingLeft: 10,
          paddingRight: 10
        }
      }}
      tokens={{ childrenGap: 24 }}
    >
      <SectionTitle>Tenant Configuration</SectionTitle>
      <TenantConfigurationCard tenant={tenant} />
    </Stack>
  );
};

export const TtmTenantConfiguration = withPermissions(
  TtmTenantConfigurationBase,
  [Permissions.TiSettingsRead]
);
