import { Dialog, IDialogProps } from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { Form } from "@components/form/Form";
import { FormTextField } from "@components/form/fields/FormTextField";

export interface CommsSmsTestingSendFormValues {
  tenantId: string;
  phoneNumber: string;
  message: string;
}

interface ICommsSmsTestingSendDialogProps extends IDialogProps {
  onSubmit: (values: CommsSmsTestingSendFormValues) => Promise<void>;
  onDismiss: () => void;
  error: HttpError | null;
}

export const CommsSmsTestingSendDialog: React.FC<ICommsSmsTestingSendDialogProps> = ({
  onSubmit,
  onDismiss,
  error,
  ...props
}) => {
  const handleSubmit = async (values: CommsSmsTestingSendFormValues) => {
    await onSubmit(values);
  };

  return (
    <Dialog
      {...props}
      onDismiss={onDismiss}
      dialogContentProps={{
        showCloseButton: true,
        title: "Send SMS"
      }}
    >
      <Form<CommsSmsTestingSendFormValues>
        defaultValues={{
          tenantId: "",
          phoneNumber: "+614",
          message: "Ops console testing message"
        }}
        submitButtonText="Send"
        allowSubmitOnClean={true}
        showCancelButton={false}
        onSubmit={handleSubmit}
        onCancel={() => onDismiss()}
        error={error}
      >
        <FormTextField name="tenantId" label="Tenant ID" required />
        <FormTextField name="phoneNumber" label="Phone number" required />
        <FormTextField
          name="message"
          label="Message"
          multiline
          rows={3}
          required
        />
      </Form>
    </Dialog>
  );
};
