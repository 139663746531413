import { withPermissions } from "@components/withPermissions";
import { TabsNav } from "@components/tabs-nav/TabsNav";
import { Permissions } from "@libs/permissions/permissions.enum";
import { CustomerSearchPage } from "modules/cam/CustomerSearchPage";
import { CustomerTabLink } from "modules/cam/CustomerTabLink";
import React from "react";
import { generatePath, useParams } from "react-router-dom";

import { CustomerPage } from "./CustomerPage";
import { CustomersTabs } from "./customers-tabs.constants";
import { CustomerAccount } from "@libs/api/gateways/cam/cam-gateway.dtos";

const TAB_KEY = "cam";

const CustomerPageTabsBase: React.FC = () => {
  const { crmId } = useParams<{ crmId: string }>();

  return (
    <TabsNav<CustomerAccount>
      tabKey={TAB_KEY}
      selectedTab={crmId}
      defaultPath="/cam"
      navigatePath={details => {
        return generatePath("/cam/:crmId/:selectedTab", {
          crmId: details.id,
          selectedTab: CustomersTabs.Customer.CRMClientDetails.id
        });
      }}
      renderTab={({ id }) => <CustomerPage crmId={id} />}
      renderDefault={() => <CustomerSearchPage />}
      renderLink={props => <CustomerTabLink {...props} />}
    />
  );
};

export const CustomerPageTabs = withPermissions(
  CustomerPageTabsBase,
  [Permissions.PltCustomerRead, Permissions.PltCustomerWrite],
  "or"
);
