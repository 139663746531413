import { EntitySnapshotTable } from "modules/tenants/sections/pros/data-sync/entity-snapshots/EntitySnapshotTable";
import { useMemo, useState } from "react";

import {
  Heading,
  MessageBar,
  MessageBarType,
  SearchBox,
  Stack,
  StackItem,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useProsEntitySyncStateQuery } from "@libs/api/gateways/tg/tg-ops-gateway.hooks";

interface EntitySnapshotsProps {
  tenant: Tenant;
}

export const EntitySnapshots = ({ tenant }: EntitySnapshotsProps) => {
  const [filterCurrentValue, setFilterCurrentValue] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [filterError, setFilterError] = useState<string>();
  const theme = useTheme();
  const {
    data: entitySyncState,
    isLoading,
    error
  } = useProsEntitySyncStateQuery(tenant.id);

  const filteredSnapshots = useMemo(() => {
    try {
      const regExp = new RegExp(filter, "i");
      return entitySyncState?.entitySnapshots?.filter(snapshot =>
        regExp.test(snapshot.entityType)
      );
    } catch (e) {
      setFilterError(String(e));
      return [];
    }
  }, [entitySyncState?.entitySnapshots, filter]);

  return (
    <Stack>
      <Stack verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <StackItem>
          <Heading variant="heading">Snapshots</Heading>
          <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
            <SearchBox
              id="snapshot-search"
              value={filterCurrentValue}
              onChange={(_e, value) => setFilterCurrentValue(value)}
              onSearch={setFilter}
              placeholder="Filter snapshots"
              removeSpecialCharacters={false}
            />

            {filterError && (
              <Stack>
                <MessageBar messageBarType={MessageBarType.error}>
                  {filterError}
                </MessageBar>
              </Stack>
            )}
          </Stack>
        </StackItem>
        <StackItem>
          <QueryStateIndicator
            isLoading={isLoading}
            data={filteredSnapshots}
            error={error}
            loadingTitle="Loading snapshots"
          >
            {data => <EntitySnapshotTable entitySnapshots={data} />}
          </QueryStateIndicator>
        </StackItem>
      </Stack>
    </Stack>
  );
};
