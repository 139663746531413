import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { Tree, TreeItem } from "modules/system/sections/plt/Tree";
import React, { useMemo, useState } from "react";

import { Stack, TextField, useTheme } from "@bps/fluent-ui";
import { useFeature } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Feature } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface FeatureData {
  id: string;
  code: string;
  name: string;
  children: FeatureData[];
}

interface FeatureMap {
  [key: string]: FeatureData;
}

const parseFeatureData = (data?: Feature[]) => {
  const features: FeatureData[] = [];
  const featureMap: FeatureMap = {};
  if (!data) {
    return [];
  }
  for (const feature of data) {
    featureMap[feature.code] = {
      id: feature.id,
      code: feature.code,
      name: feature.text,
      children: []
    };
    features.push(featureMap[feature.code]);
  }
  // Prepare recursive dependencies
  for (const feature of data) {
    if (feature.dependencies) {
      for (const code of feature.dependencies) {
        if (feature.code !== code) {
          featureMap[feature.code].children.push(featureMap[code]);
        }
      }
    }
  }
  const items: TreeItem[] = [];
  for (const featureId in featureMap) {
    const feature = featureMap[featureId];
    items.push(feature);
  }
  return items;
};

const FeatureExplorerBase: React.FC = () => {
  const { data } = useFeature();
  const [searchFilter, setSearchFilter] = useState<string>();

  const treeItems = useMemo(
    () =>
      parseFeatureData(data).filter(item =>
        searchFilter
          ? item.name.toLowerCase().indexOf(searchFilter?.toLowerCase()) > -1
          : true
      ),
    [searchFilter, data]
  );

  const theme = useTheme();

  return (
    <Stack
      tokens={{
        childrenGap: theme.spacing.s1
      }}
    >
      <TextField
        onChange={(e, value) => setSearchFilter(value)}
        placeholder="Search"
        iconProps={{
          iconName: "Search"
        }}
      />
      <Tree items={treeItems} />
    </Stack>
  );
};

export const FeatureExplorer = withPermissions(
  FeatureExplorerBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
