import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FeatureGraph } from "modules/system/sections/plt/feature/FeatureGraph";
import React from "react";

import { Spinner, Text } from "@bps/fluent-ui";
import { useTenantFeatures } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface TenantFeaturesProps {
  tenant: Tenant;
}

const TenantFeaturesBase: React.FC<TenantFeaturesProps> = ({ tenant }) => {
  const { data, isFetching } = useTenantFeatures({
    tenantIds: tenant.id
  });
  return (
    <>
      {isFetching && <Spinner />}

      {!isFetching && data?.length ? (
        <FeatureGraph onlyFeatures={data?.map(item => item.feature)} />
      ) : (
        <Text>Tenant features not found</Text>
      )}
    </>
  );
};

export const TenantFeatures = withPermissions(TenantFeaturesBase, [
  Permissions.OpsConsolePreRelease
]);
