import { ReplicationQueueProcessingStateBadge } from "../../../../../_shared-blocks/ReplicationQueueProcessingStateBadge";
import { SectionTitle } from "@components/SectionTitle";
import { OutOfSyncSince } from "modules/tenants/sections/pros/data-sync/replication-queue-processing-state/OutOfSyncSince";
import { ReplicationQueueProcessingStateTable } from "modules/tenants/sections/pros/data-sync/replication-queue-processing-state/ReplicationQueueProcessingStateTable";

import { Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SiteSyncState } from "@libs/api/gateways/sia/models/SiteSyncState";
import { useSiteSyncStateQuery } from "@libs/api/gateways/tg/tg-ops-gateway.hooks";

interface SiteSyncStateProps {
  siteId: string;
}

export const ReplicationQueueProcessingState = (props: SiteSyncStateProps) => {
  const query = useSiteSyncStateQuery(props.siteId);

  return (
    <Stack>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <SectionTitle>Replication Queue Processing State</SectionTitle>

        <ReplicationQueueProcessingStateBadge
          outOfSync={query.data?.outOfSync}
        />
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
        <QueryStateIndicator
          {...query}
          loadingTitle="Loading replication queue processing state files"
        >
          {(currentSyncStateData: SiteSyncState) => (
            <Stack tokens={{ childrenGap: 10 }}>
              <OutOfSyncSince state={currentSyncStateData} />
              <ReplicationQueueProcessingStateTable
                state={currentSyncStateData}
              />
            </Stack>
          )}
        </QueryStateIndicator>
      </Stack>
    </Stack>
  );
};
