export const UsersTabs = {
  BusinessUsers: {
    IdentitySearch: { id: "bu-identity-search", name: "Identity Search" },
    UserSearch: { id: "bu-user-search", name: "User Search" }
  },
  ConsumerUsers: {
    ProfileSearch: { id: "cu-profile-search", name: "Profile Search" },
    IdentitySearch: { id: "cu-identity-search", name: "Identity Search" },
    FamilySearch: { id: "cu-family-search", name: "Family Search" }
  }
};
