import { withPermissions } from "@components/withPermissions";

import { PltUsersList } from "modules/users/PltUsers/PltUsersList";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FilterBar, Item } from "@bps/fluent-ui";
import { useSearchUsers } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface PltUsersFilterValues {
  searchTerm: string;
  tenantId: string;
  status: string;
}

const PltUsersBase: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = searchParams.get("searchTerm") ?? "";
  const tenantId = searchParams.get("tenantId") ?? "";
  const status = searchParams.get("status") ?? "";

  const { data, isFetching } = useSearchUsers(tenantId, searchTerm);

  const items: Item<PltUsersFilterValues>[] = [
    {
      name: "tenantId",
      type: "searchBox",
      stickItem: true,
      props: {
        placeholder: "Tenant id",
        removeSpecialCharacters: false
      }
    },
    {
      name: "searchTerm",
      type: "searchBox",
      props: {
        placeholder: "User Id / Username",
        styles: { root: { width: 250 } },
        removeSpecialCharacters: false
      }
    },
    {
      name: "status",
      type: "optionsSelect",
      props: {
        placeholder: "Status",
        options: [{ key: "ACTIVE", text: "Active" }],
        hideSearchOption: true,
        styles: { root: { width: 100 } }
      }
    }
  ];
  return (
    <FilterBar<PltUsersFilterValues>
      maxItemWidth={250}
      items={items}
      initialValues={{ tenantId, searchTerm, status }}
      onChange={({ values }) => {
        setSearchParams(values as any);
      }}
      customResetButtonCondition={(_a, values) => {
        return Object.values(values).every(v => !v);
      }}
      onReset={({ reset }) => {
        reset({ searchTerm: "", tenantId: "", status: "" });
      }}
    >
      {() => {
        const users =
          data?.filter(user => (status ? user.status === status : true)) || [];
        return <PltUsersList items={users} enableShimmer={isFetching} />;
      }}
    </FilterBar>
  );
};

export const PltUsers = withPermissions(
  PltUsersBase,
  [Permissions.PltCatalogUserRead, Permissions.PltCatalogUserWrite],
  "or"
);
