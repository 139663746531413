import { DangerButton } from "@components/buttons/DangerButton";
import { Permissions } from "@libs/permissions/permissions.enum";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useState } from "react";

import { TenantIsInactiveChangeDialog } from "./TenantIsInactiveChangeDialog";
import {
  useActivateTenantMutation,
  useDeactivateTenantMutation
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const ChangeIsInActiveButton = (tenant: Tenant) => {
  const [updateTenant, setUpdateTenant] = useState<boolean>(false);

  const activateMutation = useActivateTenantMutation();
  const deactivateMutation = useDeactivateTenantMutation();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    [
      Permissions.PltCatalogTenantActivate,
      Permissions.PltCatalogTenantDeactivate
    ],
    "or"
  );

  const changeIsInactiveToValue = tenant.isInactive === false;

  const modeText = changeIsInactiveToValue === true ? "Activate" : "Deactivate";
  const modeMutation =
    changeIsInactiveToValue === true ? activateMutation : deactivateMutation;

  return (
    <>
      <TenantIsInactiveChangeDialog
        hidden={!updateTenant}
        tenantName={tenant.name}
        changeIsInactiveToValue={changeIsInactiveToValue}
        onChange={async () =>
          await modeMutation.mutateAsync({ tenantId: tenant.id })
        }
        onCancel={() => setUpdateTenant(false)}
      />

      <DangerButton
        text={modeText}
        onClick={() => setUpdateTenant(true)}
        styles={{ root: { width: "fit-content" } }}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      />
    </>
  );
};
