import { Route, Routes } from "react-router-dom";
import { CustomerPageTabs } from "./CustomerPageTabs";
import { DefaultBreadcrumbs } from "@components/breadcrumbs/DefaultBreadcrumbs";
import { CustomersTabs } from "./customers-tabs.constants";

const CustomersRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<DefaultBreadcrumbs tabs={CustomersTabs} />}>
        <Route path="/" element={<CustomerPageTabs />}>
          <Route path=":crmId/:selectedTab" element={null} />
        </Route>
      </Route>
    </Routes>
  );
};

// eslint-disable-next-line import/no-default-export
export default CustomersRoutes;
