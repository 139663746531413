import { SectionTitle } from "@components/SectionTitle";
import { Permissions } from "@libs/permissions/permissions.enum";
import React, { useState } from "react";

import {
  DefaultButton,
  FilterBar,
  Item,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import { NewUserDialogButton } from "./components/NewUserDialogButton";
import { PltUsersTable } from "./components/PltUsersTable";
import { SendNotificationsDialog } from "./components/SendNotificationsDialog";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface TenantUsersProps {
  tenant: Tenant;
}

export interface TenantUsersFilters {
  name: string;
  status: string;
  roleCode: string;
  bpIdUserId?: string;
}

const defaultFilter: TenantUsersFilters = {
  name: "",
  roleCode: "",
  status: "",
  bpIdUserId: undefined
};

export const TenantUsersScreen: React.FC<TenantUsersProps> = ({ tenant }) => {
  const theme = useTheme();
  const { data: hasNotificationPermission } = usePermissionsCheckQuery(
    Permissions.PltNotificationsWrite
  );

  const [selectedUsersIds, setSelectedUsersIds] = useState<Set<string>>(
    new Set<string>()
  );

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const items: Item<TenantUsersFilters>[] = [
    {
      name: "name",
      type: "searchBox",
      stickItem: true,
      props: {
        styles: { root: { minWidth: 400 } },
        placeholder: "Search by name or email",
        removeSpecialCharacters: false
      }
    },
    {
      name: "bpIdUserId",
      type: "searchBox",
      stickItem: true,
      props: {
        styles: { root: { minWidth: 400 } },
        placeholder: "Search by BpId User Id",
        removeSpecialCharacters: false
      }
    }
  ];

  const onDismissNotificationsDialog = () => {
    setSelectedUsersIds(new Set());
    setShowDialog(false);
  };

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <SectionTitle>Users</SectionTitle>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <NewUserDialogButton tenantId={tenant.id} />
          <DefaultButton
            disabled={!selectedUsersIds.size || !hasNotificationPermission}
            onClick={() => setShowDialog(true)}
            title={
              !hasNotificationPermission ? NO_ACTION_PERMISSION : undefined
            }
          >
            Send Batch Notifications
          </DefaultButton>
        </Stack>
      </Stack>
      <FilterBar<TenantUsersFilters>
        items={items}
        initialValues={defaultFilter}
      >
        {({ values }) => {
          return (
            <PltUsersTable
              tenantId={tenant.id}
              filter={values}
              selectedUsersIds={selectedUsersIds}
              setSelectedUsersIds={setSelectedUsersIds}
              showNotificationsDialog={() => setShowDialog(true)}
            />
          );
        }}
      </FilterBar>

      {showDialog && (
        <SendNotificationsDialog
          tenantId={tenant.id}
          usersIds={Array.from(selectedUsersIds.values())}
          onDismiss={onDismissNotificationsDialog}
        />
      )}
    </Stack>
  );
};
