import React, { FunctionComponent, useState } from "react";

import {
  IColumn,
  IconButton,
  NoDataTile,
  Stack,
  Toggle,
  useTheme
} from "@bps/fluent-ui";
import { SectionTitle } from "@components/SectionTitle";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { withPermissions } from "@components/withPermissions";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { LicenceTypeDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useLicenceTypes,
  useToggleLicenceType
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { AddLicenceTypeDialog } from "./components/add-licence-type-dialog/AddLicenceTypeDialog";
import {
  ADD_TYPE_TITLE,
  LicenceCode
} from "./components/add-licence-type-dialog/AddLicenceTypeDialog.types";

const LicenceCodesBase: FunctionComponent = () => {
  const theme = useTheme();
  const [code, setCode] = useState<LicenceCode>(undefined);

  const licencesTypeQuery = useLicenceTypes();
  const licencesToggleTypeQuery = useToggleLicenceType();
  const items = licencesTypeQuery.data ?? [];

  const sortedItems = Array.from(items).sort((a, b) => {
    if (a.isInactive === b.isInactive) return 0;
    return a.isInactive ? 1 : -1;
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  const columns: IColumn[] = [
    {
      name: "",
      key: "active",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: LicenceTypeDto) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          onClick={() => setCode(item.code)}
        />
      )
    },
    {
      name: "Code",
      key: "code",
      minWidth: 150,
      maxWidth: 250,
      onRender: (item: LicenceTypeDto) => item.code
    },
    {
      name: "Text",
      key: "text",
      minWidth: 350,
      maxWidth: 350,
      onRender: (item: LicenceTypeDto) => item.text
    },
    {
      name: "Source",
      key: "source",
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: LicenceTypeDto) => item.source
    },
    {
      name: "Grace Period",
      key: "grace-period",
      minWidth: 100,
      maxWidth: 150,
      onRender: (item: LicenceTypeDto) => item.gracePeriod
    },
    {
      name: "Country Code",
      key: "country-code",
      minWidth: 150,
      maxWidth: 250,
      onRender: (item: LicenceTypeDto) => item.countryCode
    },
    {
      name: "Status",
      key: "status",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: LicenceTypeDto) => (
        <Toggle
          automationAttribute="licence-type-toggle"
          checked={!item.isInactive}
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          onChange={async () => {
            await licencesToggleTypeQuery.mutateAsync({
              code: item.code,
              disable: !item.isInactive
            });
          }}
          offText="Inactive"
          onText="Active"
          styles={{
            root: { margin: 0 },
            text: {
              color: item.isInactive
                ? theme.semanticColors.errorText
                : theme.semanticColors.successIcon
            }
          }}
        />
      )
    }
  ];

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Licence types</SectionTitle>
        <AddLicenceTypeDialog setCode={setCode} code={code} />
      </Stack>

      {!sortedItems.length &&
        !licencesTypeQuery.isLoading &&
        !licencesTypeQuery.error && (
          <NoDataTile
            textProps={{ text: "No licences types." }}
            linkProps={{
              text: ADD_TYPE_TITLE,
              onClick: () => setCode(null)
            }}
          />
        )}

      <ShimmeredDetailsList
        items={sortedItems}
        columns={columns}
        enableShimmer={
          licencesToggleTypeQuery.isLoading ?? licencesTypeQuery.isLoading
        }
        errorMessage={licencesTypeQuery.error?.message}
      />
    </Stack>
  );
};

export const LicenceCodes = withPermissions(
  LicenceCodesBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
