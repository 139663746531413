import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import { Card, Text } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useBookingTenantSettings } from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}

const cardStyles = {
  subComponentStyles: {
    tile: {
      content: {
        padding: 0
      }
    }
  }
};

const TenantSettingsCardBase: FunctionComponent<Props> = ({
  tenant: { id: tenantId }
}) => {
  const tenantSettingsQuery = useBookingTenantSettings(tenantId);

  return (
    <Card
      iconName="Settings"
      headingLevel="section-heading"
      heading="Tenant Settings"
      styles={cardStyles}
    >
      <QueryStateIndicator {...tenantSettingsQuery}>
        {data => {
          return <Text>{JSON.stringify(data)}</Text>;
        }}
      </QueryStateIndicator>
    </Card>
  );
};

export const TenantSettingsCard = withPermissions(TenantSettingsCardBase, [
  Permissions.TiSettingsRead
]);
