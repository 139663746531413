import { Form } from "@components/form/Form";
import React, { FunctionComponent, useMemo } from "react";

import { Dialog } from "@bps/fluent-ui";

import {
  MessageCreditsEventFormDialogBaseProps,
  MessageCreditsEventFormDialogProps,
  MessageCreditsEventFormValues,
  newMessageCreditsEventFormValues
} from "./MessageCreditsEventFormDialog.types";
import { MessageCreditsEventFormFields } from "./MessageCreditsEventFormFields";
import { useAddMessageCreditsEvent } from "@libs/api/gateways/plt-comms/plt-comms-gateway.hooks";
import { AddMessageCreditsEventDto } from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";

const MessageCreditsEventFormDialogBase: FunctionComponent<MessageCreditsEventFormDialogBaseProps> = ({
  closeDialog,
  tenantId,
  messageCreditsEventId,
  messageCreditsEvent
}) => {
  const isNewMessageCreditsEvent = messageCreditsEventId === "new";

  const data = isNewMessageCreditsEvent ? undefined : messageCreditsEvent;

  const {
    mutateAsync: addMessageCreditsEvent,
    error: addMessageCreditsEventError
  } = useAddMessageCreditsEvent();

  const defaultValues = useMemo(() => {
    if (isNewMessageCreditsEvent || !data)
      return newMessageCreditsEventFormValues;
    return data;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (values: MessageCreditsEventFormValues) => {
    const dto: AddMessageCreditsEventDto = {
      tenantId,
      units: values.units,
      details: values.details
    };
    try {
      if (isNewMessageCreditsEvent) {
        await addMessageCreditsEvent(dto);
      }
      closeDialog();
    } catch (e) {
      return e;
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "New Message credits Event",
        showCloseButton: true,
        onDismiss: closeDialog
      }}
    >
      <Form<MessageCreditsEventFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={addMessageCreditsEventError}
      >
        <MessageCreditsEventFormFields tenantId={tenantId} />
      </Form>
    </Dialog>
  );
};

export const MessageCreditsEventFormDialog: FunctionComponent<MessageCreditsEventFormDialogProps> = props => {
  if (!props.messageCreditsEventId) return null;
  return (
    <MessageCreditsEventFormDialogBase
      {...props}
      messageCreditsEventId={props.messageCreditsEventId}
    />
  );
};
