import { Accordion, Heading, Stack, useTheme } from "@bps/fluent-ui";

import { ReportDetails } from "./ReportDetails";
import { PowerBiDataset } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";

interface Props {
  datasets: PowerBiDataset[];
}

export const ReportScreen = ({ datasets }: Props) => {
  const theme = useTheme();

  return (
    <Stack
      verticalFill
      tokens={{
        childrenGap: theme.spacing.s2
      }}
      styles={{
        root: {
          flex: "1 1 0",
          overflowY: "auto",
          backgroundColor: theme.semanticColors.bodyBackground,
          padding: theme.spacing.s1
        }
      }}
    >
      <Heading variant="heading">Reports</Heading>
      <Accordion>
        {datasets.map(dataset => (
          <ReportDetails dataset={dataset} key={dataset.id} />
        ))}
      </Accordion>
    </Stack>
  );
};
