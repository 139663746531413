import {
  Dialog,
  IDialogProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@bps/fluent-ui";
import { useResetCustomerSubscriptionBillingDate } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface SubscriptionResetModalProps extends Pick<IDialogProps, "onDismiss"> {
  customerTenantId: string | undefined;
  subscriptionId: string | undefined;
}

export const SubscriptionResetModal = ({
  customerTenantId,
  subscriptionId,
  onDismiss
}: SubscriptionResetModalProps) => {
  const {
    error: resetSubscriptionError,
    isLoading,
    mutateAsync: resetSubscription
  } = useResetCustomerSubscriptionBillingDate();

  const onClick = async () => {
    if (customerTenantId && subscriptionId) {
      await resetSubscription({
        customerTenantId,
        subscriptionId
      });
    }
    // Error handling?
    if (resetSubscriptionError) {
      // Do something useful
    }
    onDismiss?.();
  };

  return (
    <>
      <Dialog
        hidden={false}
        onDismiss={onDismiss}
        dialogContentProps={{
          showCloseButton: true,
          title: "Reset Billing Date"
        }}
      >
        <Stack verticalAlign="center" tokens={{ childrenGap: 4 }}>
          <Stack.Item>
            This action will reset the Billing Date for the Subscription to the
            current time.
          </Stack.Item>
          <Stack.Item>This action cannot be undone! </Stack.Item>
          {isLoading ? (
            <Spinner size={SpinnerSize.small} />
          ) : (
            <PrimaryButton
              iconProps={{ iconName: "Edit" }}
              disabled={isLoading}
              onClick={onClick}
            >
              "Reset Billing Date"
            </PrimaryButton>
          )}
        </Stack>
      </Dialog>
    </>
  );
};
