import React, { FunctionComponent } from "react";

import { confirm, IconButton, IContextualMenuItem } from "@bps/fluent-ui";
import {
  UserInviteArgs,
  UserInviteDto
} from "@libs/api/gateways/bp-id/bp-id.dtos";
import {
  useCancelUserInvite,
  useReSendUserInvite,
  useSendUserInvite
} from "@libs/api/gateways/bp-id/bp-id.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

interface InvitationsInvitationsActionsProps {
  invitation: UserInviteDto;
}
export const InvitationsActions: FunctionComponent<InvitationsInvitationsActionsProps> = ({
  invitation
}) => {
  const { data: hasInvitePermission } = usePermissionsCheckQuery([
    Permissions.PltInvitationWrite
  ]);

  const {
    mutateAsync: cancel,
    isLoading: isLoadingCancel
  } = useCancelUserInvite();

  const {
    mutateAsync: reSend,
    isLoading: isLoadingReSend
  } = useReSendUserInvite();

  const { mutateAsync: send, isLoading: isLoadingSend } = useSendUserInvite();

  const handleCancel = async () => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Confirm"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        subText: "Do you want to cancel the invitation?"
      }
    });

    if (isConfirmed) {
      await cancel(invitation.id);
    }
  };

  const handleSendAgain = async () => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Confirm"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        subText:
          "Do you want to send a new invitation with the values provided?"
      }
    });

    if (isConfirmed) {
      const payload: UserInviteArgs = {
        userId: invitation.userId,
        tenantId: invitation.tenantId,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        signInEmail: invitation.signInEmail,
        signInBpIdUserId: invitation.signInBpIdUserId,
        issuer: invitation.issuer,
        issuerAssignedId: invitation.issuerAssignedId,
        groups: invitation.groups,
        signInType: invitation.signInType,
        generateShortLinkId: true,
        redirectUrl: invitation.redirectUrl
      };
      await send(payload);
    }
  };

  const items: IContextualMenuItem[] = invitation.isValid
    ? [
        {
          key: "re-send",
          text: "Send again",
          disabled: !hasInvitePermission,
          onClick: () => {
            reSend(invitation.id);
          }
        },
        {
          key: "cancel",
          text: "Cancel",
          disabled: !hasInvitePermission,
          onClick: () => {
            handleCancel();
          }
        }
      ]
    : [
        {
          key: "send",
          text: "Send invitation",
          disabled: !hasInvitePermission,
          onClick: () => {
            handleSendAgain();
          }
        }
      ];
  return (
    <>
      <IconButton
        disabled={isLoadingCancel || isLoadingReSend || isLoadingSend}
        styles={{ root: { justifySelf: "end" } }}
        menuIconProps={{ iconName: "More" }}
        menuProps={{
          items
        }}
      />
    </>
  );
};
