import { QueryStateIndicator } from "components/QueryStateIndicator";
import { SectionTitle } from "components/SectionTitle";
import { LogEntriesComponent } from "modules/tenants/sections/ops/logging/LogEntriesComponent";

import { Link, Stack } from "@bps/fluent-ui";
import { SIATenantDetails } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";
import { useTenantAzureMonitorLogsQuery } from "@libs/api/gateways/user-experience/user-experience.hooks";
import { config } from "@libs/config/config";

interface AzureLogsProps {
  tenant: SIATenantDetails;
}

export const AzureLogs = ({ tenant }: AzureLogsProps) => {
  const query = useTenantAzureMonitorLogsQuery(tenant.id, tenant.siteId);

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <SectionTitle>Azure Monitor</SectionTitle>
      <div>
        <Link href={config.azureMonitorUrl} target="_blank" rel="noreferrer">
          Click here to open Azure Monitor for tenant {tenant.id} ({tenant.name}
          )
        </Link>
      </div>
      <QueryStateIndicator<LogEntry[]> {...query} error={query.error}>
        {data => {
          return <LogEntriesComponent logEntries={data} />;
        }}
      </QueryStateIndicator>
    </Stack>
  );
};
