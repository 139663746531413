import { Dialog, IDialogProps } from "@bps/fluent-ui";

import { AssignSelector } from "./AssignSelector";
import { useSelectors } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface AssignSelectorDialogProps extends IDialogProps {
  deploymentRing: DeploymentRing;
  onDismiss: () => void;
}

export const AssignSelectorDialog: React.FC<AssignSelectorDialogProps> = ({
  deploymentRing,
  onDismiss,
  ...props
}) => {
  const { data, isLoading, error } = useSelectors();
  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Assign Selector to Deployment Ring"
      }}
      minWidth="90%"
      maxWidth="90%"
      onDismiss={onDismiss}
      {...props}
    >
      <AssignSelector
        deploymentRing={deploymentRing}
        items={data ?? []}
        enableShimmer={isLoading}
        errorMessage={error?.message}
        onDismiss={onDismiss}
      />
    </Dialog>
  );
};
