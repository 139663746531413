import React, { FunctionComponent, useMemo } from "react";
import { IColumn, ScrollablePane, Stack, Text } from "@bps/fluent-ui";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { ScrollListener } from "@components/tables/ScrollListener";
import { getPagesResult } from "@libs/paging/paging.utils";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { useAuditLogs } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { BpIdAuditLog } from "@libs/api/gateways/bp-id/bp-id.dtos";

interface AuditLogsListProps {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

export const AuditLogsList: FunctionComponent<AuditLogsListProps> = ({
  startDate,
  endDate
}) => {
  const { data, isLoading, error, fetchNextPage, hasNextPage } = useAuditLogs({
    startDate,
    endDate
  });

  const items = useMemo(() => getPagesResult(data), [data]);

  const columns: IColumn[] = [
    {
      key: "activityDateTime",
      name: "Date Time",
      maxWidth: 150,
      minWidth: 120,
      onRender: (item: BpIdAuditLog) =>
        item.activityDateTime
          ? DateTime.fromISO(item.activityDateTime).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )
          : "-"
    },
    {
      key: "activityDisplayName",
      name: "Activity",
      minWidth: 250,
      onRender: (item: BpIdAuditLog) => (
        <Stack>
          <Text>
            {item.loggedByService} {item.category} {item.activityDisplayName}
          </Text>
          <Text>{item.result}</Text>
          <Text>{item.resultReason}</Text>
          <Text styles={{ root: { whiteSpace: "normal" } }}>
            {item.additionalDetails}
          </Text>
        </Stack>
      )
    }
  ];

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <Stack styles={{ root: { position: "relative" } }} grow>
      <ScrollablePane>
        <ShimmeredDetailsList
          items={items}
          enableShimmer={isLoading}
          errorMessage={error?.message}
          columns={columns}
          stickyHeader
        />
        <ScrollListener
          hasNextPage={true}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
