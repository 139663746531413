import React, { FunctionComponent } from "react";
import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

interface PltUserStatusBadgeProps {
  status: string;
}

export const PltUserStatusBadge: FunctionComponent<PltUserStatusBadgeProps> = ({
  status
}) => {
  let badgeColor: TextBadgeColor | undefined;
  if (status.toLowerCase().includes("pending")) {
    badgeColor = TextBadgeColor.yellow;
  }
  if (status.toUpperCase() === "ACTIVE") {
    badgeColor = TextBadgeColor.green;
  }

  if (status.toUpperCase() === "INACTIVE") {
    badgeColor = TextBadgeColor.red;
  }

  if (!badgeColor) return null;
  return (
    <TextBadge
      badgeColor={badgeColor}
      badgeSize={TextBadgeSize.medium}
      styles={{ root: { width: 120 } }}
    >
      {status}
    </TextBadge>
  );
};
