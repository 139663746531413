import { useTabsNavContext } from "@components/tabs-nav";

import { TenantsTable } from "modules/tenants/tabs/TenantsTable";
import React, { useState } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";

import { TenantsFilter } from "./TenantsFilter";
import { getPagesResult } from "@libs/paging/paging.utils";
import { TenantSearchArgs } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantsPltQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { TenantsTabs } from "../tenants-tabs.constants";

interface TenantFilterValues
  extends Pick<TenantSearchArgs, "isInactive" | "name" | "applications"> {}
export const TenantSelector = () => {
  const theme = useTheme();
  const { addTab } = useTabsNavContext();

  const [args, setArgs] = useState<TenantFilterValues>({
    isInactive: false,
    name: ""
  });

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    error
  } = useTenantsPltQuery({ ...args }, { refetchOnMount: "always" });

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  const tenants = getPagesResult(data);

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          overflow: "hidden",
          backgroundColor: theme.semanticColors.bodyBackground,
          padding: theme.spacing.s1
        }
      }}
    >
      <TenantsFilter setArgs={setArgs} />
      <TenantsTable
        tenants={tenants}
        onTenantSelected={data =>
          addTab({
            id: data.id,
            name: data.name,
            sectionId: TenantsTabs.Platform.Details.id
          })
        }
        isLoading={isLoading}
        error={error?.message}
        hasNextPage={hasNextPage}
        handleScrolledToBottom={handleScrolledToBottom}
      />
    </Stack>
  );
};
