import { Form } from "@components/form/Form";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FunctionComponent } from "react";

import { Dialog, Heading, Option, Stack, StackItem } from "@bps/fluent-ui";
import {
  useApplicationsQuery,
  useCreateConsent
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { ConsentDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface CreateConsentDialogProps {
  tenant: Tenant;
  consents: ConsentDto[];
  onDismiss: () => void;
}

export interface CreateConsentFormValues {
  applicationId: string;
  hasAllLocationAccess: boolean;
  isEnabled: boolean;
}

export const CreateConsentDialog: FunctionComponent<CreateConsentDialogProps> = ({
  tenant,
  consents,
  onDismiss
}) => {
  const {
    mutateAsync: createConsent,
    error: createConsentError
  } = useCreateConsent();

  const onSubmit = async (formData: CreateConsentFormValues) => {
    await createConsent({
      applicationId: formData.applicationId,
      tenantId: tenant.id,
      hasAllLocationAccess: formData.hasAllLocationAccess,
      isEnabled: formData.isEnabled
    });
    onDismiss();
  };

  const defaultValues = {
    hasAllLocationAccess: true,
    isEnabled: true,
    applicationId: ""
  };

  const applicationsQuery = useApplicationsQuery(tenant.id);

  const applications = applicationsQuery.data
    ?.filter(
      application =>
        !consents.some(consent => consent.applicationId === application.id)
    )
    .map(applicationResult => ({
      id: applicationResult.id,
      name: applicationResult.name
    }));

  const applicationsOption: Option[] = !applicationsQuery
    ? []
    : [
        {
          key: "",
          text: "None"
        }
      ].concat(
        applications?.map(t => {
          return { key: t.id, text: t.name };
        }) ?? []
      );

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Configure Consent",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<CreateConsentFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={createConsentError}
      >
        <FormDropdown
          name="applicationId"
          placeholder="Select application id"
          defaultSelectedKey={applicationsQuery.data?.map(x => x.id)}
          label="Integrator Application Id"
          options={applicationsOption}
          withNoEmptyOption={true}
        />
        <Heading labelPaddings>Integrator Application Id</Heading>
        <Stack tokens={{ childrenGap: 8 }} verticalAlign="space-between">
          <StackItem>
            <FormSwitch
              name="hasAllLocationAccess"
              label="All Location Access"
              styles={{
                root: { margin: 0, flexGrow: 1 },
                container: { marginTop: 4 }
              }}
            />
            <FormSwitch
              name="isEnabled"
              label="Enabled"
              styles={{
                root: { margin: 0, flexGrow: 1 },
                container: { marginTop: 4 }
              }}
            />
          </StackItem>
        </Stack>
      </Form>
    </Dialog>
  );
};
