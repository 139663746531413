import React, { useMemo } from "react";

import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

interface ServiceStartupProps {
  startup?: string;
}

export const ServiceStartup: React.FC<ServiceStartupProps> = ({ startup }) => {
  const badgeType = useMemo((): TextBadgeColor => {
    let type = TextBadgeColor.grey;
    switch (startup) {
      case undefined:
        type = TextBadgeColor.red;
        break;
      case "Disabled (Delayed Start)":
        type = TextBadgeColor.yellow;
        break;
      case "Auto (Delayed Start)":
        type = TextBadgeColor.green;
        break;
    }
    return type;
  }, [startup]);

  return (
    <TextBadge badgeSize={TextBadgeSize.small} badgeColor={badgeType}>
      {startup ?? "Unknown"}
    </TextBadge>
  );
};
