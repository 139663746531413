import { SectionTitle } from "@components/SectionTitle";
import { ProvidersTable } from "modules/tenants/sections/best-health/providers/ProvidersTable";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { withPermissions } from "@components/withPermissions";
import { Provider } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";
import { useGetProviders } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}

const BestHealthProvidersBase = ({ tenant }: Props) => {
  const query = useGetProviders(tenant.id);

  return (
    <>
      <SectionTitle>Providers</SectionTitle>
      <QueryStateIndicator<Provider[]> {...query}>
        {data => <ProvidersTable providers={data} />}
      </QueryStateIndicator>
    </>
  );
};

export const BestHealthProviders = withPermissions(
  BestHealthProvidersBase,
  [
    Permissions.BhbPracticeRead,
    Permissions.BhbPracticeWrite,
    Permissions.OpsConsolePreRelease
  ],
  "or"
);
