import { TabsNavLinkProps } from "@components/tabs-nav/TabNav.types";
import React from "react";

import { noWrap, Stack, Text } from "@bps/fluent-ui";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export const FieldTabLink: React.FC<TabsNavLinkProps<FieldDevice>> = ({
  details
}) => {
  return (
    <Stack
      styles={{ root: { lineHeight: "1rem", textAlign: "left", ...noWrap } }}
    >
      <Text variant="small" bold nowrap>
        {details.name}
      </Text>
      <Text variant="xSmall" nowrap>
        {details.id}
      </Text>
    </Stack>
  );
};
