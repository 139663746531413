import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Stack, useTheme } from "@bps/fluent-ui";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useCreateSoftwarePackageDefault,
  useUpdateSoftwarePackageDefault
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SoftwarePackageDefaultSummaryDto } from "./";
import { SoftwarePackageDefaultFormFields } from "./SoftwarePackageDefaultFormFields";

interface SoftwarePackageDefaultFormValues {
  softwarePackageVersionId: string;
}

interface SoftwarePackageDefaultFormProps {
  softwarePackage: SoftwarePackageDefaultSummaryDto;
  softwarePackageVersions: SoftwarePackageVersion[];
}

export const SoftwarePackageDefaultForm: React.FC<SoftwarePackageDefaultFormProps> = ({
  softwarePackage,
  softwarePackageVersions
}) => {
  const theme = useTheme();

  const firstAvailableVersion = softwarePackageVersions[0].id;

  const form = useForm<SoftwarePackageDefaultFormValues>({
    defaultValues: {
      softwarePackageVersionId: firstAvailableVersion
    }
  });

  const {
    mutateAsync: updateSoftwarePackageDefault
  } = useUpdateSoftwarePackageDefault();

  const {
    mutateAsync: createSoftwarePackageDefault
  } = useCreateSoftwarePackageDefault();

  const onSubmit = async (formValues: SoftwarePackageDefaultFormValues) => {
    const { softwarePackageVersionId } = formValues;
    const { id, softwarePackageId, eTag, changeLog } = softwarePackage;
    const request = {
      id,
      softwarePackageId,
      softwarePackageVersionId:
        softwarePackageVersionId ?? softwarePackage.softwarePackageVersionId,
      changeLog,
      eTag
    };

    if (softwarePackage.id === undefined) {
      // Create a software package default for the service
      try {
        await createSoftwarePackageDefault(request);
      } catch (e) {
        return e;
      }
    } else {
      // Update a software package default for the service
      try {
        await updateSoftwarePackageDefault(request);
      } catch (e) {
        return e;
      }
    }
  };

  return (
    <FormProvider {...form}>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: theme.spacing.m }}
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <SoftwarePackageDefaultFormFields
          item={softwarePackage}
          softwarePackageVersions={softwarePackageVersions}
        />
      </Stack>
    </FormProvider>
  );
};
