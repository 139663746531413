import { EntitySequenceStateTable } from "modules/tenants/sections/pros/data-sync/entity-sequence-state/EntitySequenceStateTable";

import { Heading, Stack } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useProsEntitySyncStateQuery } from "@libs/api/gateways/tg/tg-ops-gateway.hooks";

interface EntitySequenceStateProps {
  tenant: Tenant;
}

export const EntitySequenceState = ({ tenant }: EntitySequenceStateProps) => {
  const query = useProsEntitySyncStateQuery(tenant.id);

  return (
    <Stack>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <Heading variant="heading">Entity Sequence State</Heading>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
        <QueryStateIndicator
          {...query}
          loadingTitle="Loading entity sequence state"
        >
          {data => (
            <EntitySequenceStateTable entitySequences={data.entitySequences} />
          )}
        </QueryStateIndicator>
      </Stack>
    </Stack>
  );
};
