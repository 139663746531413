import { SectionTitle } from "@components/SectionTitle";
import { SecurableComponent } from "@components/SecurableComponent";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FunctionComponent, useState } from "react";

import { CompoundButton, FontIcon, Stack, useTheme } from "@bps/fluent-ui";

import { commandButton } from "../styles";
import { FormsOptionsModal } from "./FormsOptionsModal";
import { usePostTtmFormsRefresh } from "@libs/api/gateways/ttm/forms/ttm-forms-ops-gateway.hooks";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { TtmFormsRefreshOptions } from "@libs/api/gateways/ttm/forms/ttm-forms-ops-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface Props {
  tenant: Tenant;
}

export const TtmForms: FunctionComponent<Props> = ({ tenant }) => {
  const postRefresh = usePostTtmFormsRefresh(tenant.id);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.TiFormsAllowed
  );

  const [showFormsRefresh, setShowFormsRefresh] = useState<{
    callback: (options: TtmFormsRefreshOptions) => void;
  }>();

  const theme = useTheme();
  const onRefreshClick = (options: TtmFormsRefreshOptions) => {
    postRefresh.mutate(options);
  };

  return (
    <SecurableComponent permissions={[Permissions.TiSettingsRead]}>
      <Stack>
        <SectionTitle>Forms</SectionTitle>
        <Stack horizontal styles={{ root: { paddingTop: theme.spacing.m } }}>
          <CompoundButton
            disabled={postRefresh.isLoading || !hasWritePermission}
            secondaryText="Refresh form designs"
            styles={commandButton}
            onClick={() => setShowFormsRefresh({ callback: onRefreshClick })}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            <FontIcon iconName="Camera" styles={{ root: { fontSize: 40 } }} />
          </CompoundButton>
        </Stack>
      </Stack>
      {showFormsRefresh && (
        <FormsOptionsModal
          onDismiss={() => setShowFormsRefresh(undefined)}
          onSubmit={values => {
            showFormsRefresh.callback(values);
            setShowFormsRefresh(undefined);
            return Promise.resolve();
          }}
        />
      )}
    </SecurableComponent>
  );
};
