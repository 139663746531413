import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { FunctionComponent } from "react";

import { IColumn } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  AppointmentReminderJob,
  CalendarEventReminderSearchArgs
} from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.dtos";
import { useAppointmentReminderSearch } from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.hooks";

interface Props {
  tenant: Tenant;
  filters: CalendarEventReminderSearchArgs;
}

export const AppointmentRemindersTable: FunctionComponent<Props> = ({
  tenant,
  filters
}) => {
  const remindersQuery = useAppointmentReminderSearch(tenant.id, filters);

  const columns: IColumn[] = [
    {
      key: "appointmentReminderJobId",
      name: "Reminder id",
      onRender: (item: AppointmentReminderJob) => item.id,
      minWidth: 300,
      maxWidth: 350
    }
  ];
  return (
    <QueryStateIndicator {...remindersQuery}>
      {data => {
        return <ShimmeredDetailsList items={data} columns={columns} />;
      }}
    </QueryStateIndicator>
  );
};
