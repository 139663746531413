import { FunctionComponent } from "react";
import { FilterBarProps, Item } from "@components/filter-bar/FilterBar.types";
import { FilterBar } from "@components/filter-bar/FilterBar";

interface RedirectsFilterProps
  extends Pick<FilterBarProps<RedirectsFilterValues>, "children"> {}
interface RedirectsFilterValues {
  inviteCode?: string;
}
export const RedirectsFilter: FunctionComponent<RedirectsFilterProps> = props => {
  const items: Item<RedirectsFilterValues>[] = [
    {
      name: "inviteCode",
      type: "searchBox",
      stickItem: true,
      props: { placeholder: "Search by invite code" }
    }
  ];
  return <FilterBar {...props} items={items} />;
};
