import { ScrollListener } from "@components/tables/ScrollListener";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";

import {
  IColumn,
  ScrollablePane,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  TooltipHost
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";

import { OutboundSmsAudit } from "@libs/api/gateways/plt-comms/models/OutboundSmsAudit";
import { useOutboundSmsAuditQuery } from "@libs/api/gateways/plt-comms/plt-comms-gateway.hooks";
import {
  MessageMediaDeliveryReportErrorCodeMap,
  OutboundSmsAuditArgs,
  OutboundSmsAuditType
} from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";

export const OutboundSmsAuditTable: React.FC<{
  filter: OutboundSmsAuditArgs;
}> = ({ filter }) => {
  const {
    data: auditData,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useOutboundSmsAuditQuery({
    phone: filter.phone,
    tenantId: filter.tenantId,
    fromDate: filter.fromDate,
    toDate: filter.toDate
  });

  const handleScrolledToBottom = async () => {
    if (hasNextPage) {
      return void (await fetchNextPage());
    }
  };

  const auditFlattened = auditData?.pages.reduce(
    (acc: OutboundSmsAudit[], curVal) => {
      return curVal.results !== null && curVal.results !== undefined
        ? acc.concat(...curVal.results)
        : [];
    },
    []
  );

  const columns: IColumn[] = [
    {
      key: "outboundSmsId",
      fieldName: "outboundSmsId",
      name: "Outbound SMS Id",
      minWidth: 300,
      maxWidth: 300
    },
    {
      key: "outboundSmsAuditType",
      fieldName: "outboundSmsAuditType",
      name: "Type",
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: "vendorCode",
      fieldName: "vendorCode",
      name: "Vendor",
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: "deliveryStatus",
      onRender: (item: OutboundSmsAudit) => {
        if (item.content.request.body) {
          const content = JSON.parse(item.content.request.body);
          const deliveryStatus = content["status"];
          return deliveryStatus ?? "";
        }
      },
      name: "Delivery status",
      minWidth: 50,
      maxWidth: 150
    },
    {
      key: "errorCode",
      onRender: (item: OutboundSmsAudit) => {
        if (item.content.request.body) {
          const content = JSON.parse(item.content.request.body);
          const errorCode = content["error_code"];
          return (
            <TooltipHost
              content={MessageMediaDeliveryReportErrorCodeMap[errorCode] ?? ""}
            >
              {errorCode}
            </TooltipHost>
          );
        }
      },
      name: "Delivery status code",
      minWidth: 50,
      maxWidth: 150
    },
    {
      key: "statusCode",
      onRender: (item: OutboundSmsAudit) => {
        if (item.outboundSmsAuditType === OutboundSmsAuditType.SmsRequest) {
          return item.content.response.statusCode;
        }
      },
      name: "Request status code",
      minWidth: 50,
      maxWidth: 150
    },
    {
      key: "createdDate",
      onRender: (item: OutboundSmsAudit) => {
        return item.createdDate?.toFormat(DATE_FORMATS.DETAILED_DATE_TIME);
      },
      name: "Created",
      minWidth: 150,
      maxWidth: 150
    }
  ];

  return (
    <Stack
      styles={{
        root: {
          position: "relative",
          height: "100%"
        }
      }}
    >
      <ScrollablePane>
        <TextBadge
          badgeColor={TextBadgeColor.green}
          badgeSize={TextBadgeSize.small}
          styles={{
            root: { width: "fit-content", margin: 4, marginLeft: "auto" }
          }}
        >
          {auditData?.pages[0].total ?? 0} records found
        </TextBadge>
        <ShimmeredDetailsList
          enableShimmer={isLoading}
          errorMessage={error?.message}
          isSelectedOnFocus
          columns={columns}
          items={auditFlattened ?? []}
        />

        <ScrollListener
          hasNextPage={hasNextPage!}
          onScrolledToBottom={handleScrolledToBottom}
        />
      </ScrollablePane>
    </Stack>
  );
};
