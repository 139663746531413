import { useCommandRunner } from "../../../../../tenants/sections/_shared-models/CommandRunner";
import { useRootStore } from "@stores/StoresProvider";

import { DefaultButton, IButtonProps } from "@bps/fluent-ui";
import { useModifyDatabaseMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { ProsDatabaseCommands } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { DeploymentAction } from "@stores/deployment-store/deployment-store.types";

interface ProsDatabaseControlsProps extends IButtonProps {}

export const ProsDatabaseControls = ({
  ...props
}: ProsDatabaseControlsProps) => {
  const commandRunner = useCommandRunner();
  const modifyDatabaseMutation = useModifyDatabaseMutation();
  const { deployment } = useRootStore();

  const installUpdateDatabaseCommand = async () => {
    const promises = deployment.selectedTenants.map(tenant =>
      deployment.addDeployment(
        DeploymentAction.InstallUpdateProsDb,
        modifyDatabaseMutation,
        {
          siteId: tenant.id,
          command: ProsDatabaseCommands.InstallUpdate
        }
      )
    );

    await Promise.all(promises);
  };

  const enableDatabaseCommand = async () => {
    const promises = deployment.selectedTenants.map(tenant =>
      deployment.addDeployment(
        DeploymentAction.EnableProsDb,
        modifyDatabaseMutation,
        {
          siteId: tenant.id,
          command: ProsDatabaseCommands.Enable
        }
      )
    );

    await Promise.all(promises);
  };

  const isBusy =
    !deployment.selectedTenants.length ||
    deployment.isDeploying ||
    commandRunner.isBusy;

  return (
    <>
      <DefaultButton
        onClick={installUpdateDatabaseCommand}
        disabled={isBusy}
        {...props}
      >
        Install/Update PrOS database
      </DefaultButton>
      <DefaultButton
        onClick={enableDatabaseCommand}
        disabled={isBusy}
        {...props}
      >
        Enable PrOS database
      </DefaultButton>
    </>
  );
};
