import React, { useMemo } from "react";

import { NoDataTile, Stack, useTheme } from "@bps/fluent-ui";
import {
  CommandResponse,
  CustomRJSFSchema
} from "@libs/api/gateways/field/field-ops-gateway.dtos";

import { CommandResult } from "./commands/CommandResult";
import { CommandRoutes } from "./commands/CommandRoutes";
import { SoftwarePackageCommandTabs } from "./SoftwarePackageCommandTabs";

interface SoftwarePackageCommandsBodyProps {
  commandJson: CustomRJSFSchema[] | undefined;
  commandArg: string | undefined;
  response: CommandResponse | undefined;
  handleCommandChange: (command: string) => void;
  handleSubmit: ({ formData }: any) => Promise<void>;
}

export const SoftwarePackageCommandsBody: React.FC<SoftwarePackageCommandsBodyProps> = ({
  commandJson,
  commandArg,
  response,
  handleCommandChange,
  handleSubmit
}) => {
  const theme = useTheme();
  const commandTabs = useMemo(
    () =>
      commandJson &&
      commandJson?.length > 0 &&
      commandJson?.map(command => {
        return { id: command.title!, name: command.title! };
      }),
    [commandJson]
  );

  if (commandJson?.length === 0) {
    return (
      <Stack
        styles={{
          root: {
            flex: 1,
            borderColor: theme.semanticColors.variantBorder,
            borderWidth: 1,
            borderStyle: "solid",
            padding: theme.spacing.s1
          }
        }}
      >
        No command file has been uploaded.
      </Stack>
    );
  }

  if (!commandTabs) {
    return (
      <NoDataTile
        textProps={{ text: "No commands found" }}
        linkProps={{ hidden: true }}
        showBoxShadow={false}
        greyView={true}
      />
    );
  }

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      tokens={{ childrenGap: theme.spacing.s1 }}
    >
      <SoftwarePackageCommandTabs
        commandTabs={commandTabs}
        onSelectedTabChanged={handleCommandChange}
        selectedTab={commandArg}
      />

      <CommandRoutes schema={commandJson ?? []} onSubmit={handleSubmit} />

      <CommandResult response={response} />
    </Stack>
  );
};
