import { SiteComponentVersionStatusProps } from "modules/tenants/sections/pros/dashboard/site-deployment/SiteComponentVersionStatus";
import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";

export const SiteComponentVersionBadge = ({
  siteVersion,
  siteComponent
}: Omit<SiteComponentVersionStatusProps, "siteId">) => {
  if (!(siteVersion?.version && siteComponent)) return null;

  const versionIndex = siteComponent.versions.findIndex(
    availableVersion => availableVersion.text === siteVersion.version
  );

  // The versions are ordered from newest => oldest, so the difference between the index
  // where we found the current version and the number of versions in the list represents
  // the number of versions that we are behind.
  const isCurrent = versionIndex === 0;

  const getBadgeText = () => {
    if (isCurrent) return "Current";
    return versionIndex < 0 ? "Unknown" : `${0 + versionIndex} behind`;
  };

  const badgeType = isCurrent ? TextBadgeColor.green : TextBadgeColor.red;

  return (
    <TextBadge badgeColor={badgeType} badgeSize={TextBadgeSize.small}>
      {getBadgeText()}
    </TextBadge>
  );
};
