import { LicenceTypeDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export type LicenceCode = string | null | undefined;

export interface AddLicenceTypeDialogProps {
  setCode: (code: LicenceCode) => void;
  code: LicenceCode;
}

export interface AddLicenceTypeFormProps
  extends Pick<AddLicenceTypeDialogProps, "code"> {
  onDismiss: () => void;
}

export interface AddLicenceTypeFormValues
  extends Omit<LicenceTypeDto, "isInactive"> {
  isActive: boolean;
}

export const ADD_TYPE_TITLE = "Add licence type";
export const EDIT_TYPE_TITLE = "Edit licence type";
