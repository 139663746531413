import React from "react";

import { Stack, Text, useTheme } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import { FieldDeviceStatus } from "./FieldDeviceStatus";
import { InstallStatus } from "./InstallStatus";
import { ServiceStartup } from "./ServiceStartup";

interface ServiceStatusProps {
  status?: string;
  runStatusSid?: string;
  version?: string;
  startup?: string;
  startTime?: DateTime;
  observedTime?: DateTime;
}

export const ServiceStatus: React.FC<ServiceStatusProps> = ({
  status,
  runStatusSid,
  version,
  startup,
  startTime,
  observedTime
}) => {
  const theme = useTheme();
  const versionLabel = version ? `v${version}` : "Unknown";

  return (
    <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
      <FieldDeviceStatus status={runStatusSid} />
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: theme.spacing.s2 }}
      >
        <Text variant="small" bold>
          {versionLabel}
        </Text>
        <InstallStatus status={status} />
        <ServiceStartup startup={startup} />
      </Stack>
      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: theme.spacing.s2 }}
      >
        <Text variant="small">Started:</Text>
        <Text variant="small">
          {startTime?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)}
        </Text>
      </Stack>

      <Stack
        horizontal
        horizontalAlign="space-between"
        tokens={{ childrenGap: theme.spacing.s2 }}
      >
        <Text variant="small">Observed:</Text>
        <Text variant="small">
          {observedTime?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT)}
        </Text>
      </Stack>
    </Stack>
  );
};
