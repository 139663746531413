import { useRootStore } from "@stores/StoresProvider";
import React from "react";

import { DefaultButton, IButtonProps } from "@bps/fluent-ui";
import { useComponentStateUpdateMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  SiteComponentState,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { DeploymentAction } from "@stores/deployment-store/deployment-store.types";

interface SiteComponentStateButtonProps extends IButtonProps {
  componentType?: SiteComponentType;
  deploymentAction:
    | DeploymentAction.AgentInstallDesiredVersion
    | DeploymentAction.AgentDownloadDesiredVersion
    | DeploymentAction.AgentUpdateDesiredVersion;
}

export const SiteAgentStateButton: React.FC<SiteComponentStateButtonProps> = ({
  componentType,
  deploymentAction,
  children,
  ...props
}) => {
  const { deployment } = useRootStore();
  const mutation = useComponentStateUpdateMutation();

  const onClick = async () => {
    let state: SiteComponentState;
    switch (deploymentAction) {
      case DeploymentAction.AgentInstallDesiredVersion:
        state = SiteComponentState.Install;
        break;
      case DeploymentAction.AgentDownloadDesiredVersion:
        state = SiteComponentState.Download;
        break;
      case DeploymentAction.AgentUpdateDesiredVersion:
        state = SiteComponentState.Update;
        break;
    }
    if (!state || !componentType || !deployment.selectedTenants.length) {
      return;
    }
    deployment.clearDeployments();

    const promises = deployment.selectedTenants.map(tenant =>
      deployment.addDeployment(deploymentAction, mutation, {
        siteId: tenant.id,
        componentType,
        state
      })
    );
    await Promise.all(promises);
  };

  let text: string;
  switch (deploymentAction) {
    case DeploymentAction.AgentInstallDesiredVersion:
      text = "Install Desired Version";
      break;
    case DeploymentAction.AgentDownloadDesiredVersion:
      text = "Download Desired Version";
      break;
    case DeploymentAction.AgentUpdateDesiredVersion:
      text = "Update Desired Version";
      break;
  }

  return (
    <DefaultButton
      onClick={onClick}
      disabled={
        !deployment.selectedTenants.length ||
        !componentType ||
        deployment.isDeploying
      }
      {...props}
    >
      {children || text}
    </DefaultButton>
  );
};
