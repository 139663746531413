import { Dialog, IDialogProps } from "@bps/fluent-ui";

import { AuthActionForm, AuthActionFormValues } from "./AuthActionForm";
import { useUpdateFieldDeviceAuthActions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { FieldDeviceAuthAction } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface EditAuthActionDialogProps extends IDialogProps {
  authAction: FieldDeviceAuthAction;
  onDismiss: () => void;
}

export const EditAuthActionDialog: React.FC<EditAuthActionDialogProps> = ({
  authAction,
  onDismiss,
  ...props
}) => {
  const { mutateAsync: updateAuthAction } = useUpdateFieldDeviceAuthActions();

  const onSubmit = async (formData: AuthActionFormValues) => {
    await updateAuthAction({
      ...authAction,
      deviceAction: formData.deviceAction,
      deviceIdentifierType: formData.deviceIdentifierType,
      deviceIdentifierValue: formData.deviceIdentifierValue
    });

    onDismiss();
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: "Edit Action"
      }}
      minWidth={550}
      onDismiss={onDismiss}
      {...props}
    >
      <AuthActionForm
        fieldDeviceId={authAction.fieldDeviceId}
        authAction={authAction}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};
