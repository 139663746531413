import { Form } from "@components/form/Form";
import { FormDropdown } from "@components/form/fields/FormDropdown";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FunctionComponent } from "react";

import { Dialog, Heading, Option, Stack, StackItem } from "@bps/fluent-ui";
import {
  ApplicationDto,
  ConsentDto
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { useUpdateConsent } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface UpdateConsentDialogProps {
  tenant: Tenant;
  applications: ApplicationDto[];
  consents: ConsentDto[];
  consent: ConsentDto;
  onDismiss: () => void;
}

export interface UpdateConsentFormValues {
  applicationId: string;
  hasAllLocationAccess: boolean;
  enabled: boolean;
  tenantConsentId: string;
}

export const UpdateConsentDialog: FunctionComponent<UpdateConsentDialogProps> = ({
  tenant,
  applications,
  consents,
  consent,
  onDismiss
}) => {
  const {
    mutateAsync: updateConsent,
    error: updateConsentError
  } = useUpdateConsent();

  const onSubmit = async (formData: UpdateConsentFormValues) => {
    await updateConsent({
      applicationId: formData.applicationId,
      tenantId: tenant.id,
      hasAllLocationAccess: formData.hasAllLocationAccess,
      isEnabled: formData.enabled,
      tenantConsentId: consent.id
    });
    onDismiss();
  };

  const defaultValues = {
    hasAllLocationAccess: consent.hasAllLocationAccess,
    enabled: consent.isEnabled,
    applicationId: consent.applicationId,
    tenantConsentId: tenant.id
  };

  const applicationArray = consents
    ? consents.map(consent => {
        const applicationResult = applications.find(
          application => application.id === consent.applicationId
        );

        return {
          applicationId: consent.applicationId,
          applicationName: applicationResult?.name || ""
        };
      })
    : [];

  const applicationsOption: Option[] = !applications
    ? []
    : [
        {
          key: "",
          text: "None"
        }
      ].concat(
        applicationArray.map(t => {
          return { key: t.applicationId, text: t.applicationName };
        }) ?? []
      );

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Configure Consent",
        showCloseButton: true,
        onDismiss
      }}
      minWidth={450}
    >
      <Form<UpdateConsentFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        error={updateConsentError}
      >
        <FormDropdown
          name="applicationId"
          placeholder="Select application id"
          defaultSelectedKey={applications.map(x => x.id)}
          label="Integrator Application Id"
          options={applicationsOption}
          withNoEmptyOption={true}
        />
        <Heading labelPaddings>Integrator Application Id</Heading>

        <Stack tokens={{ childrenGap: 8 }} verticalAlign="space-between">
          <StackItem>
            <FormSwitch
              name="hasAllLocationAccess"
              label="All Location Access"
              styles={{
                root: { margin: 0, flexGrow: 1 },
                container: { marginTop: 4 }
              }}
            />
            <FormSwitch
              name="enabled"
              label="Enabled"
              styles={{
                root: { margin: 0, flexGrow: 1 },
                container: { marginTop: 4 }
              }}
            />
          </StackItem>
        </Stack>
      </Form>
    </Dialog>
  );
};
