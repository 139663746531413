import { dangerButtonStyles } from "@components/buttons/DangerButton";

import { ConfirmDialog, ConfirmDialogProps, useTheme } from "@bps/fluent-ui";
import { useDeleteRollout } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface DeleteRolloutDialogProps
  extends Omit<ConfirmDialogProps, "onConfirm"> {
  rollout: Rollout;
  setShowDelete: (setHidden: boolean) => void;
}

export const DeleteRolloutDialog: React.FC<DeleteRolloutDialogProps> = ({
  rollout,
  setShowDelete,
  ...props
}) => {
  const theme = useTheme();
  const { mutateAsync: deleteRollout } = useDeleteRollout(
    rollout.deploymentRingId
  );

  const onConfirm = () => {
    deleteRollout(rollout.id!);
    setShowDelete(false);
  };

  return (
    <ConfirmDialog
      confirmButtonProps={{ text: "Delete", styles: dangerButtonStyles(theme) }}
      cancelButtonProps={{ text: "No" }}
      onConfirm={onConfirm}
      dialogContentProps={{
        title: "Delete Rollout"
      }}
      {...props}
    >
      {rollout.displayName ? (
        <>Are you sure you want to delete rollout "{rollout.displayName}"?</>
      ) : (
        <>Are you sure you want to delete this rollout?</>
      )}
    </ConfirmDialog>
  );
};
