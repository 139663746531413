import React, { FunctionComponent } from "react";
import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { Shimmer } from "@bps/fluent-ui";
import { ErrorAlert } from "@components/errors/ErrorAlert";
import { ApplicationBadge } from "../../components/ApplicationBadge";

type PltTenantApplicationBadgeProps =
  | {
      tenantId: string;
      applicationCode?: never;
    }
  | { tenantId?: never; applicationCode: ApplicationType };

/**
 * This component renders TextBadge. It uses applicationCode if passed implicit or picked from TenantDto fetched by tenantId.
 * @param tenantId
 * @param applicationCode
 * @constructor
 */
export const PltTenantApplicationBadge: FunctionComponent<PltTenantApplicationBadgeProps> = ({
  tenantId,
  applicationCode
}) => {
  const {
    isLoading: isLoadingUserTenant,
    data: userTenant,
    error: errorUsersTenant
  } = useTenantQuery(tenantId!, { enabled: !!tenantId });

  if (isLoadingUserTenant) {
    return <Shimmer />;
  }
  if (errorUsersTenant) {
    return <ErrorAlert error={errorUsersTenant} />;
  }

  return <ApplicationBadge code={applicationCode ?? userTenant?.application} />;
};
