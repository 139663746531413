import {
  Dialog,
  Heading,
  IDialogProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { convertToDateTime } from "../utils";
import { RolloutForm, RolloutFormValues } from "./RolloutForm";
import { useCreateRollout } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface DuplicateRolloutDialogProps extends IDialogProps {
  onDismiss: () => void;
  rollout: Rollout;
}

export const DuplicateRolloutDialog: React.FC<DuplicateRolloutDialogProps> = ({
  onDismiss,
  rollout,
  ...props
}) => {
  const { mutateAsync: createRollout } = useCreateRollout();
  const theme = useTheme();

  const onSubmit = async (formData: RolloutFormValues) => {
    const {
      displayName,
      isActive,
      initialDownloadTimeStartUtc,
      initialDownloadTimeEndUtc,
      initialInstallTimeStartUtc,
      initialInstallTimeEndUtc,
      initialDownloadDateStartUtc,
      initialDownloadDateEndUtc,
      initialInstallDateStartUtc,
      initialInstallDateEndUtc
    } = formData;

    const downloadStartUtc = convertToDateTime(
      initialDownloadDateStartUtc!,
      initialDownloadTimeStartUtc!
    );

    const downloadEndUtc = convertToDateTime(
      initialDownloadDateEndUtc!,
      initialDownloadTimeEndUtc!
    );

    const installStartUtc = convertToDateTime(
      initialInstallDateStartUtc!,
      initialInstallTimeStartUtc!
    );

    const installEndUtc = convertToDateTime(
      initialInstallDateEndUtc!,
      initialInstallTimeEndUtc!
    );

    await createRollout({
      displayName,
      isActive: isActive!,
      deploymentRingId: rollout.deploymentRingId,
      initialDownloadStartUtc: downloadStartUtc.toISO(),
      initialDownloadEndUtc: downloadEndUtc.toISO(),
      initialInstallStartUtc: installStartUtc.toISO(),
      initialInstallEndUtc: installEndUtc.toISO(),
      rolloutId: rollout.id // Duplicating rollout packages and desired config from this rolloutId
    });

    onDismiss();
  };

  const newRollout: Rollout = {
    deploymentRingId: rollout.deploymentRingId,
    isActive: false
  };

  return (
    <Dialog
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Stack>
            <Heading variant="modal-heading">Duplicate Rollout</Heading>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <Text variant="small" bold>
                Duplicating:
              </Text>
              <Text variant="small"> {rollout.displayName}</Text>
            </Stack>
            <Text variant="small">({rollout.id})</Text>
          </Stack>
        )
      }}
      onDismiss={onDismiss}
      {...props}
    >
      <RolloutForm rollout={newRollout} onSubmit={onSubmit} />
    </Dialog>
  );
};
