import { FunctionComponent, useMemo } from "react";
import { Dialog } from "@bps/fluent-ui";
import { isGUID } from "@bps/utils";
import { Form } from "@components/form/Form";
import { FormOptionsSelect } from "@components/form/fields/FormOptionsSelect";
import {
  useAddOrUpdateComponent,
  useComponentsDefs
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import {
  CreateComponentDialogFormValues,
  CreateComponentDialogProps
} from "./CreateComponentDialog.types";
import { FormTextField } from "@components/form/fields/FormTextField";
import { ValidationMessages } from "@libs/validation/validation-messages.enum";

export const CreateComponentDialog: FunctionComponent<CreateComponentDialogProps> = ({
  tenantId,
  onDismiss
}) => {
  const { data = [], isLoading, error } = useComponentsDefs();
  const {
    mutateAsync,
    error: addOrUpdateComponentError
  } = useAddOrUpdateComponent("A new component has nee added!");

  const options = useMemo(
    () => data.map(d => ({ key: d.code, text: d.text })),
    [data]
  );
  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      dialogContentProps={{ title: "Create component", showCloseButton: true }}
    >
      <Form<CreateComponentDialogFormValues>
        defaultValues={{}}
        onSubmit={async values => {
          await mutateAsync({
            tenantId,
            code: values.code!,
            scopeId: values.scopeId
          });
          onDismiss();
        }}
        error={addOrUpdateComponentError}
        onCancel={onDismiss}
        showCancelButton
      >
        <FormOptionsSelect
          name="code"
          loading={isLoading}
          errorMessage={error?.message}
          options={options}
          required
          label="Component code"
          rules={{ required: ValidationMessages.required }}
        />
        <FormTextField
          name="scopeId"
          label="Scope ID"
          required
          rules={{
            required: ValidationMessages.required,
            validate: value =>
              (value && isGUID(value)) || ValidationMessages.guid
          }}
        />
      </Form>
    </Dialog>
  );
};
