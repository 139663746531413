import { useEffect } from "react";

import { IDropdownOption } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { SoftwarePackageVersion } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSoftwarePackageVersions } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { SoftwarePackageVersionDropDown } from "../../../common/SoftwarePackageVersionDropDown";
import { RolloutPackageSoftwarePackageSummaryDto } from "./";
import { RolloutPackageSelectionDto } from "./NewRolloutPackage";

export interface SoftwarePackageVersionDropDownProps {
  softwarePackage: RolloutPackageSoftwarePackageSummaryDto;
  selection?: RolloutPackageSelectionDto;
  onUpdateSoftwarePackageVersion: (
    softwarePackageId: string,
    softwarePackageVersionId: string
  ) => void;
}

export const PackageVersionTableDropDown = ({
  softwarePackage,
  selection,
  onUpdateSoftwarePackageVersion
}: SoftwarePackageVersionDropDownProps) => {
  const softwarePackageVersionsQuery = useSoftwarePackageVersions({
    softwarePackageId: softwarePackage.softwarePackageId
  });

  const { data: softwarePackageVersions } = softwarePackageVersionsQuery;

  // Auto-select first version if we don't have a selection yet
  useEffect(() => {
    if (
      !selection &&
      softwarePackageVersions &&
      softwarePackageVersions.length > 0
    ) {
      onUpdateSoftwarePackageVersion(
        softwarePackage.softwarePackageId,
        softwarePackageVersions[0].id
      );
    }
  }, [
    selection,
    softwarePackageVersions,
    onUpdateSoftwarePackageVersion,
    softwarePackage
  ]);

  const noData = <>No versions available.</>;

  return (
    <QueryStateIndicator<SoftwarePackageVersion[]>
      NoDataNode={noData}
      {...softwarePackageVersionsQuery}
    >
      {versions => {
        const versionOptions =
          versions.map(x => {
            return {
              key: x.id,
              text: x.version as string,
              data: x
            } as IDropdownOption;
          }) ?? [];

        return (
          <SoftwarePackageVersionDropDown
            selectedKey={selection?.softwarePackageVersionId}
            name="softwarePackageVersionId"
            options={versionOptions}
            onChange={(e, option) =>
              onUpdateSoftwarePackageVersion(
                softwarePackage.softwarePackageId,
                String(option?.key)
              )
            }
          />
        );
      }}
    </QueryStateIndicator>
  );
};
