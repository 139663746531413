import { LicenceDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface LicenceFormValues
  extends Omit<
    LicenceDto,
    "expiryDate" | "tenantId" | "id" | "changeLog" | "isInactive" | "source"
  > {
  expiryDate: Date | undefined;
  isActive: boolean;
}

export type LicenceId = string | "new" | undefined;

export interface LicenceFormDialogProps {
  licenceId: LicenceId;
  source?: string;
  tenantId: string;
  closeDialog: () => void;
}

export interface LicenceFormDialogBaseProps
  extends Omit<LicenceFormDialogProps, "licenceId"> {
  licenceId: "new" | string;
  isManagedLicence: boolean;
}

export const newLicenceFormValues: LicenceFormValues = {
  isActive: false,
  expiryDate: undefined,
  licenceTypeCode: undefined,
  userId: undefined,
  doNotRenew: false
};
