import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { PracticeLocationsTable } from "modules/tenants/sections/best-health/locations/PracticeLocationsTable";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { PracticeLocation } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";
import { usePracticeLocationsQuery } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { Permissions } from "@libs/permissions/permissions.enum";

interface Props {
  tenant: Tenant;
}

const BestHealthLocationsBase = ({ tenant }: Props) => {
  const query = usePracticeLocationsQuery(tenant.id);

  return (
    <>
      <SectionTitle>Practice Locations</SectionTitle>
      <QueryStateIndicator<PracticeLocation[]> {...query}>
        {data => <PracticeLocationsTable practiceLocations={data} />}
      </QueryStateIndicator>
    </>
  );
};

export const BestHealthLocations = withPermissions(
  BestHealthLocationsBase,
  [
    Permissions.BhbPracticeManagementWrite,
    Permissions.BhbPracticeManagementRead,
    Permissions.OpsConsolePreRelease
  ],
  "or"
);
