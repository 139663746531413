import { Stack, useTheme } from "@bps/fluent-ui";
import { PermissionsAndRoles } from "modules/users/PltUsers/PermissionsAndRoles";
import React, { FunctionComponent } from "react";

import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface AdditionalUserInformationRowProps {
  user: PltUser;
}
export const AdditionalUserInformationRow: FunctionComponent<AdditionalUserInformationRowProps> = ({
  user
}) => {
  const theme = useTheme();
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 16, padding: 8 }}
      styles={{
        root: {
          border: "1px solid",
          borderColor: theme.palette.neutralQuaternaryAlt,
          borderTop: "none"
        }
      }}
    >
      <PermissionsAndRoles user={user} />
    </Stack>
  );
};
