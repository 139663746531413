import { BhBanner } from "modules/system/sections/bh/SystemBhBanner";
import { BhMobileApp } from "modules/system/sections/bh/SystemBhMobileApp";
import { Route, Routes } from "react-router-dom";

import { BookingStatsTotal } from "./SystemBhBookingStatsTotal";
import { SystemBreadcrumbs } from "../../SystemBreadcrumbs";
import { SystemBhTabs } from "./system-bh-tabs.constants";

export const SystemBhRoutes = () => {
  return (
    <Routes>
      <Route element={<SystemBreadcrumbs tabs={SystemBhTabs} />}>
        <Route
          path={SystemBhTabs.Operations.Banner.id}
          element={<BhBanner />}
        />
        <Route
          path={SystemBhTabs.Operations.MobileApp.id}
          element={<BhMobileApp />}
        />
        <Route
          path={SystemBhTabs.Operations.StatsTotal.id}
          element={<BookingStatsTotal />}
        />
      </Route>
    </Routes>
  );
};
