import { QueryStateIndicator } from "components/QueryStateIndicator";
import { SectionTitle } from "components/SectionTitle";
import { LogEntriesComponent } from "modules/tenants/sections/ops/logging/LogEntriesComponent";

import { Link, Stack } from "@bps/fluent-ui";
import { SIATenantDetails } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";
import { useTenantSeqLogsQuery } from "@libs/api/gateways/user-experience/user-experience.hooks";
import { config } from "@libs/config/config";

interface Props {
  tenant: SIATenantDetails;
}

export const SeqLogs = ({ tenant }: Props) => {
  const query = useTenantSeqLogsQuery(tenant.id, tenant.siteId);
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <SectionTitle>Seq</SectionTitle>
      <div>
        <Link
          href={`${config.seqUrl}?filter=BpTenantId%20%3D%20"${
            tenant.id
          }"%20%7C%7C%20BpSiteId%20%3D%20"${Number(
            tenant.siteId?.replace("BPS-", "")
          )}"`}
          target="_blank"
          rel="noreferrer"
        >
          Click here to open Seq for tenant {tenant.id} ({tenant.name})
        </Link>
      </div>
      <QueryStateIndicator<LogEntry[]> {...query} error={query.error}>
        {data => <LogEntriesComponent logEntries={data} />}
      </QueryStateIndicator>
    </Stack>
  );
};
