import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import React, { FunctionComponent } from "react";

import { Link, ScrollablePane, Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantPaymentMethods } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { stripeAccountBaseUrl } from "@libs/stripe/stripe.utils";

import { AddNewDirectDebitDialog } from "./components/AddNewDirectDebitDialog";
import { AddNewCardDialog } from "./components/AddNewCardDialog";
import { PaymentCard } from "./components/PaymentCard";
import { getSortedMethods } from "./utils";

interface PaymentMethodsProps {
  tenant: Tenant;
}
const PaymentMethodsScreenBase: FunctionComponent<PaymentMethodsProps> = ({
  tenant: { id, stripeCustomerId }
}) => {
  const theme = useTheme();
  const paymentMethodsQuery = useTenantPaymentMethods(id);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Stack>
          <SectionTitle>Payment Methods</SectionTitle>
          <Link
            target="_blank"
            href={`${stripeAccountBaseUrl()}payments?customer=${stripeCustomerId}`}
          >
            Manage customer payments
          </Link>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: theme.spacing.m }}
        >
          <AddNewDirectDebitDialog />
          <AddNewCardDialog />
        </Stack>
      </Stack>

      <QueryStateIndicator {...paymentMethodsQuery}>
        {(methods = []) => (
          <Stack
            tokens={{ childrenGap: 8 }}
            styles={{ root: { position: "relative" } }}
            grow
          >
            <ScrollablePane>
              {getSortedMethods(methods).map(m => (
                <PaymentCard key={m.id} card={m} />
              ))}
            </ScrollablePane>
          </Stack>
        )}
      </QueryStateIndicator>
    </Stack>
  );
};

export const PaymentMethodsScreen = withPermissions(
  PaymentMethodsScreenBase,
  [Permissions.PltSalesRead, Permissions.PltSalesWrite],
  "or"
);
