import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { useState } from "react";

import { NoDataTile, PrimaryButton, Stack, useTheme } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { HierarchyTypes } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import {
  useConsentsQuery,
  useOrgUnitsQuery
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { Consent } from "./Consent";
import { CreateConsentDialog } from "./CreateConsentDialog";

interface IntegConsentsPros {
  tenant: Tenant;
}

const IntegConsentsBase: React.FC<IntegConsentsPros> = ({ tenant }) => {
  const theme = useTheme();
  const consentsQuery = useConsentsQuery(tenant.id);
  const orgUnitsQuery = useOrgUnitsQuery({
    tenantId: tenant.id,
    hierarchyType: HierarchyTypes.Location
  });

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltIntegConsentWrite
  );

  const [showCreateConsentDialog, setshowCreateConsentDialog] = useState<
    boolean
  >(false);

  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <SectionTitle>Consents</SectionTitle>
        <PrimaryButton
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          onClick={() => setshowCreateConsentDialog(true)}
        >
          Create Consent
        </PrimaryButton>
        {showCreateConsentDialog && (
          <CreateConsentDialog
            tenant={tenant}
            consents={consentsQuery.data ?? []}
            onDismiss={() => {
              setshowCreateConsentDialog(false);
            }}
          />
        )}
      </Stack>
      <Stack tokens={{ childrenGap: 16 }}>
        <QueryStateIndicator {...consentsQuery}>
          {consent =>
            consent?.length ? (
              consent?.map(i => (
                <Consent
                  key={i.id}
                  item={i}
                  tenant={tenant}
                  orgUnits={orgUnitsQuery.data ?? []}
                />
              ))
            ) : (
              <NoDataTile
                showBoxShadow={false}
                textProps={{ text: "No consents have been found!" }}
                linkProps={{ hidden: true }}
              />
            )
          }
        </QueryStateIndicator>
      </Stack>
    </Stack>
  );
};

export const IntegConsents = withPermissions(
  IntegConsentsBase,
  [Permissions.PltIntegConsentRead, Permissions.PltIntegConsentWrite],
  "or"
);
