import React from "react";

import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";

const PrOSOpsEventHubsBase: React.FC = () => {
  return <SectionTitle>Event Hubs</SectionTitle>;
};

export const PrOSOpsEventHubs = withPermissions(
  PrOSOpsEventHubsBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
