import { CopyableGuid } from "@components/CopyableText";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { stringify } from "query-string";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import {
  IconButton,
  Link,
  ScrollablePane,
  Stack,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { DATE_FORMATS } from "@bps/utils";

import { ProductColumn } from "../licence-form-dialog/ProductColumn";
import { LicencesFilterValues } from "../licences-filter/LicencesFilter.types";
import { LicenceItem } from "./LicencesList.types";
import { mapToLicenceItems } from "./utils";
import { useLicences } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { UsersTabs } from "../../../../../users/users-tabs.constants";

interface LicencesListProps {
  filter: LicencesFilterValues;
  tenantId: string;
  setLicenceId: (id: string) => void;
}

export const LicencesList: FunctionComponent<LicencesListProps> = ({
  filter,
  tenantId,
  setLicenceId
}) => {
  const navigate = useNavigate();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltLicencingWrite
  );

  const { data, isLoading, error } = useLicences({ ...filter, tenantId });

  const columns: DataTableColumn<LicenceItem>[] = [
    {
      key: "edit",
      name: "",
      onRender: (item: LicenceItem) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={() => setLicenceId(item.id)}
          disabled={!hasWritePermission}
          title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
        />
      ),
      minWidth: 32,
      maxWidth: 32
    },
    {
      key: "licenceId",
      name: "Licence id",
      onRender: (item: LicenceItem) => <CopyableGuid value={item.id} short />,
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: "licenceTypeCode",
      name: "Licence code",
      isSorted: true,
      sort: true,
      onRender: (item: LicenceItem) => item.licenceTypeCode,
      minWidth: 110,
      maxWidth: 110
    },
    {
      key: "username",
      isSorted: true,
      sort: true,
      name: "User name",
      onRender: (item: LicenceItem) => (
        <Link
          onClick={() =>
            navigate({
              pathname: `/users/${UsersTabs.BusinessUsers.UserSearch.id}`,
              search: stringify({ searchTerm: item.username })
            })
          }
        >
          {item.username}
        </Link>
      ),
      minWidth: 150,
      maxWidth: 300
    },
    {
      key: "product",
      isSorted: true,
      sort: true,
      name: "Product",
      onRender: (item: LicenceItem) =>
        item.productId ? <ProductColumn productId={item.productId} /> : "-",
      minWidth: 150,
      maxWidth: 300
    },
    {
      key: "expiry",
      isSorted: true,
      sort: true,
      name: "Expiry",
      onRender: (item: LicenceItem) =>
        item.expiryDate.toFormat(DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT),
      minWidth: 150,
      maxWidth: 250
    },
    {
      key: "autoRenew",
      name: "Auto-renew",
      onRender: (item: LicenceItem) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.doNotRenew ? TextBadgeColor.yellow : TextBadgeColor.green
          }
        >
          {item.doNotRenew ? "No" : "Yes"}
        </TextBadge>
      ),
      minWidth: 80,
      maxWidth: 100
    },
    {
      key: "active",
      name: "Active",
      onRender: (item: LicenceItem) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.isInactive ? TextBadgeColor.red : TextBadgeColor.green
          }
        >
          {item.isInactive ? "Inactive" : "Active"}
        </TextBadge>
      ),
      minWidth: 55,
      maxWidth: 55
    },
    {
      key: "created",
      name: "Created/updated",
      onRender: (item: LicenceItem) =>
        item.updatedDate
          ? item.updatedDate.toFormat(
              DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT
            )
          : null,
      minWidth: 150,
      maxWidth: 250
    },
    {
      key: "status",
      isSorted: true,
      sort: true,
      name: "Status",
      onRender: (item: LicenceItem) => (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={
            item.status === "Invalid"
              ? TextBadgeColor.red
              : TextBadgeColor.green
          }
        >
          {item.status}
        </TextBadge>
      ),
      minWidth: 55,
      maxWidth: 55
    }
  ];

  return (
    <Stack styles={{ root: { position: "relative", height: "100%" } }}>
      <ScrollablePane>
        <DataTable
          stickyHeader
          errorMessage={error?.message}
          enableShimmer={isLoading}
          items={mapToLicenceItems(data)}
          columns={columns}
        />
      </ScrollablePane>
    </Stack>
  );
};
