import { FormFieldProps } from "@components/form/fields/FormFieldProps";
import { FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  FieldItem,
  Option,
  OptionsSelect,
  OptionsSelectProps
} from "@bps/fluent-ui";
import { SalesProductDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useSalesProducts } from "@libs/api/gateways/plt/plt-gateway.hooks";

export type FormLicenceTypesSelectProps = Omit<
  OptionsSelectProps,
  "options" | "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps & { licenceTypeCode: string | undefined };

export const FormProductTypesSelect: FunctionComponent<FormLicenceTypesSelectProps> = ({
  name,
  rules,
  licenceTypeCode,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    isLoading: isLoadingProducts,
    error: errorProducts,
    data: dataProducts = []
  } = useSalesProducts({
    licenceTypeCodes: licenceTypeCode ? [licenceTypeCode] : undefined,
    isInactive: false // Only active products should be returned
  });

  const options: Option<SalesProductDto>[] = dataProducts.map(data => ({
    key: data?.id ?? "",
    text: data.name ?? "",
    data
  }));

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error: validationError }
      }) => (
        <FieldItem name={name} errorMessage={validationError?.message}>
          <OptionsSelect
            options={options}
            selectedKeys={value}
            onChangeSelectedKeys={onChange}
            errorMessage={errorProducts?.message}
            loading={isLoadingProducts}
            {...fieldProps}
            {...rest}
          />
        </FieldItem>
      )}
    />
  );
};
