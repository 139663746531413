import { DangerButton } from "@components/buttons/DangerButton";
import { BpIdUserDto } from "@libs/api/gateways/bp-id/bp-id.dtos";

import { DeleteUserDialog } from "modules/users/components/DeleteUserDialog";
import { UserGroupDialog } from "modules/users/components/UserGroupDialog";
import React, { useState } from "react";

import {
  DefaultButton,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import {
  useDeleteUserMutation,
  useGetUserGroups,
  useUpdateUserGroupsMutation
} from "@libs/api/gateways/bp-id/bp-id.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

interface BusinessUserCardProps {
  identity: BpIdUserDto;
}

export const BusinessUserCard: React.FC<BusinessUserCardProps> = ({
  identity
}) => {
  const [addGroup, setAddGroup] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<boolean>(false);
  const theme = useTheme();
  const deleteMutation = useDeleteUserMutation();
  const userGroups = useGetUserGroups(identity.id);

  const { mutateAsync: updateUser } = useUpdateUserGroupsMutation();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltBpidUserWrite
  );

  const onUpdate = (
    userId: string,
    addGroupIds: string[],
    removeGroupIds: string[]
  ) => {
    updateUser({ userId, addGroupIds, removeGroupIds });
  };

  return (
    <Stack
      styles={{
        root: { padding: theme.spacing.s1 }
      }}
    >
      <UserGroupDialog
        hidden={!addGroup}
        user={identity}
        userGroups={userGroups?.data}
        onUpdate={onUpdate}
        onDismiss={() => setAddGroup(false)}
      />
      <DeleteUserDialog
        hidden={!deleteUser}
        firstname={identity.firstName}
        surname={identity.lastName}
        onDelete={async () =>
          await deleteMutation.mutateAsync({ id: identity.id })
        }
        onCancel={() => setDeleteUser(false)}
      />
      <Stack
        tokens={{
          padding: theme.spacing.s2,
          childrenGap: theme.spacing.s2
        }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Text
            variant="medium"
            styles={{
              root: {
                fontWeight: "bold"
              }
            }}
          >
            Identity Record
          </Text>
        </Stack>
        <Stack
          horizontal
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <Stack
            tokens={{
              childrenGap: theme.spacing.s2
            }}
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            <Text>
              {identity.lastName}, {identity.firstName}
            </Text>
            <Text>{identity.id}</Text>
          </Stack>
          <Stack
            tokens={{
              childrenGap: theme.spacing.s2
            }}
            styles={{
              root: {
                flex: 1
              }
            }}
          >
            <Text>{identity.userPrincipalName}</Text>
          </Stack>
        </Stack>
        <Stack
          styles={{
            root: {
              flex: 1,
              backgroundColor: theme.semanticColors.infoBackground,
              padding: theme.spacing.s1
            }
          }}
        >
          <Text
            variant="medium"
            styles={{
              root: {
                fontWeight: "bold"
              }
            }}
          >
            Groups
          </Text>
          <Stack
            tokens={{
              childrenGap: theme.spacing.s2
            }}
            styles={{
              root: {
                flex: 1,
                backgroundColor: theme.semanticColors.infoBackground
              }
            }}
          >
            <Stack
              horizontal
              disableShrink
              wrap
              tokens={{ childrenGap: theme.spacing.s1 }}
            >
              {!userGroups?.isLoading ? (
                userGroups.data?.map(group => (
                  <TextBadge
                    key={group.displayName}
                    badgeColor={TextBadgeColor.lightGrey}
                    badgeSize={TextBadgeSize.small}
                    styles={{ root: { display: "inline-block" } }}
                  >
                    {group.displayName}
                  </TextBadge>
                ))
              ) : (
                <Text>No Groups Assigned</Text>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
          <DefaultButton
            onClick={() => setAddGroup(true)}
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            Add Group
          </DefaultButton>
          <DangerButton
            onClick={() => setDeleteUser(true)}
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
          >
            Delete user
          </DangerButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
