import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { FunctionComponent } from "react";

import {
  Accordion,
  AccordionItem,
  Grid,
  Stack,
  Text,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useEnvAllRoles } from "@libs/api/gateways/env/env-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

const RolesBase: FunctionComponent = () => {
  const theme = useTheme();
  const allRolesQuery = useEnvAllRoles();
  return (
    <Stack
      verticalFill
      styles={{ root: { padding: theme.spacing.s1 } }}
      tokens={{ childrenGap: theme.spacing.l2 }}
    >
      <SectionTitle>Roles</SectionTitle>
      <QueryStateIndicator {...allRolesQuery}>
        {roles => (
          <Accordion>
            {roles.map(role => {
              const prefixes = role.permissions.reduce(
                (acc, p) =>
                  acc.some(x => x === p.split(".")[0])
                    ? acc
                    : [...acc, p.split(".")[0]],
                []
              );
              return (
                <AccordionItem key={role.code} title={role.code}>
                  {prefixes.map(prefix => (
                    <Tile key={prefix}>
                      <Grid templateColumns="repeat(auto-fill, 300px)">
                        {role.permissions
                          .filter(p => p.split(".")[0] === prefix)
                          .map(p => (
                            <Text key={p}>{p}</Text>
                          ))}
                      </Grid>
                    </Tile>
                  ))}
                </AccordionItem>
              );
            })}
          </Accordion>
        )}
      </QueryStateIndicator>
    </Stack>
  );
};

export const Roles = withPermissions(
  RolesBase,
  [Permissions.EnvSecurityRead, Permissions.EnvSecurityWrite],
  "or"
);
