import { AccordionItem, Stack, Text, useTheme } from "@bps/fluent-ui";

import { toLocalTime } from "../utils";
import { ReportDetailsHeader } from "./ReportDetailsHeader";
import { ReportDetailsTable } from "./ReportDetailsTable";
import { PowerBiDataset } from "@libs/api/gateways/ttm/reporting/ttm-reporting-ops-gateway.dtos";

interface Props {
  dataset: PowerBiDataset;
}

export const ReportDetails = ({ dataset }: Props) => {
  const theme = useTheme();

  const refreshStatus = (
    text: string | undefined,
    status: string | undefined
  ) => {
    const color =
      status === "Failed"
        ? theme.semanticColors.errorText
        : theme.semanticColors.bodyText;

    const fontWeight = status === "Failed" ? "bold" : "inherit";
    return <Text styles={{ root: { color, fontWeight } }}>{text}</Text>;
  };

  const renderRefresh = (dataset: PowerBiDataset) => {
    if (dataset.refreshes.length === 0) {
      return <></>;
    } else {
      return (
        <Text styles={{ root: { minWidth: 350 } }}>
          {refreshStatus(
            `${dataset.refreshes[0].status} - ${toLocalTime(
              dataset.refreshes[0].endTime
            )}`,
            dataset.refreshes[0].status
          )}
        </Text>
      );
    }
  };

  return (
    <AccordionItem
      title={dataset.name}
      key={dataset.name}
      onRenderTitle={() => (
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Text styles={{ root: { minWidth: 250 } }}>{dataset.name}</Text>
          {renderRefresh(dataset)}
        </Stack>
      )}
    >
      <ReportDetailsHeader dataset={dataset} />
      <ReportDetailsTable dataset={dataset} />
    </AccordionItem>
  );
};
