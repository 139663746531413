import { FunctionComponent } from "react";
import { MessageBar, MessageBarType, Shimmer, Text } from "@bps/fluent-ui";
import { useSalesProduct } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface ProductColumnProps {
  productId: string;
}
export const ProductColumn: FunctionComponent<ProductColumnProps> = ({
  productId
}) => {
  const { isLoading, error, data } = useSalesProduct(productId);
  if (isLoading)
    return <Shimmer styles={{ shimmerWrapper: { height: 61 } }} width={50} />;

  if (error)
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        {error.message}
      </MessageBar>
    );

  return <Text>{data?.name}</Text>;
};
