import React from "react";

import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useSelector } from "@libs/api/gateways/field/field-ops-gateway.hooks";

import { DeploymentRingSelectorItem } from "./DeploymentRingSelectorItem";

interface DeploymentRingSelectorProps {
  deploymentRing: DeploymentRing;
}

export const DeploymentRingSelector: React.FC<DeploymentRingSelectorProps> = ({
  deploymentRing
}) => {
  const selectorQuery = useSelector(deploymentRing.selectorId!);

  return (
    <QueryStateIndicator {...selectorQuery}>
      {selector => (
        <DeploymentRingSelectorItem
          selector={selector}
          deploymentRing={deploymentRing}
        />
      )}
    </QueryStateIndicator>
  );
};
