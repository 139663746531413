import React, { FunctionComponent } from "react";
import { TabsNavLinkProps } from "@components/tabs-nav/TabNav.types";
import { ITextProps, noWrap, Stack, Text } from "@bps/fluent-ui";
import { DisplayGuid } from "@components/DisplayGuid";
import { useTenantQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface TenantTabHeaderProps extends Omit<TabsNavLinkProps, "closeTab"> {}
export const TenantTabHeader: FunctionComponent<TenantTabHeaderProps> = ({
  details
}) => {
  const headerTextStyles: ITextProps["styles"] = {
    root: {
      width: "100%",
      lineHeight: "normal",
      ...noWrap
    }
  };

  const { data } = useTenantQuery(details.id, { enabled: !details.name });

  return (
    <Stack
      horizontalAlign="start"
      styles={{ root: { ...noWrap, textAlign: "left" } }}
    >
      <Text variant="large" styles={headerTextStyles}>
        {details.name ?? data?.name}
      </Text>
      <DisplayGuid variant="xSmall" styles={headerTextStyles}>
        {details.id}
      </DisplayGuid>
    </Stack>
  );
};
