import { DateTime } from "@bps/utils";

export const licenceFormValidator = {
  licenceTypeCode: {
    required: {
      message: "Licence type code is required",
      value: true
    }
  },
  product: {
    required: {
      message: "Product is required",
      value: true
    }
  },
  expiry: (isActive: boolean) => ({
    required: {
      message: "Expiry date is required",
      value: true
    },
    validate: (date: Date | undefined) => {
      if (
        isActive &&
        date &&
        DateTime.fromJSDate(date) < DateTime.now().startOf("day")
      ) {
        return "Must be today or later";
      }
      return undefined;
    }
  })
};
