import { PaymentCardActions } from "./PaymentCardActions";
import { PaymentCardImage } from "./PaymentCardImage";
import {
  Grid,
  GridItem,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { FunctionComponent } from "react";
import {
  CardBrandLabels,
  PaymentMethodDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";

interface PaymentCardProps {
  card: PaymentMethodDto;
}
export const PaymentCard: FunctionComponent<PaymentCardProps> = ({ card }) => {
  const theme = useTheme();
  return (
    <Grid
      templateColumns="100px 160px 80px 120px 40px"
      verticalAlign="center"
      childrenTokens={{ columnGap: 40 }}
      className="cam-PaymentCard"
    >
      <PaymentCardImage brand={card.brand} type={card.type} />
      {card.type === "card" ? (
        <Text bold styles={{ root: { textTransform: "capitalize" } }}>
          {CardBrandLabels[card?.brand]} **** {card.last4}
        </Text>
      ) : (
        <Text bold>
          {card.bsbNumber.substring(0, 3)}-{card.bsbNumber.substring(3, 6)} ****{" "}
          {card.last4}
        </Text>
      )}
      {card.isDefault ? (
        <TextBadge
          badgeSize={TextBadgeSize.small}
          badgeColor={TextBadgeColor.green}
        >
          Default
        </TextBadge>
      ) : (
        <GridItem />
      )}
      {card.type === "card" ? (
        <Text styles={{ root: { color: theme.palette.neutralSecondaryAlt } }}>
          Expires {card.expMonth}/{card.expYear}
        </Text>
      ) : (
        <GridItem />
      )}
      <PaymentCardActions card={card} />
    </Grid>
  );
};
