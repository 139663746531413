import { withPermissions } from "@components/withPermissions";
import { TabsNav } from "@components/tabs-nav/TabsNav";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FunctionComponent } from "react";
import { generatePath, useParams } from "react-router-dom";

import { FieldDeviceSectionsHost } from "../sections";
import { FieldMgmtSearch } from "./FieldMgmtSearch";
import { FieldTabLink } from "./FieldTabLink";

import { FieldDevice } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { FieldDeviceTabs } from "../field-device-tabs.constants";

const TAB_KEY = "field-mgmt";

const FieldMgmtTabsBase: FunctionComponent = () => {
  const { deviceId } = useParams();

  return (
    <TabsNav<FieldDevice>
      tabKey={TAB_KEY}
      selectedTab={deviceId}
      defaultPath="/field-mgmt"
      navigatePath={details => {
        return generatePath("/field-mgmt/:deviceId/:moduleCode", {
          deviceId: details.id,
          moduleCode: FieldDeviceTabs.Device.Identifiers.id
        });
      }}
      renderTab={({ id }) => <FieldDeviceSectionsHost id={id} />}
      renderDefault={() => <FieldMgmtSearch />}
      renderLink={props => <FieldTabLink {...props} />}
    />
  );
};

export const FieldMgmtTabs = withPermissions(
  FieldMgmtTabsBase,
  [Permissions.PltFieldManagementRead, Permissions.PltFieldManagementWrite],
  "or"
);
