import { SiteSyncState } from "@libs/api/gateways/sia/models/SiteSyncState";
import { DateTime } from "@bps/utils";
import { SequenceNumberSyncState } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

export function getSequenceNumberSyncStates(state: SiteSyncState) {
  const snSyncStates = new Map<number, SequenceNumberSyncState>();

  if (state.lastSequenceNumberSent) {
    const sentAt = DateTime.fromISO(state.lastSequenceNumberSentAtUtc!);
    const receivedDelayTime =
      state.lastSequenceNumberSent === state.lastSequenceNumberReceived
        ? DateTime.fromISO(state.lastSequenceNumberReceivedAtUtc!)
        : DateTime.now();

    snSyncStates.set(state.lastSequenceNumberSent, {
      sequenceNumber: state.lastSequenceNumberSent,
      sentAt,
      status: "Queued",
      receiveDelayMillis: receivedDelayTime.valueOf() - sentAt.valueOf()
    });
  }

  if (state.lastSequenceNumberReceived) {
    if (!snSyncStates.has(state.lastSequenceNumberReceived)) {
      snSyncStates.set(state.lastSequenceNumberReceived, {
        sequenceNumber: state.lastSequenceNumberReceived
      });
    }

    const receivedAt = DateTime.fromISO(state.lastSequenceNumberReceivedAtUtc!);
    const processedDelayTime =
      state.lastSequenceNumberReceived === state.sequenceNumber
        ? DateTime.fromISO(state.sequenceNumberProcessedAtUtc!)
        : DateTime.now();

    const snss = snSyncStates.get(state.lastSequenceNumberReceived);
    snss!.receivedAt = receivedAt;
    snss!.status = "Dequeued";
    snss!.processingDelayMillis =
      processedDelayTime.valueOf() - receivedAt.valueOf();
  }

  if (state.sequenceNumber) {
    if (!snSyncStates.has(state.sequenceNumber)) {
      snSyncStates.set(state.sequenceNumber, {
        sequenceNumber: state.sequenceNumber
      });
    }

    const snss = snSyncStates.get(state.sequenceNumber);
    snss!.processedAt = DateTime.fromISO(state.sequenceNumberProcessedAtUtc!);
    snss!.status = "Processed";
  }

  return [...snSyncStates.values()].reverse();
}
