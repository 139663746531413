import {
  DefaultButton,
  Heading,
  IconButton,
  IModalProps,
  Modal,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import { IdentifierHistoryTable } from "./IdentifierHistoryTable";
import { useFieldDeviceIdentifierHistory } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { FieldDeviceIdentifier } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface IdentifierHistoryModalProps extends IModalProps {
  show: boolean;
  identifier: FieldDeviceIdentifier;
  onDismiss: () => void;
}

export const IdentifierHistoryModal: React.FC<IdentifierHistoryModalProps> = ({
  identifier,
  show,
  onDismiss,
  ...props
}) => {
  const theme = useTheme();

  const { data, isLoading, error } = useFieldDeviceIdentifierHistory(
    identifier.fieldDeviceId!,
    identifier.identifierType!
  );

  const close = () => {
    onDismiss();
  };

  return (
    <Modal isOpen={show} {...props}>
      <Stack
        verticalFill
        styles={{
          root: {
            padding: theme.spacing.l1,
            overflow: "auto",
            maxHeight: 600
          }
        }}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Heading variant="modal-heading">
            Identifier History: {identifier.identifierType}
          </Heading>
          <IconButton onClick={close} iconProps={{ iconName: "Cancel" }} />
        </Stack>

        <Stack verticalFill>
          <IdentifierHistoryTable
            items={data ?? []}
            setKey="identifierHistory-Table"
            enableShimmer={isLoading}
            errorMessage={error?.message}
          />
        </Stack>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <DefaultButton onClick={close}>Close</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
