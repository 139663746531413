import { useMemo, useState } from "react";

import {
  DetailsRow,
  IColumn,
  IconButton,
  ShimmeredDetailsListProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { ShimmeredDetailsList } from "@components/tables/ShimmeredDetailsList";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { SelectorDto } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { useUpdateSelectorDeviceCount } from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { EditSelectorDialog } from "./dialogs/EditSelectorDialog";
import { SelectorRules } from "./SelectorRules";

export interface SelectorsTableProps extends ShimmeredDetailsListProps {}

export const SelectorsTable = ({ ...props }: SelectorsTableProps) => {
  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "name",
        name: "Name",
        minWidth: 150,
        maxWidth: 300,
        isResizable: true,
        onRender: (selector: SelectorDto) => (
          <Stack
            wrap
            styles={{
              root: { whiteSpace: "initial" }
            }}
          >
            <Text>{selector.name}</Text>
            <Text variant="small">Description: {selector.description}</Text>
          </Stack>
        )
      },
      {
        key: "rules",
        name: "Rules",
        minWidth: 100,
        maxWidth: 600,
        isResizable: true,
        onRender: (selector: SelectorDto) => (
          <SelectorRules selector={selector} />
        )
      },
      {
        key: "created",
        name: "Created",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onRender: (selector: SelectorDto) =>
          DateTime.fromISO(selector.changeLog?.createdDate)?.toFormat(
            DATE_FORMATS.LONG_DATE_TIME_FORMAT
          )
      },
      {
        key: "updated",
        name: "Last Updated",
        minWidth: 150,

        isResizable: true,
        onRender: (selector: SelectorDto) =>
          DateTime.fromISO(selector.changeLog?.updatedDate)?.toFormat(
            DATE_FORMATS.LONG_DATE_TIME_FORMAT
          )
      },
      {
        key: "deviceCount",
        name: "Device Count",
        minWidth: 150,

        isResizable: true,
        onRender: (selector: SelectorDto) => selector.deviceCount
      },
      {
        key: "deviceCountRefresh",
        name: "",
        minWidth: 30,
        maxWidth: 30,
        onRender: (selector: SelectorDto) => (
          <DeviceCountRefreshButton {...selector} />
        ),
        sort: false
      },
      {
        key: "edit",
        name: "",
        minWidth: 30,
        maxWidth: 30,
        onRender: (selector: SelectorDto) => (
          <SelectorEditButton {...selector} />
        ),
        sort: false
      }
    ];
  }, []);

  return (
    <ShimmeredDetailsList
      shimmerLines={5}
      columns={columns}
      onShouldVirtualize={() => true}
      onRenderRow={row => (row ? <DetailsRow {...row} /> : null)}
      {...props}
    />
  );
};

const SelectorEditButton = (selector: SelectorDto) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [formError, setFormError] = useState<HttpError | null>(null);
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  const onEditSelectorDismiss = () => {
    setShowEdit(false);
    setFormError(null);
  };
  return (
    <>
      <IconButton
        onClick={() => setShowEdit(true)} // Edit selectors
        styles={{
          root: {
            background: "transparent"
          }
        }}
        iconProps={{ iconName: "Edit" }}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      />
      <EditSelectorDialog
        hidden={!showEdit}
        onDismiss={onEditSelectorDismiss}
        selector={selector}
        formError={formError}
        setFormError={setFormError}
      />
    </>
  );
};

const DeviceCountRefreshButton = (selector: SelectorDto) => {
  const theme = useTheme();
  const {
    mutateAsync: updateSelectorDeviceCount
  } = useUpdateSelectorDeviceCount();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );
  return (
    <>
      <IconButton
        onClick={() => updateSelectorDeviceCount(selector)}
        styles={{
          root: {
            color: theme.palette.white,
            backgroundColor: theme.palette.themePrimary
          },
          rootHovered: {
            backgroundColor: theme.palette.themeSecondary,
            color: theme.palette.white
          }
        }}
        iconProps={{ iconName: "Refresh" }}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      />
    </>
  );
};
