import { IButtonStyles, mergeStyles, TileStyles } from "@bps/fluent-ui";

export const commandButton: Partial<IButtonStyles> = {
  root: {
    border: "none",
    selectors: {
      ".ms-Button-menuIcon": { display: "none" }
    },
    maxWidth: "105px",
    padding: "10px 6px"
  },
  textContainer: { paddingTop: 10, textAlign: "center" },
  description: { fontSize: 14 },
  flexContainer: { flexDirection: "column-reverse", alignItems: "center" }
};

export const iconClass = mergeStyles({
  fontSize: 40
});

export const tileStyles: Partial<TileStyles> = {
  root: {
    display: "flex",
    flexDirection: "row",
    flex: 1
  },
  content: {
    display: "flex",
    flex: 1
  }
};
