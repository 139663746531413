import { Country } from "@libs/api/gateways/plt/plt-gateway.dtos";

export enum Currencies {
  Australia = "AUD",
  NewZealand = "NZD"
}

enum Locales {
  NZ = "en-NZ",
  AU = "en-AU"
}

export const currencyCode = (country?: Country) => {
  return country === Country.NewZealand
    ? Currencies.NewZealand
    : Currencies.Australia;
};

export const currencyFormat = (value: number, country: Country) => {
  const locales = country ? Locales[country] : Locales.AU;
  const currency = currencyCode(country);

  return new Intl.NumberFormat(locales, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 2
  }).format(value);
};
