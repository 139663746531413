import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormProvider, useForm } from "react-hook-form";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  getTheme,
  IDialogProps,
  Stack
} from "@bps/fluent-ui";
import { CustomerAccountUser } from "@libs/api/gateways/cam/cam-gateway.dtos";

interface Props extends Pick<IDialogProps, "onDismiss"> {
  pltUser: CustomerAccountUser | undefined;
  onSave?: () => void;
}

export const UserModal = ({ pltUser: initialUser, onDismiss }: Props) => {
  const theme = getTheme();

  const form = useForm<CustomerAccountUser>({
    mode: "onChange",
    defaultValues: initialUser
  });

  const handleSubmit = async () => {
    onDismiss?.();
  };

  const handleCancel = () => {
    form.reset();
    onDismiss?.();
  };

  return (
    <FormProvider<CustomerAccountUser> {...form}>
      <Dialog
        dialogContentProps={{
          title: "Customer Account User",
          showCloseButton: true,
          onDismiss
        }}
        hidden={false}
      >
        <Stack
          as="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          verticalFill
          tokens={{ childrenGap: theme.spacing.l2 }}
        >
          <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <FormTextField name="firstName" label="First Name" />
              <FormTextField name="lastName" label="Last Name" />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <FormTextField name="crmEmail" label="CRM Email" />
              <FormTextField name="status" label="Status" />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s2 }}>
              <FormTextField name="tenantUsername" label="Tenant Username" />
            </Stack>
            <Stack>
              <FormSwitch
                name="isInactive"
                label="Inactive"
                data-test="switch-inactive"
              />
              <FormTextField name="crmRoles" label="CRM Roles" multiline />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <FormTextField name="crmContactIt" label="CRM Contact ID" />
              <FormTextField name="tenantId" label="Tenant ID" />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
              <FormTextField name="tenantUserId" label="Tenant User ID" />
              <FormTextField name="bpIdUserId" label="BP ID User ID" />
            </Stack>
          </Stack>

          <DialogFooter>
            <DefaultButton
              text="Cancel"
              onClick={handleCancel}
              data-test="cancel-button"
            />
          </DialogFooter>
        </Stack>
      </Dialog>
    </FormProvider>
  );
};
