import { PropsWithChildren } from "react";

import { FilterBar, Item } from "@bps/fluent-ui";

export interface PropertiesFilterValues {
  name: string;
}

export const PropertiesFilter = ({ children }: PropsWithChildren<any>) => {
  const filterItems: Item<PropertiesFilterValues>[] = [
    {
      name: "name",
      type: "searchBox",
      stickItem: true,
      props: { placeholder: "Search by name", removeSpecialCharacters: false }
    }
  ];

  return (
    <FilterBar<PropertiesFilterValues>
      items={filterItems}
      initialValues={{
        name: ""
      }}
    >
      {children}
    </FilterBar>
  );
};
