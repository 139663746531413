import React, { FunctionComponent } from "react";
import { Link, noWrap, Text } from "@bps/fluent-ui";
import { stripeAccountBaseUrl } from "@libs/stripe/stripe.utils";

interface LatestInvoiceLinkProps {
  latestInvoiceId: string | undefined;
}
export const LatestInvoiceLink: FunctionComponent<LatestInvoiceLinkProps> = ({
  latestInvoiceId
}) => {
  if (!latestInvoiceId) return <Text>-</Text>;
  return (
    <Link
      href={`${stripeAccountBaseUrl()}invoices/${latestInvoiceId}`}
      target="_blank"
      onClick={evt => evt.stopPropagation()}
      styles={{ root: noWrap }}
    >
      {latestInvoiceId}
    </Link>
  );
};
