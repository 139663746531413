import "@syncfusion/ej2-diagrams/styles/material.css";

import { withPermissions } from "@components/withPermissions";
import { Permissions } from "@libs/permissions/permissions.enum";
import { FeatureGraphNode } from "modules/system/sections/plt/feature/FeatureGraphNode";
import { FeatureGraphPanel } from "modules/system/sections/plt/feature/FeatureGraphPanel";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import {
  Dropdown,
  IconButton,
  mergeStyles,
  Stack,
  Toggle,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { DataManager } from "@syncfusion/ej2-data";
import {
  ComplexHierarchicalTree,
  ConnectionPointOrigin,
  Connector,
  DataBinding,
  Diagram,
  DiagramComponent,
  DiagramTools,
  Inject,
  LineDistribution,
  Node
} from "@syncfusion/ej2-react-diagrams";
import { useFeature } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Feature } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface FeatureNode extends Feature {
  children: string[];
}

const parseFeatureData = (
  data: Feature[],
  filter?: string[]
): FeatureNode[] => {
  let _fd = [...data] as any[];
  for (const feature of _fd) {
    if (feature.dependencies) {
      for (const depCode of feature.dependencies) {
        let index = _fd.findIndex(x => x.code === depCode);
        if (index === undefined || index < 0) {
          continue;
        }
        if (!_fd[index]) {
          const parent = _fd.find(x => x.code === depCode);
          index = _fd.length;
          if (parent) {
            _fd.push(parent);
          }
        }

        if (!_fd[index].children) {
          _fd[index].children = [];
        }
        _fd[index].children.push(feature.code);
      }
    }
  }
  _fd = _fd.filter(feature =>
    filter
      ? filter.includes(feature.code) ||
        filter.filter(code => feature.children?.includes(code)).length
      : true
  );
  return _fd;
};

const getConnectorLabel = (connector: Connector, diagram: Diagram) => {
  const source = diagram.getNodeObject(connector.sourceID);
  const target = diagram.getNodeObject(connector.targetID);
  const origin = (source.data as Feature)?.featureDependencies?.find(
    x => x.dependentFeatureCode === (target.data as Feature).code
  );
  return origin?.source;
};

interface FeatureGraphProps {
  onlyFeatures?: string[];
}

const FeatureGraphBase: React.FC<FeatureGraphProps> = ({ onlyFeatures }) => {
  const diagramInstance = useRef<DiagramComponent>(null);
  const theme = useTheme();

  const [filterSource, setFilterSource] = useState<string>();
  const [filterActive, setFilterActive] = useState<boolean>();
  const [selectedNode, setSelectedNode] = useState<FeatureNode>();
  const { data: featureGraphData } = useFeature({
    source: filterSource,
    isActive: filterActive ? true : undefined
  });

  useEffect(() => {
    if (filterSource === "CLEAR") {
      setFilterSource(undefined);
    }
  }, [filterSource]);

  const dataSource = useMemo(
    () =>
      new DataManager(parseFeatureData(featureGraphData || [], onlyFeatures)),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [featureGraphData]
  );

  const highlightConnectors = useCallback(
    (id: string, on: boolean) => {
      const node = diagramInstance.current?.getNodeObject(id);
      const code = (node?.data as Feature)?.code;
      if (!code || !diagramInstance.current?.connectors) {
        return;
      }
      for (const connector of diagramInstance.current?.connectors) {
        const line = document.querySelector<SVGElement>(
          `#${connector.id}_path`
        );

        const width = on ? "3" : "2";
        const color = on
          ? theme.semanticColors.primaryButtonBackground
          : "#6d6d6d";
        let highlight = false;
        if (connector.sourceID) {
          const source = diagramInstance.current?.getNodeObject(
            connector.sourceID
          );
          if ((source?.data as Feature).code === code) {
            highlight = true;
          }
        }
        if (connector.targetID) {
          const target = diagramInstance.current?.getNodeObject(
            connector.targetID
          );
          if ((target?.data as Feature).code === code) {
            highlight = true;
          }
        }
        if (highlight) {
          line?.setAttribute("stroke-width", width);
          line?.setAttribute("stroke", color);
        }
      }
    },
    [diagramInstance, theme]
  );

  const connectorRender = props => {
    return (
      <Stack horizontalAlign="center">
        <TooltipHost content="">
          <Stack
            styles={{
              root: {
                padding: "2px 4px",
                display: "inline-block",
                background: theme.semanticColors.bodyBackground,
                textAlign: "center",
                border: "1px solid black",
                "&:hover": {
                  background: theme.semanticColors.listItemBackgroundHovered
                }
              }
            }}
          >
            {props.addInfo.label}
          </Stack>
        </TooltipHost>
      </Stack>
    );
  };

  const renderFilters = () => (
    <Stack horizontal horizontalAlign="space-between">
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: theme.spacing.m }}
      >
        <Dropdown
          placeholder="Source"
          name="source-dropdown"
          onChange={(_e, item) => setFilterSource(String(item?.key))}
          options={[
            { key: "PROS", text: "PROS" },
            { key: "TI", text: "TI" },
            { key: "BH", text: "BH" },
            { key: "CLEAR", text: "Clear" }
          ]}
        />
        <Toggle
          label="Only Active"
          automationAttribute="only-active-toggle"
          inlineLabel
          styles={{ root: { margin: 0 } }}
          onChange={(e, value) => setFilterActive(value)}
        />
      </Stack>
      <Stack horizontal>
        <IconButton
          iconProps={{ iconName: "ZoomIn" }}
          onClick={() =>
            diagramInstance?.current?.zoomTo({
              type: "ZoomIn",
              zoomFactor: 0.2
            })
          }
        />
        <IconButton
          iconProps={{ iconName: "ZoomOut" }}
          onClick={() =>
            diagramInstance?.current?.zoomTo({
              type: "ZoomOut",
              zoomFactor: 0.2
            })
          }
        />
      </Stack>
    </Stack>
  );

  return (
    <>
      <FeatureGraphPanel
        hidden={typeof selectedNode == "undefined"}
        onDismiss={() => setSelectedNode(undefined)}
        node={selectedNode}
      />

      <Stack
        verticalFill
        tokens={{ childrenGap: theme.spacing.m }}
        styles={{ root: { overflow: "hidden" } }}
      >
        {renderFilters()}
        <Stack verticalFill>
          <DiagramComponent
            id="diagram"
            ref={diagramInstance}
            width="100%"
            height="100%"
            className={mergeStyles({
              "& > #diagramcontent": { cursor: "default !important" }
            })}
            layout={{
              type: "ComplexHierarchicalTree",
              connectionPointOrigin: ConnectionPointOrigin.DifferentPoint,
              horizontalSpacing: 200,
              verticalSpacing: 90,
              orientation: "RightToLeft",
              springFactor: 4
            }}
            getNodeDefaults={(obj: Node) => {
              obj.width = 400;
              obj.height = 170;
              obj.shape = {
                type: "HTML"
              };
            }}
            getConnectorDefaults={(connector: Connector, diagram: Diagram) => {
              connector.type = "Orthogonal";
              connector.style.strokeWidth = 1;
              connector.style.strokeColor = "#6d6d6d";
              const label = getConnectorLabel(connector, diagram);
              if (label) {
                connector.annotations = [
                  { annotationType: "Template", addInfo: { label } }
                ];
              }
            }}
            nodeTemplate={props => (
              <FeatureGraphNode
                isSelected={selectedNode?.id === props?.data?.id}
                highlightConnectors={highlightConnectors}
                setSelectedNode={setSelectedNode}
                {...props}
              />
            )}
            annotationTemplate={connectorRender}
            dataSourceSettings={{
              id: "code",
              parentId: "children",
              dataSource
            }}
            tool={DiagramTools.ZoomPan}
            snapSettings={{ constraints: 0 }}
            getCustomCursor="pointer"
            customCursor={[{ action: "Draw", cursor: "default" }]}
          >
            <Inject
              services={[
                DataBinding,
                ComplexHierarchicalTree,
                LineDistribution
              ]}
            />
          </DiagramComponent>
        </Stack>
      </Stack>
    </>
  );
};

export const FeatureGraph = withPermissions(
  FeatureGraphBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
