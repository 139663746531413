import { CallbackDto } from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { FunctionComponent } from "react";

import {
  FilterBar,
  FilterBarProps,
  Item,
  PresetButtonType,
  useTheme
} from "@bps/fluent-ui";

export interface CallbacksFilterBarValues {
  key: string;
  isEnabled: boolean;
}
interface CallbacksFilterBarProps extends Pick<FilterBarProps, "children"> {
  callbacks: CallbackDto[];
}
export const CallbacksFilterBar: FunctionComponent<CallbacksFilterBarProps> = ({
  children
}) => {
  const theme = useTheme();

  const items: Item<CallbacksFilterBarValues>[] = [
    {
      type: "searchBox",
      name: "key",
      stickItem: true,
      props: { placeholder: "Search by key", removeSpecialCharacters: false }
    }
  ];

  const presets: PresetButtonType<CallbacksFilterBarValues>[] = [
    {
      name: "isEnabled",
      text: "Disabled",
      iconName: "ChangeIsInActiveButton",
      iconColor: theme.semanticColors.focusBorder
    }
  ];
  return (
    <FilterBar<CallbacksFilterBarValues>
      initialValues={{
        key: "",
        isEnabled: true
      }}
      children={children}
      items={items}
      presets={presets}
    />
  );
};
