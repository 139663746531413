import { Permissions } from "@libs/permissions/permissions.enum";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";

import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { useState } from "react";

import { DefaultButton } from "@bps/fluent-ui";

import { TenantIsTemporaryChangeDialog } from "./TenantIsTemporaryChangeDialog";
import { useUpdateTenantIsTemporary } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";

export const ChangeIsTemporaryButton = (tenant: Tenant) => {
  const [updateTenant, setUpdateTenant] = useState<boolean>(false);

  const updateMutation = useUpdateTenantIsTemporary();
  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogOpsWrite
  );

  const changeIsTemporaryToValue = (tenant.isTemporary || false) === false;

  const modeText = changeIsTemporaryToValue
    ? "Mark as Temporary"
    : "Remove Temporary status";

  return (
    <>
      <TenantIsTemporaryChangeDialog
        hidden={!updateTenant}
        tenantName={tenant.name}
        changeIsTemporaryToValue={changeIsTemporaryToValue}
        onChange={async () =>
          await updateMutation.mutateAsync({
            tenantId: tenant.id,
            isTemporary: changeIsTemporaryToValue
          })
        }
        onCancel={() => setUpdateTenant(false)}
      />

      <DefaultButton
        text={modeText}
        onClick={() => setUpdateTenant(true)}
        styles={{ root: { width: "fit-content" } }}
        disabled={!hasWritePermission}
        title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
      />
    </>
  );
};
