import React, { FunctionComponent } from "react";
import { NoDataTile, Separator, Stack } from "@bps/fluent-ui";
import { PltUser } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { UserSignInLogsFilter } from "./UserSignInLogsFilter";
import { UserSignInLogsList } from "./UserSignInLogsList";

interface UserSignInLogsProps {
  user: PltUser;
}

export const UserSignInLogs: FunctionComponent<UserSignInLogsProps> = props => {
  return (
    <Stack>
      <Separator />
      {props.user.bpIdUserId ? (
        <UserSignInLogsFilter>
          {({ values }) => (
            <UserSignInLogsList
              userId={props.user.bpIdUserId}
              startDate={values.startDate}
              endDate={values.endDate}
            />
          )}
        </UserSignInLogsFilter>
      ) : (
        <NoDataTile
          styles={{ root: { flexGrow: 1 } }}
          textProps={{ text: "User does not have Bp Id account" }}
          linkProps={{}}
          showBoxShadow={false}
        />
      )}
    </Stack>
  );
};
