import React, { useState } from "react";

import { SectionTitle } from "@components/SectionTitle";
import { withPermissions } from "@components/withPermissions";
import { useProsBannerMutation } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import {
  BannerForm,
  BannerFormOnSubmit
} from "../../../../../_shared-blocks/BannerForm";

const ProsBannerBase: React.FC = () => {
  const mutation = useProsBannerMutation();
  const [isLoading, setIsLoading] = useState<boolean>();

  const onSubmit: BannerFormOnSubmit = async () => {
    setIsLoading(true);
    try {
      await mutation.mutate("");
    } catch (e) {}
    setIsLoading(false);
  };

  return (
    <>
      <SectionTitle>Banner</SectionTitle>
      <BannerForm onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};

export const ProsBanner = withPermissions(
  ProsBannerBase,
  [Permissions.PltCatalogOpsRead, Permissions.PltCatalogOpsWrite],
  "or"
);
